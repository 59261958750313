import * as SHADOW from "./shadow";
import * as COLORS from "./colors";
import * as FONTS from "./fonts";
import * as BUTTONS from "./buttons";
import * as SPACING from "./spacing";
import * as ANIMATIONS from "./animations";
import * as E_READER_CONTENT from "./eReaderContent";
import * as SHOP_LAYOUT from "./shopLayout";
import * as Z_INDEX_LAYOUT from "./zIndexLayout";

const STYLE_DEFAULTS = {
  SHADOW,
  COLORS,
  FONTS,
  BUTTONS,
  SPACING,
  ANIMATIONS,
  E_READER_CONTENT,
  SHOP_LAYOUT,
  Z_INDEX_LAYOUT
};

export default STYLE_DEFAULTS;
