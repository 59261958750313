


export const trackButtonClickAction = (event : string, category : string, label : string, buttonName: string) => {
    track(event, {category: category, label : label, button_clicked : buttonName});
}

export const trackOperations = (event : string, category : string) => {
    track(event, {category: category});
}

export const trackSearch = (event : string, category : string, label : string, searchValue : string) => {
    track(event, {category: category??"", label : label??"", search_value : searchValue??""});
}

export const page = (page : string) => {
    if(window.analytics) {
        window.analytics.page(page);
    }
}

const track = (subject : string, data: Object) => {
    if (window.analytics) {
        window.analytics.track(subject??"", data);
    }
}