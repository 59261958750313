import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { generateProductItem } from "../../../services/segmentProvider/helper";
import TRACKING_EVENTS from "../../../services/segmentProvider/models/trackingEvents";
import { PositionMarkers } from "../layout/documentView/PositionMarkers";

const HtmlContent: React.FC<any> = (props: any) => {
  const { sectionId, html, isPreview } = props;
  // Track whether the content marker's like "amnd", new and dated markers are positioned or not for that component.
  const contentMarkersPositioned = useRef(false);
  const [elementList] = useState([] as any);
  const htmlRef = useRef(null as any);

  const sendSegmentData = (data: any, position: number) => {
    if (window.analytics) {
      window.analytics.track(
        TRACKING_EVENTS.READER.PRODUCT.event,
        // prettier-ignore
        generateProductItem({ designation: data.text }, position),
      );
    }
  };

  useEffect(() => {
    if (contentMarkersPositioned.current == false && isPreview === true) {
      PositionMarkers(document.getElementById("section-" + sectionId));
      contentMarkersPositioned.current = true;
    }
  }, [contentMarkersPositioned]);

  useEffect(() => {
    if (htmlRef.current && htmlRef.current.getElementsByTagName) {
      _.forEach(htmlRef.current.getElementsByTagName("a"), (element: any) => {
        // prettier-ignore
        if (element.className !== "sts-std-ref" && (!/\S/.test(element.text) || !/\/product\/.*/.test(element.pathname))) return;
        const onClick = () => sendSegmentData(element, elementList.length + 1);
        elementList.push({ data: element, onClick: onClick });
        element.addEventListener("click", onClick, false);
      });
    }

    return () => {
      _.forEach(elementList, (element: any) => element.data.removeEventListener("click", element.onClick, false));
    };
  }, []);

  return (
    <div
      ref={htmlRef}
      id={`section-${sectionId}`}
      style={{ position: "relative" }}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
};

export default React.memo(HtmlContent);
