import React, { useContext } from "react";
import * as ReaderHeaderControlsStyles from "../style";
import ICONS from "../../../../../constants/icons";
import Icon from "../../../../icons";
import { HeaderLink } from "./desktop/style";
import ReactTooltip from "react-tooltip";
import BookmarksStore from "./../../../../../stores/bookmarkStore";
import HighlightStore from "./../../../../../stores/highlightStore";
import { observer } from "mobx-react-lite";
import ReaderMenuIcon from "../../../controls/ReaderMenuIcon/ReaderMenuIcon";
import TRACKING_EVENTS from "services/segmentProvider/models/trackingEvents";
import * as segmentUtils from "utils/segmentUtils";

const generateIcons = (BookmarksStoreContext: any, HighlightStoreContext: any, isOffline?: boolean) => {
  return [
    {
      icon: ICONS.HIGHLIGHT,
      disabledMessage: isOffline ? "The highlight and note tool is not<br/>available in offline mode. You need to<br/>be connected to the internet." : "The highlight and note tool is enabled<br/>on the paid version of this publication",
      displayInMobile: true,
      displayInDesktop: true,
      toggleMode: HighlightStoreContext.toggleHighlightMode,
      isModeActive: HighlightStoreContext.highlightModeActive,
      segmentPackage: TRACKING_EVENTS.READER.HIGHLIGHT,
    },
    {
      icon: ICONS.BOOKMARK,
      disabledMessage: isOffline ? "The bookmark tool is not available in<br/>offline mode. You need to be<br/>connected to the internet." : "The bookmark tool is enabled on the<br/>paid version of this publication",
      displayInMobile: true,
      displayInDesktop: true,
      toggleMode: BookmarksStoreContext.toggleBookmarkMode,
      isModeActive: BookmarksStoreContext.bookmarkModeActive,
      segmentPackage: TRACKING_EVENTS.READER.BOOKMARK,
    },
    // PRJPROD-1408 - Hide settings button (settings not implemented yet)
    // {
    //   icon: ICONS.SETTINGS,
    //   disabledMessage: "Render settings is enabled on the paid<br/>version of this publication",
    //   displayInMobile: true,
    //   displayInDesktop: false,
    // },
  ];
};

const ReaderHeaderControls: React.FC<any> = observer((props: any) => {
  const { isDisabled, isOffline } = props;
  let { isMobile } = props;
  const BookmarksStoreContext = useContext(BookmarksStore);
  const HighlightStoreContext = useContext(HighlightStore);
  const icons = generateIcons(BookmarksStoreContext, HighlightStoreContext, isOffline);

  isMobile = isMobile ?? false;
  const iconsElements = icons.filter((data) => data.displayInDesktop !== isMobile || data.displayInMobile === isMobile);
  const content = iconsElements.map((data: any, index: number) => (
    <div key={index}>
      <a data-tip={data.disabledMessage} data-for={`header-menu-tooltip-${index}`}>
        <ReaderMenuIcon
          segmentPackage={data.segmentPackage}
          icon={data.icon}
          isMobile={isMobile}
          isMenuItemActive={data.isModeActive}
          isLoggedInMenu={false}
          isSideMenu={false}
          isDisabled={isDisabled}
          onClick={() => {
            if (isDisabled) {
              return;
            }
            data.toggleMode && data.toggleMode();
            // Do not allow more than one mode active at once
            const otherIcons = iconsElements.filter((data, iconIndex) => iconIndex !== index);
            otherIcons.map((otherIconData) => {
              if (otherIconData.isModeActive) {
                otherIconData.toggleMode();
              }
            });

            segmentUtils.trackButtonClickAction(iconsElements[index].segmentPackage.event, iconsElements[index].segmentPackage.category, iconsElements[index].segmentPackage.eventName, iconsElements[index].segmentPackage.eventName);
          }}
        />
      </a>
      {isDisabled && (
        <ReactTooltip
          id={`header-menu-tooltip-${index}`}
          place="bottom"
          type="dark"
          effect="solid"
          html
          delayHide={0}
        />
      )}
    </div>
  ));

  return (
    <ReaderHeaderControlsStyles.ReaderHeaderControls>
      <HeaderLink></HeaderLink>
      {content}
    </ReaderHeaderControlsStyles.ReaderHeaderControls>
  );
});

export default ReaderHeaderControls;
