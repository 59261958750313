import styled from "styled-components";
import STYLE_DEFAULTS from "../../../constants/styles";
import { onHoverStateTransition } from "../../../styles/global";

interface IProps {
  isMobile: boolean;
  isActive: boolean;
  isShopIcon: boolean;
}

export const LoggedInIconRing = styled.div`
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  ${(props: IProps) => {
    let styles = "";

    if (props.isShopIcon) {
      const size = props.isMobile ? "20px" : "24px";

      styles += `
        width: ${size};
        height: ${size};
        border: 2px solid ${
          props.isActive ? STYLE_DEFAULTS.COLORS.SA_WHITE_B000 : STYLE_DEFAULTS.COLORS.SA_INVERTED_DEFAULT
        };
        color:  ${props.isActive ? STYLE_DEFAULTS.COLORS.SA_WHITE_B000 : STYLE_DEFAULTS.COLORS.SA_INVERTED_DEFAULT};

        :hover {
            border: 2px solid ${STYLE_DEFAULTS.COLORS.SA_WHITE_B000};
            color: ${STYLE_DEFAULTS.COLORS.SA_WHITE_B000};
            ${onHoverStateTransition};
        }

        :focus,
        :active {
            border: 2px solid ${STYLE_DEFAULTS.COLORS.SA_WHITE_B000};
            color: ${STYLE_DEFAULTS.COLORS.SA_WHITE_B000};
            ${onHoverStateTransition};
        }
        `;
    } else {
      styles += `
        width: 24px;
        height: 24px;
        border: 2px solid ${props.isActive ? STYLE_DEFAULTS.COLORS.SA_ORANGE_ACTIVE : STYLE_DEFAULTS.COLORS.SA_B070};
        color: ${props.isActive ? STYLE_DEFAULTS.COLORS.SA_ORANGE_ACTIVE : STYLE_DEFAULTS.COLORS.SA_B070};

        :hover {
            border: 2px solid ${STYLE_DEFAULTS.COLORS.SA_ORANGE_ACTIVE};
            color: ${STYLE_DEFAULTS.COLORS.SA_ORANGE_ACTIVE};
            ${onHoverStateTransition};
        }

        :focus,
        :active {
            border: 2px solid ${STYLE_DEFAULTS.COLORS.SA_ORANGE_ACTIVE};
            color: ${STYLE_DEFAULTS.COLORS.SA_ORANGE_ACTIVE};
            ${onHoverStateTransition};
        }
        `;
    }

    return styles;
  }}
`;

export const LoggedInIconInitials = styled.div`
  ${(props: IProps) => {
    let styles = "";

    if (props.isShopIcon) {
      styles += `
        font-size: ${props.isMobile ? "10px" : "12px"}; 
        font-family: ${
          props.isMobile
            ? STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.NOTO_SANS
            : STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.HELVETICA_NEUE_MEDIUM
        };
        font-weight: ${
          props.isMobile ? STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.SEMIBOLD : STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.MEDIUM
        };
        `;
    } else {
      // font size the same for desktop and mobile
      styles += `
        font-size: 13px; 
        font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.NOTO_SANS};
        font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.SEMIBOLD};
        `;
    }

    return styles;
  }}
`;
