import SumoLogger from "sumo-logger";
import env from "models/Configuration";
import { Exception } from "models/Exception";

const opts = {
    endpoint: env?.SumoLogic.Uri
};

const sumoLogger = new SumoLogger(opts);

export const log = (message : string) => {
    sumoLogger.log(message);
};


export const logException = (ex : Exception | unknown) => {
    const exception = ex as Exception;
    let message = `Request ${exception.config?.baseURL}${exception.config?.url} fails with message ${exception.message}`;
    log(message);
}