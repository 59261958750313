import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import React from 'react';
import { RootState } from '../store';
import { MouseEvent, ChangeEvent, useState } from "react";

export interface CustomModalState {
   isOpen: boolean;
   headerIcon?: React.ReactElement;
   title: string;
   content?: React.ReactElement | string;
   top?: string;
   left?: string;
   right?: string;
   bottom?: string;

   isAcceptButtonEnabled?: boolean;
   acceptButtonTitle?: string;

   isCancelButtonEnabled?: boolean;
   cancelButtonTitle?: string;

   isCloseButtonEnabled?: boolean,

   /* Number indicating the milliseconds to wait before closing the modal. Defaults to zero (no timeout). */
   closeTimeoutMS?: number;

   /* Boolean indicating if the overlay should close the modal. Defaults to true. */
   shouldCloseOnOverlayClick?: boolean;

   /* Boolean indicating if pressing the esc key should close the modal */
   shouldCloseOnEsc?: boolean;

   onAcceptButtonClick?: (e: MouseEvent<HTMLButtonElement>) => void;
   onCancelButtonClick?: (e: MouseEvent<HTMLButtonElement>) => void;

   /* Function that will be run after the modal has closed. */
   onClose?: () => void;

}

const initialState: CustomModalState = {
   isOpen: false,
   headerIcon : undefined,
   title: "",
   content: undefined,

   isAcceptButtonEnabled: false,
   acceptButtonTitle: "Ok",
   isCancelButtonEnabled: false,
   cancelButtonTitle: "Cancel",
   isCloseButtonEnabled: true,
   closeTimeoutMS: undefined,
   shouldCloseOnOverlayClick: false,
   shouldCloseOnEsc: false,

   onAcceptButtonClick: undefined,
   onCancelButtonClick: undefined,
   onClose: undefined
};


export const customModalSlice = createSlice({
  name: 'customModal',
  initialState,

  reducers: {
    openModal: (state, action: PayloadAction<CustomModalState>) => {
      return {...state, ...action.payload, isOpen: true}
    },
    closeModal: () => {
      return initialState;
    }
  }
});

export const { openModal, closeModal } = customModalSlice.actions;

export const customModalState = (state: RootState) => state.customModal;

export default customModalSlice.reducer;
