import React from "react";
import { StatusContainer } from "./style";

interface IStatusLabel {
  label: string;
  color?: string;
}

const StatusLabel = (props: IStatusLabel) => {
  const { label, color } = props;

  return <StatusContainer color={color}>{label}</StatusContainer>;
};

export default StatusLabel;
