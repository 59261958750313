import React, { Fragment } from "react";
import { LoadingContainer, MoreInfoContainer } from "./style";
import STYLE_DEFAULTS from "../../constants/styles";

export function Loader(props: any): React.ReactElement {
  const { color, height, width, moreInfo, grey } = props;
  let { border } = props;

  if (!border && height && width) {
    const average = (parseInt(height, 10) + parseInt(width, 10)) / 2;
    //needs to be 17.6% as that's the sweet spot for the animation border
    //any smaller/bigger and it would overlap
    border = average * 0.176 + "px";
  }

  return (
    <Fragment>
      <LoadingContainer
        color={color || STYLE_DEFAULTS.COLORS.SA_ORANGE_ACTIVE}
        borderColor={grey ? STYLE_DEFAULTS.COLORS.SA_B013_3 : ""}
        height={height || "44px"}
        width={width || "44px"}
        border={border || "8px"}
        removeBeforeAfter={props.removeBeforeAfter}
      />
      {moreInfo && (
        <MoreInfoContainer>
          <h3>Loading your experience</h3>
        </MoreInfoContainer>
      )}
    </Fragment>
  );
}

export default Loader;
