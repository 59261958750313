import styled from "styled-components";
import STYLE_DEFAULTS from "../../../../constants/styles";
import { MEDIA_QUERIES } from "../../../../constants/mediaQueries";
import { P_SMALLER } from "../../../../styles/typography";
import { onHoverStateTransition } from "../../../../styles/global";

interface IProps {
  isMobile?: boolean;
  isAddButton?: boolean;
}

export const CommentModalContainer = styled.div`
  position: fixed;
  top: 25%;
  left: calc(50% - 192px);
  z-index: ${STYLE_DEFAULTS.Z_INDEX_LAYOUT.BODY_MODAL};
  background-color: ${STYLE_DEFAULTS.COLORS.SA_B010};
  -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
  width: 384px;

  @media ${MEDIA_QUERIES.SM} {
    width: 100%;
    left: 0;
  }
`;

export const CommentModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_5} ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_3}
    ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_3} ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_3};
  border-bottom: 1px solid ${STYLE_DEFAULTS.COLORS.SA_B015};

  @media ${MEDIA_QUERIES.SM} {
    display: block;
    text-align: center;
    position: relative;
  }
`;

export const CommentModalTitle = styled.div`
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.NOTO_SANS};
  font-size: ${STYLE_DEFAULTS.FONTS.SA_FONT_SIZE.SMALL};
  font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.MEDIUM};
  line-height: 1.5;
  color: ${STYLE_DEFAULTS.COLORS.SA_B070};

  @media ${MEDIA_QUERIES.SM} {
    font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.HELVETICA_NEUE};
    font-size: 21px; // relevant for mobile only
    font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.NORMAL};
    line-height: 1.25;
    color: ${STYLE_DEFAULTS.COLORS.SA_BLACK_B100};
  }
`;

export const MobileCloseIcon = styled.div`
  position: absolute;
  right: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_2};
  top: 0;
`;

export const CommentModalContent = styled.div`
  padding: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_3};
  max-height: 323px;
  overflow-y: auto;

  @media ${MEDIA_QUERIES.SM} {
    padding: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_2} ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_2}
      ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_3} ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_2};
  }
`;

export const CommentModalButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_3};

  ${(props: IProps) => {
    if (props.isAddButton) {
      return `padding: 15px ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_3} ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_2} 0;`;
    }
  }}

  @media ${MEDIA_QUERIES.SM} {
    padding-left: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_2};
  }
`;

export const CommonButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & > :nth-child(2) {
    margin-left: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_3};
  }
`;

export const CommentDeleteButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.HELVETICA_NEUE};
  font-size: ${STYLE_DEFAULTS.FONTS.SA_FONT_SIZE.NORMAL};
  letter-spacing: 0.53px;
  color: ${STYLE_DEFAULTS.COLORS.SA_RED};

  #icon {
    margin-right: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_2};
  }

  :hover {
    cursor: pointer;
    ${onHoverStateTransition};
  }
`;

export const SingleCommentContainer = styled.div`
  padding-bottom: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_4};
  border-bottom: 1px solid ${STYLE_DEFAULTS.COLORS.SA_B015};
`;

export const CommentContent = styled.div`
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.NOTO_SANS};
  font-size: ${STYLE_DEFAULTS.FONTS.SA_FONT_SIZE.NORMAL};
  line-height: 1.5;
  color: ${STYLE_DEFAULTS.COLORS.SA_BLACK_B100};
  padding: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_3};
`;

export const CommentButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const TextAreaContainer = styled.div`
  width: 100%;
  height: 120px;

  @media ${MEDIA_QUERIES.SM} {
    height: 72px;
  }
`;

export const RemainingCharactersCounter = styled(P_SMALLER)`
  text-align: right;
  color: ${STYLE_DEFAULTS.COLORS.SA_B070};

  span {
    margin-right: 0.3rem;
  }
`;
