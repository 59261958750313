import React, { useContext, useRef, useEffect, useState } from "react";
import {
  DocumentViewPanel,
  DocumentViewContainer,
  LoaderContainer,
  DocumentViewSection,
  DocumentViewAlert,
  DocumentViewAlertContainer,
  EReaderContent,
} from "./style";
import { observer } from "mobx-react-lite";
import CONTENT_SEARCH_DEFAULTS from "../../../../constants/contentSearch";
import { LOADING_STATUS } from "../../../../constants/loadingStatus";
import ReaderLayoutContext, { IReaderLayoutContext } from "./../readerLayoutContext";
import ReaderContentContext, {
  DEFAULT_SECTION_ID,
  IReaderContentContext,
} from "../../contentController/readerContentContext";
import Loader from "../../../loader/loader";
import { PreviewHeader, PreviewFooter } from "./DocumentPreview";
import BookmarksStore from "./../../../../stores/bookmarkStore";
import HighlightsStore from "./../../../../stores/highlightStore";
import UserStore from "../../../../stores/userStore";
import Alert from "../../../alerts/Alert";
import ALERT_TYPE from "../../../../constants/alerts";
import VizAwareContentController from "../../contentController/VizAwareContentController";
import COMMENT_MODAL_STATE from "../../../../constants/comments";
import HtmlContent from "../../contentController/HtmlContent";
import { closestElementDataIndexForTopOffset } from "../../../../helpers/reader";
import { OfflineHeader } from "./OfflineHeader";
import { Detector } from "react-detect-offline";

const renderBookmarkModeAlert = (bookmarkModeActive: boolean) => {
  if (bookmarkModeActive) {
    return (
      <DocumentViewAlertContainer>
        <DocumentViewAlert>
          <Alert
            type={ALERT_TYPE.INFO}
            title="You are in bookmark mode"
            message="Select the section to bookmark. To exit this mode, click again on the bookmark button on the toolbar."
          />
        </DocumentViewAlert>
      </DocumentViewAlertContainer>
    );
  }

  return null;
};

const renderHighlightModeAlert = (highlightModeActive: boolean) => {
  if (highlightModeActive) {
    return (
      <DocumentViewAlertContainer>
        <DocumentViewAlert>
          <Alert
            type={ALERT_TYPE.INFO}
            title="You are in highlight and note mode"
            message="Select the text to highlight and annotate. To exit this mode, click again on the highlight and note button on the toolbar."
          />
        </DocumentViewAlert>
      </DocumentViewAlertContainer>
    );
  }

  return null;
};

// seperated the conntent section component so only this compnent is in scope of re-render
const ReaderContentSections: React.FC = observer(() => {
  const { readerStore, isPreview } = useContext<IReaderLayoutContext>(ReaderLayoutContext);

  return (
    <div className="sts-standard" id={CONTENT_SEARCH_DEFAULTS.STS_STANDARD_CONTENT}>
      {readerStore.ReaderSections.content?.map((item: any, index: any) => {
        return isPreview ? (
          <HtmlContent key={item.id} sectionId={item.id} html={item.partialHtmlBody} isPreview={isPreview} />
        ) : (
          <VizAwareContentController key={item.id} section={item} isPreview={isPreview} />
        );
      })}
    </div>
  );
});

const DocumentView: React.FC = observer(() => {
  const { isSideMenuOpen, isMobile, readerStore, isPreview } = useContext<IReaderLayoutContext>(ReaderLayoutContext);
  const { DesignationId, ReaderSections } = readerStore;
  const firstUpdate = useRef(true);
  const divRef = React.useRef<HTMLDivElement>(null);
  const oldClosestElementDataIndex = useRef<string | null>(null);
  const stopListeningForScroll = useRef(false);
  const oldIsTocOpen = useRef(isSideMenuOpen);
  const oldIsMobile = useRef(isMobile);
  const { bookmarkModeActive, getBookmarks } = useContext(BookmarksStore);
  const { highlightModeActive, getHighlights } = useContext(HighlightsStore);
  const authContext = useContext(UserStore);

  // Track bookmark delete/comment popup, keep track of section ID where bookmark toolbox is open
  const [sectionBookmarkToolboxId, setSectionBookmarkToolboxId] = useState(DEFAULT_SECTION_ID);

  // Same for highlight
  const [highlightToolboxOpenId, setHighlightToolboxOpenId] = useState(0);

  // Track create/edit/show comment window
  const [commentModalState, setCommentModalState] = useState(COMMENT_MODAL_STATE.CLOSED);

  // Set the element id of the section hovering over
  // NOTE: inFocusSection value might be different from the section id where clicked on bookmark icon,
  // if moved mouse after clicking the bookmark icon. Use sectionBookmarkToolboxId for the
  // id of the section where clicked on the bookmark icon last
  const [inFocusSection, setInFocusSection] = useState(DEFAULT_SECTION_ID);
  const [showOfflineHeader, setShowOfflineHeader] = useState(true);

  const documentContentContext: IReaderContentContext = {
    highlightToolboxOpenId,
    setHighlightToolboxOpenId,

    sectionBookmarkToolboxId,
    setSectionBookmarkToolboxId,

    commentModalState,
    setCommentModalState,

    inFocusSection,
    setInFocusSection,
  };

  const onScroll = () => {
    if (stopListeningForScroll.current === true) {
      return;
    }
    // When user scrolls, let's store the element data-index value where user is currently or close.
    setTimeout(() => {
      // When user scrolls and the section is in browser viewport we add the extra contrainers which can change the topOffset. That's why we will try to find the closed element after some timeout.
      oldClosestElementDataIndex.current = closestElementDataIndexForTopOffset(
        75,
        document.getElementById(CONTENT_SEARCH_DEFAULTS.READER_CONTENT),
      );
    }, 200);
  };

  const restoreScrollPosition = () => {
    stopListeningForScroll.current = true;
    if (divRef.current) {
      setTimeout(() => {
        const parent = document.getElementById(CONTENT_SEARCH_DEFAULTS.READER_CONTENT);
        const elementToScroll = parent?.querySelector(`[data-index='${oldClosestElementDataIndex.current}']`);
        elementToScroll?.scrollIntoView(true);

        // now account for fixed header
        window.scroll(0, 0);

        // Let's make sure scroll event is enabled again.
        setTimeout(() => {
          stopListeningForScroll.current = false;
        }, 100);
      }, 100);
    }
  };

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
  });

  useEffect(() => {
    window.addEventListener("resize", () => restoreScrollPosition(), false);
    return () => {
      window.removeEventListener("resize", () => restoreScrollPosition(), false);
    };
  }, []);

  async function getDocumentBookmarks(designationId: string) {
    await getBookmarks(designationId);
    await getHighlights(designationId);
  }

  useEffect(() => {
    if (DesignationId && !readerStore.ReaderSections.isPreview) { // && authContext.authId) {
      getDocumentBookmarks(DesignationId);
    }
  }, [DesignationId, readerStore.ReaderSections.isPreview, authContext.authId]);

  // prettier-ignore
  if ((oldIsTocOpen.current !== isSideMenuOpen && !isMobile) || oldIsMobile.current !== isMobile) {
    oldIsTocOpen.current = isSideMenuOpen;
    oldIsMobile.current = isMobile;
    restoreScrollPosition();
  }

  /* eslint-disable no-fallthrough */
  switch (ReaderSections.status) {
    /*Content loading*/
    case LOADING_STATUS.LOADING:
      return (
        <DocumentViewContainer>
          <LoaderContainer>
            <Loader height="60px" width="60px" moreInfo />
          </LoaderContainer>
        </DocumentViewContainer>
      );
    /*Error retrieving content */
    case LOADING_STATUS.ERROR: //no content
    //TODO: return error handler
    /*No Content found */
    case LOADING_STATUS.EMPTY:
    //TODO: return empty handler
    /*Content ready to display */
    case LOADING_STATUS.READY:
    case LOADING_STATUS.DONE:
    default:
      return (
        <DocumentViewSection>
          <DocumentViewContainer
            isMobile={isMobile}
            onScroll={onScroll}
            ref={divRef}
            id={CONTENT_SEARCH_DEFAULTS.READER_CONTENT}
          >
            {renderBookmarkModeAlert(bookmarkModeActive)}
            {renderHighlightModeAlert(highlightModeActive)}
            <Detector
              polling={false}
              render={({ online }) => (
                <DocumentViewPanel>
                  {!online && showOfflineHeader && (
                    <OfflineHeader
                      parentWidth={divRef.current?.offsetWidth}
                      setShowOfflineHeader={setShowOfflineHeader}
                    />
                  )}
                  {isPreview && <PreviewHeader />}
                  <EReaderContent>
                    <ReaderContentContext.Provider value={documentContentContext}>
                      <ReaderContentSections />
                    </ReaderContentContext.Provider>
                  </EReaderContent>
                  <br />
                  {isPreview && <PreviewFooter isMobile={isMobile} />}
                </DocumentViewPanel>
              )}
            />
          </DocumentViewContainer>
        </DocumentViewSection>
      );
  }
  /* eslint-enable no-fallthrough */
});

export default DocumentView;
