import STANDARDS_AUSTRALIA_CONTACT_DETAILS from "./contactDetails";

// SHOP_MENU_ITEMS enum value must match the index of the path in the SHOP_MENU_ITEMS_INFO array
export enum SHOP_MENU_ITEMS {
  HOME = 0,
  READER,
  LOGIN,
  ERROR,
  UNAUTHORISED,
  UNKNOWN //404
}

export const SHOP_MENU_ITEMS_INFO: {
  name: string;
  path: string;
  dynamicPath?: string;
  showHeader?: boolean;
  showSearchFooter?: boolean;
  noSearchBarToggleIcon?: boolean;
  customStyle?: { breadcrumbStyle?: { noMargin?: boolean | undefined } | undefined };
}[] = [
  {
    name: "Home",
    path: "/",
    noSearchBarToggleIcon: true,
  },  
  {
    name: "Reader",
    path: "/reader",
  },  
  {
    name: "Login",
    path: "/login",
  },  
  {
    name: "Error",
    path: "/error",
  },
  {
    name: "Unauthorised",
    path: "/unauthorised",
    noSearchBarToggleIcon: true,
  },
  {
    name: "Unknown",
    path: "/404",
    noSearchBarToggleIcon: true,
  }
];
