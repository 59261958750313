import React from "react";
import * as Sentry from "@sentry/browser";
import { useNavigate } from "react-router-dom";
import messageService from "../../services/messagesService";
import { SHOP_MENU_ITEMS_INFO, SHOP_MENU_ITEMS } from "../../constants/shopMenuItems";
import * as sumoLog from 'utils/logUtils';
interface SafePureComponentState {
  hasError?: boolean;
  error?: Error;
}

// SSR errors processing
// https://asmagin.com/2019/01/18/catching-server-side-rendering-errors-in-react-jss-apps/
class SafePureComponent<P, S extends SafePureComponentState> extends React.PureComponent<P, S> {
  private hasError = false;

  constructor(props: P, context?: any) {
    super(props, context);
  }

  public componentDidMount() {
    this.setState({ hasError: this.hasError });
  }

  public render() {
    // componentDidCatch doesn't work in React 16's renderToString
    // https://github.com/facebook/react/issues/10442
    // Due to streaming nature of renderToString in React 16, need to wrap each render method in try/catch
    try {
      // Using error boundaries for client-side rendering
      if (this.state && this.state.hasError) {
        return this.renderErrorView();
      }

      return this.safeRender();
    } catch (error) {
      // this allows to differentiate server vs client rendering
      if (typeof window !== "undefined") {
        // used to avoid side-effects via setState()
        this.hasError = true;

        console.error("Error inside", this.getComponentName(), "is", error);
      }

      return this.renderErrorView();
    }
  }

  // this method should be overridden in nested classes
  protected safeRender(): React.ReactNode {
    return (
      <span dangerouslySetInnerHTML={{ __html: `<!-- Component ${this.getComponentName()} is not implemented. -->` }} />
    );
  }

  protected renderErrorView() {
    return (
      <span
        dangerouslySetInnerHTML={{
          __html: `<!-- Error inside ${this.getComponentName()}. For more info, please, check the console."-->`,
        }}
      />
    );
  }

  protected getComponentName() {
    return !!this.constructor.name ? this.constructor.name : "component";
  }
}

// Functional component error catching
// https://gist.github.com/andywer/800f3f25ce3698e8f8b5f1e79fed5c9c

type ErrorHandler = (error: Error, info: React.ErrorInfo) => void;
type ErrorHandlingComponent<Props> = (props: Props, error?: Error) => React.ReactNode;

type ErrorState = { error?: Error };

//let navigate = useNavigate();

function Catch<Props extends {}>(
  component: ErrorHandlingComponent<Props>,
  errorHandler?: ErrorHandler,
): React.ComponentType<Props> {
  return class extends SafePureComponent<Props, SafePureComponentState> {
    state: ErrorState = {
      error: undefined,
    };

    static getDerivedStateFromError(error: Error) {
      return { error };
    }

    componentDidCatch(error: Error, info: React.ErrorInfo) {
      if (errorHandler) {
        errorHandler(error, info);
      }
    }

    render() {
      return component(this.props, this.state.error);
    }
  };
}

type Props = {
  children: React.ReactNode;
};

const ShopErrorBoundary = Catch(function ShopErrorBoundary(props: Props, error?: Error) {
  if (error) {
    try {
      Sentry.withScope((scope) => {
        scope.setExtras({ error: error });
      });
    } catch (error) {
      console.error("Error boundary: ending error to Sentry failed");
    }
    sumoLog.log(error?.stack??"");
    //navigate(SHOP_MENU_ITEMS_INFO[SHOP_MENU_ITEMS.ERROR].path);
  } else {
    return <React.Fragment>{props.children}</React.Fragment>;
  }
});

export const ShopErrorHandler = (error: any, navigateToErrorPage?: boolean) => {
  try {
    Sentry.withScope((scope) => {
      scope.setExtras({ error: error });
    });
    sumoLog.log(error);
  } catch (error) {
    console.error("Error handler: sending error to Sentry failed");
  }

  messageService.sendMessage(error);
  if (navigateToErrorPage) {
    //navigate(SHOP_MENU_ITEMS_INFO[SHOP_MENU_ITEMS.ERROR].path);
  }
  
};

export default ShopErrorBoundary;
