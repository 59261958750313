import React, { useState, useRef, useContext } from "react";
import _ from "lodash";
import { SidePanelLoader } from "../style";
import Loader from "../../../../loader/loader";
import { extractMaxWords } from "../../../../../constants/regex";
import Markers, { sortMarkers, findSectionViaId } from "./common/Markers";
import { OutsideClickDetector } from "../../../../../helpers/outsideClickDetector";
import ReaderLayoutContext, { IReaderLayoutContext } from "../../readerLayoutContext";
import BookmarkStore from "../../../../../stores/bookmarkStore";
import { observer } from "mobx-react-lite";

const BookmarksMarkers: React.FC<any> = observer((props: any) => {
  const {
    isBookmarksLoading,
    showMarkerOptions,
    setShowMarkerOptions,
    isMarkerDeleteOpen,
    setIsMarkerDeleteOpen,
  } = props;
  const [bookmarkId, setBookmarkId] = useState(false);
  const wrapperRef = useRef(null);

  const readerContext = useContext<IReaderLayoutContext>(ReaderLayoutContext);
  const { setIsReaderLoading } = readerContext;
  const bookmarkContext = useContext(BookmarkStore);

  OutsideClickDetector(wrapperRef, setShowMarkerOptions);

  if (isBookmarksLoading) {
    return (
      <SidePanelLoader>
        <Loader />
      </SidePanelLoader>
    );
  }

  const getSectionBody = (id: any, header: any) => {
    const node = window.document.getElementById(id);
    if (!node) return "";
    const regEx = /[^a-zA-Z0-9 .]+/g;
    const innerText = node.innerText.replace(regEx, " ");
    const innerTextHeader = innerText.substring(0, header.length);
    const valueHeader = header.replace(regEx, " ");
    if (innerTextHeader === valueHeader) {
      const wordsWithoutHeader = innerText.substring(valueHeader.length);
      return extractMaxWords(wordsWithoutHeader, 8);
    }
    return extractMaxWords(node.innerText, 8);
  };

  //map each bookmark and add it's ToC section
  const bookmarkSorted = bookmarkContext.bookmarks
    .map((bookmark: any) => {
      const section = findSectionViaId(
        readerContext.readerStore.Toc.content?.tableOfContents,
        bookmark.sectionId,
      );
      if(section){
        const navKey = "section-" + section.tableOfContentId;

        return {
          key: section.tableOfContentId,
          navigationKey: navKey,
          header: section.value,
          body: getSectionBody(navKey, section.value),
          order: section.subOrder ?? section.order.toString(),
          commentsCount: bookmark.comments?.length || 0,
          onDeleteClick: (id: any) => {
            setBookmarkId(id);
          },
        };
      }
      else if(!section && bookmark.isArchived){
        return {
          isArchived: true,
          key: bookmark.sectionId,
          header: `[Archived] [v.${bookmark.version}]`,
          body: bookmark.sectionTitle,
          version: bookmark.version,
          order: '999',
          commentsCount: bookmark.comments?.length || 0,
          onDeleteClick: (id: any) => {
            setBookmarkId(id);
          },
        };
      }
    })
    .sort((a: any, b: any) => sortMarkers(a, b))
    .sort((a, b) =>{
      if(a!.isArchived){
        return 1;
      }
      return -1;
    });

  return (
    <Markers
      ref={wrapperRef}
      section="bookmark"
      noMarkerBody="Bookmarks can be placed on any section within this publication on the Online WebReader."
      markers={bookmarkSorted}
      readerContext={readerContext}
      onDeleteAccept={async () => {
        setIsReaderLoading(true);
        await bookmarkContext.deleteBookmark(bookmarkId);
        setIsReaderLoading(false);
      }}
      showMarkerOptions={showMarkerOptions}
      setShowMarkerOptions={setShowMarkerOptions}
      isMarkerDeleteOpen={isMarkerDeleteOpen}
      setIsMarkerDeleteOpen={setIsMarkerDeleteOpen}
    />
  );
});

export default BookmarksMarkers;
