import styled from "styled-components";
import STYLE_DEFAULTS from "../../constants/styles";

interface IProps {
  color: string;
  borderColor?: string;
  width: string;
  height: string;
  border: string;
  removeBeforeAfter?: boolean;
}

export const MoreInfoContainer = styled.div`
  text-align: center;
`;

export const LoadingContainer = styled.div`
  margin: auto;
  border: ${(props: IProps) => props.border} solid
    ${(props: IProps) => props.borderColor || STYLE_DEFAULTS.COLORS.SA_B015};
  border-radius: 50%;
  border-top: ${(props: IProps) => props.border + " solid " + props.color};
  width: ${(props: IProps) => props.width};
  height: ${(props: IProps) => props.height};
  -webkit-animation: spin 0.75s linear infinite; /* Safari */
  animation: spin 0.75s linear infinite;

  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  :before {
    ${(props: IProps) =>
      props.removeBeforeAfter
        ? ""
        : `
    content: "";
    position: absolute;
    width: ${(props: IProps) => props.border};
    height: ${(props: IProps) => props.border};
    background: ${(props: IProps) => props.color};
    border-radius: 50%;
    top: 0px;
    right: 0px;
    `}
  }

  :after {
    ${(props: IProps) =>
      props.removeBeforeAfter
        ? ""
        : `
    content: "";
    position: absolute;
    width: ${(props: IProps) => props.border};
    height: ${(props: IProps) => props.border};
    background: ${(props: IProps) => props.color};
    border-radius: 50%;
    top: 0px;
    left: 0px;`}
  }
`;
