import React from "react";
import ICONS from "../../constants/icons";
import Icon from "../icons";
import ALERT_TYPE from "../../constants/alerts";
import {
  alertTypeToIconMap,
  AlertContainer,
  AlertIcon,
  AlertMessageContainer,
  AlertContent,
  AlertTitle,
  AlertMessage,
} from "./style";
import CloseButton from "../controls/button/CloseButton";

interface IProps {
  type: ALERT_TYPE;
  title?: string;
  message: string | Array<string>;
  onClose?: () => void;
  icon?: ICONS;
  childern?: React.ReactElement;
  display?: string;
}

const renderMessage = (message: string | Array<string>, withTitle: any) => {
  if (message instanceof Array) {
    return message.map((item: any, index: any) => {
      return <AlertMessage key={`alert-${index}`}>{item}</AlertMessage>;
    });
  }
  return <AlertMessage dangerouslySetInnerHTML={{ __html: message }} />;
};

function Alert(props: IProps): React.ReactElement {
  const { type, title, message, onClose, icon, childern, display } = props;
  return (
    <AlertContainer type={type} display={display}>
      <AlertContent>
        <AlertIcon>
          <Icon tiny icon={icon || alertTypeToIconMap[type].icon} color={alertTypeToIconMap[type].color} />
        </AlertIcon>
        <AlertMessageContainer>
          {title && <AlertTitle>{title}</AlertTitle>}
          {renderMessage(message, !!title)}
        </AlertMessageContainer>
      </AlertContent>
      {onClose && (
        <CloseButton onClick={onClose} internalSize="16px" background={alertTypeToIconMap[type].backgroundColor} />
      )}
      {childern}      
    </AlertContainer>
  );
}

export default Alert;
