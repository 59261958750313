

export const INDUSTRY_SECTORS: Array<any> = [
  {
    displayName: "Choose one",
    label: "Choose one",
    value: -1,
  },
  {
    displayName: "Agriculture, Forestry, Fishing & Food",
    label: "Agriculture, Forestry, Fishing and Food",
    url: "agriculture-forestry-fishing-food",
    value: 0,
  },
  {
    displayName: "Building & Construction",
    label: "Building and Construction",
    url: "building-construction",
    value: 1,
  },
  {
    displayName: "Communications, IT & E-Commerce Services",
    label: "Communications, Information Technology and e-Commerce Services",
    url: "communications-it-ecommerce",
    value: 2,
  },
  {
    displayName: "Consumer Products, Services & Safety",
    label: "Consumer Products, Services and Safety",
    url: "consumer-products-services-safety",
    value: 3,
  },
  {
    displayName: "Education & Training Services",
    label: "Education and Training Services",
    url: "education-training-services",
    value: 4,
  },
  {
    displayName: "Electrotechnology & Energy",
    label: "Electrotechnology and Energy",
    url: "electrotechnology-energy",
    value: 5,
  },
  {
    displayName: "Health & Community Services",
    label: "Health and Community Services",
    url: "health-community-services",
    value: 6,
  },
  {
    displayName: "Manufacturing & Processing",
    label: "Manufacturing and Processing",
    url: "manufacturing-processing",
    value: 7,
  },
  {
    displayName: "Mining",
    label: "Mining",
    url: "mining",
    value: 8,
  },
  //{
  //  displayName: "Oil & Gas",
  //  label: "Oil and Gas",
  //  url: "oil-gas",
  //  value: 9,
  //},
  {
    displayName: "Public Safety, Public Administration, Business & Management",
    label: "Public Safety, Public Administration, Business and Management",
    url: "public-safety-business-management",
    value: 10,
  },
  {
    displayName: "Transport & Logistics",
    label: "Transport and Logistics",
    url: "transport-logistics",
    value: 11,
  },
  {
    displayName: "Water & Waste Services",
    label: "Water and Waste Services",
    url: "water-waste-services",
    value: 12,
  },
];

export const INDUSTRY_SECTORS_INFO_LIST = [
  {
    name: "Agriculture, Forestry, Fishing and Food",
    url: "agriculture-forestry-fishing-food",
    image: "../assets/images/industry-sectors/sector-agriculture.jpg",
    thumbnail: "../assets/images/industry-sectors/thumbnails/sector-agriculture-timg.jpg",
  },
  {
    name: "Building and Construction",
    url: "building-construction",
    image: "../assets/images/industry-sectors/sector-building.jpg",
    thumbnail: "../assets/images/industry-sectors/thumbnails/sector-building-timg.jpg",
  },
  {
    name: "Communications, Information Technology and e-Commerce Services",
    url: "communications-it-ecommerce",
    image: "../assets/images/industry-sectors/sector-communications.jpg",
    thumbnail: "../assets/images/industry-sectors/thumbnails/sector-communications-timg.jpg",
  },
  {
    name: "Consumer Products, Services and Safety",
    url: "consumer-products-services-safety",
    image: "../assets/images/industry-sectors/sector-consumer.jpg",
    thumbnail: "../assets/images/industry-sectors/thumbnails/sector-consumer-timg.jpg",
  },
  {
    name: "Education and Training Services",
    url: "education-training-services",
    image: "../assets/images/industry-sectors/sector-education.jpg",
    thumbnail: "../assets/images/industry-sectors/thumbnails/sector-education-timg.jpg",
  },
  {
    name: "Electrotechnology and Energy",
    url: "electrotechnology-energy",
    image: "../assets/images/industry-sectors/sector-electrotechnology.jpg",
    thumbnail: "../assets/images/industry-sectors/thumbnails/sector-electrotechnology-timg.jpg",
  },
  {
    name: "Health and Community Services",
    url: "health-community-services",
    image: "../assets/images/industry-sectors/sector-health.jpg",
    thumbnail: "../assets/images/industry-sectors/thumbnails/sector-health-timg.jpg",
  },
  {
    name: "Manufacturing and Processing",
    url: "manufacturing-processing",
    image: "../assets/images/industry-sectors/sector-manufacturing.jpg",
    thumbnail: "../assets/images/industry-sectors/thumbnails/sector-manufacturing-timg.jpg",
  },
  {
    name: "Mining",
    url: "mining",
    image: "../assets/images/industry-sectors/sector-mining.jpg",
    thumbnail: "../assets/images/industry-sectors/thumbnails/sector-mining-timg.jpg",
  },
  //{
  //  name: "Oil and Gas",
  //  url: "oil-gas",
  //  image: "../assets/images/industry-sectors/sector-oil.jpg",
  //  thumbnail: "../assets/images/industry-sectors/thumbnails/sector-oil-timg.jpg",
  //},
  {
    name: "Public Safety, Public Administration, Business and Management",
    url: "public-safety-business-management",
    image: "../assets/images/industry-sectors/sector-public.jpg",
    thumbnail: "../assets/images/industry-sectors/thumbnails/sector-public-timg.jpg",
  },
  {
    name: "Transport and Logistics",
    url: "transport-logistics",
    image: "../assets/images/industry-sectors/sector-transport.jpg",
    thumbnail: "../assets/images/industry-sectors/thumbnails/sector-transport-timg.jpg",
  },
  {
    name: "Water and Waste Services",
    url: "water-waste-services",
    image: "../assets/images/industry-sectors/sector-water.jpg",
    thumbnail: "../assets/images/industry-sectors/thumbnails/sector-water-timg.jpg",
  },
];

export const EXPLORE_STANDARDS_INFO_ITEM = {
  AGRICULTURE: 0,
  BUILDING: 1,
  INFORMATION_TECHNOLOGY: 2,
  CONSUMER: 3,
  EDUCATION: 4,
  ELECTROTECHNOLOGY: 5,
  HEALTH: 6,
  MANUFACTURING: 7,
  MINING: 8,
  PUBLIC_SAFETY: 9,
  TRANSPORT: 10,
  WATER: 11,
  ISO: 12,
  IEC: 13,
  NEW_RELEASES: 14,
  POPULAR: 15,
};

export const EXPLORE_STANDARDS_INFO_LIST = [
  {
    name: "Agriculture, Forestry, Fishing and Food",
    url: "agriculture-forestry-fishing-food",
  },
  {
    name: "Building and Construction",
    url: "building-construction",
  },
  {
    name: "Communications, Information Technology and e-Commerce Services",
    url: "communications-it-ecommerce",
  },
  {
    name: "Consumer Products, Services and Safety",
    url: "consumer-products-services-safety",
  },
  {
    name: "Education and Training Services",
    url: "education-training-services",
  },
  {
    name: "Electrotechnology and Energy",
    url: "electrotechnology-energy",
  },
  {
    name: "Health and Community Services",
    url: "health-community-services",
  },
  {
    name: "Manufacturing and Processing",
    url: "manufacturing-processing",
  },
  {
    name: "Mining",
    url: "mining",
  },
  // TODO if re-added, update EXPLORE_STANDARDS_INFO_ITEM
  //{
  //  name: "Oil and Gas",
  //  url: "oil-gas",
  //},
  {
    name: "Public Safety, Public Administration, Business and Management",
    url: "public-safety-business-management",
  },
  {
    name: "Transport and Logistics",
    url: "transport-logistics",
  },
  {
    name: "Water and Waste Services",
    url: "water-waste-services",
  },
  {
    name: "ISO",
    url: "iso",
  },
  {
    name: "IEC",
    url: "iec",
  },
  {
    name: "New Releases",
    url: "new-releases",
  },
  {
    name: "Popular Standards",
    url: "popular-standards",
  },
];
