import React from "react";
import MobileMenu from "./partials/mobile/MobileMenu";
import DesktopMenu from "./partials/desktop/DesktopMenu";
import ReaderLayoutContext from "./../readerLayoutContext";
import { HeaderContainer } from "./style";

const ReaderHeader: React.FC = () => {
  return (
    <HeaderContainer>
      <nav>
        <ReaderLayoutContext.Consumer>
          {value => (value.isMobile ? <MobileMenu /> : <DesktopMenu />)}
        </ReaderLayoutContext.Consumer>
      </nav>
    </HeaderContainer>
  );
};

export default ReaderHeader;
