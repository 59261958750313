import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { store } from 'state/store';
import './assets/css/fonts.css';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../src/assets/css/isosts-sa.css";
import "../src/assets/css/rc-slider.css";
import "../src/assets/css/react-popover.css";
import "react-phone-input-2/lib/style.css";
import "./assets/scripts/scrollToAnchor.js";
import AuthProvider from 'AuthProvider';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
          <AuthProvider />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
