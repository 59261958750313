import { IUserInfo } from "../models/userInfo";

/**
 * Build user initials, ideally first letter of both first name and last name
 *
 * Note: if only first/last name is available, take two first letters from it.
 *
 * @param user
 */
export const buildUserInitials = (user: IUserInfo | undefined) => {
  const firstName = user?.firstName || "";
  const lastName = user?.lastName || "";

  if (!firstName && !lastName) {
    return "?"; // unknown, can be temporary while data is fetched;
  }

  if (firstName && lastName) {
    return `${firstName[0]}${lastName[0]}`;
  }

  if (lastName) {
    return `${lastName.substring(0, 2)}`;
  }

  return `${firstName.substring(0, 2)}`;
};
