import styled from "styled-components";
import STYLE_DEFAULTS from "../../../../../../constants/styles";

interface IProps {
  padded?: boolean;
  disabled?: boolean;
}

export const MobileClosedMenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background-color: ${STYLE_DEFAULTS.COLORS.SA_WHITE_B000};
  align-items: center;
  height: 48px;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
`;

export const MobileClosedMenuHeader = styled.div``;

export const MobileClosedLeftMenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const MobileClosedRightMenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 4px;
`;

export const MobileMenuHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  height: 48px;
`;

export const MobileMenuCaption = styled.div`
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.HELVETICA_NEUE};
  font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.NORMAL};
  font-size: 0.6875rem;
  line-height: 0.75rem;
  text-transform: uppercase;
  color: ${STYLE_DEFAULTS.COLORS.SA_WHITE_B000};
  padding-bottom: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_2};
`;

export const MobileMenuBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: baseline;
`;

export const MobileMenuItem = styled.div`
  padding: ${(props: IProps) => (props.padded ? "33px 0 12px 0" : "0 0")};
  letter-spacing: 0.5px;
  height: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & a {
    color: ${STYLE_DEFAULTS.COLORS.SA_WHITE_B000};
  }
`;

export const PreviewIcon = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
  background: ${(props: IProps) => (props.disabled ? STYLE_DEFAULTS.COLORS.SA_B015 : STYLE_DEFAULTS.COLORS.SA_ORANGE_ACTIVE)};
`;
