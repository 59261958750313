import React, { useEffect, useState } from "react";

import { BlankModalOverlay, BlankModalContainer, BlankModalHeader, BlankModalTitle, BlankModalClose } from "./style";
import ICONS from "../../../constants/icons";
import Icon from "../../icons";
import { ModalButtonSection, ModalButton } from "../../alerts/style";
import Button from "../button/Button";
import BUTTONS_TYPES from "../../../constants/buttons";
import STYLE_DEFAULTS from "../../../constants/styles";

// Common transition params
const TOTAL_TRANSITION_TIME = 500;
const ANIMATION_INTERVAL_MSEC = 5;
const ANIMATION_INTERVALS_COUNT = TOTAL_TRANSITION_TIME / ANIMATION_INTERVAL_MSEC;

export enum ETransitionDirection {
  topBottom,
  rightLeft,
  bottomTop,
  leftRight,
}

const performTransition = (props: {
  id: string;
  toOpen: boolean;
  transitionDirection: ETransitionDirection;
  onModalOnOff: (b: boolean) => void;
}) => {
  const { id, toOpen, onModalOnOff, transitionDirection } = props;
  const elId = `blank-modal-animate-${id}`;
  const el = document.getElementById(elId);
  let currentPosition = toOpen ? 100 : 0;
  const step = toOpen ? -1 : 1;
  let usedIntervalsCount = 0;

  if (!el) {
    return;
  }

  switch (transitionDirection) {
    case ETransitionDirection.topBottom:
      el.style.top = "-100vh";
      break;
    case ETransitionDirection.rightLeft:
      el.style.top = "0vh";
      el.style.left = "100vh";
      break;
    case ETransitionDirection.leftRight:
      el.style.top = "0vh";
      el.style.left = `-100vh`;
      break;
  }

  const intervalID = setInterval(() => {
    currentPosition += step;

    switch (transitionDirection) {
      case ETransitionDirection.topBottom:
        el.style.top = `-${currentPosition}vh`;
        break;
      case ETransitionDirection.rightLeft:
        el.style.left = `${currentPosition}vh`;
        break;
      case ETransitionDirection.bottomTop:
        el.style.top = `${currentPosition}vh`;
        break;
      case ETransitionDirection.leftRight:
        el.style.left = `-${currentPosition}vh`;
        break;
    }

    usedIntervalsCount += 1;

    if (usedIntervalsCount === ANIMATION_INTERVALS_COUNT) {
      clearInterval(intervalID);

      onModalOnOff(toOpen);
    }
  }, ANIMATION_INTERVAL_MSEC);
};

const BlankModalTemplate = (props: any) => {
  const {
    id, // required param, for managing a specific modal, as more than one can be opened
    isOpen,
    setOpen,
    title,
    children,
    acceptButtonType,
    hasCancelButton,
    cancelButtonWidth,
    buttonPosition,
    cancelValue,
    mobileButtonsFullWidth,
    hasPrimaryButton,
    primaryButtonWidth,
    onAcceptDisabled,
    onAccept,
    onCancel,
    acceptValue,
    // default
    scrollable = true,
    closeOnAccept = true,
    hasCloseButton = true,
    transitionDirection = ETransitionDirection.bottomTop,
    footerContent,
  } = props;

  // Is set to true when modal is opened, together with "isOpen"
  // indicates when BlankModal should be animated scrolling down
  const [active, setIsActive] = useState(false);

  const onModalOnOff = (toOpen: boolean) => {
    if (toOpen) {
      setIsActive(true);
    } else {
      setIsActive(false);
      if (onCancel) {
        onCancel();
      } else if (setOpen) {
        setOpen(false);
      }
    }
  };

  const onClose = () => {
    if (active) {
      performTransition({
        id,
        toOpen: false,
        transitionDirection,
        onModalOnOff: onModalOnOff,
      });
    } else if (onCancel) {
      onCancel();
    } else {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      performTransition({
        id,
        toOpen: true,
        transitionDirection,
        onModalOnOff,
      });
    }
  }, [isOpen]);

  if (!isOpen || !children) {
    return null;
  }

  // const wrapperRef = useRef(null);
  // OutsideClickDetector(wrapperRef, onClose);

  const onAcceptButton: any = {
    primary: acceptButtonType === BUTTONS_TYPES.PRIMARY,
    secondary: acceptButtonType === BUTTONS_TYPES.SECONDARY,
    deleteButton: acceptButtonType === BUTTONS_TYPES.DELETE,
    width: primaryButtonWidth ?? "100%",
  };

  return (
    <BlankModalOverlay id={`blank-modal-animate-${id}`} top={100}>
      <BlankModalContainer scrollable={scrollable}>
        {(hasCloseButton || title) && (
          <BlankModalHeader>
            {title && <BlankModalTitle>{title}</BlankModalTitle>}
            <BlankModalClose onClick={onClose}>
              <Icon clickable icon={ICONS.CLOSE} />
            </BlankModalClose>
          </BlankModalHeader>
        )}
        {children}
        {(hasCancelButton || hasPrimaryButton) && (
          <ModalButtonSection
            style={{
              paddingBottom: STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_10,
              paddingTop: STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_10,
            }}
            buttonPosition={buttonPosition}
            mobileButtonsFullWidth={mobileButtonsFullWidth}
          >
            {hasCancelButton && (
              <ModalButton mobileButtonsFullWidth={mobileButtonsFullWidth}>
                <Button
                  secondary
                  value={cancelValue || "Cancel"}
                  onClick={onClose}
                  width={cancelButtonWidth || "100%"}
                />
              </ModalButton>
            )}
            {hasPrimaryButton && (
              <ModalButton mobileButtonsFullWidth={mobileButtonsFullWidth}>
                <Button
                  {...onAcceptButton}
                  disabled={onAcceptDisabled}
                  onClick={(event: any) => {
                    event.stopPropagation();
                    onAccept && onAccept!();
                    closeOnAccept && onClose();
                  }}
                  value={acceptValue}
                />
              </ModalButton>
            )}
            <br />
          </ModalButtonSection>
        )}
        {footerContent && footerContent}
      </BlankModalContainer>
    </BlankModalOverlay>
  );
};

export default BlankModalTemplate;
