import styled, { css } from "styled-components";
import IReaderMenuItem from "../../../../models/readerMenuItem";
import STYLE_DEFAULTS from "../../../../constants/styles";
import Styles from "../../../../styles";
import { SA_FONT_FAMILY } from "../../../../constants/styles/fonts";
import { MEDIA_QUERIES } from "../../../../constants/mediaQueries";
import { linkHoverStyle, onHoverStateTransition } from "../../../../styles/global";
import { Divider, InFocus } from "../../../../styles/typography";

interface IProps {
  isMobile?: boolean;
  isSideMenuOpen?: boolean;
  isDisabled?: boolean;
  isSelected?: boolean;
  isPreview?: boolean;
  isScrollable?: boolean;
  highlight?: string;
}

interface ITocTitle {
  isSubTitle?: boolean;
  isDisabled?: boolean;
}

export const SideMenuItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${STYLE_DEFAULTS.COLORS.SA_B015};
  width: 56px;
  min-width: 56px;
  height: 100vh;
  height: 100vh;
  position: -webkit-sticky;
  position: sticky;
  top: ${(props: IProps) => (props.isMobile ? "0px" : "60px")};
  z-index: 40;
`;

export const SideMenuItemsTop = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SideMenuIconSelectIndicator = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 4px;
  height: 100%;
`;

export const SideMenuIcon = styled.div`
  margin-top: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_2};
  position: relative;

  ${(props: IReaderMenuItem) =>
    props.isDisabled
      ? ""
      : ` :hover {
      color: #ed1212;
      cursor: pointer;

      & svg path {
        fill: rgb(240, 120, 0);
      }
    }
    ${onHoverStateTransition};
  `};

  ${(props: IReaderMenuItem) =>
    props.selected &&
    `
    background-color:  ${STYLE_DEFAULTS.COLORS.SA_WHITE_B000};

    div#selectIndicator {
        background-color: ${STYLE_DEFAULTS.COLORS.SA_ORANGE_ACTIVE};
    }
  `}
`;

export const SideMenuTocContainer = styled.div`
  padding-top: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_1};
  padding-bottom: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_1};
`;

export const SideMenuPanelContainer = styled.div`
  height: calc(100vh - 60px);
  overflow-y: auto;
  top: 0;
  opacity: 1;
  background-color: ${STYLE_DEFAULTS.COLORS.SA_WHITE_B000};

  @media ${MEDIA_QUERIES.SM} {
    height: 100vh;
    min-width: 0;
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

export const SideMenuPanelContainerMobile = styled(SideMenuPanelContainer)`
  width: calc(100vw);
  /* transform: ${(props: IProps) => (props.isSideMenuOpen ? "translateX(0)" : "translateX(-100%)")};
  transition: transform ${STYLE_DEFAULTS.ANIMATIONS.MENU_TRANSITION_DURATION_SEC}s ease-in-out; */
  z-index: ${(props: IProps) => (props.isPreview ? "0" : "50")};
  ${(props: IProps) => (props.isScrollable ? "" : "overflow: hidden;")}
`;

export const MarkerMenuContainer = styled.div`
  display: flex;
  padding-left: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_4};
  padding-right: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_4};
  justify-content: space-between;
`;
export const MarkerMenuIcon = styled.div`
  //this is a dummy placeholder
  padding-left: 35%;
`;

export const MarkerMenuItem = styled.div`
  font-size: 10px;
  font-family: ${SA_FONT_FAMILY.NOTO_SANS};
  align-items: center;
  :hover {
    color: rgb(240, 120, 0);
    cursor: pointer;
    & svg path {
      fill: rgb(250, 170, 0);
    }
  }
`;

export const MarkerMenuIconSelectIndicator = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${(props: IProps) =>
    props.isSelected ? STYLE_DEFAULTS.COLORS.SA_ORANGE_ACTIVE : STYLE_DEFAULTS.COLORS.SA_B015};
`;

export const SideContentContainer = styled.div`
  font-family: ${SA_FONT_FAMILY.NOTO_SANS};
  position: inline;
  overflow-wrap: break-word;
  font-size: 14px;
`;

export const SideMenuPanelContainerDesktop = styled(SideMenuPanelContainer)`
  position: relative;
  width: 320px;
  max-width: 320px;
  flex: ${(props: IProps) => (props.isSideMenuOpen ? 1 : 0)};
  padding-left: 0px;
  margin-right: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_5};
  -webkit-box-shadow: 1px 0px 2px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 1px 0px 2px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 1px 0px 2px 0px rgba(0, 0, 0, 0.15);
  /* transform: ${(props: IProps) => (props.isSideMenuOpen ? "translateX(0)" : "translateX(-100%)")};
  transition: flex ${STYLE_DEFAULTS.ANIMATIONS.MENU_TRANSITION_DURATION_SEC}s,
    transform ${STYLE_DEFAULTS.ANIMATIONS.MENU_TRANSITION_DURATION_SEC}s ease-in-out; */
  z-index: ${(props: IProps) => (props.isPreview ? "0" : "50")};
  ${(props: IProps) => (props.isScrollable ? "" : "overflow: hidden;")}
`;

export const SideMenuContentHeaderContainer = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: ${STYLE_DEFAULTS.COLORS.SA_WHITE_B000};
  padding-top: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_4};
`;

export const SideMenuPanelCloseButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 30;
  padding-bottom: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_5};
`;

export const SideContentHeading = styled.span`
  font-family: ${SA_FONT_FAMILY.NOTO_SANS};
  font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.MEDIUM};
  font-size: 24px;
  padding-left: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_5};
`;

export const TocDocSchema = styled.div`
  padding-top: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_6};
  padding-left: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_5};
  padding-bottom: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_6};
`;

export const TocDocDesignation = styled.span`
  font-family: ${SA_FONT_FAMILY.NOTO_SANS};
  font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.NORMAL};
  padding-top: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_1};
  font-size: 16px;
  ${linkHoverStyle};
`;

export const TocDocTitle = styled.div`
  font-family: ${SA_FONT_FAMILY.NOTO_SANS};
  font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.NORMAL};
  padding-top: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_1};
  font-size: 14px;
  line-height: 1.5;
`;

export const TocSection = styled.div`
  display: flex;
  padding-left: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_5};
  justify-content: space-between;
  padding-right: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_2};

  & svg path {
    fill: ${(props: IProps) => (props.isDisabled ? STYLE_DEFAULTS.COLORS.SA_DISABLED : STYLE_DEFAULTS.COLORS.SA_B085)};
  }
`;

export const TocSubSection = styled.div`
  padding-left: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_1};
`;

export const TocTitle = styled.div`
  max-width: 85%;
  line-height: 1.5;
  padding-right: ${(props: ITocTitle) => (props.isSubTitle ? STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_1 : "0px")};
  color: ${(props: ITocTitle) =>
    props.isDisabled
      ? STYLE_DEFAULTS.COLORS.SA_B060
      : props.isSubTitle
      ? STYLE_DEFAULTS.COLORS.SA_BLACK_B100
      : STYLE_DEFAULTS.COLORS.SA_BLACK_B100};
  ${(props: ITocTitle) => (props.isDisabled ? "" : linkHoverStyle)};
`;

export const TocExpand = styled.div`
  display: flex;
  align-items: center;
  ${(props: ITocTitle) => (props.isDisabled ? "" : linkHoverStyle)};
`;

export const SidePanelLoader = styled.div`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const ResultUnderline = styled(Styles.Underline)`
  height: 2px;
  background-color: ${STYLE_DEFAULTS.COLORS.SA_B015};
`;

export const SideMenuUnderline = styled(Styles.Underline)`
  height: 1px;
  background-color: ${STYLE_DEFAULTS.COLORS.SA_B015};
`;

export const HeaderSideMenuUnderline = styled.div`
  height: 1px;
  background-color: ${STYLE_DEFAULTS.COLORS.SA_B015};
  margin-top: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_3};
  width: 100%;
`;

export const SideMenuOfflineContainer = styled.div`
  margin-top: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_8};
  padding-left: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_5};
  padding-right: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_5};
`;

export const SideMenuOfflineDesc = styled.div`
  font-family: ${SA_FONT_FAMILY.NOTO_SANS};
  font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.NORMAL};
  font-size: 16px;
  line-height: 24px;
  color: ${STYLE_DEFAULTS.COLORS.SA_B070};
  margin-bottom: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_3};
`;

export const SideMenuOfflineDesignation = styled.div`
  margin-top: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_8};
  font-family: ${SA_FONT_FAMILY.NOTO_SANS};
  font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.MEDIUM};
  font-size: 18px;
  line-height: 24px;
  color: ${STYLE_DEFAULTS.COLORS.SA_BLACK_B100};
  display: flex;
  justify-content: space-between;
`;

export const SideMenuOfflineDownloadingState = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_2};
  margin-bottom: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_4};
`;

export const SideMenuOfflineDownloadingLoaderContainer = styled.div`
  margin-right: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_2};
`;

export const SideMenuOfflineDownloadingText = styled.div`
  font-family: ${SA_FONT_FAMILY.NOTO_SANS};
  font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.MEDIUM};
  font-size: 16px;
  line-height: 19px;
  color: ${STYLE_DEFAULTS.COLORS.SA_DISABLED};
`;

export const SectionDivider = styled(Divider)``;

export const SideMenuOfflineDownloadedState = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_2};
  margin-bottom: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_4};
`;

export const SideMenuOfflineLastSyncText = styled.div`
  font-family: ${SA_FONT_FAMILY.NOTO_SANS};
  font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.MEDIUM};
  font-size: 14px;
  line-height: 19px;
  color: ${STYLE_DEFAULTS.COLORS.SA_B070};
  margin-left: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_2};
`;

export const SideMenuOfflineLastSyncDate = styled.div`
  font-family: ${SA_FONT_FAMILY.NOTO_SANS};
  font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.NORMAL};
  font-size: 14px;
  line-height: 19px;
  color: ${STYLE_DEFAULTS.COLORS.SA_B070};
  margin-left: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_1};
`;

export const PopoverOptionText = styled.div`
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.NOTO_SANS};
  font-size: ${STYLE_DEFAULTS.FONTS.SA_FONT_SIZE.NORMAL};
  font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.NORMAL};
  background-color: ${STYLE_DEFAULTS.COLORS.SA_WHITE_B000};
  color: ${(props: IProps) => (props.isDisabled ? STYLE_DEFAULTS.COLORS.SA_DISABLED : STYLE_DEFAULTS.COLORS.SA_RED)};
  margin-left: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_2};

  :hover {
    color: ${(props: IProps) =>
      props.isDisabled ? STYLE_DEFAULTS.COLORS.SA_DISABLED : STYLE_DEFAULTS.COLORS.SA_RED_B010};
    ${onHoverStateTransition};
  }

  :active {
    color: ${(props: IProps) =>
      props.isDisabled ? STYLE_DEFAULTS.COLORS.SA_DISABLED : STYLE_DEFAULTS.COLORS.SA_RED_B015};
    ${onHoverStateTransition};
  }
`;

export const PopoverOption = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  pointer-events: ${(props: IProps) => (props.isDisabled ? "none" : "all")};
  padding: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_3};

  :hover {
    text-decoration: underline;
    text-decoration-color: ${(props: IProps) =>
      props.isDisabled ? STYLE_DEFAULTS.COLORS.SA_DISABLED : STYLE_DEFAULTS.COLORS.SA_RED_B010};
    cursor: pointer;
    ${onHoverStateTransition};
  }

  :active {
    text-decoration: underline;
    text-decoration-color: ${(props: IProps) =>
      props.isDisabled ? STYLE_DEFAULTS.COLORS.SA_DISABLED : STYLE_DEFAULTS.COLORS.SA_RED_B015};
    ${onHoverStateTransition};
  }

  :focus {
    ${InFocus}
  }
`;

export const ConfirmationModalTitle = styled.div`
  font-size: 21px;
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.NOTO_SANS};
  font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.MEDIUM};
  text-align: left;
`;

export const ConfirmationModalContent = styled.div`
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.NOTO_SANS};
  font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.NORMAL};
  font-size: 16px;
  text-align: left;
  line-height: ${STYLE_DEFAULTS.FONTS.SA_FONT_LINE_HEIGHT.HIGHER};
  color: ${STYLE_DEFAULTS.COLORS.SA_B070};
  margin-top: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_8};
  margin-bottom: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_8};
`;

export const ModalContent = styled.div`
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.NOTO_SANS};
  font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.NORMAL};
  font-size: 16px;
  color: ${STYLE_DEFAULTS.COLORS.SA_B070};
  margin-top: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_5};
`;
