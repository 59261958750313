export const MEDIA_QUERIES: any = {
  XS: "(max-width: 400px)",
  SM: "(max-width: 767px)",
  MD: "(max-width: 1024px)",
  STOREFRONT_MD: "(max-width: 990px)",
  SEARCH_FIXED_START: "(min-width: 800px)", // Starting this value the quick search bar has fixed length
  LG: "(min-width: 1440px)",
};

export const MEDIA_QUERIES_NAMES: any = {
  XS: "XS",
  SM: "SM",
  MD: "MD",
  STOREFRONT_MD: "STOREFRONT_MD",
  SEARCH_FIXED_START: "SEARCH_FIXED_START",
  LG: "LG",
};
