import styled, { css } from "styled-components";
import STYLE_DEFAULTS from "../../../constants/styles";
import { setButtonElementsColor, InFocus } from "../../../styles/typography";
import { onHoverStateTransition } from "../../../styles/global";

interface IProps {
  iconLast?: boolean;
  primary?: boolean;
  secondary?: boolean;
  red?: boolean;
  inverted?: boolean;
  white?: boolean;
  small?: boolean;
  disabled?: boolean;
  inFocus?: boolean;
  notoSans?: boolean;
  width?: string;
  color?: string;
  resizeText?: boolean;
  textSize?: string;
  value?: string;
  heavy?: boolean;
}

const primaryLinkStyling = css`
  ${setButtonElementsColor(STYLE_DEFAULTS.COLORS.SA_NAVY)};

  &.in-focus,
  :hover {
    text-decoration: underline;
    ${setButtonElementsColor(STYLE_DEFAULTS.COLORS.SA_INVERTED_DIVIDER)};
    text-decoration-color: ${STYLE_DEFAULTS.COLORS.SA_INVERTED_DIVIDER};
    ${onHoverStateTransition};
  }

  :focus,
  :active {
    text-decoration: underline;
    ${setButtonElementsColor(STYLE_DEFAULTS.COLORS.SA_NAVY_B010)};
    text-decoration-color: ${STYLE_DEFAULTS.COLORS.SA_NAVY_B010};
    ${onHoverStateTransition};
  }

  :focus {
    ${InFocus}
  }

  :visited {
    ${setButtonElementsColor(STYLE_DEFAULTS.COLORS.SA_BLUE_B015)};
  }
`;

const secondaryLinkStyling = css`
  text-decoration: underline;
  ${setButtonElementsColor(STYLE_DEFAULTS.COLORS.SA_B070)};
  text-decoration-color: ${STYLE_DEFAULTS.COLORS.SA_B070};

  :hover {
    text-decoration: none;
    ${setButtonElementsColor(STYLE_DEFAULTS.COLORS.SA_BLACK_B100)};
    ${onHoverStateTransition};
  }

  .in-focus,
  :active {
    ${setButtonElementsColor(STYLE_DEFAULTS.COLORS.SA_BLACK_B100)};
    text-decoration-color: ${STYLE_DEFAULTS.COLORS.SA_BLACK_B100};
    ${onHoverStateTransition};
  }

  :focus {
    ${InFocus}
    ${setButtonElementsColor(STYLE_DEFAULTS.COLORS.SA_BLACK_B100)};
    text-decoration-color: ${STYLE_DEFAULTS.COLORS.SA_BLACK_B100};
    ${onHoverStateTransition};
  }

  :visited {
    ${setButtonElementsColor(STYLE_DEFAULTS.COLORS.SA_B070)};
    text-decoration-color: ${STYLE_DEFAULTS.COLORS.SA_B070};
  }
`;

const redLinkStyling = css`
  ${setButtonElementsColor(STYLE_DEFAULTS.COLORS.SA_RED)};

  &.in-focus,
  :hover {
    text-decoration: underline;
    ${setButtonElementsColor(STYLE_DEFAULTS.COLORS.SA_RED_B010)};
    text-decoration-color: ${STYLE_DEFAULTS.COLORS.SA_RED_B010};
    ${onHoverStateTransition};
  }

  :focus,
  :active {
    text-decoration: underline;
    ${setButtonElementsColor(STYLE_DEFAULTS.COLORS.SA_RED_B015)};
    text-decoration-color: ${STYLE_DEFAULTS.COLORS.SA_RED_B015};
    ${onHoverStateTransition};
  }

  :focus {
    ${InFocus}
  }

  :visited {
    ${setButtonElementsColor(STYLE_DEFAULTS.COLORS.SA_RED_B015)};
  }
`;

const whiteLinkStyling = css`
  ${setButtonElementsColor(STYLE_DEFAULTS.COLORS.SA_INVERTED_DEFAULT)};

  &.in-focus,
  :hover {
    text-decoration: underline;
    ${setButtonElementsColor(STYLE_DEFAULTS.COLORS.SA_WHITE_B000)};
    text-decoration-color: ${STYLE_DEFAULTS.COLORS.SA_WHITE_B000};
    ${onHoverStateTransition};
  }

  :focus,
  :active {
    text-decoration: underline;
    ${setButtonElementsColor(STYLE_DEFAULTS.COLORS.SA_WHITE_B000)};
    text-decoration-color: ${STYLE_DEFAULTS.COLORS.SA_WHITE_B000};
    ${onHoverStateTransition};
  }

  :focus {
    ${InFocus}
  }

  :visited {
    ${setButtonElementsColor(STYLE_DEFAULTS.COLORS.SA_WHITE_B000)};
  }
`;

const invertedLinkStyling = css`
  ${setButtonElementsColor(STYLE_DEFAULTS.COLORS.SA_YELLOW)};

  &.in-focus,
  :hover {
    text-decoration: underline;
    ${setButtonElementsColor(STYLE_DEFAULTS.COLORS.SA_YELLOW_B010)};
    text-decoration-color: ${STYLE_DEFAULTS.COLORS.SA_YELLOW_B010};
    ${onHoverStateTransition};
  }

  :focus,
  :active {
    text-decoration: underline;
    ${setButtonElementsColor(STYLE_DEFAULTS.COLORS.SA_YELLOW_B015)};
    text-decoration-color: ${STYLE_DEFAULTS.COLORS.SA_YELLOW_B015};
    ${onHoverStateTransition};
  }

  :focus {
    ${InFocus}
  }

  :visited {
    ${setButtonElementsColor(STYLE_DEFAULTS.COLORS.SA_YELLOW_B015)};
  }
`;

export const LinkContainer = styled.div`
  padding: 1px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;

  ${(props: IProps) => {
    let style = "";

    if (props.disabled) {
      style += `
        ${setButtonElementsColor(STYLE_DEFAULTS.COLORS.SA_DISABLED)};
        cursor: default;
        pointer-events: none;
      `;
    } else {
      if (props.primary) {
        style += primaryLinkStyling;
      } else if (props.secondary) {
        style += secondaryLinkStyling;
      } else if (props.red) {
        style += redLinkStyling;
      } else if (props.white) {
        style += whiteLinkStyling;
      } else if (props.inverted) {
        style += invertedLinkStyling;
      } else {
        style += primaryLinkStyling;
      }
    }

    if (props.width) {
      style += `
            width: ${props.width};
            justify-content: center;
        `;
    }

    return style;
  }}

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    align-items: center; /* Only targetting IE 11 here */
  }
`;

export const ButtonContainer = styled(LinkContainer)`
  border: none;
  background: transparent;
`;

export const LinkIcon = styled.div`
  ${(props: IProps) => {
    const space = props.small ? STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_1 : STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_2;

    if (props.iconLast) {
      return `margin-left: ${space};`;
    } else {
      return `margin-right: ${space};`;
    }
  }}
`;

export const LinkValue = styled.div`
  font-family: ${(props: IProps) =>
    props.notoSans
      ? STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.NOTO_SANS
      : STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.HELVETICA_NEUE_REGULAR};
  font-weight: ${(props: IProps) =>
    props.heavy ? STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.MEDIUM : STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.NORMAL};
  font-size: ${(props: IProps) =>
    props.small ? (props.resizeText ? STYLE_DEFAULTS.FONTS.SA_FONT_SIZE.SMALL : "14px") : props.textSize ?? "16px"};
  letter-spacing: ${(props: IProps) => (props.notoSans ? "normal" : "0.53px")};
  line-height: ${(props: IProps) =>
    props.small
      ? "14px"
      : props.textSize
      ? STYLE_DEFAULTS.FONTS.SA_FONT_LINE_HEIGHT.HIGHER
      : "18px"}; // same for desktop and mobile
  padding-top: ${(props: IProps) =>
    props.notoSans ? 0 : "4px"}; // Helvetica has spacing below the font, counter act it
  width: fit-content;
  text-decoration: none;
  ${(props: IProps) => {
    if (props.color && !props.disabled) {
      return `color: ${props.color} !important;`;
    }
  }}

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    align-self: center; /* Only targetting IE 11 here */
  }
`;
