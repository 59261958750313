import React, { useContext } from "react";
import Icon from "../../../icons";
import ICONS from "../../../../constants/icons";
import STYLE_DEFAULTS from "../../../../constants/styles";
import { SectionBookmarkContainer } from "./style";
import { observer } from "mobx-react-lite";
import BookmarksStore from "../../../../stores/bookmarkStore";

interface IBookmarkBadgeProps {
  budgeActive?: boolean;
  isSectionBookmarked: boolean;
  shouldShowBookmarkBadge: boolean;
  isMobile: boolean;
  sectionId: number;
}

const BookmarkBadge = observer(
  (props: IBookmarkBadgeProps): React.ReactElement => {
    const { isSectionBookmarked, shouldShowBookmarkBadge, isMobile, sectionId, budgeActive } = props;

    const { bookmarkModeActive } = useContext(BookmarksStore);

    if (bookmarkModeActive || isSectionBookmarked) {
      return (
        <div id={`bookmark-badge-${sectionId}`}>
          <SectionBookmarkContainer
            budgeActive={budgeActive}
            inFocus={shouldShowBookmarkBadge}
            bookmarked={isSectionBookmarked}
            isMobile={isMobile}
          >
            {isSectionBookmarked ? (
              <Icon
                icon={ICONS.BOOKMARK_FILLED}
                internalSize="20px"
                size="24px"
                color={STYLE_DEFAULTS.COLORS.SA_BLUE}
                clickable
              />
            ) : (
              <Icon
                icon={ICONS.BOOKMARK}
                clickable
                internalSize="20px"
                size="24px"
                color={STYLE_DEFAULTS.COLORS.SA_B070}
              />
            )}
          </SectionBookmarkContainer>
        </div>
      );
    } else {
      return <></>;
    }
  },
);

export default BookmarkBadge;
