import { action, observable, runInAction } from "mobx";
import React from "react";

export class ReaderLayoutStore {
  @observable IsNavigating: boolean | undefined;

  // Track the id of the section where bookmark is in set in focus
  // by clicking on it on the left side panel
  @observable BookmarkInFocusId = 0;

  // Track the id of the section where bookmark *comments* are set in focus
  // by clicking on comments icon under the bookmark on the left side panel
  @observable BookmarkCommentsInFocusId = 0;

  // Track the id of the highlight set in focus
  // by clicking on it on the left side panel
  @observable HighlightInFocusId = 0;

  // Track the id of the highlight where highlight *comments* are set in focus
  // by clicking on comments icon under the highlight on the left side panel
  @observable HighlightCommentsInFocusId = 0;

  constructor() {
    //this line of code binds 'this' to your function so you can use it
    this.SetIsNavigating = this.SetIsNavigating.bind(this);
  }

  @action SetIsNavigating = async (isNavigating: boolean) => {
    runInAction(() => {
      this.IsNavigating = isNavigating;
    });
  };

  @action SetBookmarkInFocusId = async (sectionId: number) => {
    this.ResetFocusState();

    runInAction(() => {
      this.BookmarkInFocusId = sectionId;
    });
  };

  @action SetBookmarkCommentsInFocusId = async (sectionId: number) => {
    this.ResetFocusState();

    runInAction(() => {
      this.BookmarkCommentsInFocusId = sectionId;
    });
  };

  @action SetHighlightInFocusId = async (highlightId: number) => {
    this.ResetFocusState();

    runInAction(() => {
      this.HighlightInFocusId = highlightId;
    });
  };

  @action SetHighlightCommentsInFocusId = async (highlightId: number) => {
    this.ResetFocusState();

    runInAction(() => {
      this.HighlightCommentsInFocusId = highlightId;
    });
  };

  /**
   * Reset state set when user clicks on marker or its comments
   * in left side panel
   */
  @action ResetFocusState = async () => {
    runInAction(() => {
      if (this.BookmarkCommentsInFocusId) {
        this.BookmarkCommentsInFocusId = 0;
      }

      if (this.BookmarkInFocusId) {
        this.BookmarkInFocusId = 0;
      }

      if (this.HighlightCommentsInFocusId) {
        this.HighlightCommentsInFocusId = 0;
      }

      if (this.HighlightInFocusId) {
        this.HighlightInFocusId = 0;
      }
    });
  };
}

export default React.createContext(new ReaderLayoutStore());
