//MODIFIED_FILE
import { action, configure, observable, runInAction } from "mobx";
import { format } from "date-fns";
import { createContext } from "react";
import { LOADING_STATUS } from "../constants/loadingStatus";
import utils from "../utils/utils";

configure({ enforceActions: "always" });

export interface IError {
  title: string;
  content: string;
}

// NOTE: This static array is in service worker file as well. /public/sw.js.
// We can't access constants defined outside in service-worker and that's why this is copied there as well. If
// any change is made to this array in one of these two places, make sure to copy the exact value in other place as well.
const READER_RESOURCES_URL_PATTERNS_TO_CACHE = [
  "/_next/static/*",
  "/shop/ereader/sections/content*",
  "/reader/highlights*",
  "/shop/ereader/table-of-content*",
  "/reader/bookmarks*",
  "../assets/scripts/scrollToAnchor.js",
  "/npm/mathjax@3/es5/tex-mml-chtml.js",
  "/favicon.ico",
  "../assets/images/search.svg",
  "../assets/fonts/*",
  "/pub-assets/*",
  "../assets/css/print.css",
];

// NOTE: This static array is in service worker file as well. /public/sw.js.
// We can't access constants defined outside in service-worker and that's why this is copied there as well. If
// any change is made to this array in one of these two places, make sure to copy the exact value in other place as well.
const READER_RESOURCES_FOR_CREDENTIALS_TO_INCLUDE = [
  "/shop/ereader/sections/content*",
  "/reader/highlights*",
  "/shop/ereader/table-of-content*",
  "/reader/bookmarks*",
];

class OfflineLayoutStore {
  @observable downloadingStatus: LOADING_STATUS = LOADING_STATUS.EMPTY;
  @observable downloadingError?: IError;
  @observable availableCaches: string[] = [];
  @observable lastSyncedTime: string | undefined;

  private handleError = (designation: string, url: string, exception: any) => {
    console.log("Error caching it. url : " + url + " exception :" + exception);
    runInAction(() => {
      this.downloadingStatus = LOADING_STATUS.ERROR;
      this.downloadingError = {
        title: "Something went wrong",
        content: "Please try again",
      };
      this.availableCaches = this.availableCaches.filter((x) => x !== designation);
      this.deleteCache(designation);
    });
  };

  @action downloadDocumentForOffline = async (
    designation: string | undefined,
    token: string | undefined,
    sectionsToBeFetched: number[],
  ) => {
    this.downloadingStatus = LOADING_STATUS.LOADING;
    const urlsToCache = [
      window.location.href,
      ...performance.getEntriesByType("resource").map((r) => {
        return r.name;
      }),
    ];

    designation = utils.getDesignationUrl(designation);
    // For few documents performance.getEntriesByType() doesn't return all the section API's. So just to be safe we will add those manually.
    sectionsToBeFetched.forEach((section: number) => {
      const url = `${
        process.env.REACT_APP_DELIVERY_SERVICE_BASE_URL?.toString() || ""
      }/shop/ereader/sections/content?designationId=${encodeURIComponent(designation ?? "")}&startingIndex=${section}`;
      // Let's check if its already added in the urlsToCache or not.
      if (!urlsToCache.includes(url)) {
        urlsToCache.push(url);
      }
    });

    if (designation && token) {
      const cache = await caches.open(designation);

      try {
        const filteredUrls = urlsToCache.filter(
          (x, i, a) =>
            a.indexOf(x) == i && READER_RESOURCES_URL_PATTERNS_TO_CACHE.findIndex((y) => x.match(new RegExp(y))) != -1,
        );
        // Let's add a dummy url for the designation.
        // prettier-ignore
        await cache.add(new Request(`${window.location.origin.toString()}/reader/${encodeURIComponent(utils.getDesignationUrl(designation))}`));
        for (let i = 0; i < filteredUrls.length; i++) {
          const url = filteredUrls[i];
          if (READER_RESOURCES_FOR_CREDENTIALS_TO_INCLUDE.findIndex((y) => url.match(new RegExp(y))) != -1) {
            try {
              await cache.add(
                new Request(url, {
                  credentials: "include",
                }),
              );
            } catch (exception) {
              this.handleError(designation, url, exception);
              return;
            }
          } else {
            try {
              await cache.add(new Request(url, { credentials: "same-origin" }));
            } catch (exception) {
              this.handleError(designation, url, exception);
              return;
            }
          }
        }
        console.log("Cache all promise resolved.");
        runInAction(() => {
          this.downloadingStatus = LOADING_STATUS.DONE;
          this.downloadingError = undefined;
          this.availableCaches = this.availableCaches.concat([designation ?? ""]);
        });
      } catch (exception) {
        this.handleError(designation, "", exception);
      }
    } else {
      this.handleError(designation, "", "");
    }
  };

  @action checkCache = async (designation: string | undefined) => {
    designation = utils.getDesignationUrl(designation);
    if (!designation) {
      return;
    }
    if (this.availableCaches.includes(designation)) {
      // If designation is already in cache, let's not check again.
      return;
    }
    caches.has(designation).then((result) => {
      runInAction(() => {
        if (result) {
          this.availableCaches = this.availableCaches.concat([designation ?? ""]);
        } else {
          // If the cache is already not available then let's not update that.
          if (this.availableCaches.includes(designation ?? "")) {
            this.availableCaches = this.availableCaches.filter((x) => x !== designation);
          }
        }
      });
    });
  };

  @action deleteCache = async (designation: string | undefined) => {
    designation = utils.getDesignationUrl(designation);
    if (!designation) {
      return;
    }

    caches.has(designation).then((result) => {
      if (result) {
        caches.delete(designation ?? "").then((deleteResult) => {
          if (deleteResult) {
            runInAction(() => {
              this.availableCaches = this.availableCaches.filter((x) => x !== designation);
            });
          }
        });
      }
    });
  };

  @action calculateLastUpdatedTime = async (designation: string | undefined) => {
    designation = utils.getDesignationUrl(designation);
    if (!designation) {
      return;
    }

    let lastUpdatedTime: Date | null = null;
    const exist = await caches.has(designation);

    if (exist) {
      const cache = await caches.open(designation);
      const keys = await cache.keys();
      const filteredKeys = keys.filter(
        (x, i, a) =>
          // prettier-ignore
          a.indexOf(x) == i && READER_RESOURCES_FOR_CREDENTIALS_TO_INCLUDE.findIndex((y) => x.url.match(new RegExp(y))) != -1,
      );
      for (let index = 0; index <= filteredKeys.length; index += 1) {
        const response = await cache.match(filteredKeys[index], { ignoreVary: true });
        if (response !== null) {
          const fetchedOnHeader = response?.headers.get("sw-fetched-on");
          const dateHeader = response?.headers.get("date");

          if (fetchedOnHeader) {
            const responseDateTime = new Date(Number(fetchedOnHeader));
            if (lastUpdatedTime === null || responseDateTime > lastUpdatedTime) {
              lastUpdatedTime = responseDateTime;
              console.log("getLastUpdatedTime() " + lastUpdatedTime);
            }
          } else if (dateHeader) {
            const responseDateTime = new Date(dateHeader);
            if (lastUpdatedTime === null || responseDateTime > lastUpdatedTime) {
              lastUpdatedTime = responseDateTime;
              console.log("getLastUpdatedTime() " + lastUpdatedTime);
            }
          }
        }
      }
      runInAction(() => {
        this.lastSyncedTime = lastUpdatedTime ? format(lastUpdatedTime, "dd/MM/yyyy, hh:mm a") : "Not available";
      });
    }
  };

  @action resetDownloadingError = () => {
    runInAction(() => {
      this.downloadingStatus = LOADING_STATUS.EMPTY;
      this.downloadingError = undefined;
    });
  };
}

export default createContext(new OfflineLayoutStore());
