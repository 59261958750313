//MODIFIED_FILE
//import Link from "next/link";
import { Link } from "react-router-dom";
import React from "react";
import { UrlObject } from "url";

interface SeoLinkProps {
  children: any;
  href: string;
  as?: string | UrlObject;
  prefetch?: boolean;
}

const SeoLink = (props: SeoLinkProps): React.ReactElement => {
  const { children, href, as, prefetch } = props;

  const content = (
    <Link to={href} >
      <a style={{ textDecoration: "none" }}>{children}</a>
    </Link>
  );

  return content;
};

export default SeoLink;
