enum BUTTONS_TYPES {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  DELETE = "delete",
}

export const BUTTON_DEFAULTS = {
  WIDTH: "fit-content",
  HEIGHT: "48px", // Assume primary button is default
  HEIGHT_SMALL: "32px", // Assume primary button is default
};

export default BUTTONS_TYPES;
