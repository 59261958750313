import React from "react";
import IComment from "../../../../models/comment";
import Icon from "../../../icons";
import ICONS from "../../../../constants/icons";
import STYLE_DEFAULTS from "../../../../constants/styles";

interface IProps {
  sectionId: number;
  isMobile: boolean;
  isInFocus: boolean;
  comments: Array<IComment> | null;
  onClick: () => void;
}

/**
 * Note: add class names for elements, so appropriate styling will be applied, since in the
 * badge is also added via cheerio in some cases which uses classes (cannot use styled component with cheerio)
 *
 * @param props
 */

function CommentsBadge(props: IProps): React.ReactElement | null {
  const { sectionId, comments, isMobile, onClick, isInFocus } = props;
  const className = `comments-badge ${isMobile ? " mobile" : ""}${isInFocus ? "badge-in-focus" : ""}`;

  if (!comments || !comments.length) {
    return null;
  }

  return (
    <div id={`section-comments-badge-${sectionId}`} className={className} onClick={onClick}>
      <Icon
        icon={ICONS.COMMENT_FILLED}
        tiny
        color={STYLE_DEFAULTS.COLORS.SA_ORANGE_ACTIVE}
        internalSize="20px"
        clickable
      />
      <div className="comments-badge-text">{comments!.length}</div>
    </div>
  );
}

export default CommentsBadge;
