import React from "react";
import { IPopover } from "../../../models/popover";
import Popover, { ArrowContainer } from "react-tiny-popover";
import { PopoverContent } from "./style";

const GenericPopover = (props: IPopover): React.ReactElement => {
  const {
    children,
    isOpen,
    backgroundColor,
    content,
    arrow,
    arrowColor,
    arrowStyle,
    arrowSize,
    width,
    onClickOutside,
    position = "bottom",
  } = props;

  return (
    <Popover
      onClickOutside={onClickOutside}
      isOpen={isOpen}
      position={position}
      containerStyle={{zIndex: "1000"}}
      content={({ position, targetRect, popoverRect }) =>
        arrow === true ? (
          <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
            position={position}
            targetRect={targetRect}
            popoverRect={popoverRect}
            arrowColor={arrowColor}
            arrowSize={arrowSize}
            arrowStyle={arrowStyle}
          >
            <PopoverContent backgroundColor={backgroundColor} width={width}>
              {content}
            </PopoverContent>
          </ArrowContainer>
        ) : (
          <PopoverContent>{content}</PopoverContent>
        )
      }
    >
      {children}
    </Popover>
  );
};

export default GenericPopover;
