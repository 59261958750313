export const BROADCAST_CHANNEL  = {
    AUTHENTICATION: "AUTHENTICATION"
}

export const BROADCAST_CHANNEL_MESSAGES = {
    LOG_OUT: "LOG_OUT"
}


class broadcastChannelService {

    private _channel: BroadcastChannel;

    constructor(channel: string){
        this._channel = new BroadcastChannel(channel);
    }

    public postMessage = (message: string) => {
        this._channel.postMessage(message);
        this._channel.close();
    }

    public subscribe = (message: string, callback: () => void) => {

        this._channel.addEventListener('message', (event: MessageEvent<any>) => {if(event.data === message) callback()});
        return () => {
            this._channel.removeEventListener('message', (event: MessageEvent<any>) => {if(event.data === message) callback()});
            this._channel.close();
        }
    }
}

export default broadcastChannelService;