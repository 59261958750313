import React, { useState } from "react";
import { TextArea, TextAreaAutosizeWrapper } from "./style";

interface IProps {
  value: string;
  placeholder?: string;
  height?: string;
  readonly?: boolean;
  onChange: (event: any) => void;
  autoSize?: boolean;
}

const TextAreaField = (props: IProps) => {
  const { value, placeholder = "Text", onChange, height, readonly, autoSize } = props;

  const [focused, setFocused] = useState(false);
  const [isHover, setIsHover] = useState(false);

  // Use autoSize sparingly as the external component does not provide
  // the same look and feel as the textarea
  if (autoSize) {
    return (
      <TextAreaAutosizeWrapper
        value={value}
        onChange={onChange}
        defaultValue={placeholder}
        disabled={readonly}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        onMouseOver={() => setIsHover(true)}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      />
    );
  }

  return (
    <TextArea
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      onMouseOver={() => setIsHover(true)}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      value={value}
      onChange={onChange}
      focused={focused}
      isHover={isHover}
      placeholder={placeholder}
      height={height}
      readonly={readonly}
    />
  );
};

export default TextAreaField;
