import styled from "styled-components";
import STYLE_DEFAULTS from "../../../constants/styles";

interface IProps {
  color?: string;
}

export const StatusContainer = styled.span`
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.HELVETICA_NEUE_MEDIUM};
  font-size: 14px; // same for desktop and mobile
  line-height: 17px;
  letter-spacing: 0.47px;
  color: ${(props: IProps) => props.color ?? STYLE_DEFAULTS.COLORS.SA_B070};
  padding-left: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_1};
`;
