export enum LOADING_STATUS {
  EMPTY = 0,
  LOADING = 1,
  // ready to display
  READY = 3,
  DONE = 4,
  ERROR = 5,
  PRICE_CONFLICT = 6
}

export function CheckLoadingStatus(currentStatus: LOADING_STATUS, status: LOADING_STATUS) {
  return currentStatus === status;
}
