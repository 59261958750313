import styled from "styled-components";
import STYLE_DEFAULTS from "../../../constants/styles";

interface IProps {
  height?: string;
  scrollable?: boolean;
  top?: number;
}

export const BlankModalOverlay = styled.div`
  position: absolute;
  top: ${(props: IProps) => {
    return props.top ? `${props.top}vh` : 0;
  }};
  left: 0;
  height: max(100%, 100vh);
  width: 100%;
  background: rgba(0, 0, 0, 0.05);
  z-index: ${STYLE_DEFAULTS.Z_INDEX_LAYOUT.BODY_MODAL};
`;

export const BlankModalContainer = styled.div`
  ${(props: IProps) =>
    props.scrollable
      ? `
    height: 100%;
    width: 100%;`
      : ""};
  background: ${STYLE_DEFAULTS.COLORS.SA_WHITE_B000};
  overflow-y: auto;
`;

export const BlankModalHeader = styled.div`
  position: relative;
`;

export const BlankModalTitle = styled.div`
  height: 60px;
  padding-right: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_10};
  border-bottom: 1px solid ${STYLE_DEFAULTS.COLORS.SA_B015};
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.HELVETICA_NEUE};
  font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.MEDIUM};
  font-size: 21px; // mobile & desktop
  text-align: center;
  padding-top: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_4};
`;

export const BlankModalClose = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 5;
`;
