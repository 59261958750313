//MODIFIED_FILE
import React, { useState, useEffect, useContext } from "react";
import {
  DesktopHeaderContainer,
  DesktopHeaderContent,
  DesktopHeaderMenu,
  HeaderLink,
  HeaderPreview,
  ButtonContainer,
} from "./style";
import Icon from "../../../../../icons";
import ICONS from "../../../../../../constants/icons";
import ReaderHeaderControls from "../ReaderHeaderControls";
import ReaderSearchBar from "../../readerSearch/readerSearchBar";
import Button from "../../../../../controls/button/Button";
import ReaderLayoutContext, { IReaderLayoutContext } from "./../../../readerLayoutContext";
import { observer } from "mobx-react-lite";
import { Detector } from "react-detect-offline";
import { useNavigate } from "react-router-dom";
import TRACKING_EVENTS from "../../../../../../services/segmentProvider/models/trackingEvents";
import BreadcrumbsStore from "../../../../../../stores/breadcrumbsStore";
import IBreadcrumb from "../../../../../../models/breadcrumb";
import { goBackFromReader, isAdmin } from "../../../../../../helpers/reader";
import ReactTooltip from "react-tooltip";
import ReaderStore from "../../../../../../stores/ReaderStore";
import CallToAction from "../../callToAction/CallToAction";

const backLabel = (breadcrumbs: Array<IBreadcrumb>) => {
  // Let's check what last page.
  const lastPath = breadcrumbs[breadcrumbs.length - 1]?.pathname;
  if (!lastPath) {
    return "Home";
  } else {
    return "Back";
  }
};

const DesktopMenu: React.FC = observer(() => {
  const context = useContext<IReaderLayoutContext>(ReaderLayoutContext);
  const [isPreview, setIsPreview] = useState(context.isPreview);
  


  const BreadcrumbsStoreContext = useContext(BreadcrumbsStore);
  const { reset, CallToActionButton } = useContext(ReaderStore);
  const { breadcrumbs } = BreadcrumbsStoreContext;

  let navigate = useNavigate();

  useEffect(() => {
    setIsPreview(context.isPreview);
  }, [context.isPreview]);

  return (
    <Detector
      polling={false}
      render={({ online }) => (
        <DesktopHeaderContainer>
          <DesktopHeaderContent>
            <DesktopHeaderMenu>
              <a data-tip={"You are currently in offline mode."} data-for={`header-back-tooltip`}>
              {context.showBackButton &&
              <HeaderLink onClick={() => {goBackFromReader(breadcrumbs, navigate); reset();}} isDisabled={!online}>
                  <Icon icon={ICONS.CHEVRON_LEFT} internalSize="20px" clickable disabled={!online} />
                  {backLabel(breadcrumbs)}
                </HeaderLink> }
              </a>
              {!online && (
                <ReactTooltip id={`header-back-tooltip`} place="bottom" type="dark" effect="solid" html delayHide={0} />
              )}
            </DesktopHeaderMenu>
            <ReaderHeaderControls isDisabled={context.isPreview || !online || isAdmin()} isOffline={!online} />
            <HeaderPreview>
              <ReaderSearchBar />
              {CallToActionButton.isEnabled && isPreview ? (
                <ButtonContainer>
                  <Button
                    primary
                    disabled={!online}
                    value={CallToActionButton.title}
                    width="120px"
                    onClick={() =>
                      navigate(CallToActionButton.url)
                    }
                  />
                </ButtonContainer>
              ) : !isAdmin() ? (
                <CallToAction />
              ) : null}
            </HeaderPreview>
          </DesktopHeaderContent>
        </DesktopHeaderContainer>
      )}
    />
  );
});

export default DesktopMenu;
