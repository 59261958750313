import _ from "lodash";
import cheerio from "cheerio";
import IHighlightMark from "../../../../models/highlightMark";
import IComment from "../../../../models/comment";
import {
  commentBadgeEventListener,
  highlightToolboxRemoveEventListeners,
  highlightCommentToolboxEventListener,
  highlightDeleteToolboxEventListener,
} from "./eventListeners";
import { PositionMarkers } from "../../layout/documentView/PositionMarkers";
import { DOCUMENT_MARKERS } from "../../../../constants/documentMarkers";
import getBrowser from "../../../../helpers/browser";
import env from "../../../../models/Configuration";
import * as segmentUtils from "utils/segmentUtils";
import TRACKING_EVENTS from "services/segmentProvider/models/trackingEvents";

export const SELECTION_HIGHLIGHT_STYLE = {
  SELECTED: "selected",
  SELECTED_YELLOW: "yellow",
  HIGHLIGHTED: "highlighted",
  HIGHLIGHT_SELECTED: "highlight-selected",
  ID: "highlight-",
  HIGHLIGHT_TOOLBOX: "highlight-toolbox-",
  HIGHLIGHT_COMMENT_BADGE: "highlight-comments-badge-",
};

export const MARKER_POPUP_CONTAINER_CLASS_NAME = "marker-popup-container";

export const SECTION_ID_PREFIX = "section-";
const COMMENTS_BADGE_HIDDEN_CLASS_NAME = "comments-badge-hidden";

const INFINITY = 100000000; // Just assume number higher than any expected, javascript Infinity does play well with logging
export const DEFAULT_SECTION_ID = 0;

export interface ISelectOrHighlight {
  highlightId?: number;
  startDataIndex: number;
  endDataIndex: number;
  startPreviousSiblingDataIndex: number | null;
  endPreviousSiblingDataIndex: number | null;
  startOffset: number;
  endOffset: number;
  selectionPlainText: string;
  comments?: any[];
  isInFocus?: boolean;
  // Indicate selection left to right, top to bottom (true)
  // or reverse (false)
  isAnchorBeforeFocus: boolean;
}

export const DEFAULT_SELECTION: ISelectOrHighlight = {
  selectionPlainText: "",
  startDataIndex: -1,
  endDataIndex: -1,
  startPreviousSiblingDataIndex: null,
  endPreviousSiblingDataIndex: null,
  startOffset: -1,
  endOffset: -1,
  isAnchorBeforeFocus: true,
};

/**
 * Helper for logging highlighting functionality tracing.
 *
 * @param params
 */
const algoDataLog = function (...params: any) {
  if (process.env.NODE_ENV === "development") {
    console.log(...params);
  }
};

/**
 * Helper for logging web reader optimisation tracing.
 *
 * @param params
 */
const optimisationLog = function (...params: any) {
  if (process.env.NODE_ENV === "development") {
    console.log(...params);
  }
};

export const toNumber = (value: string | null | undefined) => {
  let number = -1;

  if (value) {
    number = parseInt(value);

    if (isNaN(number)) {
      number = -1;
    }
  }

  return number;
};

/**
 * select left to right: vertical position growing or horizontal position growing from start to end
 * select right to left: vertical position lessening or horizontal position lessening from start to end
 *
 * @param props
 */
const isSelectingLeftToRight = (props: any) => {
  const { selectionStartLeft, selectionStartTop, selectionEndLeft, selectionEndTop } = props;
  // @ts-ignore
  const VERTICAL_DIFFERENCE_MIN = 20; // Assume a minimum vertical start/end distance: distance between lines in px
  // @ts-ignore
  const HORIZONTAL_DIFFERENCE_MIN = 5; // Assume a minimum horizontal distance: between chars in px (in same line)

  algoDataLog(
    `isSelectingLeftToRight: selectionStartLeft=${selectionStartLeft}; selectionStartTop=${selectionStartTop} selectionEndLeft=${selectionEndLeft}; selectionEndTop=${selectionEndTop}`,
  );

  return (
    selectionEndTop - selectionStartTop > VERTICAL_DIFFERENCE_MIN || // numbers grow down wards
    (Math.abs(selectionEndTop - selectionStartTop) < VERTICAL_DIFFERENCE_MIN &&
      selectionEndLeft - selectionStartLeft > HORIZONTAL_DIFFERENCE_MIN)
  );
};

/**
 * Check if user clicked on roughly the same location - consider this a double click
 *
 * @param props
 */
const checkDoubleClick = (props: any) => {
  const { selectionStartLeft, selectionStartTop, selectionEndLeft, selectionEndTop } = props;
  // @ts-ignore
  const VERTICAL_DIFFERENCE_MAX = 5; // Assume a maximal vertical start/end distance during double click
  // @ts-ignore
  const HORIZONTAL_DIFFERENCE_MAX = 5; // Assume a maximal horizontal distance during double click

  algoDataLog(
    `checkDoubleClick: selectionStartLeft=${selectionStartLeft}; selectionStartTop=${selectionStartTop} selectionEndLeft=${selectionEndLeft}; selectionEndTop=${selectionEndTop}`,
  );

  return (
    Math.abs(selectionEndTop - selectionStartTop) <= VERTICAL_DIFFERENCE_MAX && // numbers grow down wards
    Math.abs(selectionEndLeft - selectionStartLeft) <= HORIZONTAL_DIFFERENCE_MAX
  );
};

/**
 * Parse window.getSelection data for highlighting, specific implementation for IE11
 *
 * @param isAnchorBeforeFocus - selection left to right, top to bottom (true) or reverse (false)
 */
const extractSelectionDataFieldsInIe = (isAnchorBeforeFocus: boolean) => {
  try {
    const selectionData: any = window.getSelection();
    const selectionPlainText = selectionData.toString();

    algoDataLog("\n\nIE11 processing start, isAnchorBeforeFocus: ", isAnchorBeforeFocus);
    algoDataLog("selectionData: ", selectionData);

    const { startElement, endElement } = isAnchorBeforeFocus
      ? { startElement: selectionData?.anchorNode?.parentNode, endElement: selectionData?.focusNode?.parentNode }
      : { startElement: selectionData?.focusNode?.parentNode, endElement: selectionData?.anchorNode?.parentNode };
    const { startPreviousSibling, endPreviousSibling } = isAnchorBeforeFocus
      ? {
          startPreviousSibling: selectionData?.anchorNode?.previousSibling,
          endPreviousSibling: selectionData?.focusNode?.previousSibling,
        }
      : {
          startPreviousSibling: selectionData?.focusNode?.previousSibling,
          endPreviousSibling: selectionData?.anchorNode?.previousSibling,
        };
    const { startOffset, endOffset } = isAnchorBeforeFocus
      ? { startOffset: selectionData?.anchorOffset, endOffset: selectionData?.focusOffset }
      : { startOffset: selectionData?.focusOffset, endOffset: selectionData?.anchorOffset };
    const isStartsAfterHighlight =
      startPreviousSibling?.dataset?.parentIndex != null ? startPreviousSibling?.dataset?.parentIndex : false;
    const startDataIndex = toNumber(startElement?.dataset?.index);
    const startPreviousSiblingDataIndexRaw = isStartsAfterHighlight
      ? toNumber(startPreviousSibling?.dataset?.startPreviousSiblingIndex)
      : toNumber(startPreviousSibling?.dataset?.index);
    const startPreviousSiblingDataIndex =
      startPreviousSiblingDataIndexRaw >= 0 ? startPreviousSiblingDataIndexRaw : null;

    const endDataIndex = toNumber(endElement?.dataset?.index);
    const endPreviousSiblingDataIndex =
      endPreviousSibling?.dataset?.index != null
        ? toNumber(endPreviousSibling?.dataset?.index)
        : startPreviousSiblingDataIndex || null;

    algoDataLog(`\n\nIE11 processing end: 
        startDataIndex=${startDataIndex}
        endDataIndex=${endDataIndex}
        startOffset=${startOffset}
        endOffset=${endOffset}
        startPreviousSiblingDataIndex=${startPreviousSiblingDataIndex}
        endPreviousSiblingDataIndex=${endPreviousSiblingDataIndex}
        selectionPlainText=${selectionPlainText}
        \n\n`);

    return {
      startDataIndex,
      endDataIndex,
      startOffset,
      endOffset,
      startPreviousSiblingDataIndex,
      endPreviousSiblingDataIndex,
      selectionPlainText,
    };
  } catch (error) {
    algoDataLog("Error processing data selection in IE11: ", error?.message);

    return {
      startDataIndex: -1,
      endDataIndex: -1,
      startOffset: 0,
      endOffset: 0,
      startPreviousSiblingDataIndex: null,
      endPreviousSiblingDataIndex: null,
      selectionPlainText: "",
    };
  }
};

/**
 * Parse window.getSelection() data for highlighting
 *
 * @param isAnchorBeforeFocus
 */
const extractSelectionDataFields = (isAnchorBeforeFocus: boolean) => {
  const selectionData: any = window.getSelection();
  const selectionPlainText = selectionData.toString();

  algoDataLog("selectionData: ", selectionData);

  if (getBrowser() === "Microsoft Internet Explorer") {
    return extractSelectionDataFieldsInIe(isAnchorBeforeFocus);
  }

  const { startElement, endElement } = isAnchorBeforeFocus
    ? { startElement: selectionData?.anchorNode?.parentElement, endElement: selectionData?.focusNode?.parentElement }
    : { startElement: selectionData?.focusNode?.parentElement, endElement: selectionData?.anchorNode?.parentElement };
  const { startPreviousSibling, endPreviousSibling } = isAnchorBeforeFocus
    ? {
        startPreviousSibling: selectionData?.anchorNode?.previousElementSibling,
        endPreviousSibling: selectionData?.focusNode?.previousElementSibling,
      }
    : {
        startPreviousSibling: selectionData?.focusNode?.previousElementSibling,
        endPreviousSibling: selectionData?.anchorNode?.previousElementSibling,
      };
  const { startOffset, endOffset } = isAnchorBeforeFocus
    ? { startOffset: selectionData?.anchorOffset, endOffset: selectionData?.focusOffset }
    : { startOffset: selectionData?.focusOffset, endOffset: selectionData?.anchorOffset };
  const isStartsAfterHighlight = startPreviousSibling?.hasAttribute("data-parent-index") || false;
  const startDataIndex = toNumber(startElement?.getAttribute("data-index"));
  const startPreviousSiblingDataIndexRaw = isStartsAfterHighlight
    ? toNumber(startPreviousSibling?.getAttribute("data-start-previous-sibling-index"))
    : toNumber(startPreviousSibling?.getAttribute("data-index"));
  const startPreviousSiblingDataIndex = startPreviousSiblingDataIndexRaw >= 0 ? startPreviousSiblingDataIndexRaw : null;

  const endDataIndex = toNumber(endElement?.getAttribute("data-index"));
  const endPreviousSiblingDataIndex = endPreviousSibling?.hasAttribute("data-index")
    ? toNumber(endPreviousSibling?.getAttribute("data-index"))
    : startPreviousSiblingDataIndex || null;

  return {
    startDataIndex,
    endDataIndex,
    startOffset,
    endOffset,
    startPreviousSiblingDataIndex,
    endPreviousSiblingDataIndex,
    selectionPlainText,
    isAnchorBeforeFocus,
  };
};

/**
 * Notes:
 * 1. Anchor is where user starts the selection, focus is where selection ends:
 *      either from left to right/top to bottom  - anchor before focus - or
 *      right to left/bottom to top - focus before anchor
 *
 */
const parseSelectionData = (props: any) => {
  // In double click user clicks twice on roughly the same location
  const isDoubleClick: boolean = checkDoubleClick(props);
  const isAnchorBeforeFocus = isDoubleClick || isSelectingLeftToRight(props);

  const {
    startDataIndex,
    endDataIndex,
    startOffset,
    endOffset,
    startPreviousSiblingDataIndex,
    endPreviousSiblingDataIndex,
    selectionPlainText,
  } = extractSelectionDataFields(isAnchorBeforeFocus);

  algoDataLog(
    `startDataIndex=${startDataIndex};endDataIndex=${endDataIndex};startOffset=${startOffset};endOffset=${endOffset}`,
  );

  if (startDataIndex < 0 || endDataIndex < 0 || (startDataIndex === endDataIndex && endOffset === startOffset)) {
    // Invalid selection, or nothing is actually selected, do not process
    return null;
  }

  // In mobile devices, the start and end still get reversed sometimes, add guards:
  // only check start before end if props not fully provided, otherwise it is determined by props
  // NOTICE: when user selects in right direction (right to left, top to bottom), but
  // stops between the lines, window.getSelection() focus is the top of the parent section -
  // above the selection start, so it reverses the slection direction. Thus check isStartBeforeEndCorrect
  // even if all data is present in props
  const isStartBeforeEndCorrect =
    isDoubleClick ||
    startDataIndex < endDataIndex ||
    (startDataIndex === endDataIndex &&
      // @ts-ignore
      ((startPreviousSiblingDataIndex && startPreviousSiblingDataIndex < endPreviousSiblingDataIndex) ||
        (!startPreviousSiblingDataIndex && endPreviousSiblingDataIndex) ||
        (startPreviousSiblingDataIndex === endPreviousSiblingDataIndex && startOffset < endOffset))) ||
    (!startPreviousSiblingDataIndex && endPreviousSiblingDataIndex && endPreviousSiblingDataIndex >= startDataIndex);

  algoDataLog("isStartBeforeEndCorrect: ", isStartBeforeEndCorrect);

  const selection: ISelectOrHighlight = {
    startDataIndex: isStartBeforeEndCorrect ? startDataIndex : endDataIndex,
    endDataIndex: isStartBeforeEndCorrect ? endDataIndex : startDataIndex,
    startPreviousSiblingDataIndex: isStartBeforeEndCorrect
      ? startPreviousSiblingDataIndex
      : endPreviousSiblingDataIndex,
    endPreviousSiblingDataIndex: isStartBeforeEndCorrect ? endPreviousSiblingDataIndex : startPreviousSiblingDataIndex,
    startOffset: isStartBeforeEndCorrect ? startOffset : endOffset,
    endOffset: isDoubleClick
      ? startOffset + selectionPlainText.length
      : isStartBeforeEndCorrect
      ? endOffset
      : startOffset,
    selectionPlainText,
    isAnchorBeforeFocus,
  };

  //@ts-ignore
  algoDataLog("selection: ", selection);

  return selection;
};

export const validateSelectionOrHighlightParams = (selectOrHighlight: ISelectOrHighlight) => {
  let isValid = true;

  if (
    !selectOrHighlight ||
    selectOrHighlight.startOffset < 0 ||
    selectOrHighlight.endOffset < 0 ||
    !selectOrHighlight.startDataIndex ||
    !selectOrHighlight.endDataIndex ||
    isNaN(selectOrHighlight.startDataIndex) ||
    isNaN(selectOrHighlight.endDataIndex)
  ) {
    isValid = false;
  }

  return isValid;
};

/**
 *  We skip some tags in highlighting algo, e.g. table tag - processing it
 * will highlight the whole table
 *
 * @param el
 */
const shouldSkipSelectOrHighlightTag = (el: any) => {
  if (!el || !el[0]) {
    return true;
  }

  const tagsToSkipWhitelist = env && env.app && env.app.reactAppHTMLTagsSkipHighlightWhiteList?.split(",");

  if (tagsToSkipWhitelist?.indexOf(el[0].name)! > -1) {
    return true;
  }

  // Also we need to make sure the markers will not be selected.
  if (el[0].attribs.class) {
    // As classes can be multiple, let's split that by space.
    const classes = el[0].attribs.class.split(/\s*;\s*/);
    // If any of those classes is one of the position marker then we need to skip it.
    if (DOCUMENT_MARKERS.some((marker) => classes.indexOf(marker) > -1)) {
      return true;
    }
  }

  return false;
};

/**
 * Check whether tag is in highlight selection boundary
 *
 * 0: tag is in boundaries, -1: before start, 1: after end
 *
 * @param data
 */
const isTagOutOfBoundaries = (data: any) => {
  const {
    parentTagDataIndex,
    startPreviousSiblingDataIndex,
    endPreviousSiblingDataIndex, // can be null or index number
    elIndex, // null for text, number for tag
    endOffset,
    offsetFromCurrentTag,
  } = data;
  let result = 0;

  // Before the range
  if (startPreviousSiblingDataIndex && elIndex && elIndex <= startPreviousSiblingDataIndex) {
    result = -1;
  }

  // After the range
  if (
    (endPreviousSiblingDataIndex && elIndex && elIndex > endPreviousSiblingDataIndex) || //tag indicates that its outside the boundary
    (offsetFromCurrentTag >= endOffset && !endPreviousSiblingDataIndex) || // we already marked enough and endPreviousSiblingDataIndex not set - we are done
    (!endPreviousSiblingDataIndex && elIndex > parentTagDataIndex) // current element is after the parent and endPreviousSiblingDataIndex is not specified
  ) {
    result = 1;
  }

  //@ts-ignore
  algoDataLog(`isTagOutOfBoundaries: params=${JSON.stringify(data)}; result=${result} `);

  return result;
};

/**
 * Customised check for out of boundary, includes provided additional condition
 *
 * @param data
 */
const isTagOutOfBoundariesWithSpecialCondition = (data: any, condition: boolean | null) => {
  const {
    parentTagDataIndex,
    startPreviousSiblingDataIndex,
    endPreviousSiblingDataIndex, // can be null or index number
    elIndex, // null for text, number for tag
    endOffset,
    offsetFromCurrentTag,
  } = data;
  const isOutOfBoundaries = isTagOutOfBoundaries({
    parentTagDataIndex,
    startPreviousSiblingDataIndex,
    endPreviousSiblingDataIndex, // can be null or index number
    elIndex, // null for text, number for tag
    endOffset,
    offsetFromCurrentTag,
  });

  return condition ? 0 : isOutOfBoundaries;
};

/**
 * Check whether text should be marked for highlighting
 *
 * @param data
 */
const shouldBeMarked = (data: any) => {
  const {
    startOffset, // can be 0 or number
    endOffset, // can be number or INFINITY - to indicate till the end
    offsetFromCurrentTag, // can be null or index number
    elText, // "" for tag el, string for text el
    startPreviousSiblingDataIndex,
    lastProcessedPreviousSiblingDataIndex,
  } = data;

  //@ts-ignore
  algoDataLog(`shouldBeMarked: params=${JSON.stringify(data)}; `);

  if (startPreviousSiblingDataIndex && lastProcessedPreviousSiblingDataIndex < startPreviousSiblingDataIndex) {
    // Not yet reached the text to highlight
    return false;
  }

  if (offsetFromCurrentTag + elText.length < startOffset) {
    return false;
  }

  if (endOffset !== INFINITY && offsetFromCurrentTag >= endOffset) {
    return false;
  }

  return true;
};

/**
 * Component for displaying highlight select tool (when not in highlight mode)
 * Note: as it needs to be injected into the HTML content, using React was not suitable
 *
 * @param isAnchorBeforeFocus
 */
const textSelectPopover = (isAnchorBeforeFocus: boolean) => {
  return `<div id="text-select-popover">
        <div class="text-select-popup-container ${isAnchorBeforeFocus && "shift-left"}">
            <div class="icon_control">
                <div id="icon" data-testid="icon-highlight" data-cy="icon-highlight" class="icon-content inline-highlight">
                    <svg width="16" height="16" viewBox="0 0 16 16"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M14.578 7.627c.739 0 1.347.564 1.415 1.285l.007.137v4.109c0 .739-.564 1.346-1.285 1.415l-.137.007h-.853l-2.427 1.214a.475.475 0 0 1-.665-.283l-.017-.077-.004-.064v-.79H8.888a1.423 1.423 0 0 1-1.403-1.188l-.016-.137-.003-.097v-4.11c0-.738.564-1.346 1.285-1.415l.137-.006h5.689zM1.292 13.262l1.132 1.132-.767.766a.533.533 0 0 1-.425.154l-.788-.072a.488.488 0 0 1-.301-.83l1.15-1.15zM2.99 9.68l3.017 3.017-2.945 1.132a.533.533 0 0 1-.568-.12l-.516-.516a.533.533 0 0 1-.12-.568L2.989 9.68zm10.167 1.897H10.31a.474.474 0 0 0-.085.94l.085.008h2.845a.474.474 0 0 0 0-.948zM14.303.63l.754.754a1.6 1.6 0 0 1 0 2.263l-2.914 2.913-3.254.001-.188.008-.17.021c-1.18.188-2.049 1.138-2.126 2.293L6.4 9.05v3.253l-.017.017-3.017-3.017L12.04.63a1.6 1.6 0 0 1 2.263 0zM13.156 9.68H10.31a.474.474 0 0 0-.085.94l.085.008h2.845a.474.474 0 0 0 0-.948z"
                            fill="#58595B" fill-rule="evenodd"></path>
                    </svg>
                </div>
            </div>
        </div>
        <div class="text-select-popup-arrow bottom ${isAnchorBeforeFocus && "shift-left"}"></div>
    </div>`;
};

/**
 * Component for highlight comments badge
 * Note: as it needs to be injected into the HTML content, using React was not suitable
 *
 * @param props
 */
const highlightCommentsBadgeString = (props: {
  comments: IComment[];
  highlightId: number;
  isMobile: boolean;
  isInFocus: boolean;
}) => {
  const { comments, highlightId, isMobile, isInFocus } = props;

  return comments && comments.length // Insert code for highlight comments badge if there are comments, later attach event listener to the badge to click on it
    ? `<div id="highlight-comments-badge-${highlightId}" class="highlight-comments-badge${
        isInFocus ? ` badge-in-focus` : ``
      }" data-highlight-id="${highlightId}">
        <div class="highlight-badge-icon-control">
            <div id="icon" class="highlight-badge-icon-content" data-testid="icon-commentfilled" data-cy="icon-commentfilled">
                <svg width="16" height="16" viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M13 1.5a2.5 2.5 0 0 1 2.495 2.336L15.5 4v7.222a2.5 2.5 0 0 1-2.336 2.495l-.164.005h-1.499l-4.266 2.134a.834.834 0 0 1-1.179-.53l-.02-.101-.007-.114-.001-1.389H3a2.5 2.5 0 0 1-2.478-2.167l-.016-.162-.006-.17V4a2.5 2.5 0 0 1 2.336-2.495L3 1.5h10zm-2.5 6.944h-5a.833.833 0 0 0-.105 1.66l.105.007h5a.833.833 0 0 0 .105-1.66l-.105-.007zm0-3.333h-5a.833.833 0 0 0-.105 1.66l.105.007h5a.833.833 0 0 0 .105-1.66L10.5 5.11z"
                        fill="#58595B" fill-rule="nonzero"></path>
                </svg></div>
            </div>
        <div class="comments-badge-text ${isMobile ? " mobile" : ""}">${comments.length}</div>
    </div>`
    : "";
};

/**
 * Component for highlight toolbox, comments and delete actions
 * Note: as it needs to be injected into the HTML content, using React was not suitable
 *
 * @param highlightId
 * @param comments
 */
const highlightToolboxContentString = (highlightId: number, comments: IComment[]) => {
  return `<div  class="highlight-tools"  data-highlight-id="${highlightId}" style="display: inline-block;">
    <div id="highlight-toolbox-${highlightId}" class= "highlight-toolbox-container" style="display: inline-block;">
    <div class="${MARKER_POPUP_CONTAINER_CLASS_NAME} top">
        <div class="icon-control comments-icon${comments && comments.length ? " has-comments" : ""}">
        ${
          comments && comments.length
            ? `<div id="icon" data-testid="icon-commentfilled" data-cy="icon-commentfilled"
                class="icon-content"><svg width="16" height="16" viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M13 1.5a2.5 2.5 0 0 1 2.495 2.336L15.5 4v7.222a2.5 2.5 0 0 1-2.336 2.495l-.164.005h-1.499l-4.266 2.134a.834.834 0 0 1-1.179-.53l-.02-.101-.007-.114-.001-1.389H3a2.5 2.5 0 0 1-2.478-2.167l-.016-.162-.006-.17V4a2.5 2.5 0 0 1 2.336-2.495L3 1.5h10zm-2.5 6.944h-5a.833.833 0 0 0-.105 1.66l.105.007h5a.833.833 0 0 0 .105-1.66l-.105-.007zm0-3.333h-5a.833.833 0 0 0-.105 1.66l.105.007h5a.833.833 0 0 0 .105-1.66L10.5 5.11z"
                        fill="#58595B" fill-rule="nonzero"></path>
                </svg></div>`
            : `<div id="icon" data-testid="icon-commentfilled" data-cy="icon-commentfilled"
                class="icon-content highlight-comment"><?xml version="1.0" encoding="UTF-8"?>
                <svg width="16px" height="15px" viewBox="0 0 16 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <desc>Created with Sketch.</desc>
                    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="icon_export/16/comment" transform="translate(0.000000, -1.000000)" fill="#58595B" fill-rule="nonzero">
                            <path d="M13,1.5 C14.3254834,1.5 15.4100387,2.53153594 15.4946823,3.83562431 L15.5,4 L15.5,11.2222222 C15.5,12.5477056 14.4684641,13.6322609 13.1643757,13.7169045 L13,13.7222222 L11.5011111,13.7222222 L7.23471142,15.8564671 C6.74772331,16.0999612 6.18515394,15.818206 6.05604772,15.3258083 L6.03615681,15.2246135 L6.02870009,15.1111111 L6.02777778,13.7222222 L3,13.7222222 C1.73195038,13.7222222 0.684402191,12.7781434 0.521890956,11.5545212 L0.505767569,11.3933876 L0.5,11.2222222 L0.5,4 C0.5,2.6745166 1.53153594,1.58996133 2.83562431,1.50531768 L3,1.5 L13,1.5 Z M13,3.16666667 L3,3.16666667 C2.57516558,3.16666667 2.22458169,3.48457099 2.17315952,3.89546844 L2.16666667,4 L2.16666667,11.2222222 C2.16666667,11.6470566 2.48457099,11.9976405 2.89546844,12.0490627 L3,12.0555556 L6.86203342,12.0555556 C7.28686784,12.0555556 7.63745173,12.3734599 7.6888739,12.7843573 L7.69536675,12.8888889 L7.69444444,13.7633333 L10.9337999,12.1435329 C10.9852278,12.1178189 11.0390027,12.0975836 11.0942374,12.0830363 L11.1780734,12.0655075 L11.3064779,12.0555556 L13,12.0555556 C13.4248344,12.0555556 13.7754183,11.7376512 13.8268405,11.3267538 L13.8333333,11.2222222 L13.8333333,4 C13.8333333,3.57516558 13.515429,3.22458169 13.1045316,3.17315952 L13,3.16666667 Z M10.5,8.44444444 C10.9602373,8.44444444 11.3333333,8.81754049 11.3333333,9.27777778 C11.3333333,9.73801507 10.9602373,10.1111111 10.5,10.1111111 L5.5,10.1111111 C5.03976271,10.1111111 4.66666667,9.73801507 4.66666667,9.27777778 C4.66666667,8.81754049 5.03976271,8.44444444 5.5,8.44444444 L10.5,8.44444444 Z M10.5,5.11111111 C10.9602373,5.11111111 11.3333333,5.48420715 11.3333333,5.94444444 C11.3333333,6.40468174 10.9602373,6.77777778 10.5,6.77777778 L5.5,6.77777778 C5.03976271,6.77777778 4.66666667,6.40468174 4.66666667,5.94444444 C4.66666667,5.48420715 5.03976271,5.11111111 5.5,5.11111111 L10.5,5.11111111 Z" id="Shape"></path>
                        </g>
                    </g>
                </svg></div>`
        }
            </div>
            <div id="highlight-delete-icon-${highlightId}" class="icon-control delete-icon">
                <div id="icon" data-testid="icon-bin" data-cy="icon-bin" class="icon-content highlight-delete"><svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <desc>Created with Sketch.</desc>
                <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="icon_export/16/bin" fill="#58595B" fill-rule="nonzero">
                        <path d="M10.4996701,0 C10.8051313,0 11.0591828,0.235833547 11.1118675,0.54683249 L11.1218924,0.666666667 L11.1216667,2 L14.8461111,2 C15.189755,2 15.4683333,2.29847683 15.4683333,2.66666667 C15.4683333,3.0348565 15.189755,3.33333333 14.8461111,3.33333333 L13.6272667,3.33257143 L13.6273394,15.3333333 C13.6273394,15.6606132 13.4072281,15.9328112 13.1169624,15.9892591 L13.0051172,16 L3.04956163,16 C2.74410044,16 2.49004899,15.7641665 2.43736424,15.4531675 L2.42733941,15.3333333 L2.42726667,3.33257143 L1.15722222,3.33333333 C0.813578378,3.33333333 0.535,3.0348565 0.535,2.66666667 C0.535,2.29847683 0.813578378,2 1.15722222,2 L4.8998,2 L4.90004991,0.666666667 C4.90004991,0.339386815 5.12016122,0.0671888395 5.4104269,0.0107408926 L5.52227214,0 L10.4996701,0 Z M12.3824667,3.33257143 L3.671,3.33257143 L3.671,14.6666667 L12.3821111,14.6666667 L12.3824667,3.33257143 Z M5.51277778,5.33333333 C5.85642162,5.33333333 6.135,5.63181017 6.135,6 L6.135,12.6666667 C6.135,13.0348565 5.85642162,13.3333333 5.51277778,13.3333333 C5.16913393,13.3333333 4.89055556,13.0348565 4.89055556,12.6666667 L4.89055556,6 C4.89055556,5.63181017 5.16913393,5.33333333 5.51277778,5.33333333 Z M8.00166667,5.33333333 C8.34531051,5.33333333 8.62388889,5.63181017 8.62388889,6 L8.62388889,12.6666667 C8.62388889,13.0348565 8.34531051,13.3333333 8.00166667,13.3333333 C7.65802282,13.3333333 7.37944444,13.0348565 7.37944444,12.6666667 L7.37944444,6 C7.37944444,5.63181017 7.65802282,5.33333333 8.00166667,5.33333333 Z M10.4905556,5.33333333 C10.8341994,5.33333333 11.1127778,5.63181017 11.1127778,6 L11.1127778,12.6666667 C11.1127778,13.0348565 10.8341994,13.3333333 10.4905556,13.3333333 C10.1469117,13.3333333 9.86833333,13.0348565 9.86833333,12.6666667 L9.86833333,6 C9.86833333,5.63181017 10.1469117,5.33333333 10.4905556,5.33333333 Z M9.87704444,1.33333333 L6.14371111,1.33333333 L6.14353333,2 L9.87686667,2 L9.87704444,1.33333333 Z" id="icon"></path>
                    </g>
                </g>
            </svg></div>
            </div>
    </div>
    <div class="marker-popover-arrow bottom"></div>
    </div></div>`;
};

/**
 * Mark highlight in text element
 *
 * @param data
 */
const markInTextElement = (data: any) => {
  const {
    parentTagDataIndex,
    startPreviousSiblingDataIndex,
    lastProcessedPreviousSiblingDataIndex,
    startOffset,
    endOffset,
    offsetFromCurrentTag,
    element,
    $,
    isSelect,
    highlightId,
    addSpanId,
    isInFocus,
    comments,
    isMobile,
  } = data;

  algoDataLog(`markInTextElement: start`);

  //el.type === "text"
  const isToBeMarked = shouldBeMarked({
    startOffset,
    endOffset,
    offsetFromCurrentTag,
    elText: element.type === "text" ? element.data : "", // "" for tag el, string for text el
    startPreviousSiblingDataIndex,
    lastProcessedPreviousSiblingDataIndex,
  });

  //@ts-ignore
  algoDataLog(`markInTextElement: 
  isToBeMarked=${isToBeMarked}
  parentTagDataIndex=${parentTagDataIndex}
  startPreviousSiblingDataIndex=${startPreviousSiblingDataIndex}
  lastProcessedPreviousSiblingDataIndex=${lastProcessedPreviousSiblingDataIndex}
  startOffset=${startOffset}
  endOffset=${endOffset}
  offsetFromCurrentTag=${offsetFromCurrentTag}
  element data=${element.data}
  `);

  let processedTextLength = 0;

  if (isToBeMarked) {
    const tagContent = $(element).text(); // Notice: .html() returns content with internal tags, while .text() - only text
    const markStartAt = startOffset;
    const markEndAt = Math.min(endOffset, offsetFromCurrentTag + element.data.length);
    const textInMarking = tagContent.substring(markStartAt, markEndAt);

    // sometimes content is just empty space, in this case do not insert actual spans,
    // but process as usual
    if (!_.trim(tagContent).length) {
      return {
        updatedElement: $(tagContent),
        processedTextLength: markEndAt - markStartAt,
      };
    }

    const openingUnchanged = tagContent.substring(0, markStartAt);
    const endingUnchanged = tagContent.substring(markEndAt, element.data.length);
    const openingSpan = isSelect
      ? `<span class="${SELECTION_HIGHLIGHT_STYLE.SELECTED}" >`
      : `<span ${
          addSpanId ? `id="${SELECTION_HIGHLIGHT_STYLE.ID}${highlightId}"` : ""
        } style="position: relative;" data-highlight-start-index="${highlightId}" data-parent-index="${parentTagDataIndex}" data-start-previous-sibling-index="${startPreviousSiblingDataIndex}" data-highlight-end-offset="${endOffset}" class="${
          SELECTION_HIGHLIGHT_STYLE.HIGHLIGHTED
        }" >`;
    const highlightCommentsBadge = addSpanId
      ? highlightCommentsBadgeString({
          comments,
          highlightId,
          isMobile,
          isInFocus,
        })
      : "";
    const toolbox = addSpanId ? highlightToolboxContentString(highlightId, comments) : "";
    const closingSpan = "</span>";

    algoDataLog(`textInMarking=${textInMarking}, length=${textInMarking.length}`);

    const updatedContent =
      process.env.NODE_ENV !== "test"
        ? openingUnchanged +
          openingSpan +
          highlightCommentsBadge +
          toolbox +
          textInMarking +
          closingSpan +
          endingUnchanged
        : openingUnchanged + openingSpan + textInMarking + closingSpan + endingUnchanged;

    processedTextLength = markEndAt - markStartAt;

    //@ts-ignore
    algoDataLog(
      `markInTextElement: element.data =${element.data} markStartAt=${markStartAt} markEndAt=${markEndAt} processedTextLength=${processedTextLength}`,
    );

    algoDataLog(`markInTextElement: end, updated`);

    // Create new element with additional tags
    return {
      updatedElement: $(updatedContent),
      processedTextLength,
    };
  }

  algoDataLog(`markInTextElement: end, not updated`);

  return {
    updatedElement: $(element),
    processedTextLength,
  };
};

/**
 * Recursively mark full content of tag, including all children
 *
 * @param props
 */
const markFullTag = (props: {
  tagData: any;
  $: any;
  isSelect: boolean;
  highlightId: number | null;
  addSpanId: boolean;

  // only relevant for styling if addSpanId is true
  isMobile: boolean;
}) => {
  const { tagData, $, isSelect, highlightId, addSpanId, isMobile } = props;
  const { parentTagDataIndex, startPreviousSiblingDataIndex, comments, isInFocus } = tagData;
  const searchAttr = `[data-index=${parentTagDataIndex}]`;
  const parentEl = $(searchAttr).first();
  let isIdAdded = false;

  if (shouldSkipSelectOrHighlightTag(parentEl)) {
    return;
  }

  //@ts-ignore
  algoDataLog(`markFullTag: tagData=${JSON.stringify(tagData)}`);

  const parentElContentsArr = parentEl.contents();

  if (!parentElContentsArr || !parentElContentsArr.length) {
    return;
  }

  const newContent = parentElContentsArr.map((index: number, el: any) => {
    if (el.type === "tag") {
      // Update start and end offsets based on previous sibling data index:
      // whether the next text should be included or excluded
      const elIndex = el.type === "tag" ? toNumber(el.attribs["data-index"]) : null;

      //@ts-ignore
      algoDataLog(`markFullTag: tag: index=${elIndex} el.name=${el.name}; attr=${JSON.stringify(el.attribs)}`);

      markFullTag({
        tagData: {
          parentTagDataIndex: elIndex,
          startPreviousSiblingDataIndex: null,
          endPreviousSiblingDataIndex: null,
          startOffset: 0,
          endOffset: INFINITY,
        },
        $,
        isSelect,
        highlightId,
        addSpanId: false,
        isMobile: false,
      });
    } else {
      const { updatedElement, processedTextLength } = markInTextElement({
        parentTagDataIndex,
        startPreviousSiblingDataIndex,
        lastProcessedPreviousSiblingDataIndex: 0,
        startOffset: 0,
        endOffset: INFINITY,
        offsetFromCurrentTag: 0,
        element: el,
        $,
        isSelect,
        highlightId,
        addSpanId: addSpanId && !isIdAdded,
        comments,
        isMobile,
        isInFocus,
      });

      if (processedTextLength) {
        isIdAdded = true;
      }

      return updatedElement;
    }

    return el;
  });

  parentEl.empty(); // remove previous content and
  // replace with modified content
  newContent.each((index: number, el: any) => {
    parentEl.append($(el));
  });
};

/**
 * Mark highlight in a tag element
 *
 * @param props
 */
const markTagElement = (props: {
  currentElement: any;
  parentTagDataIndex: number;
  startPreviousSiblingDataIndex: number;
  endPreviousSiblingDataIndex: number;
  offsetFromCurrentTag: number;
  endOffset: number;
  passedTheRange: boolean;
  lastProcessedPreviousSiblingDataIndex: number;
  inBoundariesCondition: boolean | null;
}) => {
  const {
    currentElement,
    parentTagDataIndex,
    startPreviousSiblingDataIndex,
    endPreviousSiblingDataIndex,
    endOffset,
    inBoundariesCondition,
  } = props;

  let { offsetFromCurrentTag, passedTheRange, lastProcessedPreviousSiblingDataIndex } = props;

  // Update start and end offsets based on previous sibling data index:
  // whether the next text should be included or excluded
  const elIndex = currentElement.type === "tag" ? toNumber(currentElement.attribs["data-index"]) : null;
  const isOutOfBoundaries = isTagOutOfBoundariesWithSpecialCondition(
    {
      parentTagDataIndex,
      startPreviousSiblingDataIndex,
      endPreviousSiblingDataIndex, // can be null or index number
      elIndex, // null for text, number for tag
      endOffset,
      offsetFromCurrentTag,
    },
    inBoundariesCondition,
  );

  if (!isOutOfBoundaries) {
    offsetFromCurrentTag = 0; // New tag resets the offset - thats getSelection behavior
  } else if (isOutOfBoundaries > 0) {
    passedTheRange = true;
  }

  lastProcessedPreviousSiblingDataIndex = elIndex ?? 0;

  //@ts-ignore
  algoDataLog(
    `markTagElement: tag: index=${elIndex} currentElement.name=${currentElement.name}; attr=${JSON.stringify(
      currentElement.attribs,
    )}`,
  );

  //@ts-ignore
  algoDataLog(
    `markTagElement, tag element: isOutOfBoundaries=${isOutOfBoundaries}; offsetFromCurrentTag=${offsetFromCurrentTag}, lastProcessedPreviousSiblingDataIndex=${lastProcessedPreviousSiblingDataIndex}`,
  );

  return {
    offsetFromCurrentTag,
    lastProcessedPreviousSiblingDataIndex,
    passedTheRange,
  };
};

/**
 * Mark in HTML tag for selection or highlight, from startPreviousSiblingDataIndex or tag start offset
 * till endPreviousSiblingDataIndex or offset
 *
 * Note: Add highlight id in only first span for highlighting (for side panel navigation) - since tag ids need to be unique
 *
 * @param tagData
 * @param $
 * @param isSelect
 * @param addSpanId - indicates first span of the select/highlight, use it to add or identify elements, e.g. comments badge
 */
const markInTag = (props: {
  tagData: any;
  $: any;
  isSelect: boolean;
  highlightId: number | null;
  addSpanId: boolean;

  // only relevant for styling if addSpanId is true
  isMobile: boolean;
}) => {
  const { tagData, $, isSelect, highlightId, addSpanId, isMobile } = props;
  const {
    parentTagDataIndex,
    startPreviousSiblingDataIndex,
    endPreviousSiblingDataIndex,
    startOffset,
    endOffset,
    comments,
    isInFocus,
  } = tagData;
  const searchAttr = `[data-index=${parentTagDataIndex}]`;
  const parentEl = $(searchAttr).first();
  let isIdAdded = false;

  if (shouldSkipSelectOrHighlightTag(parentEl)) {
    return;
  }

  //@ts-ignore
  algoDataLog(`markInTag: tagData=${JSON.stringify(tagData)}`);

  const parentElContentsArr = parentEl.contents();
  let offsetFromCurrentTag = 0;
  let lastProcessedPreviousSiblingDataIndex = 0;
  let passedTheRange = false;

  const newContent = parentElContentsArr.map((index: number, el: any) => {
    if (!passedTheRange) {
      if (el.type === "tag") {
        const result = markTagElement({
          currentElement: el,
          parentTagDataIndex,
          startPreviousSiblingDataIndex,
          endPreviousSiblingDataIndex,
          offsetFromCurrentTag,
          endOffset,
          passedTheRange,
          lastProcessedPreviousSiblingDataIndex,
          inBoundariesCondition: null, // no special condition in this case
        });

        offsetFromCurrentTag = result.offsetFromCurrentTag;
        lastProcessedPreviousSiblingDataIndex = result.lastProcessedPreviousSiblingDataIndex;
        passedTheRange = result.passedTheRange;

        algoDataLog(`markInTag: tagData=${JSON.stringify(tagData)}`);
      } else {
        const { updatedElement, processedTextLength } = markInTextElement({
          parentTagDataIndex,
          startPreviousSiblingDataIndex,
          lastProcessedPreviousSiblingDataIndex,
          startOffset:
            // Processing in child element (though not the starting portion of the selection), start from the beginning
            lastProcessedPreviousSiblingDataIndex &&
            lastProcessedPreviousSiblingDataIndex > startPreviousSiblingDataIndex
              ? 0
              : startOffset,
          endOffset:
            // if endPreviousSiblingDataIndex is set and we did not reach it yet, process all content of the element
            endPreviousSiblingDataIndex && lastProcessedPreviousSiblingDataIndex < endPreviousSiblingDataIndex
              ? parentTagDataIndex > endPreviousSiblingDataIndex
                ? endOffset
                : INFINITY
              : endOffset,
          offsetFromCurrentTag,
          element: el,
          $,
          isSelect,
          highlightId,
          addSpanId: addSpanId && !isIdAdded,
          comments,
          isMobile,
          isInFocus,
        });

        if (processedTextLength) {
          offsetFromCurrentTag += processedTextLength;
          isIdAdded = true;
        }

        return updatedElement;
      }
    }

    return el;
  });

  parentEl.empty(); // remove previous content and
  // replace with modified content
  newContent.each((index: number, el: any) => {
    parentEl.append($(el));
  });
};

const markInTagForStartIndex = (
  tagData: any,
  $: any,
  isSelect: boolean,
  highlightId: number | null,
  addSpanId: boolean,
  isMobile: boolean,
) => {
  const {
    startDataIndex,
    parentTagDataIndex,
    startPreviousSiblingDataIndex,
    endPreviousSiblingDataIndex,
    startOffset,
    endOffset,
    comments,
    isInFocus,
  } = tagData;
  const searchAttr = `[data-index=${parentTagDataIndex}]`;
  const parentEl = $(searchAttr).first();
  let isIdAdded = false;

  if (shouldSkipSelectOrHighlightTag(parentEl)) {
    return;
  }

  //@ts-ignore
  algoDataLog(`markInTagForStartIndex: tagData=${JSON.stringify(tagData)}`);

  const parentElContentsArr = parentEl.contents();
  let offsetFromCurrentTag = 0;
  let lastProcessedPreviousSiblingDataIndex = 0;
  let passedTheRange = false;

  const newContent = parentElContentsArr.map((index: number, el: any) => {
    if (!passedTheRange) {
      if (el.type === "tag") {
        // Update start and end offsets based on previous sibling data index:
        // whether the next text should be included or excluded
        const elIndex = el.type === "tag" ? toNumber(el.attribs["data-index"]) : null;
        const result = markTagElement({
          currentElement: el,
          parentTagDataIndex,
          startPreviousSiblingDataIndex,
          endPreviousSiblingDataIndex,
          offsetFromCurrentTag,
          endOffset,
          passedTheRange,
          lastProcessedPreviousSiblingDataIndex,
          inBoundariesCondition: elIndex !== null && elIndex < startDataIndex,
        });

        offsetFromCurrentTag = result.offsetFromCurrentTag;
        lastProcessedPreviousSiblingDataIndex = result.lastProcessedPreviousSiblingDataIndex;
        passedTheRange = result.passedTheRange;
      } else {
        const { updatedElement, processedTextLength } = markInTextElement({
          parentTagDataIndex,
          startPreviousSiblingDataIndex: lastProcessedPreviousSiblingDataIndex,
          lastProcessedPreviousSiblingDataIndex,
          startOffset:
            // Processing in child element (though not the starting portion of the selection), start from the beginning
            lastProcessedPreviousSiblingDataIndex &&
            lastProcessedPreviousSiblingDataIndex >= startDataIndex &&
            lastProcessedPreviousSiblingDataIndex <= endPreviousSiblingDataIndex
              ? 0
              : startOffset,
          endOffset:
            lastProcessedPreviousSiblingDataIndex < startDataIndex
              ? 0
              : // if endPreviousSiblingDataIndex is set and we did not reach it yet, process all content of the element
              lastProcessedPreviousSiblingDataIndex >= startDataIndex &&
                lastProcessedPreviousSiblingDataIndex < endPreviousSiblingDataIndex
              ? INFINITY
              : endOffset,
          offsetFromCurrentTag,
          element: el,
          $,
          isSelect,
          highlightId,
          addSpanId: addSpanId && !isIdAdded,
          comments,
          isMobile,
          isInFocus,
        });

        if (
          processedTextLength &&
          lastProcessedPreviousSiblingDataIndex >= startDataIndex &&
          lastProcessedPreviousSiblingDataIndex === endPreviousSiblingDataIndex
        ) {
          offsetFromCurrentTag += processedTextLength;
          isIdAdded = true;
        }

        return updatedElement;
      }
    }

    return el;
  });

  parentEl.empty(); // remove previous content and
  // replace with modified content
  newContent.each((index: number, el: any) => {
    parentEl.append($(el));
  });
};

const markInTagForEndIndex = (
  tagData: any,
  $: any,
  isSelect: boolean,
  highlightId: number | null,
  addSpanId: boolean,
  isMobile: boolean,
) => {
  const {
    parentTagDataIndex,
    endDataIndex,
    startPreviousSiblingDataIndex,
    endPreviousSiblingDataIndex,
    startOffset,
    endOffset,
    comments,
    isInFocus,
  } = tagData;
  const searchAttr = `[data-index=${parentTagDataIndex}]`;
  const parentEl = $(searchAttr).first();
  let isIdAdded = false;

  if (shouldSkipSelectOrHighlightTag(parentEl)) {
    return;
  }

  const parentElContentsArr = parentEl.contents();
  let offsetFromCurrentTag = 0;
  let lastProcessedPreviousSiblingDataIndex = 0;
  let passedTheRange = false;

  const newContent = parentElContentsArr.map((index: number, el: any) => {
    if (!passedTheRange) {
      if (el.type === "tag") {
        // Update start and end offsets based on previous sibling data index:
        // whether the next text should be included or excluded
        const elIndex = el.type === "tag" ? toNumber(el.attribs["data-index"]) : null;
        const result = markTagElement({
          currentElement: el,
          parentTagDataIndex,
          startPreviousSiblingDataIndex,
          endPreviousSiblingDataIndex,
          offsetFromCurrentTag,
          endOffset,
          passedTheRange,
          lastProcessedPreviousSiblingDataIndex,
          inBoundariesCondition: (elIndex ?? endDataIndex) < endDataIndex,
        });

        offsetFromCurrentTag = result.offsetFromCurrentTag;
        lastProcessedPreviousSiblingDataIndex = result.lastProcessedPreviousSiblingDataIndex;
        passedTheRange = result.passedTheRange;
      } else {
        algoDataLog(
          `markInTagForEndIndex: tagData=${JSON.stringify(
            tagData,
          )} lastProcessedPreviousSiblingDataIndex: ${lastProcessedPreviousSiblingDataIndex}`,
        );

        // Already processed beyond the intended end of selection
        // if (!startPreviousSiblingDataIndex && !endPreviousSiblingDataIndex && lastProcessedPreviousSiblingDataIndex) {
        //   return el;
        // }

        const { updatedElement, processedTextLength } = markInTextElement({
          parentTagDataIndex,
          startPreviousSiblingDataIndex,
          lastProcessedPreviousSiblingDataIndex,
          startOffset:
            // Processing in child element (though not the starting portion of the selection), start from the beginning
            lastProcessedPreviousSiblingDataIndex &&
            lastProcessedPreviousSiblingDataIndex > startPreviousSiblingDataIndex
              ? 0
              : startOffset,
          endOffset:
            lastProcessedPreviousSiblingDataIndex === endDataIndex ||
            lastProcessedPreviousSiblingDataIndex < startPreviousSiblingDataIndex
              ? 0
              : // if endPreviousSiblingDataIndex is set and we did not reach it yet, process all content of the element
              (endPreviousSiblingDataIndex && lastProcessedPreviousSiblingDataIndex < endPreviousSiblingDataIndex) ||
                lastProcessedPreviousSiblingDataIndex < endDataIndex
              ? INFINITY
              : endOffset,
          offsetFromCurrentTag,
          element: el,
          $,
          isSelect,
          highlightId,
          addSpanId: addSpanId && !isIdAdded,
          comments,
          isMobile,
          isInFocus,
        });

        if (processedTextLength) {
          offsetFromCurrentTag += processedTextLength;
          isIdAdded = true;
        }

        return updatedElement;
      }
    }

    return el;
  });

  parentEl.empty(); // remove previous content and
  // replace with modified content
  newContent.each((index: number, el: any) => {
    parentEl.append($(el));
  });
};

/**
 * Mark full HTML tag for selection or highlight
 *
 * @param startTagIndex
 * @param endTagIndex
 * @param $
 * @param isSelect
 */
const markFullTags = (
  startTagIndex: number,
  endTagIndex: number,
  endPreviousSiblingDataIndex: number | null,
  $: any,
  isSelect: boolean,
  highlightId: number | null,
) => {
  //@ts-ignore
  algoDataLog(`markFullTags: startTagIndex=${startTagIndex}; endTagIndex=${endTagIndex}`);

  for (let index = startTagIndex; index <= endTagIndex; index += 1) {
    markFullTag({
      tagData: {
        parentTagDataIndex: index,
        startPreviousSiblingDataIndex: null,
        endPreviousSiblingDataIndex,
        startOffset: 0,
        endOffset: INFINITY,
      },
      $,
      isSelect,
      highlightId: highlightId || null,
      addSpanId: false,
      isMobile: false,
    });
  }
};

/**
 * When highlight or selection start and end in the same HTML tag
 * @param selectOrHighlight
 * @param $
 * @param isSelect
 */
const markStartAndEndInSameTag = (
  selectOrHighlight: ISelectOrHighlight,
  $: any,
  isSelect: boolean,
  isMobile: boolean,
) => {
  //@ts-ignore
  algoDataLog(`markStartAndEndInSameTag: selectOrHighlight=${JSON.stringify(selectOrHighlight)}`);

  markInTag({
    tagData: {
      parentTagDataIndex: selectOrHighlight.startDataIndex,
      startPreviousSiblingDataIndex: selectOrHighlight.startPreviousSiblingDataIndex,
      endPreviousSiblingDataIndex: selectOrHighlight.endPreviousSiblingDataIndex,
      startOffset: selectOrHighlight.startOffset,
      endOffset: selectOrHighlight.endOffset,
      comments: selectOrHighlight.comments,
      isInFocus: selectOrHighlight.isInFocus,
    },
    $,
    isSelect,
    highlightId: selectOrHighlight.highlightId || null,
    addSpanId: true,
    isMobile: isMobile,
  });
};

/**
 * Mark selection/highlight start
 * @param selectOrHighlight
 * @param $
 * @param isSelect
 */
const markStart = (selectOrHighlight: ISelectOrHighlight, $: any, isSelect: boolean, isMobile: boolean) => {
  //@ts-ignore
  algoDataLog(`markStart: selectOrHighlight=${JSON.stringify(selectOrHighlight)}`);

  markInTag({
    tagData: {
      parentTagDataIndex: selectOrHighlight.startDataIndex,
      startPreviousSiblingDataIndex: selectOrHighlight.startPreviousSiblingDataIndex,
      endPreviousSiblingDataIndex: INFINITY,
      startOffset: selectOrHighlight.startOffset,
      endOffset: INFINITY,
      comments: selectOrHighlight.comments,
      isInFocus: selectOrHighlight.isInFocus,
    },
    $,
    isSelect,
    highlightId: selectOrHighlight.highlightId || null,
    addSpanId: true,
    isMobile: isMobile,
  });
};

/**
 * Mark selection/highlight in end elem children
 *  It is possible for endPreviousSiblingDataIndex to be higher than endDataIndex, see example below
 * In this case selectOrHighlight.endPreviousSiblingDataIndex will be higher than end tag, need to mark full tags
 * between selectOrHighlight.endDataIndex and selectOrHighlight.endPreviousSiblingDataIndex
 *
 *  <div class="sts-p " data-index="72">
 *    (Source:
 *        <a class="sts-std-ref" href="product/SA TS%C2%A090005.3.2:2014" data-index="73">
 *            SA TS&nbsp;90005.3.2:2014
 *        </a>
 *    )
 *  </div>
 *  )
 *
 * @param selectOrHighlight
 * @param $
 * @param isSelect
 */
const markEndElementChildren = (selectOrHighlight: ISelectOrHighlight, $: any, isSelect: boolean) => {
  if (
    selectOrHighlight.endPreviousSiblingDataIndex &&
    selectOrHighlight.endPreviousSiblingDataIndex > selectOrHighlight.endDataIndex
  ) {
    const start = selectOrHighlight.startPreviousSiblingDataIndex
      ? selectOrHighlight.startPreviousSiblingDataIndex + 1
      : selectOrHighlight.endDataIndex + 1;
    const end = selectOrHighlight.endPreviousSiblingDataIndex;

    //@ts-ignore
    algoDataLog(`markEndElementChildren: selection includes children tags from=${start} to=${end}`);

    markFullTags(
      start,
      end,
      selectOrHighlight.endPreviousSiblingDataIndex,
      $,
      isSelect,
      selectOrHighlight.highlightId || null,
    );
  }
};

/**
 * Mark selection/highlight end
 * @param selectOrHighlight
 * @param $
 * @param isSelect
 */
const markEnd = (selectOrHighlight: ISelectOrHighlight, $: any, isSelect: boolean) => {
  //@ts-ignore
  algoDataLog(`markEnd: selectOrHighlight=${JSON.stringify(selectOrHighlight)}`);

  markInTag({
    tagData: {
      parentTagDataIndex: selectOrHighlight.endDataIndex,
      startPreviousSiblingDataIndex: null,
      endPreviousSiblingDataIndex: selectOrHighlight.endPreviousSiblingDataIndex,
      startOffset: 0,
      endOffset: selectOrHighlight.endOffset,
    },
    $,
    isSelect,
    highlightId: selectOrHighlight.highlightId || null,
    addSpanId: false,
    isMobile: false,
  });

  markEndElementChildren(selectOrHighlight, $, isSelect);
};

const getElementParentDataIndex = (dataIndex: number, $: any) => {
  const searchAttr = `[data-index=${dataIndex}]`;

  return $(searchAttr).parent().attr("data-index");
};

/**
 * Mark content in parent between 2 specified nodes
 *
 * Example:
 *
 *<p data-index="1">parent  para with some text
 *    <p data-index="2">(*) Second normal para with some text</p>
 *      in between two child nodes
 *    <p data-index="3">Third normal para with some text(**)</p>
 * </p>
 *
 * Where the highlight is from location (*) to (**). This function
 * marks content in index=1 between end of index=2 and start of index=3
 *
 * @param selectOrHighlight
 * @param $
 * @param isSelect
 */
const markInParent = (selectOrHighlight: ISelectOrHighlight, $: any, isSelect: boolean) => {
  //@ts-ignore
  algoDataLog(`markEnd: markInParent=${JSON.stringify(selectOrHighlight)}`);

  const startParentDataIndex = getElementParentDataIndex(selectOrHighlight.startDataIndex, $);

  const startIndex = selectOrHighlight.startDataIndex;
  const endIndex = selectOrHighlight.endDataIndex;

  for (let i = startIndex; i < endIndex; i += 1) {
    markInTag({
      tagData: {
        parentTagDataIndex: startParentDataIndex,
        startPreviousSiblingDataIndex: i,
        endPreviousSiblingDataIndex: i + 1,
        startOffset: 0,
        endOffset: 0,
      },
      $,
      isSelect,
      highlightId: selectOrHighlight.highlightId || null,
      addSpanId: false,
      isMobile: false,
    });
  }
};

const processSelectOrHighlightInSameTag = (
  selectOrHighlight: ISelectOrHighlight,
  $: any,
  isSelect: boolean,
  isMobile: boolean,
) => {
  //@ts-ignore
  algoDataLog(`processSelectOrHighlightInSameTag: selection starts and ends in the same tag`);

  // Highlight/select starts and ends in the same node
  markStartAndEndInSameTag(selectOrHighlight, $, isSelect, isMobile);
  markEndElementChildren(selectOrHighlight, $, isSelect);
};

const processStartTagHigherThanEndTag = (
  selectOrHighlight: ISelectOrHighlight,
  $: any,
  isSelect: boolean,
  isMobile: boolean,
) => {
  algoDataLog(`processStartTagHigherThanEndTag: selection starts in higher data-index and ends in lower.`);

  // We also have to make sure we highlight all the inner tags which come in between.
  const numberOfInnerTags =
    selectOrHighlight.endPreviousSiblingDataIndex === null
      ? 0
      : selectOrHighlight.endPreviousSiblingDataIndex - selectOrHighlight.startDataIndex;
  for (let i = 0; i <= numberOfInnerTags; i++) {
    markInTag({
      tagData: {
        parentTagDataIndex: selectOrHighlight.startDataIndex + i,
        startPreviousSiblingDataIndex: null,
        endPreviousSiblingDataIndex: null,
        startOffset: i === 0 ? selectOrHighlight.startOffset : 0,
        endOffset: INFINITY,
        comments: selectOrHighlight.comments,
        isInFocus: selectOrHighlight.isInFocus,
      },
      $,
      isSelect,
      highlightId: selectOrHighlight.highlightId || null,
      addSpanId: i === 0 ? true : false,
      isMobile: isMobile,
    });
  }

  // Here marking the text which is in the the parent tag.
  markInTagForStartIndex(
    {
      startDataIndex: selectOrHighlight.startDataIndex,
      parentTagDataIndex: selectOrHighlight.endDataIndex,
      startPreviousSiblingDataIndex: selectOrHighlight.endPreviousSiblingDataIndex,
      endPreviousSiblingDataIndex: selectOrHighlight.endPreviousSiblingDataIndex,
      startOffset: 0, // startOffset is 0 because the selection started in styled tag.
      endOffset: selectOrHighlight.endOffset, // End offset is from endPreviousSibling
      comments: selectOrHighlight.comments,
      isInFocus: selectOrHighlight.isInFocus,
    },
    $,
    isSelect,
    selectOrHighlight.highlightId || null,
    false,
    isMobile,
  );
};

const processStartTagLowerThanEndTag = (
  selectOrHighlight: ISelectOrHighlight,
  $: any,
  isSelect: boolean,
  isMobile: boolean,
) => {
  algoDataLog(`processStartTagLowerThanEndTag: selection starts lower data-index and ends in higher.`);

  // There are 3 parts here. First let's mark elements which are in startIndex.
  // This will select only the parts which are directly inside startIndex.
  // The inner tags won't get selected with this.
  markInTagForEndIndex(
    {
      parentTagDataIndex: selectOrHighlight.startDataIndex,
      endDataIndex: selectOrHighlight.endDataIndex,
      startPreviousSiblingDataIndex: selectOrHighlight.startPreviousSiblingDataIndex,
      endPreviousSiblingDataIndex: selectOrHighlight.endPreviousSiblingDataIndex,
      startOffset: selectOrHighlight.startOffset,
      endOffset: selectOrHighlight.endOffset,
      comments: selectOrHighlight.comments,
      isInFocus: selectOrHighlight.isInFocus,
    },
    $,
    isSelect,
    selectOrHighlight.highlightId || null,
    true,
    isMobile,
  );

  // Let's mark all the inner tags which comes in between. So its possible that say selection starts in parent with data-index=1 and ends in  tag
  // with data-index=5. In that case, we need to make sure we highlight all inner tags with data-index 2 to 4.
  // But if the endPreviousSiblingDataIndex is not null it means we need to select more inner tags (with higher data-index then endIndex)
  let numInnerTags = selectOrHighlight.endDataIndex - selectOrHighlight.startDataIndex;
  if (
    selectOrHighlight.endPreviousSiblingDataIndex &&
    selectOrHighlight.endPreviousSiblingDataIndex - selectOrHighlight.startDataIndex > numInnerTags
  ) {
    numInnerTags = selectOrHighlight.endPreviousSiblingDataIndex - selectOrHighlight.startDataIndex;
  }

  for (let i = 1; i <= numInnerTags; i++) {
    // If selection starts in a parent tag which consists of lot other inner tags which is before the selection starts then we have to ignore those. Assumption is the last of that tag will be a previewSiblingIndex.
    if (
      selectOrHighlight.startPreviousSiblingDataIndex &&
      selectOrHighlight.startDataIndex + i <= selectOrHighlight.startPreviousSiblingDataIndex
    ) {
      // If there is startPreviousSiblingIndex then we don't want to highlight/select tags which are in between startPreviousSiblingIndex and startIndex
      continue;
    }

    const isEndDataIndex = selectOrHighlight.startDataIndex + i === selectOrHighlight.endDataIndex;
    if (isEndDataIndex) {
      markInTag({
        tagData: {
          parentTagDataIndex: selectOrHighlight.endDataIndex,
          startPreviousSiblingDataIndex: null,
          endPreviousSiblingDataIndex: selectOrHighlight.endPreviousSiblingDataIndex,
          startOffset: 0,
          endOffset: selectOrHighlight.endOffset,
          comments: selectOrHighlight.comments,
          isInFocus: selectOrHighlight.isInFocus,
        },
        $,
        isSelect,
        highlightId: selectOrHighlight.highlightId || null,
        addSpanId: false,
        isMobile: false,
      });
    } else {
      markInTagForEndIndex(
        {
          parentTagDataIndex: selectOrHighlight.startDataIndex + i,
          endDataIndex: selectOrHighlight.endDataIndex,
          // startPreviousSiblingDataIndex: selectOrHighlight.startPreviousSiblingDataIndex,
          startPreviousSiblingDataIndex: null,
          endPreviousSiblingDataIndex: selectOrHighlight.endPreviousSiblingDataIndex,
          startOffset: 0,
          endOffset: INFINITY,
          comments: selectOrHighlight.comments,
          isInFocus: selectOrHighlight.isInFocus,
        },
        $,
        isSelect,
        selectOrHighlight.highlightId || null,
        false,
        isMobile,
      );
    }
  }

  // Account for case where there is additional content between  startDataIndex and endDataIndex
  // in an element that is parent to both start and end
  markInParent(selectOrHighlight, $, isSelect);
};

const processSelectOrHighlight = (
  selectOrHighlight: ISelectOrHighlight,
  $: any,
  isSelect: boolean,
  isMobile: boolean,
) => {
  algoDataLog(`processSelectOrHighlight, processing selection: `, JSON.stringify(selectOrHighlight));

  // Insert highlight/select spans into the HTML where highlight covers partial content,
  // add class to indicate highlight/select to tags where highlight fully covers content

  markStart(selectOrHighlight, $, isSelect, isMobile);
  algoDataLog(`processSelectOrHighlight: done marking start`);

  markEnd(selectOrHighlight, $, isSelect);
  algoDataLog(`processSelectOrHighlight: done marking end`);

  // Highlight/select all tags between start and end
  const start = selectOrHighlight.startDataIndex + 1;
  const end = selectOrHighlight.endDataIndex - 1;

  //@ts-ignore
  algoDataLog(`processSelectOrHighlight: selection includes full tags from=${start} to=${end}`);

  markFullTags(
    start,
    end,
    selectOrHighlight.endPreviousSiblingDataIndex,
    $,
    isSelect,
    selectOrHighlight.highlightId || null,
  );
};

/**
 * Main function responsible for marking selection or highlight in publication HTML.
 *
 * General algorithm:
 * selectOrHighlight provides information on selection/highligh being processed (start/end node ets),
 * $ - facilitates publication section HTML manipulations
 *
 * 1. Add id to every HTML tag in the document, id is sequential, staring with 1 and is marked with data-index attribute.
 * 2. On text selection, call window.getSelection() and parse response to get the information required for highlighting,
 *    see ISelectOrHighlight interface for extracted data
 * 3. Find all relevant HTML content in section that needs to be marked and add new elements for highlight marking and actions.
 *    There are many scenarios of HTML structure that require specific handling when marking content. The
 *    entry point for this handling is markSelectionOrHighlight function.
 *    All known scenarios are documented as test cases in "./highlights.test.ts" file.
 *    See textSelectPopover, highlightCommentsBadgeString and highlightToolboxContentString for components
 *    added for highlighting functionality
 *
 * @param selectOrHighlight parsed content of window.getSelection() or content of highlight read from BE
 * @param $ - reference to Cheerio library, which provides functionality similar to jQuery for HTML manipulation,
 *          see https://cheerio.js.org/ for Cheerio documentation
 *
 * @param isSelect - whether processing an existing highlight or a selection
 * @param isMobile - whether currently in mobile
 *
 *
 */

export const markSelectionOrHighlight = (
  selectOrHighlight: ISelectOrHighlight,
  $: any,
  isSelect: boolean,
  isMobile: boolean,
) => {
  if (!validateSelectionOrHighlightParams(selectOrHighlight)) {
    return;
  }

  if (selectOrHighlight.startDataIndex === selectOrHighlight.endDataIndex) {
    processSelectOrHighlightInSameTag(selectOrHighlight, $, isSelect, isMobile);
  } else if (selectOrHighlight.startDataIndex > selectOrHighlight.endDataIndex) {
    processStartTagHigherThanEndTag(selectOrHighlight, $, isSelect, isMobile);
  } /*if (selectOrHighlight.startDataIndex < selectOrHighlight.endDataIndex) */ else {
    processStartTagLowerThanEndTag(selectOrHighlight, $, isSelect, isMobile);
  }
  //   else {
  // Should be covered by above cases
  //     processSelectOrHighlight(selectOrHighlight, $, isSelect, isMobile);
  //   }
};

export const setSectionHtml = (sectionId: number, $: any) => {
  const sectionElement = document.getElementById(`${SECTION_ID_PREFIX}${sectionId}`);

  if (sectionElement && sectionElement.firstElementChild) {
    sectionElement.firstElementChild.outerHTML = $("body").html() || sectionElement.outerHTML;
  }

  // After setting the highlight, we need to position the markers again as we lose the positioning of them.
  PositionMarkers(document.getElementById("section-" + sectionId));
};

/**
 * When removing last highlight in section, it is still visible and need to be removed - restore section original HTML
 *
 * @param sectionId
 * @param sectionHtml
 */
export const resetSectionHtml = (sectionId: number, sectionHtml: string) => {
  const sectionElement = document.getElementById(`${SECTION_ID_PREFIX}${sectionId}`);
  const highlightElements = sectionElement?.querySelectorAll(
    `.${SELECTION_HIGHLIGHT_STYLE.HIGHLIGHTED},.${SELECTION_HIGHLIGHT_STYLE.HIGHLIGHT_SELECTED}`,
  );

  if (sectionElement && sectionElement.firstElementChild && highlightElements?.length) {
    sectionElement.firstElementChild.outerHTML = sectionHtml;
  }

  // After deleting the highlight, we need to position the markers again as we loses the positioning of them.
  PositionMarkers(document.getElementById("section-" + sectionId));
};

/**
 * Find highlights that belong to this section and wrap respective HTML
 * in proper tags to show selection
 *
 * Assumption: function only is called when sectionHighlights is not empty
 */
export const markExistingHighlights = (params: any) => {
  const { sectionHtml, sectionHighlights, sectionId, isMobile, highlightInFocusId } = params;
  const $ = cheerio.load(sectionHtml, { decodeEntities: false });

  sectionHighlights.forEach((highlight: IHighlightMark) => {
    markSelectionOrHighlight(
      {
        highlightId: highlight.highlightId,
        startDataIndex: highlight.startDataIndex,
        endDataIndex: highlight.endDataIndex,
        startPreviousSiblingDataIndex: highlight.startPreviousSiblingDataIndex,
        endPreviousSiblingDataIndex: highlight.endPreviousSiblingDataIndex,
        startOffset: highlight.startOffset,
        endOffset: highlight.endOffset,
        selectionPlainText: highlight.selectionPlainText,
        comments: highlight.comments,
        isInFocus: highlightInFocusId === highlight.highlightId,
        // Not relevant for existing highlights
        isAnchorBeforeFocus: true,
      },
      $,
      false,
      isMobile,
    );
  });

  // This call updates DOM, assume elements exist after the call
  setSectionHtml(sectionId, $);

  return $;
};

const onHighlightCreate = (props: any, event: any) => {
  event?.preventDefault();

  const { selection, createHighlight, sectionId, designationId, popoverElement, isOffline } = props;

  if (isOffline) {
    // Highlight can't be created into offline mode.
    return;
  }

  if (selection?.selectionPlainText) {
    createHighlight(
      Object.assign({}, selection, {
        sectionId,
        designationId,
      }),
    );

    popoverElement?.removeEventListener("click", onHighlightCreate);

    // Remove text select popover
    popoverElement?.remove();

    // Make all "selected" markings yellow - as if already highlighted
    const selectedElements = document.getElementsByClassName(SELECTION_HIGHLIGHT_STYLE.SELECTED);

    for (let i = 0; i < selectedElements.length; i++) {
      selectedElements[i].classList.add(SELECTION_HIGHLIGHT_STYLE.SELECTED_YELLOW);
    }

    segmentUtils.trackButtonClickAction(TRACKING_EVENTS.READER.HIGHLIGHTER.event, TRACKING_EVENTS.READER.HIGHLIGHTER.category, TRACKING_EVENTS.READER.HIGHLIGHTER.eventName, TRACKING_EVENTS.READER.HIGHLIGHTER.eventName);
  }
};

const addSelectionPopover = (selection: ISelectOrHighlight, $: any) => {
  const searchAttr = `.${SELECTION_HIGHLIGHT_STYLE.SELECTED}`;
  const allSelected = $(searchAttr);
  const popover = textSelectPopover(selection.isAnchorBeforeFocus);
  const parentEl = selection.isAnchorBeforeFocus ? allSelected.eq(-1) : allSelected.eq(0);

  // If user selects left to right, top to bottom (isAnchorBeforeFocus is true), put the popover at
  // the end of the selected text, where user left off. If isAnchorBeforeFocus is false,
  // set the popover at the start of the selected text.
  selection.isAnchorBeforeFocus ? parentEl.append($(popover)) : parentEl.prepend($(popover));
};

export const textSelectOrHighlight = (
  currentContentState: any,
  mouseUpLeft: number,
  mouseUpTop: number,
  isOffline: boolean,
) => {
  const {
    sectionHtml,
    mouseDownPosition,
    isMobile,
    createHighlight,
    sectionId,
    designationId,
    userAuthId,
    highlightModeActive,
    getHighlightsBySectionId,
  } = currentContentState;
  const selection = parseSelectionData({
    selectionStartLeft: mouseDownPosition.left,
    selectionStartTop: mouseDownPosition.top,
    selectionEndLeft: mouseUpLeft,
    selectionEndTop: mouseUpTop,
  });

  if (selection) {
    let $;
    const sectionHighlights = getHighlightsBySectionId(sectionId);

    // Render existing highlights first, so new selection can be correctly marked
    // in addition to existing highlights
    if (sectionHighlights?.length) {
      $ = markExistingHighlights({
        sectionHtml,
        sectionHighlights,
        sectionId,
        isMobile,
      });
    } else {
      $ = cheerio.load(sectionHtml, { decodeEntities: false });
    }

    markSelectionOrHighlight(selection, $, true, isMobile);

    // Set selection popover either at the beginning or the end of the selection
    addSelectionPopover(selection, $);

    setSectionHtml(sectionId, $);

    const popoverElement = document.getElementById(`text-select-popover`);

    if (highlightModeActive) {
      onHighlightCreate({ selection, createHighlight, sectionId, designationId, userAuthId, popoverElement }, null);
    }

    popoverElement?.addEventListener(
      "click",
      onHighlightCreate.bind(null, {
        selection,
        createHighlight,
        sectionId,
        designationId,
        userAuthId,
        popoverElement,
        isOffline,
      }),
    );
  }
};

const positionHighlightToolbox = (hasComment: boolean, toolboxElement: any) => {
  // If need to show both toolbox and comments badge, show toolbox in "below" position -
  // below the selection first span
  if (hasComment) {
    const popupElement = toolboxElement?.getElementsByClassName(MARKER_POPUP_CONTAINER_CLASS_NAME)[0];

    popupElement?.classList.remove("top");
    popupElement?.classList.add("bottom");

    const popupArrowElement = toolboxElement?.getElementsByClassName("marker-popover-arrow")[0];

    popupArrowElement?.classList.remove("bottom");
    popupArrowElement?.classList.add("top");
  }
};

export const selectHighlightCancel = () => {
  // This is a live list - element is removed each time the below loop runs
  const highlightedElements = document.getElementsByClassName(SELECTION_HIGHLIGHT_STYLE.HIGHLIGHT_SELECTED);

  while (highlightedElements && highlightedElements.length) {
    //@ts-ignore
    highlightedElements[0].classList.remove(SELECTION_HIGHLIGHT_STYLE.HIGHLIGHT_SELECTED);
  }
};

export const selectHighlight = (highlightInFocusId: number) => {
  // First remove previous highlighted, then set new
  selectHighlightCancel();

  const searchAttr = `[data-highlight-start-index="${highlightInFocusId}"]`;
  const highlightElements = document.querySelectorAll(searchAttr);

  if (highlightElements?.length > 0) {
    for (let i = 0; i < highlightElements.length; i++) {
      const el = highlightElements[i];
      if (el.className.indexOf(SELECTION_HIGHLIGHT_STYLE.HIGHLIGHT_SELECTED) < 0) {
        el.className += ` ${SELECTION_HIGHLIGHT_STYLE.HIGHLIGHT_SELECTED}`;
      }
    }
  }
};

/**
 * User clicked on an existing highlight - add styling to it and trigger showing highlight toolbox
 *
 * @param params
 * @param event
 */
export const onHighlightSelect = (params: any, event: any, isOffline: boolean) => {
  const {
    setHighlightToolboxOpenId,
    highlights,
    sectionId,
    setCommentModalState,
    setIsHighlightDeleteConfirmOpen,
  } = params;
  const attrValue = event.target.getAttribute("data-highlight-start-index");
  const highlightId = toNumber(attrValue);
  const selectedHighlight = _.find(highlights, (highlight: IHighlightMark) => highlight.highlightId === highlightId);

  // Only process it inside the relevant section
  if (selectedHighlight?.sectionId === sectionId) {
    selectHighlight(highlightId);
    setHighlightToolboxOpenId(highlightId);

    const toolboxElement = document.getElementById(`${SELECTION_HIGHLIGHT_STYLE.HIGHLIGHT_TOOLBOX}${highlightId}`);

    // Show toolbox
    toolboxElement?.classList.add("visible");

    // If need to show both toolbox and comments badge, show toolbox in "below" position -
    // below the selection first span
    positionHighlightToolbox(selectedHighlight?.comments?.length, toolboxElement);

    // Attach listeners to toolbox functionality
    toolboxElement?.getElementsByClassName("comments-icon")[0].addEventListener(
      "click",
      highlightCommentToolboxEventListener.bind(null, {
        setHighlightToolboxOpenId,
        highlightId,
        setCommentModalState,
        selectedHighlight,
        toolboxElement,
        isOffline,
      }),
    );
    toolboxElement?.getElementsByClassName("delete-icon")[0].addEventListener(
      "click",
      highlightDeleteToolboxEventListener.bind(null, {
        setHighlightToolboxOpenId,
        highlightId,
        setIsHighlightDeleteConfirmOpen,
        toolboxElement,
        isOffline,
      }),
    );
  }
};

export const onHighlightSelectCancel = (params: any, event: any) => {
  const { highlightToolboxOpenId, setHighlightToolboxOpenId } = params;
  const searchAttr = `[data-highlight-start-index="${highlightToolboxOpenId}"]`;
  const highlightElements = document.querySelectorAll(searchAttr);

  for (let i = 0; i < highlightElements.length; i++) {
    highlightElements[i].classList.remove(SELECTION_HIGHLIGHT_STYLE.HIGHLIGHT_SELECTED);
  }

  const toolboxElement = document.getElementById(
    `${SELECTION_HIGHLIGHT_STYLE.HIGHLIGHT_TOOLBOX}${highlightToolboxOpenId}`,
  );

  // Hide toolbox
  toolboxElement?.classList.remove("visible");
  highlightToolboxRemoveEventListeners({ toolboxElement });

  setHighlightToolboxOpenId(0);
};

export const onBookmarkSelectCancel = (params: any, event: any) => {
  const { setSectionBookmarkToolboxId } = params;

  setSectionBookmarkToolboxId(0);
};

/**
 * User cancels text selection for cancel, remove "selected" classes and hide highlight popover
 *
 * @param params
 * @param event
 */
export const onCancelTextSelect = () => {
  const popoverElement = document.getElementById(`text-select-popover`);

  // Remove popover
  popoverElement?.remove();

  // Remove SELECTION_HIGHLIGHT_STYLE.SELECTED class from all elements
  // This is a live list - element is removed each time the below loop runs
  const selectedElements = document.getElementsByClassName(SELECTION_HIGHLIGHT_STYLE.SELECTED);

  while (selectedElements && selectedElements.length) {
    //@ts-ignore
    selectedElements[0].outerHTML = selectedElements[0].innerText;
  }
};

/**
 * Check if one element is a child of another
 *
 * @param parent
 * @param child
 */
export const isDescendant = (parent: HTMLElement | null, child: HTMLElement | null) => {
  if (!parent || !child) {
    return false;
  }

  // When same element - count as self -containing
  if (parent.isEqualNode(child)) {
    return true;
  }

  let node = child.parentElement;

  while (node != null) {
    if (node == parent) {
      return true;
    }

    node = node.parentElement;
  }

  return false;
};

export const addHighlightCommentsBadgeListener = (props: any) => {
  const { sectionHighlights, setHighlightToolboxOpenId, setCommentModalState, highlight } = props;

  sectionHighlights.map((highlight: IHighlightMark) => {
    if (highlight.comments?.length) {
      const highlightsBadge = document.getElementById(
        `${SELECTION_HIGHLIGHT_STYLE.HIGHLIGHT_COMMENT_BADGE}${highlight.highlightId}`,
      );

      optimisationLog(`Adding commentBadgeEventListener event listener for highlight ${highlight.highlightId}`);

      highlightsBadge?.addEventListener(
        "click",
        commentBadgeEventListener.bind(null, {
          setHighlightToolboxOpenId,
          setCommentModalState,
          highlight,
        }),
      );
    }
  });
};

export const removeHighlightCommentsBadgeListener = (props: any) => {
  const { sectionHighlights } = props;

  sectionHighlights.map((highlight: IHighlightMark) => {
    if (highlight.comments?.length) {
      const highlightsBadge = document.getElementById(
        `${SELECTION_HIGHLIGHT_STYLE.HIGHLIGHT_COMMENT_BADGE}${highlight.highlightId}`,
      );

      optimisationLog(`Removing commentBadgeEventListener event listener for highlight ${highlight.highlightId}`);

      highlightsBadge?.removeEventListener("click", commentBadgeEventListener.bind(null, {}));
    }
  });
};

export const showHiddenBadgeInSection = (sectionId: number) => {
  const containingSection = document.getElementById(`section-${sectionId}`);
  const highlightsHiddenBadges = containingSection?.getElementsByClassName("highlight-comments-badge hidden");

  if (highlightsHiddenBadges?.length) {
    for (let i = 0; i < highlightsHiddenBadges.length; i++) {
      highlightsHiddenBadges[i].classList.remove("hidden");
    }
  }
};

export const hideCommentsBadge = (markerId: number, hideCommentsBadge: boolean, isHighlightComments: boolean) => {
  let commentsBadgeElement: HTMLElement | null = null;

  if (hideCommentsBadge) {
    // Hide comments badge
    if (isHighlightComments) {
      commentsBadgeElement = document.getElementById(`highlight-comments-badge-${markerId}`);
    } else {
      commentsBadgeElement = document.getElementById(`section-comments-badge-${markerId}`);
    }

    commentsBadgeElement?.classList.add(COMMENTS_BADGE_HIDDEN_CLASS_NAME);
  }

  return commentsBadgeElement;
};

export const restoreCommentsBadge = (isHideCommentsBadge: boolean, commentsBadgeElement: HTMLElement | null) => {
  if (isHideCommentsBadge) {
    commentsBadgeElement?.classList.remove(COMMENTS_BADGE_HIDDEN_CLASS_NAME);
  }
};

export const getMobileOperatingSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
};
