import { runInAction } from "mobx";
import _ from "lodash";
import deliveryService from "apis/deliveryService";
import { ShopErrorHandler } from "components/error/ErrorBoundary";
import SYSTEM_ERRORS from "constants/errors";
import IComment from "models/comment";
import * as segmentUtils from "utils/segmentUtils";
import TRACKING_EVENTS from "services/segmentProvider/models/trackingEvents";

interface ICreateProps {
  marker: any;
  markerId: number | undefined;
  markerType: "bookmark" | "highlight";
  commentContent: string;
}

export const createMarkerComment = async (props: ICreateProps) => {
  const { marker, markerId, markerType, commentContent } = props;

  try {
    if (!marker || !markerId) {
      throw new Error(`Unable to find marker for comment create ${JSON.stringify(marker)}`);
    }

    marker.comments!.push({
      commentId: 0,
      content: commentContent,
    });

    const optimisticMarkerComment = marker.comments![marker.comments!.length - 1];
    const created =
      markerType === "bookmark"
        ? await deliveryService.createComment(markerId, null, commentContent)
        : await deliveryService.createComment(null, markerId, commentContent);

    runInAction(`create ${markerType} comment`, () => {
      optimisticMarkerComment.commentId = created.data.commentId;
    });

    segmentUtils.trackOperations(TRACKING_EVENTS.READER.COMMENTS.event, TRACKING_EVENTS.READER.COMMENTS.category);
  } catch (error) {
    runInAction("create bookmark comment error", () => {
      if (marker) {
        // Remove optimistically added comment
        marker.comments = _.filter(marker.comments, (item: any) => !!item.commentId);
      }

      ShopErrorHandler(SYSTEM_ERRORS.COMMENT_ERROR);
    });
  }
};

interface IUpdateProps {
  marker: any;
  markerType: "bookmark" | "highlight";
  commentId: number;
  commentContent: string;
}

export const updateMarkerComment = async (props: IUpdateProps) => {
  const { marker, markerType, commentId, commentContent } = props;
  let commentToUpdate: IComment | undefined = undefined;
  let contentBeforeUpdate = "";

  try {
    if (!marker) {
      throw new Error(`Unable to find marker for comment update from given marker ${JSON.stringify(marker)}`);
    }

    commentToUpdate = _.find(marker.comments, (comment: IComment) => comment.commentId === commentId);

    if (!commentToUpdate) {
      throw new Error(`Unable to find comment to update: marker=${JSON.stringify(marker)} commentId=${commentId}`);
    }

    contentBeforeUpdate = commentToUpdate.content;
    commentToUpdate.content = commentContent;

    await deliveryService.updateComment(commentId, commentContent);
  } catch (error) {
    runInAction(`update ${markerType} comment error`, () => {
      if (commentToUpdate) {
        // Remove optimistically updated comment
        commentToUpdate.content = contentBeforeUpdate;
      }

      ShopErrorHandler(SYSTEM_ERRORS.COMMENT_ERROR);
    });
  }
};

interface IDeleteProps {
  marker: any;
  markerType: "bookmark" | "highlight";
  commentId: number;
}

export const deleteMarkerComment = async (props: IDeleteProps) => {
  const { marker, markerType, commentId } = props;
  let commentToDelete: IComment | undefined = undefined;

  try {
    if (!marker) {
      throw new Error(`Unable to find marker for comment delete from given marker ${JSON.stringify(marker)}`);
    }

    commentToDelete = _.find(marker.comments, (comment: IComment) => comment.commentId === commentId);

    if (!commentToDelete) {
      throw new Error(`Unable to find comment to delete: marker=${JSON.stringify(marker)} commentId=${commentId}`);
    }

    runInAction(`delete ${markerType} comment`, () => {
      marker.comments = _.filter(marker.comments, (comment: IComment) => comment.commentId !== commentId);
    });

    await deliveryService.deleteComment(commentId);
  } catch (error) {
    runInAction(`delete ${markerType} comment error`, () => {
      if (commentToDelete) {
        // Put back optimistically removed comment
        marker.comments.push(commentToDelete);
      }

      ShopErrorHandler(SYSTEM_ERRORS.COMMENT_ERROR);
    });
  }
};

// just displaying the current time for now
export const displayOrderDate = function (date?: Date) {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  if (!date) {
    date = new Date();
  }
  const day = date.getDate();

  const monthIndex = date.getMonth();
  const monthName = monthNames[monthIndex];
  const year = date.getFullYear();

  return `${day} ${monthName} ${year} at ${(date.getHours() + 24) % 12 || 12}:${
    (date.getMinutes() < 10 ? "0" : "") + date.getMinutes()
  } 
    ${date.getHours() >= 12 ? "pm" : "am" /* lower case am/pm*/}`;
};
