import { Configuration, RedirectRequest } from "@azure/msal-browser";
import env from "./models/Configuration";
import { LogLevel } from "@azure/msal-browser";
import { PublicClientApplication } from "@azure/msal-browser";


const msalConfig: Configuration = {
    auth:{
        ...env.b2c.config,
        postLogoutRedirectUri: "/",
        navigateToLoginRequestUrl: true
    },
    cache:{
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
    },
    system: {
        allowRedirectInIframe: true,
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            }
        }
    }
}

export const msalInstance = new PublicClientApplication(msalConfig);

export const loginRequest: RedirectRequest = {
    scopes: env.b2c.redirectRequestScopes
}

export const passwordRequest: RedirectRequest = {
    authority: env.b2c.passwordAuthority,
    scopes: env.b2c.redirectRequestScopes
}
