import styled from "styled-components";
import STYLE_DEFAULTS from "../../../../constants/styles";

export const HeaderContainer = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: ${STYLE_DEFAULTS.COLORS.SA_WHITE_B000};
  z-index: ${STYLE_DEFAULTS.Z_INDEX_LAYOUT.HEADER_CONTAINER};
`;

export const ReaderHeaderControls = styled.div`
  display: flex;
  flex-direction: row;
`;
