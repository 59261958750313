import styled, { css } from "styled-components";
import STYLE_DEFAULTS from "../../../../../../constants/styles";
import { onHoverStateTransition } from "../../../../../../styles/global";
import { InFocus } from "../../../../../../styles/typography";

interface IProps {
  isMobile?: boolean;
  isSideMenuOpen?: boolean;
  isDisabled?: boolean;
  isSelected?: boolean;
  isPreview?: boolean;
  isScrollable?: boolean;
  highlight?: string;
}

export const NoMarkerContainer = styled.div`
  padding-top: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_10};
  align-items: center;
  text-align: center;
`;

export const NoMarkerHeader = styled.div`
  font-size: 18px;
  font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.MEDIUM};
  padding-bottom: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_4};
`;

export const NoMarkerBody = styled.div`
  font-size: 16px;
  padding: 0px 20px 0px 20px;
`;

export const MarkerContents = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MarkerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_2};
  font-size: 14px;

  :hover {
    background-color: ${STYLE_DEFAULTS.COLORS.SA_B015};
    cursor: pointer;
    ${onHoverStateTransition};
  }
`;

export const MarkerHeader = styled.div`
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.NOTO_SANS};
  font-size: ${STYLE_DEFAULTS.FONTS.SA_FONT_SIZE.SMALL};
  font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.MEDIUM};
  color: ${STYLE_DEFAULTS.COLORS.SA_BLACK_B100};
  line-height: ${STYLE_DEFAULTS.FONTS.SA_FONT_LINE_HEIGHT.HIGHER};
  margin-bottom: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_5};
  word-break: break-word;
  overflow-wrap: break-word;
  word-wrap: break-word;
`;

export const MarkeSubheader = styled.div`
  background-color: ${STYLE_DEFAULTS.COLORS.SA_HIGHLIGHT_YELLOW};
  width: fit-content;
  margin-top: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_2};
  font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.NORMAL};
`;

export const MarkerBody = styled.span`
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.NOTO_SANS};
  font-size: ${STYLE_DEFAULTS.FONTS.SA_FONT_SIZE.SMALL};
  font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.NORMAL};
  color: ${STYLE_DEFAULTS.COLORS.SA_B070};
  line-height: ${STYLE_DEFAULTS.FONTS.SA_FONT_LINE_HEIGHT.HIGHER};

  word-break: break-word;
  overflow-wrap: break-word;
  word-wrap: break-word;

  ${(props: IProps) => (props.highlight ? `background-color: ${props.highlight};` : "")}
`;

export const ArchivedInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -3px;
  top: 30px;
  background-color: ${STYLE_DEFAULTS.COLORS.SA_WHITE_B000};
  padding: 14px ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_5};
  border-radius: ${STYLE_DEFAULTS.BUTTONS.SA_BUTTON_RADIUS};
  -webkit-border-radius: ${STYLE_DEFAULTS.BUTTONS.SA_BUTTON_RADIUS};
  -moz-border-radius: ${STYLE_DEFAULTS.BUTTONS.SA_BUTTON_RADIUS};
  pointer-events: ${(props: IProps) => (props.isDisabled ? "none" : "all")};
  -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
  :hover {
    text-decoration: underline;
    text-decoration-color: ${(props: IProps) =>
      props.isDisabled ? STYLE_DEFAULTS.COLORS.SA_DISABLED : STYLE_DEFAULTS.COLORS.SA_RED_B010};
    cursor: pointer;
    ${onHoverStateTransition};
  }

  :active {
    text-decoration: underline;
    text-decoration-color: ${(props: IProps) =>
      props.isDisabled ? STYLE_DEFAULTS.COLORS.SA_DISABLED : STYLE_DEFAULTS.COLORS.SA_RED_B015};
    ${onHoverStateTransition};
  }

  :focus {
    ${InFocus}
  }

  width: 200px;
`;
export const MarkerOptionsIcon = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 30;

  & svg path {
    cursor: pointer;
  }
`;

export const MarkerOptions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -3px;
  top: 30px;
  background-color: ${STYLE_DEFAULTS.COLORS.SA_WHITE_B000};
  padding: 14px ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_5};
  border-radius: ${STYLE_DEFAULTS.BUTTONS.SA_BUTTON_RADIUS};
  -webkit-border-radius: ${STYLE_DEFAULTS.BUTTONS.SA_BUTTON_RADIUS};
  -moz-border-radius: ${STYLE_DEFAULTS.BUTTONS.SA_BUTTON_RADIUS};
  pointer-events: ${(props: IProps) => (props.isDisabled ? "none" : "all")};
  -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);

  :hover {
    text-decoration: underline;
    text-decoration-color: ${(props: IProps) =>
      props.isDisabled ? STYLE_DEFAULTS.COLORS.SA_DISABLED : STYLE_DEFAULTS.COLORS.SA_RED_B010};
    cursor: pointer;
    ${onHoverStateTransition};
  }

  :active {
    text-decoration: underline;
    text-decoration-color: ${(props: IProps) =>
      props.isDisabled ? STYLE_DEFAULTS.COLORS.SA_DISABLED : STYLE_DEFAULTS.COLORS.SA_RED_B015};
    ${onHoverStateTransition};
  }

  :focus {
    ${InFocus}
  }
`;

// TODO: rework as Generic popover - as part of UI improvements
// (requires further tests in other components using the popover currently)
export const MarkerOptionsArrow = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  top: 25px;
  left: 2px;
  border-bottom: 8px solid ${STYLE_DEFAULTS.COLORS.SA_WHITE_B000};
`;

export const MarkerOptionsText = styled.div`
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.NOTO_SANS};
  font-size: ${STYLE_DEFAULTS.FONTS.SA_FONT_SIZE.NORMAL};
  font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.NORMAL};
  background-color: ${STYLE_DEFAULTS.COLORS.SA_WHITE_B000};
  color: ${(props: IProps) => (props.isDisabled ? STYLE_DEFAULTS.COLORS.SA_DISABLED : STYLE_DEFAULTS.COLORS.SA_RED)};
  margin-left: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_2};

  :hover {
    color: ${(props: IProps) =>
      props.isDisabled ? STYLE_DEFAULTS.COLORS.SA_DISABLED : STYLE_DEFAULTS.COLORS.SA_RED_B010};
    ${onHoverStateTransition};
  }

  :active {
    color: ${(props: IProps) =>
      props.isDisabled ? STYLE_DEFAULTS.COLORS.SA_DISABLED : STYLE_DEFAULTS.COLORS.SA_RED_B015};
    ${onHoverStateTransition};
  }
`;

export const MarkerCommentsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

export const MarkerCommentsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 41px;
  margin: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_2} ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_5} 0 0;
  padding: 2px 4px 2px 2px;

  :hover,
  :focus {
    -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
    ${onHoverStateTransition};
  }

  :hover {
    cursor: pointer;
    ${onHoverStateTransition};
  }
`;

export const MarkerCommentsCount = styled.div`
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.NOTO_SANS};
  font-size: 14px; // same for desktop and mobile
  font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.NORMAL};
  line-height: 1.5;
  padding-left: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_2};
  color: ${STYLE_DEFAULTS.COLORS.SA_BLACK_B100};

  :hover {
    cursor: pointer;
    ${onHoverStateTransition};
  }
`;
