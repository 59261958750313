import CONTENT_SEARCH_DEFAULTS from "../../../../constants/contentSearch";

export function PositionMarkers(node: any, leftPadding = 0, rightPadding = 0) {
  if (node == null) {
    return;
  }
  const markerLeftPosition = 0;
  const markerRightPosition = (node?.offsetWidth || 0) + 5;
  // 1. Let's find all amnd. markers
  Array.from(document.getElementsByClassName("amnd-marker")).forEach((el: any) => {
    const height = el.parentNode.offsetHeight + "px";
    const left = markerLeftPosition - 30 - leftPadding + "px";
    if (el.style.height !== height) {
      el.style.height = height;
    }
    if (el.style.left !== left) {
      el.style.left = left;
    }
  });

  // 2. Let's find all amnd. markers dated
  Array.from(document.getElementsByClassName("amnd-marker-dated-indicator")).forEach((el: any) => {
    const height = el.parentNode.offsetHeight + "px";
    const left = markerRightPosition - 10 - leftPadding + "px";
    if (el.style.height !== height) {
      el.style.height = height;
    }
    if (el.style.left !== left) {
      el.style.left = left;
    }
  });

  // 3. Let's find all new markers
  Array.from(document.getElementsByClassName("new-marker")).forEach((el: any) => {
    const height = el.parentNode.offsetHeight + "px";
    const left = markerLeftPosition - 20 - leftPadding + "px";
    if (el.style.height !== height) {
      el.style.height = height;
    }
    if (el.style.left !== left) {
      el.style.left = left;
    }
  });

  // 4. Let's find all au region markers
  Array.from(document.getElementsByClassName("region-marker-au")).forEach((el: any) => {
    const left = markerRightPosition - leftPadding + "px";
    if (el.style.left !== left) {
      el.style.left = left;
    }
  });

  // 5. Let's find all nz region markers
  Array.from(document.getElementsByClassName("region-marker-nz")).forEach((el: any) => {
    const left = markerRightPosition - leftPadding + "px";
    if (el.style.left !== left) {
      el.style.left = left;
    }
  });
}
