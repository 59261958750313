import styled from "styled-components";
import STYLE_DEFAULTS from "../../../../../../constants/styles";
import { MEDIA_QUERIES } from "../../../../../../constants/mediaQueries";

interface IProps {
  isDisabled?: boolean;
}

export const DesktopHeaderContainer = styled.div`
  min-width: 800px; /* min width before mobile mode is active */
  -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15);
  background-color: ${STYLE_DEFAULTS.COLORS.SA_WHITE_B000};
`;

export const DesktopHeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media ${MEDIA_QUERIES.LG} {
    width: 1440px;
    margin: 0 auto;
  }
`;

export const DesktopHeaderMenu = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  color: ${STYLE_DEFAULTS.COLORS.SA_B060};
`;

export const HeaderLink = styled.div<IProps>`
  display: flex;
  align-items: center;
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.NOTO_SANS};
  color: ${(props: IProps) => (props.isDisabled ? STYLE_DEFAULTS.COLORS.SA_DISABLED : STYLE_DEFAULTS.COLORS.SA_B070)};
  font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.NORMAL};
  font-size: 1rem;
  text-decoration: none;
  cursor: pointer;
`;

export const HeaderPreview = styled.div`
  flex: 1;
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  align-self: right;
  align-items: center;
  padding-right: 20px;
`;

export const ButtonContainer = styled.div`
  margin-left: 12px;
`;
