import React, { useContext, useState, useEffect, useRef } from "react";
import _ from "lodash";
import { observer } from "mobx-react-lite";
import ReaderLayoutContext, { IReaderLayoutContext } from "../../readerLayoutContext";
import { SideContentContainer } from "../style";
import SideMenuContentHeader from "../sideMenuContentHeader";
import ICONS from "../../../../../constants/icons";
import BookmarksStore from "../../../../../stores/bookmarkStore";
import HighlightStore from "../../../../../stores/highlightStore";
import UserStore from "../../../../../stores/userStore";
import ShopTabs from "../../../../controls/shopTabs/ShopTabs";
import ShopTab from "../../../../controls/shopTabs/ShopTab";
import HighlightsMarkers from "./HighlightMarkers";
import BookmarksMarkers from "./BookmarkMarkers";
import CommentMarkers from "./CommentMarkers";
import TRACKING_EVENTS from "../../../../../services/segmentProvider/models/trackingEvents";

const SideMenuMarkers: React.FC = observer(() => {
  const context = useContext<IReaderLayoutContext>(ReaderLayoutContext);
  const BookmarkStoreContext = useContext(BookmarksStore);
  const HighlightStoreContext = useContext(HighlightStore);
  const userContext = useContext(UserStore);
  const shouldLoadBookmarks = !BookmarkStoreContext.bookmarks.length;
  const shouldLoadHighlights = !HighlightStoreContext.highlights.length;
  const [isBookmarksLoading, setIsBookmarksLoading] = useState(shouldLoadBookmarks);
  const [isHighlightsLoading, setIsHighlightsLoading] = useState(shouldLoadHighlights);

  // These need to be declared here, not in Markers, although used only in Markers
  // This is because the number of markers change when add or remove and React will
  // throw "Rendered more/less hooks than during the previous render" error
  // 0 - no marker options is open, otherwise the id of the marker
  const [showMarkerOptions, setShowMarkerOptions] = useState(0);
  // 0 - no marker delete is open, otherwise the id of the marker
  const [isMarkerDeleteOpen, setIsMarkerDeleteOpen] = useState(0);

  async function getDocumentBookmarks() {
    console.log("SideMenuMarkers getDocumentBookmarks");

    if (shouldLoadBookmarks) {
      await BookmarkStoreContext.getBookmarks(context.readerStore.DesignationId!).then(() =>
        setIsBookmarksLoading(false),
      );
    }

    if (shouldLoadHighlights) {
      await HighlightStoreContext.getHighlights(context.readerStore.DesignationId!).then(() =>
        setIsHighlightsLoading(false),
      );
    }
  }

  useEffect(() => {
    if (context.readerStore.DesignationId && !context.readerStore.Reader.isPreview) { //&& userContext.authId) {
      getDocumentBookmarks();
    }
  }, [context.readerStore.DesignationId, context.readerStore.Reader.isPreview, userContext.authId]);

  return (
    <SideContentContainer>
      <SideMenuContentHeader title={"My Markers"}>
        <br />
        <br />
      </SideMenuContentHeader>
      <ShopTabs hasUnderline iconPadding="0% 0% 0% 35%">
        <ShopTab
          label="HIGHLIGHTS"
          icon={ICONS.HIGHLIGHT_FILLED}
          segmentPackage={TRACKING_EVENTS.READER.MARKER_HIGHLIGHTS}
        >
          <HighlightsMarkers
            isHighlightsLoading={isHighlightsLoading}
            showMarkerOptions={showMarkerOptions}
            setShowMarkerOptions={setShowMarkerOptions}
            isMarkerDeleteOpen={isMarkerDeleteOpen}
            setIsMarkerDeleteOpen={setIsMarkerDeleteOpen}
          />
        </ShopTab>
        <ShopTab label="COMMENTS" icon={ICONS.COMMENT_FILLED} segmentPackage={TRACKING_EVENTS.READER.MARKER_COMMENTS}>
          <CommentMarkers
            isCommentsLoading={isHighlightsLoading || isBookmarksLoading}
            showMarkerOptions={showMarkerOptions}
            setShowMarkerOptions={setShowMarkerOptions}
            isMarkerDeleteOpen={isMarkerDeleteOpen}
            setIsMarkerDeleteOpen={setIsMarkerDeleteOpen}
          />
        </ShopTab>
        <ShopTab
          label="BOOKMARKS"
          icon={ICONS.BOOKMARK_FILLED}
          segmentPackage={TRACKING_EVENTS.READER.MARKER_BOOKMARKS}
        >
          <BookmarksMarkers
            userAuthAid={userContext.authId}
            isBookmarksLoading={isBookmarksLoading}
            showMarkerOptions={showMarkerOptions}
            setShowMarkerOptions={setShowMarkerOptions}
            isMarkerDeleteOpen={isMarkerDeleteOpen}
            setIsMarkerDeleteOpen={setIsMarkerDeleteOpen}
          />
        </ShopTab>
      </ShopTabs>
    </SideContentContainer>
  );
});

export default SideMenuMarkers;
