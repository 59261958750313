import React from "react";
import Icon from "../../icons";
import ICONS from "../../../constants/icons";
import STYLE_DEFAULTS from "../../../constants/styles";
import { MenuButton, MenuCaption, MenuUnderline, MenuIcon } from "./style";
import LoggedInIcon from "../loggedInIcon/LoggedInIcon";

interface IMenuWithIconProps {
  icon: ICONS;
  caption: string;
  iconColor?: string;
  captionColor?: string;
  isMobile?: boolean;
  isMinimised?: boolean;

  // Disregard provided icon and show LoggedInIcon instead
  isLoggedInMenu?: boolean;
  userInitials?: string;

  // Determines underline color
  isMenuItemActive?: boolean;

  // Determines visibility
  hideUnderline?: boolean;
  onClick?: () => void;
  onKeyDown?: (e: React.KeyboardEvent<any>) => void;
  cypressId?: string;
  badgeCounter?: number;
  badgeCounterLoading?: boolean;
}

const renderIcon = (props: IMenuWithIconProps) => {
  const {
    icon,
    isMobile,
    iconColor,
    isMenuItemActive,
    badgeCounter,
    badgeCounterLoading,
    isLoggedInMenu,
    userInitials,
  } = props;

  return (
    <MenuIcon>
      {isLoggedInMenu ? (
        <LoggedInIcon
          initials={userInitials || ""}
          isActive={isMenuItemActive || false}
          isShopIcon
          isMobile={isMobile || false}
        />
      ) : (
        <Icon
          clickable
          tiny
          internalSize={isMobile ? "20px" : "24px"}
          size={isMobile ? "20px" : "24px"}
          icon={icon}
          color={
            iconColor
              ? iconColor
              : isMenuItemActive
              ? STYLE_DEFAULTS.COLORS.SA_WHITE_B000
              : STYLE_DEFAULTS.COLORS.SA_INVERTED_DEFAULT
          }
          badgeCounter={badgeCounter}
          badgeCounterLoading={badgeCounterLoading}
        />
      )}
    </MenuIcon>
  );
};

const MenuIconWithCaption: React.FC<IMenuWithIconProps> = (props: IMenuWithIconProps) => {
  const {
    onClick,
    onKeyDown,
    caption,
    isMinimised,
    isMobile,
    captionColor,
    isMenuItemActive,
    cypressId,
    hideUnderline,
    isLoggedInMenu,
  } = props;
  const cypressIdElement = { id: cypressId, "data-cy": cypressId };
  const color = captionColor
    ? captionColor
    : isMenuItemActive
    ? STYLE_DEFAULTS.COLORS.SA_WHITE_B000
    : isMobile
    ? STYLE_DEFAULTS.COLORS.SA_MOBILE_MENU_TEXT_COLOR
    : STYLE_DEFAULTS.COLORS.SA_INVERTED_DEFAULT;

  return (
    <MenuButton
      onClick={onClick}
      onKeyDown={onKeyDown}
      isMobile={isMobile}
      {...cypressIdElement}
      color={color}
      isLargeIcon={isLoggedInMenu}
      tabIndex={0}
    >
      {renderIcon(props)}
      {!isMinimised && <MenuCaption isMobile={isMobile}>{caption}</MenuCaption>}
      <MenuUnderline isVisible={!hideUnderline && isMenuItemActive} isMobile={isMobile} />
    </MenuButton>
  );
};

export default MenuIconWithCaption;
