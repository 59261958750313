import { Routes as Switch, Route } from "react-router-dom";
import HomePage from "../../pages/HomePage";
import ReaderLayout from "../reader/layout/ReaderLayout";
import LoginIAM from "loginIAM";

const Routes = () => {

  return (
    <Switch>
      <Route  path="/" element={<HomePage />}/>
      <Route  path="/sso-iam-callback" element={<LoginIAM />}/>
      <Route  path="/:designationId" element={<ReaderLayout />}/>
      <Route  path="/:designationId/:preview" element={<ReaderLayout />}/>
      <Route  path="/:designationId/version/:version" element={<ReaderLayout />}/>
      <Route  path="/:designationId/version/:version/:preview" element={<ReaderLayout />}/>
    </Switch>
)}

export default Routes