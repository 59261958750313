import env from "../models/Configuration";

/*This MUST match with server names */
export const featureFlagNames  = {
    ConcurrentSessionDetection: "ConcurrentSessionDetection",
    IAMAuthentication: "IAMAuthentication",
}

/**
 * Retrieves the specified config from backend API.
 * @param {string} flagKey Feature Flag key
 * @returns the config status for the specified key
 */
const getConfigByKey = async (flagKey = '') => {
    if (!flagKey || !flagKey.toString().trim().length) {
        throw 'A config key must be supplied.';
    } 
    try {
        const result = await fetch(`${env?.api?.deliveryServiceBaseUrl}/api/featureflag/${flagKey.toString().trim()}`);
        const data = await result.json();
        console.debug(`Feature ${flagKey} is ${data}`);
        return data;         
    } catch (error) {
        console.error(error);
    } 
};

/**
 * Retrieves the list of config from backend API.
 * @param {string} flagKeys Feature Flag keys
 * @returns the config status for the list of key
 */
const getConfigsByKeys = async (flagKeys : string[]) => {
    
    try {
        const result = await fetch(`${env?.api?.deliveryServiceBaseUrl}/api/featureflag/list`, 
                                   {method: "POST", headers: { "Content-Type": "application/json" }, body: JSON.stringify(flagKeys)});
        const data = await result.json();
        return data;          
    } catch (error) {
        console.error(error);
    } 
};

  
export { getConfigByKey, getConfigsByKeys};