
import { detectIncognito } from "detectincognitojs";

import * as browserDetection from "@braintree/browser-detection";
/**
 * 
 * @returns If browser is chrome incognito or not
 */
export const isChromeIncognito = async () => {
    let browser = await detectIncognito();
    let isIncognito = browser.isPrivate;
    let isChrome = browserDetection.isChrome();
    if (isChrome && isIncognito) {
        return true;
    }
    return false;
}

/**
 * 
 * @returns If IOS browser e.g Safari, Chrome,...
 */
export const isIosBrowser = () => {

    return browserDetection.isIos() || 
           browserDetection.isIosSafari() || 
           browserDetection.isIpadOS() || 
           browserDetection.isIosGoogleSearchApp();
}