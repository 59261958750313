const READER = {
  HIGHLIGHT: {
    event: "Button Click",
    eventName: "Highlight Top",
    category: "Web Reader",
  },
  BOOKMARK: {
    event: "Button Click",
    eventName: "Bookmark Top",
    category: "Web Reader",
  },
  HIGHLIGHTER: {
    event: "Button Click",
    eventName: "Highlighter",
    category: "Web Reader",
  },
  SEARCH_FOCUS: {
    event: "Focus Search",
    eventName: "Search",
    category: "Web Reader",
  },
  SEARCH_CLICK: {
    event: "Click Search",
    eventName: "Search",
    category: "Web Reader",
  },
  SEARCH: {
    event: "Web Reader Search",
    eventName: "Web Reader Search",
    category: "Web Reader",
  },
  TOC: {
    event: "Button Click",
    eventName: "Table Of Contents",
    category: "Web Reader",
  },
  MARKERS: {
    event: "Button Click",
    eventName: "Markers",
    category: "Web Reader",
  },
  MARKER_HIGHLIGHTS: {
    event: "Button Click",
    eventName: "Marker Highlights",
    category: "Web Reader",
  },
  MARKER_COMMENTS: {
    event: "Button Click",
    eventName: "Marker Comments",
    category: "Web Reader",
  },
  MARKER_BOOKMARKS: {
    event: "Button Click",
    eventName: "Marker Bookmarks",
    category: "Web Reader",
  },
  COMMENTS: {
    event: "Comment Saved",
    eventName: "Comment Saved",
    category: "Web Reader",
  },
  PRODUCT: {
    event: "Product Click",
    eventName: "Product Click",
    category: "Web Reader"
  }
};

export default READER;
