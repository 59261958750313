import React from "react";
import { DeleteMarkerTitle, DeleteMarkerBody } from "./style";
import GenericModal from "../../../alerts/GenericModal";
import STYLE_DEFAULTS from "../../../../constants/styles";
import Alert from "../../../alerts/Alert";
import ALERT_TYPE from "../../../../constants/alerts";
import BUTTONS_TYPES from "../../../../constants/buttons";

interface IProps {
  section: "bookmark" | "highlight" | "comment";
  isOpen: boolean;
  onAccept: () => void;
  onCancel: () => void;
  commentsCount: number;
  showCommentsDeleteAlert?: boolean;
}

// TODO: add commentsCount where called to properly structure text (single vs plural)
const DeleteMarkerModal = (props: IProps) => {
  const { section, isOpen, onAccept, onCancel, commentsCount, showCommentsDeleteAlert } = props;
  const onDeleteAttributes = {
    hasCloseIcon: true,
    hasCancelButton: true,
    isOpen: isOpen,
    acceptValue: "Delete",
    onAccept,
    onCancel,
    buttonPosition: "flex-end",
    acceptButtonType: BUTTONS_TYPES.DELETE,
  };

  if (!isOpen) {
    return null;
  }

  const andCommentString = commentsCount === 0 ? "" : commentsCount === 1 ? " and comment" : " and comments";

  return (
    <GenericModal {...onDeleteAttributes}>
      <DeleteMarkerTitle>{`Delete ${section}${andCommentString}`}</DeleteMarkerTitle>
      {commentsCount > 0 && showCommentsDeleteAlert && (
        <Alert
          type={ALERT_TYPE.WARNING}
          message={`This ${section} contains at least 1 comment that will also be deleted.`}
        />
      )}
      <DeleteMarkerBody>{`This will delete your ${section}${
        commentsCount ? ` and its comment${commentsCount > 1 ? "s" : ""}` : ""
      } permanently. Are you sure?`}</DeleteMarkerBody>
    </GenericModal>
  );
};

export default DeleteMarkerModal;
