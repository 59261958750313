import COMMENT_MODAL_STATE from "../../../../constants/comments";

export const commentBadgeEventListener = (props: any, event: any) => {
  event?.preventDefault();

  const { setHighlightToolboxOpenId, setCommentModalState, highlight } = props;

  setHighlightToolboxOpenId(highlight.highlightId);
  setCommentModalState(
    highlight.comments?.length ? COMMENT_MODAL_STATE.VIEW_COMMENTS : COMMENT_MODAL_STATE.CREATE_UPDATE_COMMENT,
  );
};

export const highlightCommentToolboxEventListener = (props: any, event: any) => {
  event?.preventDefault();

  const { setHighlightToolboxOpenId, highlightId, setCommentModalState, selectedHighlight, toolboxElement, isOffline } = props;

  if (isOffline && !selectedHighlight.comments.length) {
    // If user is currently offline then we don't allow user to add a comment.
    return;
  }

  setHighlightToolboxOpenId(highlightId);
  setCommentModalState(
    selectedHighlight.comments.length ? COMMENT_MODAL_STATE.VIEW_COMMENTS : COMMENT_MODAL_STATE.CREATE_UPDATE_COMMENT,
  );

  toolboxElement?.classList.remove("visible");

  highlightToolboxRemoveEventListeners({ toolboxElement });
};

export const highlightDeleteToolboxEventListener = (props: any, event: any) => {
  event?.preventDefault();

  const { setHighlightToolboxOpenId, highlightId, setIsHighlightDeleteConfirmOpen, toolboxElement, isOffline } = props;
  if (isOffline) {
    // If user is currently offline then we don't allow deleting a highlight.
    return;
  }
  toolboxElement?.classList.remove("visible");

  setHighlightToolboxOpenId(highlightId);
  setIsHighlightDeleteConfirmOpen(true);
  highlightToolboxRemoveEventListeners({ toolboxElement });
};

export const highlightToolboxRemoveEventListeners = (props: any) => {
  const { toolboxElement } = props;

  toolboxElement
    ?.getElementsByClassName("comments-icon")[0]
    .removeEventListener("click", highlightCommentToolboxEventListener);
  toolboxElement
    ?.getElementsByClassName("delete-icon")[0]
    .removeEventListener("click", highlightDeleteToolboxEventListener);
};
