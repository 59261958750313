import React, { useContext, useState } from "react";
import { SectionBookmarkModeContainer, SectionContentContainer } from "./style";
import BookmarkBadge from "../../controls/BookmarkBadge/BookmarkBadge";
import COMMENT_MODAL_STATE from "../../../../constants/comments";
import CommentsBadge from "../comments/CommentsBadge";
import BookmarksStore from "../../../../stores/bookmarkStore";
import { observer } from "mobx-react-lite";
import HtmlContent from "../HtmlContent";
import MarkerToolboxPopup from "../../controls/markerToolbox/MarkerToolboxPopup";
import { DEFAULT_SECTION_ID } from "../readerContentContext";
import { Detector } from "react-detect-offline";

const SectionBookmarkContainer: React.FC<any> = observer(
  (props: any): React.ReactElement => {
    console.log("SectionBookmarkContainer... section id: ", props.sectionId);
    const {
      highlightModeActive,
      isSectionBookmarked,
      isMobile,
      backgroundColor,
      createBookmark,
      designationId,
      sectionId,
      shouldShowBookmarkBadge,
      sectionHtml,
      lastSubSection,
      sectionBookmarkToolboxId,
      setSectionBookmarkToolboxId,
      onDeleteBookmarkAccept,
      setCommentModalState,
      bookmarkComments,
      highlightToolboxOpenId,
      ReaderLayoutStoreContext,
      setBookmarkCommentsSectionId,
    } = props;

    const { bookmarkModeActive } = useContext(BookmarksStore);
    // Note: Even though we are doing this during transformation,
    // doing it here to make sure mathml will work properly even if document is not transformed recently. Need to remove this code later.
    const html = (sectionHtml || "").replace(/mml:/g, "");
    const isActive = isSectionBookmarked && sectionBookmarkToolboxId === sectionId;
    const commentsCount = bookmarkComments?.length || 0;

    const onComment = () => {
      setBookmarkCommentsSectionId(sectionId);
      setCommentModalState(
        bookmarkComments?.length ? COMMENT_MODAL_STATE.VIEW_COMMENTS : COMMENT_MODAL_STATE.CREATE_UPDATE_COMMENT,
      );
    };

    const onClick = (event: any) => {
      if (!isSectionBookmarked) {
        createBookmark(designationId, sectionId);
      } else {
        setSectionBookmarkToolboxId(sectionBookmarkToolboxId ? DEFAULT_SECTION_ID : sectionId);
      }
    };

    const onCommentClick = () => {
      setBookmarkCommentsSectionId(sectionId);
      setCommentModalState(
        bookmarkComments?.length ? COMMENT_MODAL_STATE.VIEW_COMMENTS : COMMENT_MODAL_STATE.CREATE_UPDATE_COMMENT,
      );
    };

    return (
      <Detector
        polling={ false }
        render={({ online }) => (
          <SectionBookmarkModeContainer
            bookmarkModeActive={bookmarkModeActive}
            bookmarked={isSectionBookmarked}
            isMobile={isMobile}
            backgroundColor={backgroundColor}
            highlightModeActive={highlightModeActive}
            highlightToolboxOpen={!!highlightToolboxOpenId}
            isOffline={!online}
          >
            <div onClick={onClick}>
              <BookmarkBadge
                isSectionBookmarked={isSectionBookmarked}
                shouldShowBookmarkBadge={shouldShowBookmarkBadge}
                isMobile={isMobile}
                sectionId={sectionId}
                budgeActive={isActive || ReaderLayoutStoreContext.BookmarkInFocusId === sectionId}
              />
            </div>
            <MarkerToolboxPopup
              commentsCount={commentsCount}
              onDeleteAccept={onDeleteBookmarkAccept}
              isToolboxOpen={isActive}
              onComment={onComment}
            />

            <CommentsBadge
              sectionId={sectionId}
              isMobile={isMobile}
              comments={bookmarkComments}
              isInFocus={ReaderLayoutStoreContext.BookmarkCommentsInFocusId === sectionId}
              onClick={onCommentClick}
            />
            <SectionContentContainer
              isMobile={isMobile}
              lastSubSection={lastSubSection}
              highlightModeActive={highlightModeActive}
              className={highlightModeActive ? "highlight-active" : ""}
            >
              <HtmlContent sectionId={sectionId} html={html} isPreview={ReaderLayoutStoreContext.isPreview}/>
            </SectionContentContainer>
          </SectionBookmarkModeContainer>
        )}
      />
    );
  },
);

export default React.memo(SectionBookmarkContainer);
