//MODIFIED_FILE
import React, { useEffect, useState, useContext } from "react";
//import { useRouter } from "next/dist/client/router";
import { useParams, useNavigate } from "react-router-dom";
import UserStore from "../stores/userStore";

//creates an infinite scroll element
//with passed in children
export function InfiniteScroll(props: any): React.ReactElement {
  //loadMore: function to load more results
  //maxHeight: if init load of data doesn't reach max height, load more
  //elementId: id of element
  const { children, loadMore, maxHeight, elementId } = props;

  //const router = useRouter();
  let params = useParams();
  const userContext = useContext(UserStore);
  //const { isLoggedIn } = userContext;  //TODO
  const previewMode = true;//!isLoggedIn || params.preview === "1";
  //in preview mode there's a label inside the result container
  //the below handles that may adding previewHeight onto the original height
  const previewHeightScroll = previewMode ? 110 : 0;
  const previewHeightResize = previewMode ? 45 : 0;

  //handles scroll when at bottom
  const handleScroll = () => {
    const el = document.getElementById(elementId);
    if (el === null) {
      return;
    }
    const docHeight = Math.max(el.scrollHeight, el.offsetHeight, el.clientHeight, el.scrollHeight, el.offsetHeight);

    if (el.offsetHeight + el.scrollTop >= docHeight - previewHeightScroll) {
      setTimeout(() => {
        loadMore();
      }, 100);
    }
  };

  //handles resize when there's no scroll bar
  const handleResize = async () => {
    setTimeout(async () => {
      let el = document.getElementById(elementId);
      while (el !== null && el.scrollHeight <= maxHeight + previewHeightResize && loadMore() === true) {
        el = document.getElementById(elementId);
      }
    }, 100);
  };

  //find elements until scroll bar is visible
  handleResize();

  //set event listeners
  useEffect(() => {
    //get element
    const el = document.getElementById(elementId);
    if (el !== null) {
      //set listener of scroll event
      el.addEventListener("scroll", handleScroll);
    }
  }, []);

  //return children with loader
  return <div>{children}</div>;
}
