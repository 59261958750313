export enum READER_MENU_ITEMS {
  TOC = 0,
  HIGHLIGHT = 1,
  ANNOTATE = 2,
  BOOKMARK = 3,
  SEARCH = 4,
  BOOKMARKS = 5,
  SETTINGS = 6,
  DOWNLOAD = 7,
}
