import React from "react";
import COMMENT_MODAL_STATE from "../../../constants/comments";

export const DEFAULT_SECTION_ID = 0;

export interface IReaderContentContext {
  highlightToolboxOpenId: number;
  setHighlightToolboxOpenId: (n: number) => void;

  sectionBookmarkToolboxId: number;
  setSectionBookmarkToolboxId: (n: number) => void;

  commentModalState: COMMENT_MODAL_STATE;
  setCommentModalState: (s: COMMENT_MODAL_STATE) => void;

  inFocusSection: number;
  setInFocusSection: (n: number) => void;
}

// Set defaults
const contextValue: IReaderContentContext = {
  highlightToolboxOpenId: DEFAULT_SECTION_ID,
  setHighlightToolboxOpenId: (n: number) => undefined,

  sectionBookmarkToolboxId: DEFAULT_SECTION_ID,
  setSectionBookmarkToolboxId: (n: number) => undefined,

  commentModalState: COMMENT_MODAL_STATE.CLOSED,
  setCommentModalState: (s: COMMENT_MODAL_STATE) => undefined,

  inFocusSection: DEFAULT_SECTION_ID,
  setInFocusSection: (n: number) => undefined,
};

export default React.createContext(contextValue);
