import styled from "styled-components";
import STYLE_DEFAULTS from "../../../../constants/styles";
import { SA_FONT_WEIGHT } from "../../../../constants/styles/fonts";
import { SA_B020 } from "../../../../constants/styles/colors";
import { MEDIA_QUERIES } from "../../../../constants/mediaQueries";

const SPACING = STYLE_DEFAULTS.SPACING;
const E_READER_CONTENT = STYLE_DEFAULTS.E_READER_CONTENT;

interface IProps {
  isMobile?: boolean;
  isFooter?: boolean;
  isMarginMarkerExist?: boolean;
  isOffline?: boolean;
}

export const IFrameSection = styled.div``;

export const EReaderContent = styled.div`
  padding: ${E_READER_CONTENT.READER_CONTENT_PADDING_TOP}px 0;
  padding-left: ${STYLE_DEFAULTS.E_READER_CONTENT.READER_CONTENT_PADDING_LEFT}px;
  padding-right: ${STYLE_DEFAULTS.E_READER_CONTENT.READER_CONTENT_PADDING_RIGHT}px;
`;

export const AlertContainer = styled.div`
  padding-left: ${STYLE_DEFAULTS.E_READER_CONTENT.READER_CONTENT_PADDING_LEFT}px;
  padding-right: ${STYLE_DEFAULTS.E_READER_CONTENT.READER_CONTENT_PADDING_RIGHT}px;
`;

export const OfflineAlertContainer = styled.div`
  padding-left: ${STYLE_DEFAULTS.E_READER_CONTENT.READER_CONTENT_PADDING_LEFT}px;
  padding-right: ${STYLE_DEFAULTS.E_READER_CONTENT.READER_CONTENT_PADDING_RIGHT}px;
  position: fixed;
  z-index: 10;
  width: 920px;

  @media ${MEDIA_QUERIES.SM} {
    width: calc(100vw - 86px);
  }

  @media ${MEDIA_QUERIES.XS} {
    width: calc(100vw - 86px);
  }
`;

// Includes sticky message when necessary
export const DocumentViewSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
  width: calc(100% - 40px);
  height: calc(100vh - 60px); /* 60px is the height of the header. */
  overflow-x: hidden;
  margin-right: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_5};

  @media ${MEDIA_QUERIES.SM} {
    margin: 0 ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_2};
    width: calc(100% - 16px);
  }
`;

export const DocumentViewPanel = styled.div`
  max-width: 990px; /* Padding of 30 either side of iframe make it 920px */
  /* Extra padding is in the iframe */
  margin-left: auto; /* To make it centered */
  margin-right: auto; /* To make it centered */
  height: 100vh;
`;

export const DocumentViewContainer = styled.div`
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.NOTO_SANS};
  flex: 1;
  width: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-wrap: break-word;
  margin-bottom: 20px;
  padding-top: ${(props: IProps) =>
    props.isMobile ? STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_2 : STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_5};
`;

export const DocumentViewAlertContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const DocumentViewAlert = styled.div`
  padding-left: ${STYLE_DEFAULTS.E_READER_CONTENT.READER_CONTENT_PADDING_LEFT}px;
  padding-right: ${STYLE_DEFAULTS.E_READER_CONTENT.READER_CONTENT_PADDING_RIGHT}px;
  max-width: 990px;
  width: 100%;
  box-sizing: border-box;
`;

export const LoaderContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-60%, -40%);

  @media ${MEDIA_QUERIES.SM} {
    top: calc(50% - 48px); // Account for header
    left: calc(50% + 28px); // Account for left side menu (not visible, but seems to affect the positioning)
  }
`;

export const DocumentViewPreviewFooter = styled.div`
  border-width: 100%;
  border-top: 1px solid ${SA_B020};
  border-bottom: 1px solid ${SA_B020};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: ${(props: IProps) => (props.isMobile ? "center" : "left")};
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.NOTO_SANS};
  margin-bottom: 60px;
`;

export const DocumentViewHeader = styled.div`
  font-size: ${(props: IProps) => (props.isFooter ? "32px" : "14px")};
  padding-bottom: ${SPACING.SA_SPACING_FACTOR_3};
  font-weight: ${SA_FONT_WEIGHT.BOLD};
`;

export const DocumentViewContent = styled.div`
  align-items: ${(props: IProps) => (props.isFooter ? "center" : "left")};
  font-size: ${(props: IProps) => (props.isFooter ? SPACING.SA_SPACING_FACTOR_4 : SPACING.SA_SPACING_FACTOR_3)};
  padding: ${SPACING.SA_SPACING_FACTOR_3} ${SPACING.SA_SPACING_FACTOR_3} ${SPACING.SA_SPACING_FACTOR_3} ${SPACING.SA_SPACING_FACTOR_3};
`;

export const FootButtonContainer = styled.div`
  padding: ${SPACING.SA_SPACING_FACTOR_3} ${(props: IProps) => (props.isMobile ? "100px" : "0px")};
`;
