export const screenSize = {
        mobile: '375',
        mobilelarge: '428',
        tabletMax: '820',
        tabletMin: '765',
        laptop:'1024',
        desktop:'1440'
}

const device = {
    mobileSMax: `(max-width: ${screenSize.mobile}px)`,
    mobileLMax: `(max-width: ${screenSize.mobilelarge}px)`,
    tabletMax: `(max-width: ${screenSize.tabletMax}px)`,
    tabletMin: `(max-width: ${screenSize.tabletMin}px)`,
    laptopMax: `(max-width: ${screenSize.laptop}px)`,
    desktopMax: `(max-width: ${screenSize.desktop}px)`,
    desktopLargeMin: `(min-width: ${screenSize.desktop}px)`
};

export default device;