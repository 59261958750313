import { useState, useEffect } from 'react';
import { screenSize }  from '../constants/device';

/** Gets browser window width and height info */
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

/** Custom hook providing information on screen size status */
export default function useScreenSize() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
      /** Checks if screen size is considered as a Small */
      IsSmallScreen : windowDimensions.width <= Number(screenSize.mobilelarge),
      /** Checks if screen size is considered as a Medium */
      IsMediumScreen : windowDimensions.width <= Number(screenSize.tabletMax),
      /** Checks if screen size is considered as a Large */
      IsLargeScreen : windowDimensions.width >= Number(screenSize.tabletMax)
  };
}