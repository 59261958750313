import { MEDIA_QUERIES } from "constants/mediaQueries";
import STYLE_DEFAULTS from "constants/styles";
import styled from "styled-components";

export const ModalContainer = styled.div`
  position: fixed;
  top: 25%;
  left: calc(50% - 192px);
  z-index: ${STYLE_DEFAULTS.Z_INDEX_LAYOUT.BODY_MODAL};
  background-color: ${STYLE_DEFAULTS.COLORS.SA_B010};
  -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
  width: 384px;

  @media ${MEDIA_QUERIES.SM} {
    width: 100%;
    left: 0;
  }
`;

export const CustomModalHeader = styled.div`
    display: flex;
    flex-direction: row;
    padding-top: 8px;
    
`;

export const CustomModalTitle = styled.div`
    text-align: left;
    width: 80%;
    font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.NOTO_SANS};
    font-size: ${STYLE_DEFAULTS.FONTS.SA_FONT_SIZE.SMALL};
    font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.MEDIUM};
    line-height: 1.5;
    color: ${STYLE_DEFAULTS.COLORS.SA_B070};
  
    @media ${MEDIA_QUERIES.SM} {
      font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.HELVETICA_NEUE};
      font-size: 21px; // relevant for mobile only
      font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.NORMAL};
      line-height: 1.25;
      color: ${STYLE_DEFAULTS.COLORS.SA_BLACK_B100};
    }
`;

export const CustomModalCloseButton = styled.button`
    margin-left: 20%;
    background: none;
    border: none;
    cursor: pointer;
`;