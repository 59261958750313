//MODIFIED_FILE
import qs from "querystring";
import callApi, { CustomAxiosRequestConfig } from "./AxiosController";
import { Method, ResponseType } from "axios";
import { IUserInfo } from "../models/userInfo";
import { IHighlightRequest } from "../models/highlightMark";
import { SHIPPING_TYPE } from "../constants/shippingMethods";
import ACCESS_LEVEL from "../constants/accessLevel";
import { isAdmin } from "../helpers/reader";
import env from "../models/Configuration";

const callApiWithCredentials = async (config: CustomAxiosRequestConfig) => {
  const data = await callApi(Object.assign({}, config, { withCredentials: true }));

  return data;
};

//set base url which is defined in .env file
const BASE_URL: string = env.api.deliveryServiceBaseUrl?.toString() || "";
const BACKOFFICE_BASE_URL: string = process.env.REACT_APP_BACKOFFICE_SERVICE_BASE_URL?.toString() || "";
const ANNOTATIONS_BASE_URL: string = env.api.deliveryServiceBaseUrl?.toString() || "";


async function fetchSections(designationId: string, previewOnly: boolean, startingIndex: number, version: string) {
  const isBackOffice = isAdmin(); // Check if the call came through backOffice
  const baseUrl = isBackOffice
    ? `/office/ereader/sections/content` // In case of BO we never access preview
    : `/shop/ereader/sections/${previewOnly ? "preview" : "content"}`;
  const url =
    `${baseUrl}?designationId=${encodeURIComponent(designationId)}&startingIndex=${startingIndex}` +
    (version ? "&version=" + version : "");
  const config = {
    baseURL: isBackOffice ? BACKOFFICE_BASE_URL : BASE_URL,
    url: url,
    method: "get" as Method,
    timeout: 60000, // 60 seconds as some document has very big sections and causes the connection timeout.
  };

  const data = await callApiWithCredentials(config);
  await applyMathJax(data);
  return data;
}

async function applyMathJax(data: any) {
  const sections = data.data.sections;
  const sectionsWithMath = sections.filter((section: any) => section.partialHtmlBody.includes("<math"));

  if (sectionsWithMath.length === 0) {
    return;
  }

  const parser = new DOMParser();
  const docs = sectionsWithMath.map((section: any) => {
    const doc = parser.parseFromString(section.partialHtmlBody || '', 'text/html');
    return { section, doc };
  });

  const promises = docs.map(({ doc }: any) => (window as any).MathJax.typesetPromise([doc.body]));

  try {
    await Promise.all(promises);
    docs.forEach(({ section, doc }: any) => {
      section.partialHtmlBody = doc.documentElement.outerHTML;
    });
  } catch (err) {
    console.error('Failed to apply MathJax.', err);
  }
}

async function fetchToc(designationId: string, version?: string) {
  const isBackOffice = isAdmin(); // Check if the call came through backOffice
  const url = isBackOffice
    ? "/office/ereader/table-of-content?designationId=" + encodeURIComponent(designationId)
    : "/shop/ereader/table-of-content?designationId=" +
      encodeURIComponent(designationId) +
      (version ? "&version=" + version : "");
  const config = {
    baseURL: isBackOffice ? BACKOFFICE_BASE_URL : BASE_URL,
    url: url,
    method: "get" as Method,
  };
  const data = await callApiWithCredentials(config);
  return data;
}

async function fetchUser(timeout?: number) {
  const url = "/user";
  const config = {
    baseURL: BASE_URL,
    url: url,
    method: "get" as Method,
    timeout: timeout,
  };
  const data = await callApiWithCredentials(config);
  return data;
}

async function createUser(userInfo: IUserInfo) {
  const config = {
    baseURL: BASE_URL,
    url: "/user",
    method: "post" as Method,
    data: {
      password: userInfo.password,
      email: userInfo.email,
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      companyName: userInfo.companyName,
      companySize: userInfo.companySize,
      jobRole: userInfo.jobRole,
      industrySector: userInfo.industrySector,
      cartCode: userInfo.cartCode,
      phone: userInfo.phone,
      marketingCommunicationPref: userInfo.marketingCommunicationPref,
    },
  };
  const data = await callApiWithCredentials(config);
  return data;
}

async function updateUser(userInfo: IUserInfo) {
  const config = {
    baseURL: BASE_URL,
    url: "/user",
    method: "patch" as Method,
    data: {
      email: userInfo.email,
      phone: userInfo.phone,
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      companyName: userInfo.companyName,
      companySize: userInfo.companySize,
      jobRole: userInfo.jobRole,
      industrySector: userInfo.industrySector,
    },
  };
  const data = await callApiWithCredentials(config);
  return data;
}

async function fetchProductDetails(designationId: string, cookies?: string) {
  const url = `/shop/publications/productDetails?designationId=${encodeURIComponent(designationId)}`;
  const config = {
    baseURL: BASE_URL,
    url: url,
    method: "get" as Method,
    headers: {
      Cookie: cookies,
    },
  };
  const data = await callApiWithCredentials(config);

  return data;
}

async function fetchNormRefs(designationId: string, page: number, limit: number) {
  const url = `/shop/publications/normRefs?designationId=${encodeURIComponent(
    designationId,
  )}&page=${page}&limit=${limit}`;
  const config = {
    baseURL: BASE_URL,
    url: url,
    method: "get" as Method,
  };

  const data = await callApiWithCredentials(config);

  return data;
}

async function fetchBookmarks(designationId: string) {
  const url = `/api/bookmark?designationId=${encodeURIComponent(designationId)}`;
  const config = {
    baseURL: ANNOTATIONS_BASE_URL,
    url: url,
    method: "get" as Method,
  };

  const data = await callApiWithCredentials(config);

  return data;
}

async function createBookmark(designationId: string, sectionId: number) {
  const url = "/api/bookmark/create";
  const config = {
    baseURL: ANNOTATIONS_BASE_URL,
    url: url,
    method: "post" as Method,
    data: {
      designationId,
      sectionId,
    },
  };

  const data = await callApiWithCredentials(config);

  return data;
}

async function deleteBookmark(bookmarkId: string) {
  const url = `/api/bookmark/${bookmarkId}/delete`;
  const config = {
    baseURL: ANNOTATIONS_BASE_URL,
    url: url,
    method: "delete" as Method,
  };

  const data = await callApiWithCredentials(config);
  return data;
}

async function fetchHighlights(designationId: string) {
  const url = `/api/highlight?designationId=${encodeURIComponent(designationId)}`;
  const config = {
    baseURL: ANNOTATIONS_BASE_URL,
    url: url,
    method: "get" as Method,
  };

  const data = await callApiWithCredentials(config);

  return data;
}

async function createHighlight(props: IHighlightRequest) {
  const url = "/api/highlight/create";
  const config = {
    baseURL: ANNOTATIONS_BASE_URL,
    url: url,
    method: "post" as Method,
    data: props,
  };

  const data = await callApiWithCredentials(config);

  return data;
}

async function deleteHighlight(highlightId: any) {
  const url = `/api/highlight/${highlightId}/delete`;
  const config = {
    baseURL: ANNOTATIONS_BASE_URL,
    url: url,
    method: "delete" as Method,
  };

  const data = await callApiWithCredentials(config);

  return data;
}

async function fetchProtectedPdf(designationId: string) {
  const url = `/drm/getProtectedPdf?designationId=${encodeURIComponent(designationId)}`;
  const config = {
    baseURL: BASE_URL,
    url: url,
    method: "get" as Method,
    timeout: 180000, //180 seconds
  };
  const data = await callApiWithCredentials(config);
  return data;
}

async function validateOrderProducts(cartCode?: string) {
  const config = {
    baseURL: BASE_URL,
    url: "/shop/orders/products/validate",
    data: {
      cartCode: cartCode,
    },
    method: "post" as Method,
  };

  const data = await callApiWithCredentials(config);

  return data;
}

async function resetOrderProducts(cartCode?: string) {
  const config = {
    baseURL: BASE_URL,
    url: "/shop/orders/products/reset",
    data: {
      cartCode: cartCode,
    },
    method: "post" as Method,
  };

  const data = await callApiWithCredentials(config);

  return data;
}

async function createComment(bookmarkId: number | null, highlightId: number | null, content: string) {
  const url = "api/comment/create";
  const config = {
    baseURL: BASE_URL,
    url: url,
    method: "post" as Method,
    data: {
      bookmarkId: bookmarkId || undefined,
      highlightId: highlightId || undefined,
      content,
    },
  };

  const data = await callApiWithCredentials(config);

  return data;
}

async function updateComment(commentId: number, content: string) {
  const url = "api/comment/update";
  const config = {
    baseURL: BASE_URL,
    url: url,
    method: "put" as Method,
    data: {
      commentId,
      content,
    },
  };

  const data = await callApiWithCredentials(config);

  return data;
}

async function deleteComment(commentId: number) {
  const url = `api/comment/${commentId}/delete`;
  const config = {
    baseURL: BASE_URL,
    url: url,
    method: "delete" as Method,
  };

  const data = await callApiWithCredentials(config);

  return data;
}

async function fetchTopStandards(sector?: string, limit?: number, publisher?: string) {
  const baseUrl = "/shop/publications/top";
  const url =
    `${baseUrl}?sector=${encodeURIComponent(sector || " ")}` +
    `&limit=${encodeURIComponent(limit || " ")}` +
    `&publisher=${encodeURIComponent(publisher || " ")}`;

  const config = {
    baseURL: BASE_URL,
    url: url,
    method: "get" as Method,
  };
  const data = await callApiWithCredentials(config);
  return data;
}

async function fetchNewStandards(limit?: number) {
  const config = {
    baseURL: BASE_URL,
    url: `/shop/publications/new?limit=${encodeURIComponent(limit || " ")}`,
    method: "get" as Method,
  };
  const data = await callApiWithCredentials(config);
  return data;
}

async function trackContent(designationId: string) {
  const config = {
    baseURL: BASE_URL,
    url: `/shop/content/track`,
    method: "post" as Method,
    data: {
      designationId: designationId,
    },
  };
  const data = await callApiWithCredentials(config);
  return data;
}

async function untrackContent(designationId: string, remove: boolean) {
  const config = {
    baseURL: BASE_URL,
    url: `/shop/content/untrack?remove=${remove}`,
    method: "post" as Method,
    data: {
      designationId: designationId,
    },
  };
  const data = await callApiWithCredentials(config);
  return data;
}

async function fetchTrackedContent(limit: number, page: number, sortBy: string, publisher?: string) {
  const config = {
    baseURL: BASE_URL,
    url:
      publisher !== undefined
        ? `/shop/content/tracked?limit=${limit}&page=${page}&publisher=${publisher}&sortBy=${sortBy}`
        : `/shop/content/tracked?limit=${limit}&page=${page}&sortBy=${sortBy}`,
    method: "get" as Method,
    headers: {
      Pragma: "no-cache",
    },
  };
  const data = await callApiWithCredentials(config);
  return data;
}

async function fetchContentLibrary() {
  const config = {
    baseURL: BASE_URL,
    url: `/content/library`,
    method: "get" as Method,
  };
  const data = await callApiWithCredentials(config);
  return data;
}

export default {
  fetchSections,
  fetchToc,
  fetchUser,
  createUser,
  updateUser,
  fetchProductDetails,
  fetchNormRefs,
  fetchBookmarks,
  createBookmark,
  deleteBookmark,
  fetchHighlights,
  createHighlight,
  deleteHighlight,
  fetchProtectedPdf,
  validateOrderProducts,
  resetOrderProducts,
  createComment,
  updateComment,
  deleteComment,
  fetchTopStandards,
  fetchNewStandards,
  trackContent,
  untrackContent,
  fetchTrackedContent,
  fetchContentLibrary,
};
