import styled from "styled-components";
import STYLE_DEFAULTS from "../../../constants/styles";
import { InFocus, setButtonElementsColor } from "../../../styles/typography";
import { BUTTON_DEFAULTS } from "../../../constants/buttons";
import { onHoverStateTransition } from "../../../styles/global";

interface IProps {
  withMargin?: boolean;
  primary?: boolean;
  secondary?: boolean;
  width?: string;
  height?: string;
  disabled?: boolean;
  isLoading?: boolean;
  deleteButton?: boolean;
  color?: string;
  small?: boolean;
  value?: string;
  isInFocus?: boolean;
  background?: string;
  name?: string;
}

const primaryButtonStyling = `
  background-color: ${STYLE_DEFAULTS.COLORS.SA_ORANGE_ACTIVE};
  ${setButtonElementsColor(STYLE_DEFAULTS.COLORS.SA_BLACK_B100)};

  :hover {
    background-color: ${STYLE_DEFAULTS.COLORS.SA_ORANGE_W010};
    ${onHoverStateTransition};
  }

  :focus {
    background-color: ${STYLE_DEFAULTS.COLORS.SA_ORANGE_W015};
    ${InFocus}
  }

  :active {
    background-color: ${STYLE_DEFAULTS.COLORS.SA_ORANGE_W025};
    ${onHoverStateTransition};
  }
`;

const secondaryButtonStyling = `
  background-color: ${STYLE_DEFAULTS.COLORS.SA_WHITE_B000};
  ${setButtonElementsColor(STYLE_DEFAULTS.COLORS.SA_B070)};
  border: 1px solid ${STYLE_DEFAULTS.COLORS.SA_B070};

  :hover {
    background-color: ${STYLE_DEFAULTS.COLORS.SA_B015};
    border: 1px solid ${STYLE_DEFAULTS.COLORS.SA_BLACK_B100};
    ${setButtonElementsColor(STYLE_DEFAULTS.COLORS.SA_BLACK_B100)};
    ${onHoverStateTransition};
  }

  :focus {
    background-color: ${STYLE_DEFAULTS.COLORS.SA_B013_3};
    border: 1px solid ${STYLE_DEFAULTS.COLORS.SA_BLACK_B100};
    ${InFocus}
    ${setButtonElementsColor(STYLE_DEFAULTS.COLORS.SA_BLACK_B100)};
  }

  :active {
    background-color: ${STYLE_DEFAULTS.COLORS.SA_B013_3};
    border: 1px solid ${STYLE_DEFAULTS.COLORS.SA_BLACK_B100};
    ${setButtonElementsColor(STYLE_DEFAULTS.COLORS.SA_BLACK_B100)};
    ${onHoverStateTransition};
  }
`;

const deleteButtonStyling = `
  background-color: ${STYLE_DEFAULTS.COLORS.SA_RED};
  ${setButtonElementsColor(STYLE_DEFAULTS.COLORS.SA_WHITE_B000)};

  :hover {
    background-color: ${STYLE_DEFAULTS.COLORS.SA_RED_B010};
    ${onHoverStateTransition};
  }

  :focus {
    background-color: ${STYLE_DEFAULTS.COLORS.SA_RED_B015};
    ${InFocus}
  }

  :active {
    background-color: ${STYLE_DEFAULTS.COLORS.SA_RED_W025};
    ${onHoverStateTransition};
  }
`;

export const ButtonContainer = styled.button`
  padding: ${(props: IProps) =>
    props.small
      ? `0 ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_2}`
      : `0 ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_4}`};
  height: ${(props: IProps) => props.height};
  box-sizing: border-box;
  border-radius: ${STYLE_DEFAULTS.BUTTONS.SA_BUTTON_RADIUS};
  -webkit-border-radius: ${STYLE_DEFAULTS.BUTTONS.SA_BUTTON_RADIUS};
  -moz-border-radius: ${STYLE_DEFAULTS.BUTTONS.SA_BUTTON_RADIUS};
  cursor: pointer;
  color: ${(props: IProps) => props.color || STYLE_DEFAULTS.COLORS.SA_BLACK_B100};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;

  ${(props: IProps) => {
    let style = "";

    if (props.disabled) {
      const background =
        props.primary || props.small
          ? STYLE_DEFAULTS.COLORS.SA_B015
          : props.secondary
          ? STYLE_DEFAULTS.COLORS.SA_WHITE_B000
          : "none";

      style += `
        background-color:  ${background};
        pointer-events: none;
        border: 1px solid ${STYLE_DEFAULTS.COLORS.SA_B015};
      `;
      style += setButtonElementsColor(STYLE_DEFAULTS.COLORS.SA_DISABLED);
    } else if (props.isLoading) {
      style += `
        background-color:  ${STYLE_DEFAULTS.COLORS.SA_B015};
        pointer-events: none;
      `;
      style += setButtonElementsColor(STYLE_DEFAULTS.COLORS.SA_B030);
    } else {
      if (props.primary) {
        style += primaryButtonStyling;
      } else if (props.secondary) {
        style += secondaryButtonStyling;
      } else if (props.deleteButton) {
        style += deleteButtonStyling;
      }
    }

    if (props.isInFocus) {
      style += InFocus;
    }

    if (props.width) {
      style += `
              width: ${props.width};
          `;
    } else {
      style += `
          width: ${BUTTON_DEFAULTS.WIDTH};
          `;
    }

    if (props.background) {
      style += `
              background: ${props.background};
            `;
    }

    return style;
  }}
`;

export const ButtonIcon = styled.div`
  margin-right: ${(props: IProps) => (props.withMargin ? "8px" : 0)};
`;

export const ButtonValue = styled.div`
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.HELVETICA_NEUE_REGULAR};
  font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.NORMAL};
  font-size: ${(props: IProps) =>
    props.small ? "14px" : "16px"}; /* same for desktop and mobile, do not use em - must be constant */
  letter-spacing: 0.53px;
  line-height: 1.125; /* same for desktop and mobile */
  padding-top: 3px; /* proprietary Helvetica font has extra space underneath the text - add top padding to center the text */
  width: fit-content;
  text-align: center;
`;

export const CloseButtonContainer = styled.div`
  width: ${(props: IProps) => props.width};
  height: ${(props: IProps) => props.height};

  :hover {
    ${setButtonElementsColor(STYLE_DEFAULTS.COLORS.SA_BLACK_B100)};
    ${onHoverStateTransition};
  }

  :focus {
    ${setButtonElementsColor(STYLE_DEFAULTS.COLORS.SA_BLACK_B100)};
  }

  :active {
    ${setButtonElementsColor(STYLE_DEFAULTS.COLORS.SA_BLACK_B100)};
    ${onHoverStateTransition};
  }
`;
