import styled from "styled-components";
import STYLE_DEFAULTS from "../../../constants/styles";
import { onHoverStateTransition } from "../../../styles/global";

interface IProps {
  isMobile?: boolean;
  color?: string;
  isLargeIcon?: boolean;
}

export const MenuButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: ${(props: IProps) => (props.isMobile ? "48px" : "80px")};
  height: ${(props: IProps) => (props.isMobile ? "60px" : "80px")};
  cursor: pointer;
  box-sizing: border-box;
  color: ${(props: IProps) => props.color};

  :hover,
  :focus,
  :active {
    color: ${STYLE_DEFAULTS.COLORS.SA_WHITE_B000};
    ${onHoverStateTransition};

    svg g,
    svg rect,
    svg path {
      fill: ${STYLE_DEFAULTS.COLORS.SA_WHITE_B000};
    }
  }

  :hover {
    ${onHoverStateTransition};
  }

  ${(props: IProps) => {
    let paddingTopPx = props.isMobile ? 11 : 18;

    if (props.isLargeIcon) {
      paddingTopPx -= 4;
    }

    return `padding-top: ${paddingTopPx}px;`;
  }}
`;

export const MenuIcon = styled.div`
  margin-bottom: ${(props: IProps) => (props.isMobile ? "3px" : "4px")};
`;

export const MenuCaption = styled.div`
  font-family: ${(props: IProps) =>
    props.isMobile
      ? STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.NOTO_SANS
      : STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.HELVETICA_NEUE};
  font-weight: ${(props: IProps) =>
    props.isMobile ? STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.MEDIUM : STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.NORMAL};
  font-size: ${STYLE_DEFAULTS.FONTS.SA_FONT_SIZE.SMALLER};
  letter-spacing: ${(props: IProps) => (props.isMobile ? 0 : "0.4px")};
  line-height: ${(props: IProps) => (props.isMobile ? 1 : "14px")};
  margin-bottom: ${(props: IProps) => (props.isMobile ? "11px" : "17px")};
  text-transform: ${(props: IProps) => (props.isMobile ? "uppercase" : "none")};
`;

interface IMenuUnderline {
  isVisible?: boolean;
  isMobile?: boolean;
}

export const MenuUnderline = styled.div`
  position: absolute;
  bottom: 0;
  background-color: ${STYLE_DEFAULTS.COLORS.SA_ORANGE_ACTIVE};
  width: 100%;
  max-width: ${(props: IMenuUnderline) => (props.isMobile ? "48px" : "80px")};
  height: 2px;
  visibility: ${(props: IMenuUnderline) => (props.isVisible ? "visible" : "hidden")};
`;

interface IFlipIconProps {
  size?: string;
  showSecondIcon?: boolean;
}

export const FlipIconContainer = styled.div`
  width: ${(props: IFlipIconProps) => props.size};
  height: ${(props: IFlipIconProps) => props.size};
  perspective: 600px;
`;

export const FlipIconInner = styled.div`
  width: 100%;
  height: 100%;
  transition: transform 1s;
  transform-style: preserve-3d;
  cursor: pointer;
  position: relative;

  ${(props: IFlipIconProps) => {
    if (props.showSecondIcon) {
      return `
          transform: rotateY(180deg);
        `;
    }
  }}
`;

export const FlipIconFront = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  line-height: 260px;
  color: ${STYLE_DEFAULTS.COLORS.SA_WHITE_B000};
  text-align: center;
  font-weight: bold;
  font-size: 40px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;

  ${(props: IFlipIconProps) => {
    if (props.showSecondIcon) {
      return `
          opacity: 0;
          transition: opacity 800ms;
        `;
    } else {
      return `
        opacity: 1;
        transition:  opacity 300ms;
      `;
    }
  }}
`;

export const FlipIconBack = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  line-height: 260px;
  color: ${STYLE_DEFAULTS.COLORS.SA_WHITE_B000};
  text-align: center;
  font-weight: bold;
  font-size: 40px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: rotateY(180deg);
`;
