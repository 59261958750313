export const extractMaxWords = (text: string, maxWords: number) => {
  if (!text) return "";
  const re = new RegExp(`^((?:\\S+\\s+){${maxWords - 1}}\\S+)`);
  let textTrim = text;
  if (textTrim.trimStart) {
    textTrim = textTrim.trimStart();
  }
  const match = re.exec(textTrim);
  return match && match?.length > 0 ? match[0] + "..." : text;
};
