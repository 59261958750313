import React, { useEffect, useState } from 'react';
import Routes from './components/routes/Routes';
import { defaultTheme } from "./constants/theme";
import { ThemeProvider } from 'styled-components';
import { BreakpointProvider } from './services/breakpointProvider/BreakpointProvider';
import { MEDIA_QUERIES } from "./constants/mediaQueries";
import env from "./models/Configuration";

import ErrorModal from 'components/errorModal/ErrorModal';
import { Spinner } from 'react-bootstrap';
import broadcastChannelService, { BROADCAST_CHANNEL, BROADCAST_CHANNEL_MESSAGES } from 'services/broadcastChannelService';

interface IAppProps {
  isAuthenticated: boolean;
  isAuthorised: boolean;
  onlogout?: () => void;
}

const App = ({ isAuthenticated, isAuthorised, onlogout }: IAppProps) => {
  
  const channel = new broadcastChannelService(BROADCAST_CHANNEL.AUTHENTICATION);

  const [isUserLoggingOut, setIsUserLoggingOut] = useState(false);

  useEffect(() => {

    channel.subscribe(BROADCAST_CHANNEL_MESSAGES.LOG_OUT, handleLogout);

  }, []);

  useEffect(() => {
    if (window.analytics && window.analytics.load) {
      window.analytics._writeKey = env.app.segmentKey;
      window.analytics.load(env.app.segmentKey);
    }
  }, []);

  const handleLogout = () => {
    setIsUserLoggingOut(true);
    onlogout?.call(this); 
  }



  return (
      <BreakpointProvider queries={MEDIA_QUERIES}>
        <>
          <script
            type="text/javascript"
            id="MathJax-script"
            async
            src="https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-mml-chtml.js"
          ></script>
          <script type="text/javascript" src="./assets/scripts/scrollToAnchor.js" />
          <link rel="stylesheet" href="../src/assets/css/print.css" media="print" />
        </>
        <ThemeProvider theme={defaultTheme}>
          <div onContextMenu={(event: any) => { event.preventDefault();}}
               onCopy={(event: any) => { event.preventDefault();}}
               onCut={(event: any) => { event.preventDefault();}}>
            {isUserLoggingOut ? <Spinner id="page-spinner" animation="border" variant="warning" /> :
            
            isAuthorised ? <Routes /> : 
                          <ErrorModal isOpen = {true}
                                        title='Authorisation Error' 
                                        message='User not authorised to view subscription details.' 
                                        onCloseClick={handleLogout} 
                                        onOkClick={handleLogout}/>}
            </div>
        </ThemeProvider>
      </BreakpointProvider>
  );
}

export default App;

