(function (document) {

  var anchorScrolls = {
    ANCHOR_REGEX: /^#[^ ]+$/,
    OFFSET_HEIGHT_PX: 50,
    
    /**
     * Establish events, and fix initial scroll position if a hash is provided.
     */
    init: function () {
      this.scrollToCurrent();
      window.addEventListener("hashchange", this.scrollToCurrent.bind(this));
      document.body.addEventListener("click", this.delegateAnchors.bind(this));
    },

    /**
     * If the provided href is an anchor which resolves to an element on the
     * page, scroll to it.
     * @param  {String} href
     * @return {Boolean} - Was the href an anchor.
     */
    scrollIfAnchor: function (href) {
      var match;

      if (!this.ANCHOR_REGEX.test(href)) {
        return false;
      }

      match = document.getElementById(href.slice(1));

      if (match !== undefined && match !== null) {
        match.scrollIntoView(true);

        // now account for fixed header
        const scrolledY = window.scrollY;

        if (scrolledY) {
          window.scroll(0, scrolledY - 100);
        }
      }

      return !!match;
    },

    /**
     * Attempt to scroll to the current location's hash.
     */
    scrollToCurrent: function () {
      this.scrollIfAnchor(window.location.hash);
    },

    /**
     * If the click event's target was an anchor, fix the scroll position.
     */
    delegateAnchors: function (e) {
      var elem = e.target;

      if (elem.nodeName === "A" && this.scrollIfAnchor(elem.getAttribute("href"))) {
        e.preventDefault();
      }
    },
  };


  if (document.readyState !== 'loading') {
    anchorScrolls.init();
  } else {
    document.addEventListener('DOMContentLoaded', anchorScrolls.init.bind(anchorScrolls));
  }

})(window.document, window.history, window.location);