// eReader content padding - must remain the same when bookmark/highlight/etc
// mode is triggered, currently "35px 40px 40px 35px;"
export const READER_CONTENT_PADDING_TOP = 35;
export const READER_CONTENT_PADDING_BOTTOM = 40;
export const READER_CONTENT_PADDING_LEFT = 35;
export const READER_CONTENT_PADDING_RIGHT = 35;

export const READER_HIGHLIGHTED_SECTION_DESKTOP_LEFT = 20;
export const READER_HIGHLIGHTED_SECTION_DESKTOP_RIGHT = 20;
export const READER_HIGHLIGHTED_SECTION_MOBILE_LEFT = 8;
export const READER_HIGHLIGHTED_SECTION_MOBILE_RIGHT = 8;
