//MODIFIED_FILE
//import b2cMsal from "./b2cMsal"
import React from "react";
//import b2cMsal from "../components/b2cAuth/b2cMsal";
import axios from "axios";
import { observable, computed, action, runInAction } from "mobx";
import deliveryService from "../apis/deliveryService";
import _ from "lodash";
import { IUserInfo, IUserAddress, AddressType, IUserCard } from "../models/userInfo";
import COMPANY_SIZE from "../constants/companySize";

// base url which is defined in .env file
const BASE_URL: string = process.env.REACT_APP_DELIVERY_SERVICE_BASE_URL?.toString() || "";
const LOGIN_STATUS_CHECK_INTERVAL_MSEC = 10000;

//static class that holds the current users information
export class UserStore {
  // User authentication
  @observable token: string | undefined = undefined;
  @observable authId: string | undefined = undefined;
  @observable userId: string | undefined = undefined;
  //expires_in contains the epoch time in seconds for when the token will expire, could change it to expires_at but kept it consistent with Azure
  @observable expiresIn: number | undefined = undefined;
  // User details
  @observable user: IUserInfo | undefined = undefined;
  @observable userCards: Array<IUserCard> = [];
  // Store details
  @observable isInitialised = false;
  @observable isTousAgreed = true;
  @observable status: any;
  @observable cartOverwrittenOnLogin = false;
  @observable setRemovedOnLogin = false;
  axiosInterceptorId: number | undefined = undefined;
  loggedInStatusTimeIntervalHandle: number | undefined;


  @action async GetSegmentInfo(additionalData?: any) {
    // if store isn't initialised, attempt to get data
    if (!this.isInitialised) {
      //this.InitialiseUserStore();
    }
    // if user isn't initialised, attempt to get data
    if (!this.user?.email) {
      await this.FetchUserInfo();
    }
    const companySize = _.find(COMPANY_SIZE, (size: any) => size.value === this.user?.companySize);
    return {
      ...additionalData,
      email: this.user?.email,
      company_size: companySize?.label,
      job_role: this.user?.jobRole,
      industry_role: this.user?.industrySector,
      subscribe: this.user?.marketingCommunicationPref,
      user_id: this.userId,
      name: `${this.user?.firstName} ${this.user?.lastName}`,
    };
  }

  @action ResetCartOverwrittenOnLogin = () => {
    runInAction(() => {
      this.cartOverwrittenOnLogin = false;
    });
  };

  @action ResetSetRemovedOnLogin = () => {
    runInAction(() => {
      this.setRemovedOnLogin = false;
    });
  };
  
  @action InitialiseUser = async (data?: any) => {
    runInAction(() => {
      if (!this.user) {
        this.user = {} as any;
      }
      // compiler throws an error without the below check
      // even though we set the object if it's undefined above
      if (this.user !== undefined) {
        this.user.firstName = data?.firstName;
        this.user.lastName = data?.lastName;
        this.user.id = data?.userId;
        this.user.tousVersion = data?.tousVersion;
        this.user.userInitials = data?.userInitials
      }
    });
  };

  @action FetchUserInfo = async (override?: boolean, timeout?: number) => {
    if (override || (this.authId && (!this.user || this.authId !== this.user?.authId))) {
      return deliveryService.fetchUser(timeout).then((response) => {
        if (response.status === 200) {
          runInAction(() => {
            this.user = response.data;
            this.userId = response.data?.id;
          });
          return true;
        }
        return false;
      });
    }
    return false;
  };



  @action RemoveAddress = async (addressId: number) => {
    runInAction(() => {
      this.user?.addresses?.splice(
        this.user?.addresses?.findIndex((i) => i.addressId === addressId),
        1,
      );
    });
  };



  @action AddUpdateAddress = async (newAddress?: IUserAddress, oldAddressId?: number) => {
    if (!newAddress) {
      return false;
    }
    if (newAddress.type === AddressType.Shipping) {
      newAddress.isAusTaxResident = undefined;
      newAddress.ownsAusBusiness = undefined;
      newAddress.hasAusInvestment = undefined;
      newAddress.hasAusPermanentEstablishment = undefined;
    }
    runInAction(() => {
      // prettier-ignore
      const existingAddress = _.find(this.user?.addresses, (x: any) => x.addressId === (oldAddressId ?? newAddress.addressId));
      if (existingAddress) {
        existingAddress.addressId = newAddress.addressId;
        existingAddress.firstName = newAddress.firstName;
        existingAddress.lastName = newAddress.lastName;
        existingAddress.companyName = newAddress.companyName;
        existingAddress.line1 = newAddress.line1;
        existingAddress.line2 = newAddress.line2;
        existingAddress.suburb = newAddress.suburb;
        existingAddress.state = newAddress.state;
        existingAddress.country = newAddress.country;
        existingAddress.postCode = newAddress.postCode;
        existingAddress.phone = newAddress.phone;
        existingAddress.type = newAddress.type;
        existingAddress.createdAt = newAddress.createdAt ?? 0;
        existingAddress.deletedAt = newAddress.deletedAt ?? 0;
        existingAddress.isAusTaxResident = newAddress?.isAusTaxResident;
        existingAddress.ownsAusBusiness = newAddress?.ownsAusBusiness;
        existingAddress.hasAusInvestment = newAddress?.hasAusInvestment;
        existingAddress.hasAusPermanentEstablishment = newAddress?.hasAusPermanentEstablishment;
      } else {
        this.user?.addresses?.push({
          addressId: newAddress.addressId,
          firstName: newAddress.firstName,
          lastName: newAddress.lastName,
          companyName: newAddress.companyName,
          line1: newAddress.line1,
          line2: newAddress.line2,
          suburb: newAddress.suburb,
          state: newAddress.state,
          country: newAddress.country,
          postCode: newAddress.postCode,
          phone: newAddress.phone,
          type: newAddress.type,
          isAusTaxResident: newAddress.isAusTaxResident,
          ownsAusBusiness: newAddress.ownsAusBusiness,
          hasAusInvestment: newAddress.hasAusInvestment,
          hasAusPermanentEstablishment: newAddress.hasAusPermanentEstablishment,
          createdAt: 0,
          deletedAt: 0,
        });
      }
      return true;
    });
  };
 

  @action updateCommunicationPrefsInStore = (marketingPref: boolean, trackingPref: boolean) => {
    runInAction(() => {
      if (this.user) {
        this.user.marketingCommunicationPref = marketingPref;
        this.user.trackingCommunicationPref = trackingPref;
      }
    });
  };
}

export default React.createContext(new UserStore());
