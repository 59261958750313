import React, { useContext, useEffect, useRef } from "react";
import Icon from "../../../icons";
import ICONS from "../../../../constants/icons";
import { ReaderMenuIconContainer, ActiveIndicator } from "./style";
import LoggedInIcon from "../../../controls/loggedInIcon/LoggedInIcon";
import { ISegmentPackage } from "../../../../services/segmentProvider/models/segmentPackage";

interface IProps {
  icon?: ICONS;
  isMobile: boolean;
  isMenuItemActive: boolean;
  isSideMenu: boolean;
  isDisabled?: boolean;
  segmentPackage?: ISegmentPackage;
  onClick?: () => void;

  // Disregard provided icon and show LoggedInIcon instead
  isLoggedInMenu?: boolean;
  userInitials?: string;
}

const ReaderMenuIcon = (props: IProps): React.ReactElement => {
  const {
    icon,
    isMobile,
    isMenuItemActive,
    isLoggedInMenu,
    userInitials,
    isSideMenu,
    isDisabled,
    onClick,
  } = props;

  return (
    <ReaderMenuIconContainer isMobile={isMobile}>
      {isLoggedInMenu ? (
        <LoggedInIcon
          initials={userInitials || ""}
          isActive={isMenuItemActive || false}
          isShopIcon={false}
          isMobile={isMobile || false}
        />
      ) : (
        <Icon
          icon={icon}
          size="60px"
          internalSize="24px"
          xsScreenSize="48px"
          xsScreenInternalSize="20px"
          clickable={!isDisabled}
          disabled={isDisabled}
          selected={isMenuItemActive}
          onClick={onClick}
        />
      )}
      <ActiveIndicator isMobile={isMobile} isMenuItemActive={isMenuItemActive} isSideMenu={isSideMenu} />
    </ReaderMenuIconContainer>
  );
};

export default ReaderMenuIcon;
