import React, { useState } from "react";
import Icon from "../../../icons";
import ICONS from "../../../../constants/icons";
import STYLE_DEFAULTS from "../../../../constants/styles";
import DeleteMarkerModal from "../DeleteMarkerModal/DeleteMarkerModal";
import { Detector } from "react-detect-offline";

interface IMarkerToolboxProps {
  isToolboxOpen: boolean;
  commentsCount: number;
  onComment: () => void;
  onDelete: () => void;
}

const renderMarkerToolboxPopup = (props: IMarkerToolboxProps) => {
  const { isToolboxOpen, commentsCount, onComment, onDelete } = props;

  if (!isToolboxOpen) {
    return null;
  }

  return (
    <div id={`bookmark-toolbox`}>
      <div className={`marker-popup-container${commentsCount > 0 ? " has-comments" : ""}`}>
        <div
          onClick={() => {
            onComment();
          }}
          className={commentsCount > 0 ? "comments-icon has-comments" : "comments-icon"}
        >
          <Icon
            icon={commentsCount ? ICONS.COMMENT_FILLED : ICONS.COMMENT}
            color={commentsCount > 0 ? STYLE_DEFAULTS.COLORS.SA_ORANGE_ACTIVE : STYLE_DEFAULTS.COLORS.SA_WHITE_B000}
            tiny
            internalSize="20px"
            clickable
          />
        </div>
        <div onClick={onDelete} className={"marker-delete"}>
          <Icon icon={ICONS.BIN} tiny internalSize="20px" color={STYLE_DEFAULTS.COLORS.SA_WHITE_B000} clickable />
        </div>
      </div>
      <div className="marker-popover-arrow top" />
    </div>
  );
};

interface IBookmarkDeleteProps {
  commentsCount: number;
  setIsDeleteConfirmOpen: (b: boolean) => void;
  onDeleteAccept: () => void;
}

const renderDeleteBookmarkPopup = (props: IBookmarkDeleteProps) => {
  const { commentsCount, setIsDeleteConfirmOpen, onDeleteAccept } = props;

  return (
    <DeleteMarkerModal
      section="bookmark"
      commentsCount={commentsCount}
      isOpen
      onAccept={() => {
        onDeleteAccept();
        setIsDeleteConfirmOpen(false);
      }}
      onCancel={() => {
        setIsDeleteConfirmOpen(false);
      }}
    />
  );
};

interface IProps {
  isToolboxOpen: boolean;
  commentsCount: number;
  onComment: () => void;
  onDeleteAccept: () => void;
}

/**
 * MarkerToolboxPopup - handles bookmark toolbox.
 * (Highlight toolbox is handled via events and ContentSectionController)
 *
 * @param props
 */
const MarkerToolboxPopup = (props: IProps) => {
  const { isToolboxOpen, commentsCount, onComment, onDeleteAccept } = props;

  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);

  if (isDeleteConfirmOpen) {
    return renderDeleteBookmarkPopup({
      commentsCount,
      setIsDeleteConfirmOpen,
      onDeleteAccept,
    });
  }

  return (
    <Detector
      polling={ false }
      render={({ online }) =>
        renderMarkerToolboxPopup({
          isToolboxOpen,
          commentsCount,
          onComment: () => (online || commentsCount > 0) && onComment(),
          onDelete: () => online && setIsDeleteConfirmOpen(true),
        })
      }
    />
  );
};

export default MarkerToolboxPopup;
