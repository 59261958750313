import { useContext, useEffect, useState } from 'react';
import env from "./models/Configuration";
import { Route, Routes, useLocation, useNavigate  } from 'react-router-dom';
import _ from "lodash";
import { useAuth } from 'react-oidc-context';
import App from 'App';
import React from 'react';
import { IUserState } from 'models/UserStateModel';
import UserStore  from 'stores/userStore';
import LogOutPage from 'pages/LogOutPage';
import { IUserRole } from 'models/UserRoleModel';
import { roles } from 'constants/roles';
import * as browserDetection from "@braintree/browser-detection";
import { isChromeIncognito, isIosBrowser } from 'utils/browserUtils';
import { isFirefox } from '@braintree/browser-detection';

const LoginIAM = () => {

  const auth = useAuth();
  const navigate = useNavigate();
  let location = useLocation();
  const userContext = useContext(UserStore);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [isUserAuthorised, setIsUserAuthorised] = useState(false);
  
  useEffect(() => {

    if(isPublicRoute()) {
      return;
    }
    if(auth.isAuthenticated ) {
      setIsUserLoggedIn(true);
      setUserAuthorisation();
      initUser();
      redirectUrl();
    } 
    else if(!auth.isAuthenticated && !auth.isLoading) {
        let userState: IUserState = {callbackUrl: location.pathname};
        auth.signinRedirect({ extraQueryParams: {login_hint: env?.IAM.login_hint}, state: userState});
    }  

  }, [auth])

  const handleLogout = async () => {
    if(isIosBrowser() || await isChromeIncognito() || isFirefox()) 
      auth.signoutRedirect();
    else
      auth.signoutSilent();
  }

  const initUser = () => {
    let user = {firstName: auth.user?.profile.given_name, 
                lastName: auth?.user?.profile?.family_name, 
                userId: auth?.user?.profile?.email,
                userInitials: (auth.user?.profile.given_name?.substring(0, 1)??"") + (auth?.user?.profile?.family_name?.substring(0, 1)??"")}
    userContext.InitialiseUser(user);
  }

  const redirectUrl = () => {
    let userState: IUserState = auth.user?.state as IUserState;
    navigate(userState?.callbackUrl??location.pathname);
  }

  const setUserAuthorisation = () => {
    let userProfileAttributes : any  = auth.user?.profile?.custom_attributes as any;
    let userProfileRoles = userProfileAttributes['Role'];
    let userRoles : IUserRole[] = JSON.parse(userProfileRoles) as IUserRole[];
    let isUserAuthorised = userRoles.find(userRole => userRole.Name === roles.SUBSCRIPTION_WORKSPACE_SUBSCRIBER);
    setIsUserAuthorised(!!isUserAuthorised);
  }

  const isPublicRoute = () => {
    return location.pathname === '/logout';
  }


  return (
      <>
        {isUserLoggedIn && <App isAuthenticated={isUserLoggedIn} 
                                isAuthorised = {isUserAuthorised} 
                                onlogout={handleLogout} 
                                />}
        <Routes>
          <Route path= "/logout"   element={<LogOutPage />} />
        </Routes>
      </>
  );
}

export default LoginIAM;

