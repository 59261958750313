enum ICONS {
  ALERT = "Alert",
  ADD_TO_CART = "AddToCart",
  ANNOTATE = "Annotate",
  BOOKMARK = "Bookmark",
  BOOKMARKS = "Bookmarks",
  BOOKMARKS2 = "Bookmarks2",
  BOOKMARK_FILLED = "BookmarkFilled",
  MORE_VERT = "MoreVert",
  BULB = "Bulb",
  CALENDAR = "Calendar",
  CARET_DOWN = "CaretDown",
  CARET_UP = "CaretUp",
  CART = "Cart",
  CART_ADD = "CartAdd",
  CHEVRON_DOWN = "ChevronDown",
  CHEVRON_RIGHT = "ChevronRight",
  CHEVRON_LEFT = "ChevronLeft",
  CHEVRON_UP = "ChevronUp",
  CLOCK = "Clock",
  CLOSE = "Close",
  CLOSE_CIRCLE = "CloseCircle",
  CUSTOMER = "Customer",
  CUSTOMER_ADD = "CustomerAdd",
  DOCTYPE = "Doctype",
  DOUBLE_CHEVRON_LEFT = "DoubleChevronLeft",
  DOUBLE_CHEVRON_RIGHT = "DoubleChevronRight",
  EDIT = "Edit",
  ENVELOP = "Envelop",
  EXPORT = "Export",
  EXTERNAL_LINK = "ExternalLink",
  GLOBE = "Globe",
  HIGHLIGHT = "Highlight",
  HIGHLIGHT_FILLED = "HighlightFilled",
  INFO = "Info",
  MICROPHONE = "Microphone",
  MENU = "Menu",
  LOCK = "Lock",
  OFFICE = "Office",
  POINTER = "Pointer",
  PHONE = "Phone",
  PLUS = "Plus",
  PLUS_NO_CIRCLE = "PlusNoCircle",
  PREVIEW = "Preview",
  RSS = "Rss",
  REFUND = "Refund",
  SUBSCRIPTION = "Subscription",
  SEARCH = "Search",
  SETTINGS = "Settings",
  TICK = "Tick",
  TICK_CIRCLE = "TickCircle",
  TOC = "Toc",
  QUESTION_MARK = "QuestionMark",
  VIEW = "View",
  WARNING = "Warning",
  NO_VIEW = "NoView",
  FACEBOOK = "Facebook",
  LINKEDIN = "LinkedIn",
  TWITTER = "Twitter",
  YOUTUBE = "Youtube",
  COMMENT = "Comment",
  COMMENT_FILLED = "CommentFilled",
  BIN = "Bin",
  PADLOCK = "Padlock",
  CART_EMPTY = "CartEmpty",
  LOGOUT = "Logout",
  LIBRARY = "Library",
  INVOICE = "Invoice",
  DOWNLOAD = "Download",
  FILE = "File",
  STANDARDS_STORE = "StandardsStore",
  PRINT = "Print",
  CVN_AMEX = "CvnAmex",
  CVN_OTHER = "CvnOther",
  FINANCE_REPORT = "FinanceReport",
  REPORT = "Report",
  EMPTY_INVOICE = "EmptyInvoice",
  EMPTY_TRACKER = "EmptyTracker",
  EMPTY_CONTENT_LIBRARY = "EmptyContentLibrary",
  EMPTY_CONTENT_LIBRARY_MASKED = "EmptyContentLibraryMasked",
  // Devices
  DESKTOP = "Desktop",
  TABLET = "Tablet",
  MOBILE = "Mobile",
  // OS
  WINDOWS = "Windows",
  MAC = "Mac",
}

export default ICONS;
