import { createGlobalStyle, css } from "styled-components";
import STYLE_DEFAULTS from "../constants/styles";
import { MEDIA_QUERIES } from "../constants/mediaQueries";
/**
 * @license
 * MyFonts Webfont Build ID 753102
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license from one of MyFonts official sites.
 * http://www.fonts.com
 * http://www.myfonts.com
 * http://www.linotype.com
 *
 */

export const globalStyles = css`
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.NOTO_SANS};
  font-size: 100%;

  @media ${MEDIA_QUERIES.SM} {
    font-size: 87.5%;
  }
`;

export const GlobalStyle = createGlobalStyle`
  html {
   ${globalStyles}
  }

  body: {
    padding: 0;
    margin: 0;
  }  
`;

// Using template literals causes insertion of commas around the value, breaking the transition
export const onHoverStateTransition = css`
  transition: all 0.25s ease;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
`;

/** Reusable style for adding hover styles to clickable links it is appled to */
export const linkHoverStyle = css`
  :hover {
    color: rgb(240, 120, 0);
    cursor: pointer;

    & svg path {
      fill: rgb(250, 170, 0);
      stroke: rgb(250, 170, 0);
    }

    ${onHoverStateTransition};
  }
`;

export const onHoverState = (base: any) => {
  return {
    ...base,
    transition: `all 0.25s ease;`,
    WebkitTransition: `all 0.25s ease;`, // note the capital 'W' here
    msTransition: `all 0.25s ease;`, // 'ms' is the only lowercase vendor prefix
    OTransition: `all 0.25s ease;`,
  };
};
