import React from "react";
import * as Styled from "./StyledHomePage";


const HomePage = () => {
  return (
    <>
      
      <Styled.ContentRowContainer>
          Welcome to web reader
      </Styled.ContentRowContainer>
    </>
  );
};

export default HomePage;
