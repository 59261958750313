import styled from "styled-components";
import STYLE_DEFAULTS from "../../../../constants/styles";
import { InFocus } from "../../../../styles/typography";
import { onHoverStateTransition } from "../../../../styles/global";

interface IProps {
  bookmarkModeActive?: boolean;
  highlightModeActive?: boolean;
  bookmarked?: boolean;
  inFocus?: boolean;
  isMobile?: boolean;
  lastSubSection?: boolean;
  backgroundColor?: string;
  highlightToolboxOpen?: boolean;
  isOffline?: boolean;
}

export const SectionBookmarkModeContainer = styled.div`
  /* https://stackoverflow.com/questions/826782/how-to-disable-text-selection-highlighting */
  -webkit-touch-callout: text; /* iOS Safari */
  -webkit-user-select: text; /* Safari */
  -khtml-user-select: text; /* Konqueror HTML */
  -moz-user-select: text; /* Old versions of Firefox */
  -ms-user-select: text; /* Internet Explorer/Edge */
  user-select: text;
  position: relative;
  /*background-color: ${(props: IProps) => props.backgroundColor || STYLE_DEFAULTS.COLORS.SA_WHITE_B000};*/

  .highlighted {
    background-color: ${STYLE_DEFAULTS.COLORS.SA_YELLOW};
  }

  /* when user clicks on existing highlight   */
  .highlighted.highlight-selected {
    ${InFocus}
  }

  .selected {
    background-color: ${(props: IProps) =>
      props.highlightModeActive ? STYLE_DEFAULTS.COLORS.SA_YELLOW : STYLE_DEFAULTS.COLORS.SA_BLUE_SELECTED};

    &.yellow {
      background-color: ${STYLE_DEFAULTS.COLORS.SA_YELLOW};
    }
  }

  .icon-control svg {
    width: 20px;
    height: 20px;
  }

  .comments-badge,
  .highlight-comments-badge {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-box-shadow: 0px 1px 3px 0.1px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 1px 3px 0.1px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 1px 3px 0.1px rgba(0, 0, 0, 0.3);
    background-color: ${STYLE_DEFAULTS.COLORS.SA_WHITE_B000};
    position: absolute;
    top: -12px;
    left: 56px; // same in desktop and mobile
    width: 40px;
    box-sizing: border-box;
    height: 24px;
    padding: 0 ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_1} 0 2px;
  }

  .comments-badge:hover,
  .highlight-comments-badge:hover,
  .comments-badge:focus,
  .highlight-comments-badge:focus {
    cursor: pointer;

    -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
    ${onHoverStateTransition};
  }

  .comments-badge.badge-in-focus,
  .highlight-comments-badge.badge-in-focus {
    ${InFocus}
  }

  .highlight-comments-badge {
    position: absolute;
    top: -15px;
    left: -10px;
    z-index: 50;
  }

  .comments-badge.mobile,
  .highlight-comments-badge.mobile {
    top: 11px;
  }

  .comments-badge-text {
    cursor: pointer;
    font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.NOTO_SANS};
    font-size: 14px; //same for desktop and mobile
    font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.NORMAL};
    line-height: 1.5;
    color: ${STYLE_DEFAULTS.COLORS.SA_BLACK_B100};
    margin-left: 6px;
    text-indent: 0px; /* This is to make sure the e-reader content text-indent won't get applied here */
  }

  .highlight-badge-icon-control {
    width: 20px;
    height: 20px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    color: ${STYLE_DEFAULTS.COLORS.SA_ORANGE_ACTIVE};
  }

  .highlight-badge-icon-control svg {
    pointer-events: none;
    width: 20px;
    height: 20px;
  }

  .highlight-badge-icon-control svg g,
  .highlight-badge-icon-control svg rect,
  .highlight-badge-icon-control svg path {
    fill: ${STYLE_DEFAULTS.COLORS.SA_ORANGE_ACTIVE};
  }

  .icon-content,
  .highlight-badge-icon-content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 24px;
    width: 24px;

    svg {
      cursor: pointer;
    }
  }

  .highlight-toolbox-container {
    visibility: hidden;
  }

  .highlight-toolbox-container.visible {
    visibility: visible;
  }

  .text-select-popup-arrow,
  .marker-popover-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    z-index: 10;

    &.bottom {
      top: -3px;
      left: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_1};
      border-top: 8px solid ${STYLE_DEFAULTS.COLORS.SA_BLACK_B100};
    }
    &.top {
      top: 27px;
      left: 27px;
      border-bottom: 8px solid ${STYLE_DEFAULTS.COLORS.SA_BLACK_B100};
    }
  }

  .text-select-popup-arrow.bottom {
    top: -${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_6};
    left: 2px;

    &.shift-left {
      left: -17px;
    }
  }

  .text-select-popup-arrow {
    top: -23px;
    left: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_1};
    border-top: 8px solid ${STYLE_DEFAULTS.COLORS.SA_BLACK_B100};
  }

  .text-select-popup-container,
  .marker-popup-container {
    position: absolute;
    top: 34px;
    left: -25px;
    width: 125px;
    height: 44px;
    padding: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_3} ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_6};
    box-sizing: border-box;
    background-color: ${STYLE_DEFAULTS.COLORS.SA_BLACK_B100};
    opacity: 80%;
    -webkit-box-shadow: 0px 1px 3px 0.1px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 1px 3px 0.1px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 1px 3px 0.1px rgba(0, 0, 0, 0.3);
    border-radius: ${STYLE_DEFAULTS.BUTTONS.SA_BUTTON_RADIUS};
    -webkit-border-radius: ${STYLE_DEFAULTS.BUTTONS.SA_BUTTON_RADIUS};
    -moz-border-radius: ${STYLE_DEFAULTS.BUTTONS.SA_BUTTON_RADIUS};
    z-index: 500;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    & svg path {
      fill: ${STYLE_DEFAULTS.COLORS.SA_WHITE_B000};
      cursor: pointer;
    }

    :hover {
      cursor: pointer;
      ${onHoverStateTransition};
    }

    &.top {
      top: -45px;
    }
  }

  .text-select-popup-container {
    padding: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_6};
    top: -71px;
    left: -25px;
    width: 68px;

    &.shift-left {
      left: -46px;
    }
  }

  #text-select-popover {
    position: relative;
    display: inline-block;

    &:hover {
      cursor: pointer;
      ${onHoverStateTransition};
    }
  }

  .comments-icon.has-comments {
    svg g,
    svg rect,
    svg path {
      fill: ${STYLE_DEFAULTS.COLORS.SA_ORANGE_ACTIVE};
    }
  }

  .comments-icon,
  .marker-delete,
  .highlight-delete,
  .highlight-comment,
  .inline-highlight {
    svg g,
    svg rect,
    svg path {
      fill: ${(props: IProps) =>
        props.isOffline ? STYLE_DEFAULTS.COLORS.SA_DISABLED : STYLE_DEFAULTS.COLORS.SA_WHITE_B000};
    }
  }

  .comments-badge-hidden {
    visibility: hidden;
  }

  ${(props: IProps) => {
    let styles = "";

    if (props.highlightModeActive) {
      styles += "cursor: pointer;";
    }

    if (props.bookmarkModeActive || props.bookmarked) {
      if (props.isMobile) {
        styles += `
                  border-top: 2px solid ${
                    props.bookmarked ? STYLE_DEFAULTS.COLORS.SA_BLUE : STYLE_DEFAULTS.COLORS.SA_B015
                  };
                  padding-top: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_10};`;
      }
      styles += `padding-top: ${
        props.isMobile ? STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_10 : STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_5
      };`;

      if (props.bookmarked) {
        styles += `
            margin-top: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_5};
            `;
      }

      return styles;
    }
  }}
`;

export const SectionContentContainer = styled.div`
  &.highlight-active * ::selection {
    background-color: ${STYLE_DEFAULTS.COLORS.SA_YELLOW};
  }

  ${(props: IProps) => {
    let styles = "";

    if (props.lastSubSection) {
      styles += `margin-bottom: ${props.isMobile ? "20px" : "40px"}`;
    } else if (props.bookmarkModeActive) {
      styles += `border-bottom: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_5} solid ${STYLE_DEFAULTS.COLORS.SA_WHITE_B000};`;
    }

    return styles;
  }}
`;
