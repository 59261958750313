import React, { useEffect, useRef } from "react";
import { ReactComponent as Alert } from "./../../assets/icons/alert.svg";
import { ReactComponent as AddToCart } from "./../../assets/icons/add_to_cart.svg";
import { ReactComponent as Annotate } from "./../../assets/icons/annotate.svg";
import { ReactComponent as Bookmark } from "./../../assets/icons/bookmark.svg";
import { ReactComponent as BookmarkFilled } from "./../../assets/icons/bookmark_filled.svg";
import { ReactComponent as Bookmarks } from "./../../assets/icons/book.svg";
import { ReactComponent as Bookmarks2 } from "./../../assets/icons/book2.svg";
import { ReactComponent as Calendar } from "./../../assets/icons/calendar.svg";
import { ReactComponent as Bulb } from "./../../assets/icons/lightbulb.svg";
import { ReactComponent as CaretDown } from "./../../assets/icons/caret-down.svg";
import { ReactComponent as CaretUp } from "./../../assets/icons/caret-up.svg";
import { ReactComponent as Cart } from "./../../assets/icons/cart.svg";
import { ReactComponent as CartAdd } from "./../../assets/icons/cart_add.svg";
import { ReactComponent as ChevronDown } from "./../../assets/icons/chevron_down.svg";
import { ReactComponent as ChevronRight } from "./../../assets/icons/chevron_right.svg";
import { ReactComponent as ChevronLeft } from "./../../assets/icons/chevron_left.svg";
import { ReactComponent as ChevronUp } from "./../../assets/icons/chevron_up.svg";
import { ReactComponent as Close } from "./../../assets/icons/close.svg";
import { ReactComponent as CloseCircle } from "./../../assets/icons/close_circle.svg";
import { ReactComponent as Customer } from "./../../assets/icons/user.svg";
import { ReactComponent as CustomerAdd } from "./../../assets/icons/user_add.svg";
import { ReactComponent as Doctype } from "./../../assets/icons/doctype.svg";
import { ReactComponent as DoubleChevronLeft } from "./../../assets/icons/double_chevron_left.svg";
import { ReactComponent as DoubleChevronRight } from "./../../assets/icons/double_chevron_right.svg";
import { ReactComponent as Edit } from "./../../assets/icons/edit.svg";
import { ReactComponent as ExternalLink } from "./../../assets/icons/external_link.svg";
import { ReactComponent as Highlight } from "./../../assets/icons/highlightnote.svg";
import { ReactComponent as Globe } from "./../../assets/icons/globe.svg";
import { ReactComponent as Office } from "./../../assets/icons/office.svg";
import { ReactComponent as Info } from "./../../assets/icons/info.svg";
import { ReactComponent as Microphone } from "./../../assets/icons/mic.svg";
import { ReactComponent as Menu } from "./../../assets/icons/menu.svg";
import { ReactComponent as Lock } from "./../../assets/icons/lock.svg";
import { ReactComponent as Plus } from "./../../assets/icons/plus.svg";
import { ReactComponent as PlusNoCircle } from "./../../assets/icons/plus_no_circle.svg";
import { ReactComponent as Pointer } from "./../../assets/icons/pointer.svg";
import { ReactComponent as Preview } from "./../../assets/icons/preview.svg";
import { ReactComponent as Rss } from "./../../assets/icons/rss.svg";
import { ReactComponent as Refund } from "./../../assets/icons/refund.svg";
import { ReactComponent as Subscription } from "./../../assets/icons/subscription.svg";
import { ReactComponent as Search } from "./../../assets/icons/search.svg";
import { ReactComponent as Settings } from "./../../assets/icons/settings.svg";
import { ReactComponent as Tick } from "./../../assets/icons/tick.svg";
import { ReactComponent as TickCircle } from "./../../assets/icons/tick_circle.svg";
import { ReactComponent as Toc } from "./../../assets/icons/list.svg";
import { ReactComponent as QuestionMark } from "./../../assets/icons/question_mark1.svg";
import { ReactComponent as View } from "./../../assets/icons/view.svg";
import { ReactComponent as Warning } from "./../../assets/icons/warning.svg";
import { ReactComponent as NoView } from "./../../assets/icons/no_view.svg";
import { ReactComponent as Facebook } from "./../../assets/icons/facebook.svg";
import { ReactComponent as LinkedIn } from "./../../assets/icons/linkedin.svg";
import { ReactComponent as Twitter } from "./../../assets/icons/twitter.svg";
import { ReactComponent as Youtube } from "./../../assets/icons/youtube.svg";
import { ReactComponent as Clock } from "./../../assets/icons/clock.svg";
import { ReactComponent as Phone } from "./../../assets/icons/phone.svg";
import { ReactComponent as Envelop } from "./../../assets/icons/envelope.svg";
import { ReactComponent as Export } from "./../../assets/icons/export.svg";
import { ReactComponent as MoreVert } from "./../../assets/icons/more_vert.svg";
import { ReactComponent as HighlightFilled } from "./../../assets/icons/highlight.svg";
import { ReactComponent as Comment } from "./../../assets/icons/comment.svg";
import { ReactComponent as CommentFilled } from "./../../assets/icons/comment_filled.svg";
import { ReactComponent as Bin } from "./../../assets/icons/bin.svg";
import { ReactComponent as Padlock } from "./../../assets/icons/padlock.svg";
import { ReactComponent as CartEmpty } from "./../../assets/icons/cart_empty.svg";
import { ReactComponent as Logout } from "./../../assets/icons/logout.svg";
import { ReactComponent as Library } from "./../../assets/icons/library.svg";
import { ReactComponent as Invoice } from "./../../assets/icons/invoice.svg";
import { ReactComponent as Download } from "./../../assets/icons/download.svg";
import { ReactComponent as File } from "./../../assets/icons/file.svg";
import { ReactComponent as StandardsStore } from "./../../assets/icons/standards_store.svg";
import { ReactComponent as Print } from "./../../assets/icons/print.svg";
import { ReactComponent as CvnAmex } from "./../../assets/icons/cvn_amex.svg";
import { ReactComponent as CvnOther } from "./../../assets/icons/cvn_back.svg";
import { ReactComponent as FinanceReport } from "./../../assets/icons/finance_report.svg";
import { ReactComponent as EmptyInvoice } from "./../../assets/icons/invoice_empty.svg";
import { ReactComponent as EmptyTracker } from "./../../assets/icons/tracker_empty.svg";
import { ReactComponent as EmptyContentLibrary } from "./../../assets/icons/library_empty.svg";
import { ReactComponent as EmptyContentLibraryMasked } from "./../../assets/icons/library_empty_mask.svg";
import { ReactComponent as Report } from "./../../assets/icons/report.svg";
// Devices
import { ReactComponent as Desktop } from "./../../assets/icons/desktop.svg";
import { ReactComponent as Tablet } from "./../../assets/icons/tablet.svg";
import { ReactComponent as Mobile } from "./../../assets/icons/mobile.svg";
// OS
import { ReactComponent as Windows } from "./../../assets/icons/windows.svg";
import { ReactComponent as Mac } from "./../../assets/icons/mac.svg";

import IIcon from "../../models/icon";
import { IconControl, IconContent, SelectedIndication, IconBadge } from "./style";
import STYLE_DEFAULTS from "../../constants/styles";
import CounterBadge from "../controls/badges/CounterBadge";
import ICONS from "../../constants/icons";

/**
 * Contains all icons used in the app.
 * Make sure to add every icon to the enum defined in src/constants/icons.ts
 */

const Icons = {
  Alert,
  AddToCart,
  Annotate,
  Bookmark,
  BookmarkFilled,
  MoreVert,
  Bookmarks,
  Bookmarks2,
  Calendar,
  Clock,
  Bulb,
  CaretDown,
  CaretUp,
  Cart,
  CartAdd,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  Close,
  CloseCircle,
  Customer,
  CustomerAdd,
  Doctype,
  DoubleChevronLeft,
  DoubleChevronRight,
  Globe,
  Edit,
  Envelop,
  Export,
  ExternalLink,
  Highlight,
  HighlightFilled,
  Info,
  Menu,
  Microphone,
  Lock,
  Office,
  Plus,
  PlusNoCircle,
  Pointer,
  Preview,
  Phone,
  Rss,
  Refund,
  Subscription,
  Search,
  Settings,
  Tick,
  TickCircle,
  Toc,
  QuestionMark,
  View,
  Warning,
  NoView,
  Facebook,
  LinkedIn,
  Twitter,
  Youtube,
  Comment,
  CommentFilled,
  Bin,
  Padlock,
  CartEmpty,
  Logout,
  Library,
  Invoice,
  Download,
  File,
  StandardsStore,
  Print,
  CvnAmex,
  CvnOther,
  FinanceReport,
  Report,
  EmptyInvoice,
  EmptyTracker,
  EmptyContentLibrary,
  EmptyContentLibraryMasked,
  // Devices
  Desktop,
  Tablet,
  Mobile,
  // OS
  Windows,
  Mac,
};

export const renderIconComponent = (icon: ICONS) => {
  const IconComponent = Icons[icon];

  return <IconComponent />;
};

function Icon(props: IIcon): React.ReactElement | null {
  const {
    icon,
    large,
    medium,
    small,
    tiny,
    disabled,
    selected,
    background,
    color,
    size,
    internalSize,
    width,
    height,
    xsScreenSize,
    xsScreenInternalSize,
    onClick,
    clickable,
    id,
    rotate,
    strokeColor,
    badgeCounter,
    badgeCounterLoading,
    noRole,
  } = props;

  const segmentReference = useRef<HTMLDivElement>(null);


  if (!icon) {
    return null;
  }
  // Assumption here - either selected or color/strokeColor should be provided
  const iconColor = selected ? STYLE_DEFAULTS.COLORS.SA_ORANGE_ACTIVE : color;
  const showBadge = badgeCounter != null && (badgeCounterLoading || badgeCounter > 0) ? true : false;

  const attributes: any = {
    ref: segmentReference,
    id: id,
    large: large,
    medium: medium,
    small: small,
    tiny: tiny,
    disabled: disabled,
    background: background,
    size: size,
    width: width,
    height: height,
    internalSize: internalSize, // Set size of the icon image
    xsScreenSize: xsScreenSize, // Icon size on screens under 375px
    xsScreenInternalSize: xsScreenInternalSize, // svg size on screens under 375px
    color: iconColor,
    strokeColor: strokeColor,
    onClick: onClick,
    clickable: clickable,
    rotate: rotate,
  };

  if (clickable && !noRole) {
    attributes.role = "button";
    attributes["aria-label"] = icon;
    attributes.value = icon;
  } else {
    attributes["aria-hidden"] = true;
    attributes.focusable = false;
  }

  return (
    <IconControl {...attributes}>
      <IconContent
        id="icon"
        data-testid={`icon-${icon.toLowerCase()}`}
        data-cy={`icon-${icon.toLowerCase()}`}
        internalSize={internalSize}
        width={width}
        height={height}
        xsScreenInternalSize={xsScreenInternalSize}
      >
        {renderIconComponent(icon)}
        {showBadge && (
          <IconBadge iconSize={size || ""}>
            <CounterBadge isLoading={badgeCounterLoading || !badgeCounter || false} value={badgeCounter || 0} />
          </IconBadge>
        )}
      </IconContent>
      {selected && <SelectedIndication />}
    </IconControl>
  );
}

export default Icon;
