import styled from "styled-components";


export const ErrorModalHeader = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ErrorModalTitle = styled.div`
  text-align: left;
  width: 80%;
  color: #000000;
  font-size: 22px;
 
  span {
    padding: 0px 0px 0px 5px;
  }

  svg {
    color: #CE1114;
  }
`;

export const ErrorModalCloseButton = styled.button`
  margin-left: 20%;
  background: none;
  border: none;

`;

export const ErrorModalContent = styled.div`
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  color: #58595B;
`;

export const ErrorModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
`;