import React, { useState, useRef, useContext } from "react";
import _ from "lodash";
import { SidePanelLoader } from "../style";
import Loader from "../../../../loader/loader";
import Markers, { sortMarkers, findSectionViaId, NoMarkers } from "./common/Markers";
import { OutsideClickDetector } from "../../../../../helpers/outsideClickDetector";
import IComment from "../../../../../models/comment";
import { extractMaxWords } from "../../../../../constants/regex";
import { observer } from "mobx-react-lite";
import ReaderLayoutContext, { IReaderLayoutContext } from "../../readerLayoutContext";
import HighlightStore from "../../../../../stores/highlightStore";
import BookmarkStore from "../../../../../stores/bookmarkStore";

const buildBookmarksComment = (props: any) => {
  const { bookmarkContext, readerContext, onDeleteClick } = props;
  const comments: any[] = [];
  
  bookmarkContext.bookmarks.map((bookmark: any) => {
    if (bookmark?.comments?.length > 0) {
      const section = findSectionViaId(
        readerContext.readerStore.Toc.content?.tableOfContents,
        bookmark.sectionId,
      );
      bookmark.comments.map((comment: IComment) => {
        if(section){
          // Section comments - navigate to section header
          const navKey = "section-" + section.tableOfContentId;
          comments.push({
            key: comment.commentId,
            navigationKey: navKey,
            header: section.value,
            body: comment.content,
            order: section.subOrder ?? section.order.toString(),
            isBookmarkComment: true,
            parentKey: section.tableOfContentId,
            hasComments: false, //comment does not have comments
            onDeleteClick: () => onDeleteClick(comment.commentId, section),
          });    
        }
        else if(!section && bookmark.isArchived){
          comments.push({
            key: comment.commentId,
            //navigationKey: navKey,
            header: `[Archived] [v.${bookmark.version}] `,
            body: comment.content,
            order: '999',
            isBookmarkComment: true,
            isArchived: true,
            version: bookmark.version,
            //parentKey: section.tableOfContentId,
            hasComments: false, //comment does not have comments
            //onDeleteClick: () => onDeleteClick(comment.commentId, section),
          });    
        }
          
      });
    }
  });

  comments.sort((a, b) =>{
    if(a!.isArchived){
      return 1;
    }
    return -1;
  });
  return comments;
};

const buildHighlightsComment = (props: any) => {
  const { highlightContext, readerContext, onDeleteClick } = props;
  const comments: any[] = [];

  highlightContext.highlights.map((highlight: any) => {
    if (highlight?.comments?.length > 0) {
      const section = findSectionViaId(readerContext.readerStore.Toc.content?.tableOfContents, highlight.sectionId);
      // Section comments - navigate to section header
      const navKey = "highlight-" + highlight.highlightId;

      highlight.comments.map((comment: IComment) => {
        if(section){
          comments.push({
            key: comment.commentId,
            navigationKey: navKey,
            sectionId: highlight.sectionId,
            header: section?.value,
            subHeader: extractMaxWords(highlight.selectionPlainText, 16),
            body: comment.content,
            order: section ? section.subOrder ?? section.order.toString() : "",
            isBookmarkComment: false,
            parentKey: highlight.highlightId,
            commentsCount: 0, //comment does not have comments
            onDeleteClick: () => onDeleteClick(comment.commentId, highlight),
          });
        }
        else if(!section && highlight.isArchived){
          comments.push({
            key: comment.commentId,
            navigationKey: navKey,
            sectionId: highlight.sectionId,
            header: `[Archived] [v.${highlight.version}] `,
            subHeader: extractMaxWords(highlight.selectionPlainText, 16),
            body: comment.content,
            order: '999',
            isBookmarkComment: false,
            isArchived: highlight.isArchived,
            version: highlight.version,
            parentKey: highlight.highlightId,
            commentsCount: 0, //comment does not have comments
            onDeleteClick: () => onDeleteClick(comment.commentId, highlight),
          });
        }
        
      });
    }
  });

  comments.sort((a, b) =>{
    if(a!.isArchived){
      return 1;
    }
    return -1;
  });

  return comments;
};

const CommentMarkers: React.FC<any> = observer((props: any) => {
  const {
    isCommentsLoading,
    showMarkerOptions,
    setShowMarkerOptions,
    isMarkerDeleteOpen,
    setIsMarkerDeleteOpen,
  } = props;
  const [commentId, setCommentId] = useState(0);
  const [sectionId, setSectionId] = useState(0);
  const [highlightId, setHighlightId] = useState(0);
  const [isBookmarkComment, setIsBookmarkComment] = useState(false);
  const wrapperRef = useRef(null);

  const readerContext = useContext<IReaderLayoutContext>(ReaderLayoutContext);
  const { setIsReaderLoading } = readerContext;
  const highlightContext = useContext(HighlightStore);
  const bookmarkContext = useContext(BookmarkStore);

  OutsideClickDetector(wrapperRef, setShowMarkerOptions);

  if (isCommentsLoading) {
    return (
      <SidePanelLoader>
        <Loader />
      </SidePanelLoader>
    );
  }

  let commentsSorted: any = [];

  commentsSorted = buildBookmarksComment({
    bookmarkContext,
    readerContext,
    onDeleteClick: (id: any, section: any) => {
      setCommentId(id);
      setSectionId(section.tableOfContentId);
      setIsBookmarkComment(true);
    },
  });

  const highlightComments = buildHighlightsComment({
    highlightContext,
    readerContext,
    onDeleteClick: (id: any, highlight: any) => {
      setCommentId(id);
      setHighlightId(highlight.highlightId);
      setIsBookmarkComment(false);
    },
  });

  commentsSorted = commentsSorted.concat(highlightComments);
  commentsSorted = commentsSorted.sort((a: any, b: any) => sortMarkers(a, b));

  return (
    <Markers
      ref={wrapperRef}
      section="comment"
      noMarkerBody="Comments can be added to your highlight or bookmark on the Online WebReader."
      markers={commentsSorted}
      readerContext={readerContext}
      onDeleteAccept={async () => {
        setIsReaderLoading(true);

        if (isBookmarkComment) {
          await bookmarkContext.deleteBookmarkComment(sectionId, commentId);
        } else {
          await highlightContext.deleteHighlightComment(highlightId, commentId);
        }

        setIsReaderLoading(false);
      }}
      showMarkerOptions={showMarkerOptions}
      setShowMarkerOptions={setShowMarkerOptions}
      isMarkerDeleteOpen={isMarkerDeleteOpen}
      setIsMarkerDeleteOpen={setIsMarkerDeleteOpen}
    />
  );
});

export default CommentMarkers;
