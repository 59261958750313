import React, { useState, useContext, useEffect } from "react";
import {
  CommentModalContainer,
  CommentModalContent,
  CommentModalHeader,
  CommentModalTitle,
  MobileCloseIcon,
  CommentModalButtons,
  CommentContent,
  SingleCommentContainer,
  CommentButton,
  CommentDeleteButton,
  CommonButtons,
  RemainingCharactersCounter,
  TextAreaContainer,
} from "./style";
import Icon from "../../../icons";
import ICONS from "../../../../constants/icons";
import MenuIconWithCaption from "../../../controls/menuIcon/MenuIconWithCaption";
import STYLE_DEFAULTS from "../../../../constants/styles";
import Button from "../../../controls/button/Button";
import BookmarksStore from "../../../../stores/bookmarkStore";
import HighlightsStore from "../../../../stores/highlightStore";
import COMMENT_MODAL_STATE from "../../../../constants/comments";
import IComment from "../../../../models/comment";
import { showHiddenBadgeInSection, hideCommentsBadge, restoreCommentsBadge } from "../helpers/helpers";
import DeleteMarkerModal from "../../controls/DeleteMarkerModal/DeleteMarkerModal";
import { observer } from "mobx-react-lite";
import LinkButton from "../../../controls/linkButton/LinkButton";
import TextAreaField from "../../../controls/textAreaField/TextAreaField";
import { Detector } from "react-detect-offline";

const DEFAULT_COMMENT_ID = 0;
const COMMENT_CHARACTERS_LIMIT = 500;

const renderSaveCommentButtons = (props: IStateProps) => {
  const { onSaveComment, content, editCommentId, onCloseEdit, setIsDeleteConfirmOpen } = props;

  return (
    <CommentModalButtons>
      <CommonButtons>
        <Button
          onClick={onSaveComment}
          value="Save"
          primary={content !== ""}
          secondary={content === ""}
          width="120px"
          disabled={content === ""}
        />
        <Button onClick={onCloseEdit} value="Cancel" secondary width="115px" />
      </CommonButtons>
      {editCommentId ? (
        <CommentDeleteButton onClick={() => setIsDeleteConfirmOpen(true)}>
          <Icon icon={ICONS.BIN} color={STYLE_DEFAULTS.COLORS.SA_RED} tiny internalSize="20px" />
          Delete
        </CommentDeleteButton>
      ) : (
        ""
      )}
    </CommentModalButtons>
  );
};

const renderCreateUpdateComment = (props: IStateProps) => {
  const { content, setContent } = props;

  return (
    <>
      <TextAreaContainer>
        <TextAreaField
          value={content}
          onChange={(event: any) => {
            if (event.target.value.length <= COMMENT_CHARACTERS_LIMIT) {
              setContent(event.target.value);
            }
          }}
          placeholder="Comment content"
        />
      </TextAreaContainer>
      <RemainingCharactersCounter>
        <span>Remaining characters: </span>
        {COMMENT_CHARACTERS_LIMIT - (content ? content.length : 0)}
      </RemainingCharactersCounter>
      {renderSaveCommentButtons(props)}
    </>
  );
};

const renderComments = (props: IStateProps, isOffline?: boolean) => {
  const { comments, editCommentId, setContent, setEditCommentId } = props;

  return comments?.map((comment: IComment, index: number) => (
    // Optimistically added comment does not have id, so use index to be sure of proper key
    <SingleCommentContainer key={index}>
      {editCommentId && editCommentId === comment.commentId ? (
        renderCreateUpdateComment(props)
      ) : (
        <>
          <CommentContent>{comment.content}</CommentContent>
          <CommentButton>
            <LinkButton
              disabled={isOffline}
              text="Edit"
              secondary
              onClick={() => {
                setContent(comment.content);
                setEditCommentId(comment.commentId);
              }}
            />
          </CommentButton>
        </>
      )}
    </SingleCommentContainer>
  ));
};

const renderExistingComments = (props: IStateProps, isOffline?: boolean) => {
  const { setCommentModalState, editCommentId } = props;
  const content = renderComments(props, isOffline);

  return (
    <CommentModalContent>
      {content}
      {/* Do not show add comment while editing an existing comment */}
      {!editCommentId ? (
        <CommentModalButtons isAddButton>
          <LinkButton
            disabled={isOffline}
            icon={ICONS.PLUS}
            text="Add comment"
            secondary
            onClick={() => setCommentModalState(COMMENT_MODAL_STATE.CREATE_UPDATE_COMMENT)}
          />
        </CommentModalButtons>
      ) : (
        ""
      )}
    </CommentModalContent>
  );
};

const renderSaveCommentModalContent = (props: IStateProps) => {
  const { content, setContent } = props;
  const existingComments = renderComments(props);

  return (
    <CommentModalContent>
      {existingComments}
      {renderCreateUpdateComment(props)}
    </CommentModalContent>
  );
};

interface IProps {
  comments: Array<IComment> | null;
  isHighlightComments: boolean;
  highlightId?: number | null;
  modalStateOnOpen: COMMENT_MODAL_STATE;
  sectionId: number;
  isMobile: boolean;
  onClose: () => void;
}

interface IStateProps {
  comments: Array<IComment> | null;
  commentModalState: COMMENT_MODAL_STATE;
  sectionId: number;
  isMobile: boolean;
  content: string;
  setContent: (s: string) => void;
  editCommentId: number;
  setEditCommentId: (n: number) => void;
  onClose: () => void;
  onCloseEdit: () => void;
  onSaveComment: () => void;
  setCommentModalState: (s: COMMENT_MODAL_STATE) => void;
  isDeleteConfirmOpen: boolean;
  setIsDeleteConfirmOpen: (b: boolean) => void;
}

/**
 * Handle creating and modifying comments for bookmarks and highlights
 */
const CommentModal: React.FC<IProps> = observer((props: IProps) => {
  const { comments, isHighlightComments, highlightId, modalStateOnOpen, sectionId, isMobile, onClose } = props;
  const BookmarkStoreContext = useContext(BookmarksStore);
  const HighlightStoreContext = useContext(HighlightsStore);
  const [content, setContent] = useState("");
  const [commentModalState, setCommentModalState] = useState(modalStateOnOpen);
  const [editCommentId, setEditCommentId] = useState(DEFAULT_COMMENT_ID);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);

  const isToBeClosed = () => {
    return (
      modalStateOnOpen === COMMENT_MODAL_STATE.CLOSED ||
      (modalStateOnOpen === COMMENT_MODAL_STATE.VIEW_COMMENTS && (!comments || !comments?.length))
    );
  };

  useEffect(() => {
    setCommentModalState(modalStateOnOpen);
    const isHideCommentsBadge = !isToBeClosed();
    const commentsBadgeElement: HTMLElement | null = hideCommentsBadge(
      isHighlightComments ? highlightId! : sectionId!,
      isHideCommentsBadge,
      isHighlightComments,
    );

    return () => {
      restoreCommentsBadge(isHideCommentsBadge, commentsBadgeElement);
    };
  }, [modalStateOnOpen]);

  if (isToBeClosed()) {
    return <></>;
  }

  const onSaveComment = async () => {
    // This function is async, but we do not need to wait for it to finish
    if (isHighlightComments && highlightId) {
      if (editCommentId) {
        HighlightStoreContext.updateHighlightComment(highlightId, editCommentId, content);
      } else {
        HighlightStoreContext.createHighlightComment(highlightId, content);
      }
    } else {
      if (editCommentId) {
        BookmarkStoreContext.updateBookmarkComment(sectionId, editCommentId, content);
      } else {
        BookmarkStoreContext.createBookmarkComment(sectionId, content);
      }
    }

    setContent("");
    setCommentModalState(COMMENT_MODAL_STATE.VIEW_COMMENTS);
    setEditCommentId(DEFAULT_COMMENT_ID);
  };

  const onCloseModal = () => {
    onClose();
    setCommentModalState(COMMENT_MODAL_STATE.VIEW_COMMENTS);
    setContent("");
    setEditCommentId(DEFAULT_COMMENT_ID);
    showHiddenBadgeInSection(sectionId);
  };

  const onCloseEdit = () => {
    if (editCommentId) {
      setCommentModalState(COMMENT_MODAL_STATE.VIEW_COMMENTS);
    } else {
      onClose();
    }

    setEditCommentId(DEFAULT_COMMENT_ID);
    setContent("");
  };

  const onDeleteComment = () => {
    setIsDeleteConfirmOpen(false);
    setContent(""); // reset content

    if (isHighlightComments && highlightId) {
      HighlightStoreContext.deleteHighlightComment(highlightId, editCommentId);
    } else {
      BookmarkStoreContext.deleteBookmarkComment(sectionId, editCommentId);
    }
    setEditCommentId(DEFAULT_COMMENT_ID);
  };

  const currentState: IStateProps = {
    comments,
    sectionId,
    isMobile,
    content,
    setContent,
    commentModalState,
    setCommentModalState,
    editCommentId,
    setEditCommentId,
    onSaveComment,
    onClose: onCloseModal,
    onCloseEdit,
    isDeleteConfirmOpen,
    setIsDeleteConfirmOpen,
  };

  return (
    <Detector
      polling={ false }
      render={({ online }) => (
        <div>
          <CommentModalContainer>
            <CommentModalHeader>
              <CommentModalTitle>{isMobile ? "Add comment" : "Comment"}</CommentModalTitle>
              {isMobile ? (
                <MobileCloseIcon>
                  <MenuIconWithCaption
                    onClick={onCloseModal}
                    icon={ICONS.CLOSE}
                    caption="Close"
                    iconColor={STYLE_DEFAULTS.COLORS.SA_B070}
                    captionColor={STYLE_DEFAULTS.COLORS.SA_B070}
                    isMinimised={false}
                    isMobile
                    hideUnderline
                  />
                </MobileCloseIcon>
              ) : (
                <Icon icon={ICONS.CLOSE} clickable tiny internalSize="16px" onClick={onCloseModal} />
              )}
            </CommentModalHeader>
            {commentModalState === COMMENT_MODAL_STATE.CREATE_UPDATE_COMMENT
              ? renderSaveCommentModalContent(currentState)
              : renderExistingComments(currentState, !online)}
            <div id="comment-modal-end"></div>
          </CommentModalContainer>
          <DeleteMarkerModal
            section="comment"
            commentsCount={0}
            isOpen={isDeleteConfirmOpen}
            onAccept={onDeleteComment}
            onCancel={() => setIsDeleteConfirmOpen(false)}
          />
        </div>
      )}
    />
  );
});

export default CommentModal;
