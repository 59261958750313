import styled from "styled-components";
import STYLE_DEFAULTS from "../../../../constants/styles";

interface IProps {
  background?: string;
  borderColor?: string;
  caseSensitive?: boolean;
  fontWeight?: string;
  height?: string;
  paddingRight?: string;
  paddingLeft?: string;
  blue?: boolean;
  green?: boolean;
  fontColor?: string;
  letterSpacing?: string;
  onClick?: () => void;
}

export const DynamicBadgeContainer = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: auto;
  height: ${(props: IProps) => props.height || "15px"};
  cursor: ${(props: IProps) => (props.onClick ? "pointer" : "default")};
  padding-right: ${(props: IProps) => props.paddingRight || "0px"};
  padding-left: ${(props: IProps) => props.paddingLeft || "0px"};
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  background-color: ${(props: IProps) => props.background || "rgba(255, 255, 255, 0)"};

  ${(props: IProps) => {
    if (props.borderColor) {
      return `
      border: 1px solid ${props.borderColor};
    `;
    }
  }}
`;

export const DynamicBadgeIcon = styled.div`
  margin-right: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_1};
`;

export const DynamicBadgeValue = styled.div`
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.NOTO_SANS};
  font-size: 11px; // Same for desktop and mobile
  font-weight: ${(props: IProps) => props.fontWeight ?? STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.NORMAL};
  color: ${(props: IProps) => props.fontColor ?? STYLE_DEFAULTS.COLORS.SA_B070};
  letter-spacing: ${(props: IProps) => props.letterSpacing || "0"};
  ${(props: IProps) => {
    if (!props.caseSensitive) {
      return `text-transform: uppercase;`;
    }
  }}
`;
