import styled from "styled-components";
import STYLE_DEFAULTS from "../../constants/styles";
import { MEDIA_QUERIES } from "../../constants/mediaQueries";

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_5} 28px ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_5};

  @media ${MEDIA_QUERIES.SM} {
    margin: 0 ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_2} ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_3}
      ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_2};
  }
`;

export const MessageBody = styled.div`
  display: flex;
  flex-direction: row;
  overflow-wrap: break-word;
`;

export const MessageMainContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_3};
  background-color: #f2f8fc;
  border: 1px solid ${STYLE_DEFAULTS.COLORS.SA_BLUE};
  border-radius: ${STYLE_DEFAULTS.BUTTONS.SA_BUTTON_RADIUS};
  -webkit-border-radius: ${STYLE_DEFAULTS.BUTTONS.SA_BUTTON_RADIUS};
  -moz-border-radius: ${STYLE_DEFAULTS.BUTTONS.SA_BUTTON_RADIUS};
  margin-bottom: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_5};

  @media ${MEDIA_QUERIES.SM} {
    margin-bottom: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_3};
  }
`;

export const SearchTips = styled.div`
  margin-left: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_2};
`;

export const SearchTipsHeader = styled.div`
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.HELVETICA_NEUE};
  font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.MEDIUM};
  font-size: 0.875rem;
  line-height: ${STYLE_DEFAULTS.FONTS.SA_FONT_LINE_HEIGHT.NORMAL};
  margin-bottom: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_2};
`;

export const SearchTipsContent = styled.div`
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.NOTO_SANS};
  font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.NORMAL};
  line-height: 1.5;
  margin-bottom: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_2};

  ul {
    padding-left: 14px;
  }

  li {
    margin-bottom: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_2};
    font-size: 12px; // same in desktop and mobile
  }
`;

export const MessageHeader = styled.div`
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.HELVETICA_NEUE};
  font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.NORMAL};
  font-size: 1.5rem;
  line-height: ${STYLE_DEFAULTS.FONTS.SA_FONT_LINE_HEIGHT.NORMAL};
  margin-top: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_5};
  margin-bottom: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_3};
`;

export const MessageSubtitle = styled.div`
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.NOTO_SANS};
  font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.NORMAL};
  font-size: 1rem;
  line-height: ${STYLE_DEFAULTS.FONTS.SA_FONT_LINE_HEIGHT.NORMAL};
  margin-bottom: 22px;
`;

export const MessageBodyIcon = styled.div`
  margin: 1rem;
`;

export const MessageBodyContent = styled.div`
  width: 100%;

  & ul {
    font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.HELVETICA_NEUE};
    font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.LIGHT};
    font-size: 0.875em;
    line-height: 1em;
    overflow-wrap: break-word;

    box-sizing: border-box;
  }
`;

export const MessageFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const MessageFooterText = styled.div`
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.HELVETICA_NEUE};
  font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.MEDIUM};
  font-size: 1.125em;
  line-height: ${STYLE_DEFAULTS.FONTS.SA_FONT_LINE_HEIGHT.NORMAL};
  color: ${STYLE_DEFAULTS.COLORS.SA_BLACK_B100};
  margin-bottom: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_3};
`;

export const MessageFooterLink = styled.div`
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.HELVETICA_NEUE};
  font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.NORMAL};
  font-size: 1em;

  a {
    color: ${STYLE_DEFAULTS.COLORS.SA_BLUE};
  }
`;

export const MessageFooterDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${STYLE_DEFAULTS.COLORS.SA_B015};
  margin-bottom: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_5};
`;
