import styled from "styled-components";
import STYLE_DEFAULTS from "../constants/styles";

interface IProps {
  marginTop?: string;
  marginBottom?: string;
}

const Underline = styled.div`
  height: 1px;
  background-color: ${STYLE_DEFAULTS.COLORS.SA_B015};
  margin-top: ${(props: IProps) => props.marginTop || STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_4};
  margin-bottom: ${(props: IProps) => props.marginBottom || STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_4};
  width: 100%;
`;

export default Underline;
