interface B2cConfig {
    clientId: string;
    authority: string;
    knownAuthorities?: string[];
    redirectUri?: string;
}

interface IAMConfig {
    client_id: string;
    authority: string;
    redirect_uri: string;
}

interface IEnv {
    b2c: {
        config: B2cConfig;
        redirectRequestScopes: string[];
        passwordAuthority: string;
    }
    IAM: {
        config: IAMConfig;
        login_hint: string;
    }
    api: {
        deliveryServiceBaseUrl: string;
    }
    app: {
        sessionTimeoutInMinute: number;
        iamRefreshTokenIntervalInMinute: number,
        reactAppHTMLTagsSkipHighlightWhiteList: string;
        segmentKey: string;
    }
    SumoLogic: {
        Uri: string;
    }
}

declare global {
    interface Window {
        _env_: IEnv;
        analytics: any;
    }
}

export default window._env_;
