import React from "react";
import * as NoResultMsgStyles from "./style";
import Button from "../controls/button/Button";
import ICONS from "../../constants/icons";
import Icon from "../icons";
import STYLE_DEFAULTS from "../../constants/styles";
import STANDARDS_AUSTRALIA_CONTACT_DETAILS from "../../constants/contactDetails";
import { SHOP_MENU_ITEMS, SHOP_MENU_ITEMS_INFO } from "../../constants/shopMenuItems";

interface IInput {
  searchQuery: string;
  messageOverride?: string;
}

function NoResultsMessage(props: IInput): React.ReactElement {
  const { searchQuery, messageOverride } = props;
  const message = messageOverride || "We're sorry!";

  return (
    <NoResultMsgStyles.MessageContainer>
      <NoResultMsgStyles.MessageBody>
        <NoResultMsgStyles.MessageBodyContent>
          <NoResultMsgStyles.MessageHeader>{message}</NoResultMsgStyles.MessageHeader>
          <NoResultMsgStyles.MessageSubtitle>
            We couldn’t find any results based on your search &ldquo;{searchQuery}&rdquo;.
          </NoResultMsgStyles.MessageSubtitle>
          <NoResultMsgStyles.MessageMainContent>
            <Icon tiny icon={ICONS.BULB} color={STYLE_DEFAULTS.COLORS.SA_BLUE} />
            <NoResultMsgStyles.SearchTips>
              <NoResultMsgStyles.SearchTipsHeader>Search tips</NoResultMsgStyles.SearchTipsHeader>
              <NoResultMsgStyles.SearchTipsContent>
                <ul>
                  <li>Check your spelling &amp; try again</li>
                  <li>
                    Try a similar but different search term like &ldquo;wet area&rdquo; instead of &ldquo;sink&rdquo; or
                    &ldquo;basin&rdquo;
                  </li>
                  <li>Keep your search terms simple, as our search works best with shorter descriptions</li>
                </ul>
              </NoResultMsgStyles.SearchTipsContent>
            </NoResultMsgStyles.SearchTips>
          </NoResultMsgStyles.MessageMainContent>
        </NoResultMsgStyles.MessageBodyContent>
      </NoResultMsgStyles.MessageBody>
      {/*
      <NoResultMsgStyles.MessageFooterDivider />
      <NoResultMsgStyles.MessageFooter>
        <NoResultMsgStyles.MessageFooterText>Need help?</NoResultMsgStyles.MessageFooterText>
        <NoResultMsgStyles.MessageFooterLink>
          <a href={SHOP_MENU_ITEMS_INFO[SHOP_MENU_ITEMS.HOME].path} target="_blank" rel="noopener noreferrer">
            Contact Us
          </a>
        </NoResultMsgStyles.MessageFooterLink>
      </NoResultMsgStyles.MessageFooter>
     */}
    </NoResultMsgStyles.MessageContainer>
  );
}

export default NoResultsMessage;
