import DOCUMENT_STATUS from "./documentStatus";

const CONTENT_SEARCH_DEFAULTS = {
  ALGOLIA_RELEVANCE_INDEX_NAME: process.env.REACT_APP_ALGOLIA_PRIMARY_INDEX ?? "",
  ALGOLIA_LATEST_PUBLISHED_INDEX_NAME: process.env.REACT_APP_ALGOLIA_LATEST_PUBLISHED_REPLICA_INDEX ?? "",
  ALGOLIA_OLDEST_PUBLISHED_INDEX_NAME: process.env.REACT_APP_ALGOLIA_OLDEST_PUBLISHED_REPLICA_INDEX ?? "",
  ALGOLIA_MOST_POPULAR_INDEX_NAME: process.env.REACT_APP_ALGOLIA_MOST_POPULAR_REPLICA_INDEX ?? "",

  // Quick search
  QUICK_SEARCH_HITS_MAX: 7,
  DEFAULT_DOC_TYPE_NAME: "Other",
  SECTORS_CATEGORY_NAME: "Industry Sectors",

  // Search results page
  SEARCH_RESULTS_PER_PAGE_LIST: [10, 20, 50],
  SEARCH_DEFAULT_REFINEMENT_LIST_LENGTH: 5,

  //eReader search
  READER_CONTENT: "ereader-content",
  STS_STANDARD_CONTENT: "sts-standard-content",
  READER_SEARCH_HITS_MAX: 5,
  HIT_STATUS_ORDER: [DOCUMENT_STATUS.CURRENT, DOCUMENT_STATUS.PENDING_REVISION, DOCUMENT_STATUS.AVAILABLE_SUPERSEDED, DOCUMENT_STATUS.SUPERSEDED, DOCUMENT_STATUS.WITHDRAWN, DOCUMENT_STATUS.OBSOLESCENT]
};

export default CONTENT_SEARCH_DEFAULTS;