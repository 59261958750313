import React from "react";
import _ from "lodash";
import cheerio from "cheerio";
import CONTENT_SEARCH_DEFAULTS from "../../../../../constants/contentSearch";
import { observable, action, computed, runInAction } from "mobx";
import { IReaderSections } from "../../../../../models/toc";
import { setSectionHtml } from "../../../contentController/helpers/helpers";

//This class stores the list of matched results found in the eReader from the users input
//It recursively traverses through each element down to it's lowest child
//from there it searches any text node (Node.TEXT_NODE) for a match and then moves to it's parent
class SearchResultsStore {
  //special characters that regex doesn't like with an extra forward-slash /
  private specialCharacters = /[-/\\^$*+?.()|[\]{}]/g;
  //search tag that which identifies the match and helps scrolling to node when user clicks on result
  private markTag = "search_mark";
  //regex to skip and tags i.e. scripts and the above tag
  private skipTags = new RegExp("^(?:" + this.markTag + "|SCRIPT|STYLE)$");
  //increment of results, new searches always start at 0
  private indexId = 0;
  //boolean to identify if a search is in progress
  private isSearching = false;

  private readerSectionsContentCopy: Array<IReaderSections> = [];

  // function to be used to update ReaderStore sections after adding or removing search tags
  private updateReaderSectionsFn: (newContent: string, sectionId: number) => void = (
    newContent: string,
    sectionId: number,
  ) => {
    return;
  };

  // function to be used to reset ReaderStore content sections (remove added tags)
  private resetReaderSectionsFn: () => void = () => {
    return;
  };

  //contains all the results
  @observable SearchResults: any = [];
  //current user input
  @observable SearchTerm = "";
  //index of the last searched section i.e. TOC
  @observable LastUsedSectionIndex = 0;
  //index of the last section tht returned a result
  @observable FirstResultSectionIndex = 0;
  //determine if the search uses the last used section index OR the FirstResultSectionIndex
  @observable UseLastResultSection = false;
  //Max results before we stop searching
  @observable MaxResults = 15;

  //returns if there's more data to load or if it's at the end of the document
  @computed get MoreToLoad() {
    return this.LastUsedSectionIndex < this.readerSectionsContentCopy.length - 1;
  }

  //returns true if user input is empty
  @computed get IsSearchTermEmpty() {
    return this.SearchTerm === undefined || !(this.SearchTerm = this.SearchTerm.replace(/(^\s+|\s+$)/g, ""));
  }

  //current generated regex with users input
  @computed private get MatchRegex() {
    return RegExp("(\\S*)" + this.SearchTerm + "(\\S*)", "mi");
  }

  @action initialiseUpdateReaderSectionsFn = (
    updateReaderSectionsFn: (newContent: string, sectionId: number) => void,
  ) => {
    runInAction(() => {
      this.updateReaderSectionsFn = updateReaderSectionsFn;
    });
  };

  @action initialiseResetReaderSectionsFn = (resetReaderSectionsFn: () => void) => {
    runInAction(() => {
      this.resetReaderSectionsFn = resetReaderSectionsFn;
    });
  };

  @action setReaderSectionsContentCopy = (readerSectionsContent: Array<IReaderSections>) => {
    runInAction(() => {
      this.readerSectionsContentCopy = readerSectionsContent;
    });
  };

  @action addTags = (el: any, $: any, sectionSearchResults: any, sectionId: number | undefined) => {
    if (el.type === "tag") {
      this.searchParents(el, $, sectionSearchResults, sectionId);
    } else {
      // Process text node only.
      //get the regular expression match
      const nodeText = el.data;
      const nbsp = /(\u00A0)/g;
      const contentWithoutNbsp = nodeText.replace(nbsp, " ");
      const regExp = this.MatchRegex.exec(contentWithoutNbsp);
      if (regExp === null) return;

      const resultId = "search-id-" + this.indexId;
      this.indexId++;
      const matchingText = regExp[0];
      const index = regExp.index;
      const textBefore = nodeText.substring(0, index) || "";
      const textAfter = nodeText.substring(index + regExp[0].length) || "";
      const maxHits = CONTENT_SEARCH_DEFAULTS.READER_SEARCH_HITS_MAX;

      const updatedContent =
        textBefore +
        "<" +
        this.markTag +
        ' id="' +
        resultId +
        '">' +
        matchingText +
        "</" +
        this.markTag +
        ">" +
        textAfter;

      // prettier-ignore
      const wordsBefore = textBefore && textBefore.length ? textBefore.split(/\s+/g).filter((e: string) => e != "") : [];
      const wordsAfter = textAfter && textAfter.length ? textAfter.split(/\s+/g).filter((e: string) => e != "") : [];
      const beforePrefix = regExp[1] !== "" ? " " + regExp[1] : regExp[1];

      //add results
      sectionSearchResults.push({
        id: resultId,
        sectionId,
        //get the LAST x words from before array and append the 2nd group of the regular expression
        before: wordsBefore.slice(Math.max(wordsBefore.length - maxHits + 1, 0)).join(" ") + " " + beforePrefix,
        //extract the phrase from the regular expression match
        phrase: regExp[0].slice(regExp[1].length, regExp[0].length - regExp[2].length),
        //get the FIRST x words from the after array and prefix the 3rd group of the regular expression
        after: regExp[2] + " " + wordsAfter.slice(0, maxHits + 1).join(" "),
      });

      // insert content after current element, then remove current element. (Won't remove siblings, will only remove current elemenet)
      $(el).replaceWith(updatedContent);
      let nextSibling = el.next?.next;
      while (nextSibling?.name === this.markTag) {
        nextSibling = nextSibling?.next;
      }

      //as we create and split the node to insert the mark tag
      //we need to check that there's another sibling AFTER the newly inserted mark i.e. the continued data
      if (nextSibling) {
        this.addTags(nextSibling, $, sectionSearchResults, sectionId);
      }
    }
  };

  /**
   * Recursively mark full content of tag, including all children
   *
   * @param props
   */
  @action searchParents = (parentEl: any, $: any, sectionSearchResults: any, sectionId: number | undefined) => {
    if (this.MatchRegex === null || !this.MatchRegex) return;

    const parentElContentsArr = $(parentEl).contents();

    if (!parentElContentsArr || !parentElContentsArr.length) {
      return;
    }

    parentElContentsArr.map((_: number, el: any) => {
      this.addTags(el, $, sectionSearchResults, sectionId);
    });
  };

  // remove highlighting - reset reader content to the original content as read from DB
  @action removeTags = () => {
    this.resetReaderSectionsFn();
  };

  //using the current data in class, search through the sections
  //if the results is more than max results, save current section id (for later use) and exit function
  //if you need to load more, increase max results and re-call this function
  @action searchReader = () => {
    let found = false;
    if (this.isSearching === true) return;
    //get starting index
    const start = this.UseLastResultSection ? this.FirstResultSectionIndex : this.LastUsedSectionIndex;

    //loop through each section, find results and add tag
    for (let i = start; i < this.readerSectionsContentCopy.length; i++) {
      this.isSearching = true;
      const section = this.readerSectionsContentCopy[i];
      this.LastUsedSectionIndex = i;

      //if results greater than the max results flag, exit
      if (found || this.indexId >= this.MaxResults) {
        //set MaxResults to index
        this.MaxResults = this.indexId;
        this.isSearching = false;
        return;
      }
      const sectionSearchResults: any[] = [];
      const $ = cheerio.load(section.partialHtmlBody || "", { decodeEntities: false });
      const sectionId = section.id || 0; // matches table of content id
      const parentEl = $.root();

      //add tags
      this.searchParents(parentEl, $, sectionSearchResults, sectionId);

      //if results are returned add them to the main results
      if (sectionSearchResults.length > 0) {
        let runningTotal = sectionSearchResults.length;

        //only update for the first result
        if (this.SearchResults.length === 0) {
          this.FirstResultSectionIndex = i;
        } else {
          this.SearchResults.filter((x: any) => (runningTotal += x.results.length));
        }
        found = true;

        this.SearchResults.push({
          runningTotal: runningTotal,
          section: section.sectionName,
          results: sectionSearchResults,
        });

        // Load HTML updated with search tags into DOM, also updates markers, e.g. amendments
        setSectionHtml(sectionId, $);

        // Update HTML in reader store
        this.updateReaderSectionsFn($("body").html() || section.partialHtmlBody || "", sectionId);
      }
    }
    this.isSearching = false;
  };

  //initialise the classes default data
  @action initialise = async (index = 0) => {
    this.indexId = 0;
    this.SearchResults = [];
    this.LastUsedSectionIndex = 0;
    this.FirstResultSectionIndex = index;
    this.MaxResults = 15;
  };

  //gets private variable MoreToLoad
  @action hasMoreToLoad = () => {
    return this.MoreToLoad;
  };

  //load more data from the dataset
  @action loadMoreSearchResults = async () => {
    //have a pre-check to see if three's anything more to load
    //explicitly check for false
    if (this.hasMoreToLoad() === false) {
      return false;
    }
    //if search term is empty return empty results
    if (this.SearchTerm === undefined || !(this.SearchTerm = this.SearchTerm.replace(/(^\s+|\s+$)/g, ""))) return;
    //update results
    this.MaxResults += 15;
    this.searchReader();
    //return if there's more to load
    return this.hasMoreToLoad();
  };

  exitSearch(newSearchTerm: string) {
    if (this.SearchTerm === newSearchTerm) return true;
    return (
      newSearchTerm.length > 1 &&
      this.SearchResults.length === 0 &&
      this.MoreToLoad === false &&
      (this.SearchTerm === newSearchTerm || this.SearchTerm === newSearchTerm.substr(0, newSearchTerm.length - 1))
    );
  }

  //initialise date and generate search results
  @action generateSearchResults = async (searchTerm: string, setDropdown: any) => {
    setDropdown(searchTerm != "");
    setTimeout(() => {
      runInAction(() => {
        const newSearchTerm = searchTerm.replace(this.specialCharacters, "\\$&").toLowerCase();
        //if there's no results and the user just enters the same word + 1, exit.
        //No point searching as there's already no results
        if (this.exitSearch(newSearchTerm)) {
          this.SearchTerm = newSearchTerm;
          return;
        }
        //below code determines the starting section based on the user input
        let lastResultIndex = 0;
        this.UseLastResultSection = false;
        if (this.SearchTerm === newSearchTerm.substr(0, newSearchTerm.length - 1)) {
          lastResultIndex = this.FirstResultSectionIndex;
          this.UseLastResultSection = true;
        }
        //init data
        this.initialise(lastResultIndex);
        this.SearchTerm = newSearchTerm;
        //remove current tags, if any!
        this.removeTags();
        //if search term is empty return empty results
        if (this.IsSearchTermEmpty) return;
        //search reader
        this.searchReader();
        this.UseLastResultSection = false;
      });
    }, 0);
  };
}

export default React.createContext(new SearchResultsStore());
