
import React, { useContext } from "react";
import Modal, { Styles } from 'react-modal';

// State Management
import { closeModal, customModalState } from "state/slices/customModalSlice";
import { useAppSelector } from "hooks/useAppSelector";
import { useAppDispatch } from "hooks/useAppDispatch";
import ReaderLayoutContext, { IReaderLayoutContext } from "components/reader/layout/readerLayoutContext";

// Hooks
import useScreenSize from "hooks/useScreenSize";

// Icons
import ICONS from "constants/icons";
import Icon from "components/icons";
import { MobileCloseIcon } from "components/reader/contentController/comments/style";

// Components
import { DividerHr } from "../styledComponents/hr";
import MenuIconWithCaption from "components/controls/menuIcon/MenuIconWithCaption";

// Styles
import STYLE_DEFAULTS from "constants/styles";
import * as Styled from "./style";

/** Reusable Modal Component */
const CustomModal = () => {

  const customModal = useAppSelector(customModalState);
  const { isMobile, } = useContext<IReaderLayoutContext>(ReaderLayoutContext);

  const dispatch = useAppDispatch();

  const screenSize = useScreenSize();

  const modalStyle: Styles = {
    overlay: {
      zIndex: 999,
      background: "rgba(0, 0, 0, 0.08)",
    },
    content: {
      position: 'absolute',
      maxWidth: customModal?.maxWidth ?? '384px',
      top: screenSize.IsMediumScreen ? "5%" : customModal?.top ?? "10%",
      left: screenSize.IsMediumScreen ? "5%" : customModal?.left ?? "10%",
      right: screenSize.IsMediumScreen ? "5%" : customModal?.right ?? "10%",
      bottom: customModal?.bottom ?? "auto",
      border: '1px solid #ccc',
      background: STYLE_DEFAULTS.COLORS.SA_B010,
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch',
      borderRadius: '4px',
      outline: 'none',
      padding: '12px'
    },
  };

  const handleClickOnClose = () => {
    dispatch(closeModal());
  }

  return (
    <Modal
      isOpen={customModal.isOpen}
      style={modalStyle}
      ariaHideApp={false}
    >
      <Styled.CustomModalHeader>
        <Styled.CustomModalTitle>  {customModal?.headerIcon} {customModal.title}</Styled.CustomModalTitle>
        <Styled.CustomModalCloseButton onClick={handleClickOnClose}>
          {isMobile ? (
            <MobileCloseIcon>
              <MenuIconWithCaption
                icon={ICONS.CLOSE}
                caption="Close"
                iconColor={STYLE_DEFAULTS.COLORS.SA_B070}
                captionColor={STYLE_DEFAULTS.COLORS.SA_B070}
                isMinimised={false}
                isMobile
                hideUnderline
              />
            </MobileCloseIcon>
          ) : (
            <Icon icon={ICONS.CLOSE} clickable tiny internalSize="16px" />
          )}
        </Styled.CustomModalCloseButton>
      </Styled.CustomModalHeader>
      <DividerHr></DividerHr>
      {customModal.content}
    </Modal>
  );
};

export default CustomModal;