import styled from "styled-components";
import { MEDIA_QUERIES } from "./../../../constants/mediaQueries";
import STYLE_DEFAULTS from "../../../constants/styles";

interface IProps {
  isMobile: boolean;
}

export const ReaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  background-color: ${STYLE_DEFAULTS.COLORS.SA_B005};
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

export const ReaderContentWrapper = styled.main`
  width: 100%;

  @media ${MEDIA_QUERIES.LG} {
    width: ${(props: IProps) => (props.isMobile ? "100vw" : "1440px")};
    margin: 0 auto;
  }
`;

export const ArchivedWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;

  /* Absolute position */
  left: 0px;
  position: absolute;
  top: 0px;

  /* Take full size */
  height: 100%;
  width: 100%;
`;

export const ArchivedWatermark = styled.div`
  color: rgba(0, 0, 0, 0.2);

  /* Text styles */
  font-size: 6rem;
  font-weight: bold;
  text-transform: uppercase;

  /* Rotate the text */
  transform: rotate(-45deg);

  /* Disable the selection */
  user-select: none;
`;
