import React, { useContext } from "react";
import { LoggedInIconRing, LoggedInIconInitials } from "./style";
import ILoggedInIcon from "../../../models/loggedInicon";
import UserStore from "stores/userStore";

function LoggedInIcon(props: ILoggedInIcon): React.ReactElement {
  const { isActive, isShopIcon, isMobile } = props;

  const userContext = useContext(UserStore);
  const { user } = userContext;

  return (
    <LoggedInIconRing isActive={isActive} isShopIcon={isShopIcon} isMobile={isMobile}>
      <LoggedInIconInitials isActive={isActive} isShopIcon={isShopIcon} isMobile={isMobile}>
        {user?.userInitials}
      </LoggedInIconInitials>
    </LoggedInIconRing>
  );
}

export default LoggedInIcon;
