import React from "react";
import GenericModal from "../../../alerts/GenericModal";
import BUTTONS_TYPES from "../../../../constants/buttons";
import { ConfirmationModalContent, ConfirmationModalTitle } from "./style";
import { MEDIA_QUERIES_NAMES } from "../../../../constants/mediaQueries";
import { useBreakpoint } from "../../../../services/breakpointProvider/BreakpointProvider";

interface IProps {
  isOpen: boolean;
  setOpen: any;
  onAccept: () => void;
  onCancel: () => void;
}

const DeleteOfflineDocumentModal = (props: IProps) => {
  const { isOpen, onAccept, onCancel, setOpen } = props;

  const breakpoints: any = useBreakpoint();
  const isMobile: boolean = breakpoints[MEDIA_QUERIES_NAMES.XS] || breakpoints[MEDIA_QUERIES_NAMES.SM];

  if (!isOpen) {
    return null;
  }

  return (
    <GenericModal
      hasCloseIcon
      isOpen
      setOpen={setOpen}
      width={isMobile ? "100%" : "600px"}
      hasCancelButton
      acceptValue="Delete"
      onAccept={onAccept}
      onCancel={onCancel}
      shadow
      top="60px"
      buttonPosition="flex-end"
      acceptButtonType={BUTTONS_TYPES.DELETE}
    >
      <ConfirmationModalTitle>Remove from browser storage</ConfirmationModalTitle>
      <ConfirmationModalContent>
        This will remove the offline version from your browser.<br/><br/>You can download the file again for offline viewing anytime from the Online WebReader.
      </ConfirmationModalContent>
    </GenericModal>
  );
};

export default DeleteOfflineDocumentModal;
