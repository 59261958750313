import styled from "styled-components";
import STYLE_DEFAULTS from "../../constants/styles";
import { MEDIA_QUERIES } from "../../constants/mediaQueries";

interface IProps {
  isMobile: boolean;
}

export const ErrorLayout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: ${STYLE_DEFAULTS.COLORS.SA_WHITE_B000};

  @media ${MEDIA_QUERIES.LG} {
    width: ${(props: IProps) => (props.isMobile ? "100vw" : "1440px")};
    margin: 0 auto;
  }
`;

export const ErrorPanel = styled.div`
  text-align: center;
  width: ${(props: IProps) => (props.isMobile ? "100vw" : "680px")};
  align-self: center;
  margin-top: ${(props: IProps) => (props.isMobile ? "60px" : "120px")};
  align-items: center;
`;

export const ErrorTitle = styled.div`
  font-size: 2em;
  color: ${STYLE_DEFAULTS.COLORS.SA_B085};
  display: block;
  padding-bottom: 10px;
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.HELVETICA_NEUE};
`;

export const ErrorChildren = styled.div`
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.HELVETICA_NEUE};
  font-size: 3em;
  padding: 0 8px 28px 8px;
`;

export const ErrorFooter = styled.div`
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.NOTO_SANS};
`;

export const ErrorFooterMessage = styled.div`
  font-size: 1em;
  font-weight: 400;
`;

export const ErrorButton = styled.div`
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.HELVETICA_NEUE};
  font-size: 1em;
  padding-top: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_10};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const ConnectionErrorMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_5};
  margin-left: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_5};
  margin-right: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_5};

  @media ${MEDIA_QUERIES.SM} {
    margin-left: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_2};
    margin-right: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_2};
  }
`;

export const ConnectionErrorMessageTitle = styled.div`
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.HELVETICA_NEUE};
  font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.NORMAL};
  font-size: 1.5rem;
  line-height: 1.25;
  margin-bottom: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_2};
`;

export const ConnectionErrorMessageMessage = styled.div`
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.NOTO_SANS};
  font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.NORMAL};
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_6};
`;
