//MODIFIED_FILE
import { observable, action, configure, runInAction, computed } from "mobx";
import _ from "lodash";
import { createContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import qs from "querystring";
import IBreadcrumb, { IPageTransition } from "../models/breadcrumb";
import { SHOP_MENU_ITEMS_INFO, SHOP_MENU_ITEMS } from "../constants/shopMenuItems";
import { IBreadcrumbStyle } from "../components/controls/breadcrumbs/style";

configure({ enforceActions: "always" });

const allowedTransitions: IPageTransition[] = [
  //
  // Source: home page
  //

  {
    fromUrl: SHOP_MENU_ITEMS_INFO[SHOP_MENU_ITEMS.HOME].path,
    toUrl: SHOP_MENU_ITEMS_INFO[SHOP_MENU_ITEMS.HOME].path,
  }

];

const isValidTransition = (fromUrl: string, toUrl: string) => {
  const filterBySources = _.filter(allowedTransitions, (transition: IPageTransition) => transition.fromUrl === fromUrl);
  const filterByDestination = _.filter(filterBySources, (transition: IPageTransition) => transition.toUrl === toUrl);

  return filterByDestination.length > 0;
};

const areEqual = (breadcrumb1: IBreadcrumb | undefined | null, breadcrumb2: IBreadcrumb | undefined | null) => {
  if (!breadcrumb1 && !breadcrumb2) {
    return true;
  }

  if (breadcrumb1 && breadcrumb2 && breadcrumb1.pathname === breadcrumb2.pathname) {
    return true;
  }

  return false;
};

export class BreadcrumbsStore {
  @observable breadcrumbsRegistry: Map<number, IBreadcrumb> = new Map();
  @observable customStyle: IBreadcrumbStyle | undefined;

  /**
   * Reset breadcrumbs list by emptying the history
   *
   */
  @action resetBreadcrumbs = async () => {
    runInAction("reset the pile", () => {
      this.breadcrumbsRegistry.clear();
    });
  };

  /**
   * If home page breadcrumb - add it, otherwise add homepage then the given breadcrumb
   *
   * @param breadcrumb
   */
  @action addFirstBreadcrumb = async (breadcrumb: IBreadcrumb) => {
    runInAction("Add first crumb", () => {
      // Identifier for the breadcrumb, running integer starting with 1.The key is set to 1
      // on every page load/refresh or breadcrumbs reset - only relevant for breadcrumbs in the current browser tab
      const breadcrumbsCount = 1;

      // Clear the history if needed
      if (this.breadcrumbsRegistry.size > 0) {
        this.breadcrumbsRegistry.clear();
      }

      if (breadcrumb.pathname !== SHOP_MENU_ITEMS_INFO[SHOP_MENU_ITEMS.HOME].path) {
        this.breadcrumbsRegistry.set(breadcrumbsCount, {
          key: breadcrumbsCount,
          label: SHOP_MENU_ITEMS_INFO[SHOP_MENU_ITEMS.HOME].name,
          pathname: SHOP_MENU_ITEMS_INFO[SHOP_MENU_ITEMS.HOME].path,
        });
      }

      this.addBreadcrumbHelper(
        {
          key: breadcrumbsCount,
          label: breadcrumb.label,
          pathname: breadcrumb.pathname,
          params: breadcrumb.params,
        },
        breadcrumbsCount,
      );
    });
  };

  private addBreadcrumbHelper(breadcrumb: IBreadcrumb, breadcrumbsCount: number) {
    if (breadcrumb.pathname === SHOP_MENU_ITEMS_INFO[SHOP_MENU_ITEMS.HOME].path) {
      breadcrumbsCount += 1;
      this.breadcrumbsRegistry.set(breadcrumbsCount, {
        key: breadcrumbsCount,
        label: SHOP_MENU_ITEMS_INFO[SHOP_MENU_ITEMS.HOME].name,
        pathname: SHOP_MENU_ITEMS_INFO[SHOP_MENU_ITEMS.HOME].path,
        params: {
          queryParams: {
            // FIXME: We do not yet have the content library page section, use account information section for now
            workItem: "Account Information",
          },
        },
      });

      breadcrumbsCount += 1;
      this.breadcrumbsRegistry.set(breadcrumbsCount, Object.assign({}, breadcrumb, { key: breadcrumbsCount }));
    } else {
      breadcrumbsCount += 1;
      this.breadcrumbsRegistry.set(breadcrumbsCount, Object.assign({}, breadcrumb, { key: breadcrumbsCount }));
    }

    return breadcrumbsCount;
  }

  /**
   * Add new breadcrumbs to the list
   *
   * @param breadcrumb
   */
  @action addBreadcrumb = async (breadcrumb: IBreadcrumb) => {
    if (!breadcrumb.label) {
      return;
    }

    runInAction("add new crumb to the pile", () => {
      const lastBreadcrumb =
        this.breadcrumbsRegistry.size > 0 ? this.breadcrumbsRegistry.get(this.breadcrumbsRegistry.size) : null;

      // add custom style
      this.customStyle = breadcrumb.customStyle?.breadcrumbStyle;

      if (!lastBreadcrumb) {
        // Add first breadcrumb
        this.addFirstBreadcrumb(breadcrumb);
      } else {
        let breadcrumbsCount = this.breadcrumbsRegistry.size;

        if (areEqual(lastBreadcrumb, breadcrumb)) {
          // Replace last breadcrumb with the new one - might have different params
          this.breadcrumbsRegistry.set(breadcrumbsCount, Object.assign({}, breadcrumb, { key: lastBreadcrumb.key }));

          return;
        }

        if (isValidTransition(lastBreadcrumb!.pathname, breadcrumb.pathname)) {
          // If adding workspace breadcrumb - add 2, one for landing page and one for the actual page
          // (if actual page is the landing page - add 2 anyway, because the name is different)
          breadcrumbsCount = this.addBreadcrumbHelper(breadcrumb, breadcrumbsCount);
        } else {
          this.resetBreadcrumbs();
          this.addFirstBreadcrumb(breadcrumb);
        }
      }
    });
  };

  /**
   * When user clicks on a breadcrumb, all breadcrumbs after the clicked one should be removed
   *
   * @param breadcrumb
   */
  @action handleClickBreadcrumb = async (breadcrumb: IBreadcrumb) => {
    const { pathname, params } = breadcrumb;

    if (!breadcrumb.key) {
      return;
    }

    // For workspace, 2 breadcrumbs are added, one the landing page, second the actual
    // page section, both breadcrumbs have the same path. When clicking on either of
    // those workspace breadcrumbs, it is the same as adding new workspace breadcrumb
    // (the same actions are done as in add new breadcrumb)
    if (pathname === SHOP_MENU_ITEMS_INFO[SHOP_MENU_ITEMS.HOME].path) {
      this.addBreadcrumb({
        key: null,
        label: breadcrumb.params?.queryParams?.workItem || SHOP_MENU_ITEMS_INFO[SHOP_MENU_ITEMS.HOME].name,
        pathname: SHOP_MENU_ITEMS_INFO[SHOP_MENU_ITEMS.HOME].path,
        params: breadcrumb.params,
      });
    } else {
      const lastBreadcrumbKey = this.breadcrumbsRegistry.size;

      runInAction("remove dangling breadcrumbs", () => {
        // Remove all breadcrumbs following the one that was clicked, starting from the last breadcrumb
        for (let i = lastBreadcrumbKey; i >= breadcrumb.key! + 1; i -= 1) {
          this.breadcrumbsRegistry.delete(i);
        }
      });
    }

    /*TODO
    Router.push({
      pathname: pathname,
      query: !params || _.isEmpty(params?.queryParams) ? "" : qs.stringify(params!.queryParams),
    });
    */
  };

  @computed({ keepAlive: true })
  get breadcrumbs() {
    return Array.from(this.breadcrumbsRegistry.values());
  }
}

export default createContext(new BreadcrumbsStore());
