import React from "react";
import { CloseButtonContainer } from "./style";
import Icon from "../../icons";
import ICONS from "../../../constants/icons";
import { ICloseButton } from "../../../models/button";
import STYLE_DEFAULTS from "../../../constants/styles";

/**
 * Style close button
 *
 * @param props
 */
function CloseButton(props: ICloseButton): React.ReactElement {
  const {
    onClick,
    internalSize,
    width = "16px",
    height = "16px",
    background = STYLE_DEFAULTS.COLORS.SA_WHITE_B000,
  } = props;

  return (
    <CloseButtonContainer
      onClick={onClick}
      width={width}
      height={height}
      role="button"
      tabIndex={0}
      background={background}
    >
      <Icon icon={ICONS.CLOSE} internalSize={internalSize} size={internalSize} clickable />
    </CloseButtonContainer>
  );
}

export default CloseButton;
