const DOCUMENT_STATUS = {
  WITHDRAWN: "Withdrawn",
  CURRENT: "Current",
  OBSOLESCENT: "Obsolescent",
  AVAILABLE_SUPERSEDED: "Available Superseded",
  PENDING_REVISION: "Pending Revision",
  SUPERSEDED: "Superseded"
};

export default DOCUMENT_STATUS;
