import React, { FunctionComponent, useContext, useState } from "react";

// Icon/Icon Components
import LoggedInIcon from "components/controls/loggedInIcon/LoggedInIcon";
import Icon from "components/icons";
import ICONS from "constants/icons";

// Components
import * as Styled from './style';
import { DividerHr } from "components/styledComponents/hr";
import ReaderLayoutContext, { IReaderLayoutContext } from "../../readerLayoutContext";
import LogOut from "./logOut/LogOut";

// State Management
import { useAppDispatch } from "hooks/useAppDispatch";
import { openModal } from "state/slices/customModalSlice";
import  UserStore  from "stores/userStore";

/** Component for showing account initials and popover info and menu */
const CallToAction: FunctionComponent = () => {
    const context = useContext<IReaderLayoutContext>(ReaderLayoutContext);
    const dispatch = useAppDispatch();
    const [isAccountPopoverOpen, setIsAccountPopoverOpen] = useState(false);

    const userContext = useContext(UserStore);
    const { user } = userContext;

    const handleClickOnLogOut = async () => {
        dispatch(openModal({
            isOpen: true,
            title: "Logout",
            content: <LogOut />,
            left:"35%",
            right:"35%"
        }));
    }

    const handleMouseOver = () => {
        setIsAccountPopoverOpen(true);
    }

    const handleMouseOut = () => {
        setIsAccountPopoverOpen(false);
    }

    return (
        <Styled.CallToActionContainer id="call-to-action">
            <Styled.AccountContainer onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} onClick={handleMouseOver}>
                <Styled.Account id="account-button">
                    <Styled.AccountSA id="account-initials">
                        <LoggedInIcon initials={context.userInitials} isActive={false} isShopIcon={false} isMobile={false} />
                    </Styled.AccountSA>
                </Styled.Account>
                <Styled.AccountPopover id="account-popover" isAccountPopoverOpen={isAccountPopoverOpen} >
                    <h4>{user?.firstName} {user?.lastName}</h4>
                    <p>{user?.id}</p>
                    <DividerHr />
                    <Styled.AccountMenuItem id="account-popover-logout-button" onClick={handleClickOnLogOut}>
                        <Icon icon={ICONS.LOGOUT} />
                        <span>Logout</span>
                    </Styled.AccountMenuItem>
                </Styled.AccountPopover>
            </Styled.AccountContainer>
        </Styled.CallToActionContainer>
    );
}

export default CallToAction;