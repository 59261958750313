import { observable, action, configure, runInAction, computed } from "mobx";
import _ from "lodash";
import { createContext } from "react";
import { ShopErrorHandler } from "../components/error/ErrorBoundary";
import SYSTEM_ERRORS from "../constants/errors";
import deliveryService from "../apis/deliveryService";
import { IBookmarkResponse } from "../models/bookmark";
import { createMarkerComment, updateMarkerComment, deleteMarkerComment } from "./common/helpers";

configure({ enforceActions: "always" });

export class BookmarksStore {
  // Map allows to observe content changes better in our case
  @observable _bookmarksRegistry: Map<number, IBookmarkResponse> = new Map();
  @observable bookmarkModeActive = false;

  @action toggleBookmarkMode = async () => {
    runInAction("toggle bookmark mode", () => {
      this.bookmarkModeActive = !this.bookmarkModeActive;
    });
  };

  @action getBookmarks = async (designationId: string) => {
    try {
      const response = await deliveryService.fetchBookmarks(designationId);

      runInAction("get bookmarks", () => {

        //clear bookmarks befor assigning new ones to make sure not coming from cache
        this._bookmarksRegistry.clear();

        response.data.forEach((bookmark: IBookmarkResponse) => {
          this._bookmarksRegistry.set(bookmark.sectionId, bookmark);
        });
      });
    } catch (error) {
      runInAction("get bookmarks error", () => {
        ShopErrorHandler(SYSTEM_ERRORS.BOOKMARK_ERROR);
      });
    }
  };

  //delete bookmark will only be called from the parent
  //this deletes a bookmark via api then updates the iframe
  @action deleteBookmark = async (sectionId: any) => {
    const bookmarkToRemove = this._bookmarksRegistry.get(sectionId);

    try {
      if (!bookmarkToRemove) {
        throw new Error(`Unable to find bookmark from given tableOfContentId ${sectionId}`);
      }

      this.removeBookmark(sectionId);

      const response = await deliveryService.deleteBookmark(bookmarkToRemove.bookmarkId);

      if (response.status !== 200) {
        throw "Delete bookmark failed";
      }
    } catch (error) {
      runInAction("delete bookmark API error", () => {
        // Restore the optimistically removed bookmark
        bookmarkToRemove ??
          this._bookmarksRegistry.set(bookmarkToRemove!.sectionId, bookmarkToRemove!);
        ShopErrorHandler(SYSTEM_ERRORS.BOOKMARK_ERROR);
      });
    }
  };

  @action createBookmark = async (designationId: string, sectionId: number) => {
    try {
      this.addOptimisticBookmark(sectionId);

      const created = await deliveryService.createBookmark(designationId, sectionId);

      runInAction("create bookmark API", () => {
        // Remove optimistic and insert the actually created bookmark
        this.removeBookmark(sectionId);
        this._bookmarksRegistry.set(sectionId, created.data);
      });
    } catch (error) {
      runInAction("create bookmark error", () => {
        // remove optimistically added bookmark - restore original
        this.removeBookmark(sectionId);
        ShopErrorHandler(SYSTEM_ERRORS.BOOKMARK_ERROR);
      });
    }
  };

  @action createBookmarkComment = async (sectionId: number, content: string) => {
    const bookmarkToUpdate = this._bookmarksRegistry.get(sectionId);

    await createMarkerComment({
      marker: bookmarkToUpdate,
      markerId: bookmarkToUpdate?.bookmarkId,
      markerType: "bookmark",
      commentContent: content,
    });
  };

  @action updateBookmarkComment = async (sectionId: number, commentId: number, content: string) => {
    const bookmarkToUpdate = this._bookmarksRegistry.get(sectionId);
    updateMarkerComment({ marker: bookmarkToUpdate, markerType: "bookmark", commentId, commentContent: content });
  };

  @action deleteBookmarkComment = async (sectionId: number, commentId: number) => {
    const bookmarkToUpdate = this._bookmarksRegistry.get(sectionId);
    deleteMarkerComment({ marker: bookmarkToUpdate, markerType: "bookmark", commentId });
  };

  @action addOptimisticBookmark = (sectionId: any) => {
    runInAction("add optimistic bookmark", () => {
      const bookmark = {
        bookmarkId: 0,
        version: "",
        sectionId: sectionId,
        sectionKey: "",
        isArchived: false,
        sectionTitle: "",
        // tableOfContent: {
        //   key: "",
        //   tableOfContentId: sectionId,
        //   value: "",
        //   version: ''
        // },
        optimistic: true,
        comments: []
      };
      this._bookmarksRegistry.set(sectionId, bookmark);
    });
  };

  @action removeBookmark = (sectionId: number) => {
    runInAction("remove either existing or optimistic bookmark", () => {
      this._bookmarksRegistry.delete(sectionId);
    });
  };

  @computed({ keepAlive: true })
  get bookmarks() {
    return Array.from(this._bookmarksRegistry.values());
  }
}

export default createContext(new BookmarksStore());
