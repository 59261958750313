import styled from "styled-components";
import STYLE_DEFAULTS from "../../../../constants/styles";
import { MEDIA_QUERIES } from "../../../../constants/mediaQueries";

export const DeleteMarkerTitle = styled.div`
  font-size: 21px;
  font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.MEDIUM};
  text-align: left;
  padding-bottom: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_5};

  @media ${MEDIA_QUERIES.SM} {
    padding-bottom: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_3};
  }
`;

export const DeleteMarkerBody = styled.div`
  text-align: left;
`;
