import styled, { css } from "styled-components";
import STYLE_DEFAULTS from "./../constants/styles";
import { onHoverStateTransition } from "./global";

interface IProps {
  color?: string;
}

export const H1_HELVETICA_NEUE = styled.h1`
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.HELVETICA_NEUE};
  font-size: 3rem;
  font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.LIGHT};
  text-rendering: optimizeLegibility;
  color: ${STYLE_DEFAULTS.COLORS.SA_BLACK_B100};
  line-height: ${STYLE_DEFAULTS.FONTS.SA_FONT_LINE_HEIGHT.HIGHER};
`;

export const H2_HELVETICA_NEUE = styled.h2`
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.HELVETICA_NEUE_REGULAR};
  font-size: 2rem;
  font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.NORMAL};
  text-rendering: optimizeLegibility;
  color: ${STYLE_DEFAULTS.COLORS.SA_BLACK_B100};
  line-height: ${STYLE_DEFAULTS.FONTS.SA_FONT_LINE_HEIGHT.HIGHER};
`;

export const H3_HELVETICA_NEUE = styled.h3`
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.HELVETICA_NEUE_REGULAR};
  font-size: 1.5em;
  font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.NORMAL};
  text-rendering: optimizeLegibility;
  color: ${STYLE_DEFAULTS.COLORS.SA_BLACK_B100};
  line-height: ${STYLE_DEFAULTS.FONTS.SA_FONT_LINE_HEIGHT.HIGHER};
`;

export const H4_HELVETICA_NEUE = styled.h4`
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.HELVETICA_NEUE_MEDIUM};
  font-size: 1.125em;
  text-rendering: optimizeLegibility;
  color: ${STYLE_DEFAULTS.COLORS.SA_BLACK_B100};
  line-height: ${STYLE_DEFAULTS.FONTS.SA_FONT_LINE_HEIGHT.HIGHER};
`;

export const H5_HELVETICA_NEUE = styled.h5`
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.HELVETICA_NEUE};
  font-size: ${STYLE_DEFAULTS.FONTS.SA_FONT_SIZE.SMALL};
  font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.MEDIUM};
  text-rendering: optimizeLegibility;
  color: ${STYLE_DEFAULTS.COLORS.SA_BLACK_B100};
  line-height: ${STYLE_DEFAULTS.FONTS.SA_FONT_LINE_HEIGHT.HIGHER};
`;

export const H6_HELVETICA_NEUE = styled.h6`
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.HELVETICA_NEUE};
  font-size: ${STYLE_DEFAULTS.FONTS.SA_FONT_SIZE.SMALLER};
  font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.MEDIUM};
  text-rendering: optimizeLegibility;
  color: ${STYLE_DEFAULTS.COLORS.SA_BLACK_B100};
  line-height: ${STYLE_DEFAULTS.FONTS.SA_FONT_LINE_HEIGHT.HIGHER};
`;

export const H1_NOTO_SANS = styled.h1`
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.NOTO_SANS};
  font-size: 3em;
  font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.NORMAL};
  color: ${STYLE_DEFAULTS.COLORS.SA_BLACK_B100};
  line-height: ${STYLE_DEFAULTS.FONTS.SA_FONT_LINE_HEIGHT.HIGHER};
`;

export const H2_NOTO_SANS = styled.h2`
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.NOTO_SANS};
  font-size: 2em;
  font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.MEDIUM};
  color: ${STYLE_DEFAULTS.COLORS.SA_BLACK_B100};
  line-height: ${STYLE_DEFAULTS.FONTS.SA_FONT_LINE_HEIGHT.HIGHER};
`;

export const H3_NOTO_SANS = styled.h3`
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.NOTO_SANS};
  font-size: 1.5em;
  font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.MEDIUM};
  color: ${STYLE_DEFAULTS.COLORS.SA_BLACK_B100};
  line-height: ${STYLE_DEFAULTS.FONTS.SA_FONT_LINE_HEIGHT.HIGHER};
`;

export const H4_NOTO_SANS = styled.h4`
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.NOTO_SANS};
  font-size: 1.125em;
  font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.MEDIUM};
  color: ${STYLE_DEFAULTS.COLORS.SA_BLACK_B100};
  line-height: ${STYLE_DEFAULTS.FONTS.SA_FONT_LINE_HEIGHT.HIGHER};
`;

export const H5_NOTO_SANS = styled.h5`
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.NOTO_SANS};
  font-size: ${STYLE_DEFAULTS.FONTS.SA_FONT_SIZE.SMALL};
  font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.MEDIUM};
  color: ${STYLE_DEFAULTS.COLORS.SA_BLACK_B100};
  line-height: ${STYLE_DEFAULTS.FONTS.SA_FONT_LINE_HEIGHT.HIGHER};
`;

export const H6_NOTO_SANS = styled.h6`
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.NOTO_SANS};
  font-size: ${STYLE_DEFAULTS.FONTS.SA_FONT_SIZE.SMALLER};
  font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.MEDIUM};
  color: ${STYLE_DEFAULTS.COLORS.SA_BLACK_B100};
  line-height: ${STYLE_DEFAULTS.FONTS.SA_FONT_LINE_HEIGHT.HIGHER};
`;

export const P_REGULAR = styled.p`
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.NOTO_SANS};
  font-size: ${STYLE_DEFAULTS.FONTS.SA_FONT_SIZE.NORMAL};
  font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.NORMAL};
  color: ${STYLE_DEFAULTS.COLORS.SA_BLACK_B100};
  line-height: ${STYLE_DEFAULTS.FONTS.SA_FONT_LINE_HEIGHT.HIGHER};
`;

export const P_LARGE = styled.p`
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.NOTO_SANS};
  font-size: ${STYLE_DEFAULTS.FONTS.SA_FONT_SIZE.LARGE};
  font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.NORMAL};
  color: ${STYLE_DEFAULTS.COLORS.SA_BLACK_B100};
  line-height: ${STYLE_DEFAULTS.FONTS.SA_FONT_LINE_HEIGHT.HIGHER};
`;

export const P_SMALL = styled.p`
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.NOTO_SANS};
  font-size: ${STYLE_DEFAULTS.FONTS.SA_FONT_SIZE.SMALL};
  font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.NORMAL};
  color: ${STYLE_DEFAULTS.COLORS.SA_BLACK_B100};
  line-height: ${STYLE_DEFAULTS.FONTS.SA_FONT_LINE_HEIGHT.HIGHER};
`;

export const P_SMALLER = styled.p`
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.NOTO_SANS};
  font-size: ${STYLE_DEFAULTS.FONTS.SA_FONT_SIZE.SMALLER};
  font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.NORMAL};
  color: ${STYLE_DEFAULTS.COLORS.SA_BLACK_B100};
  line-height: ${STYLE_DEFAULTS.FONTS.SA_FONT_LINE_HEIGHT.HIGHER};
`;

export const BREADCRUMB = styled.a`
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.HELVETICA_NEUE_MEDIUM};
  font-size: 11px; // only shown on desktop
  font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.NORMAL};
  letter-spacing: 2px;
  color: ${STYLE_DEFAULTS.COLORS.SA_BLACK_B100};
  line-height: 1;
  text-transform: uppercase;
  padding-top: 3px;

  & a {
    color: ${STYLE_DEFAULTS.COLORS.SA_BLACK_B100};
    text-decoration: none;
  }
`;

export const InputLabel = styled.div`
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.NOTO_SANS};
  font-size: 14px; // same on desktop and mobile
  font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.NORMAL};
  line-height: 19px; // same on desktop and mobile
`;

export const SelectorHeader = styled.div`
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.NOTO_SANS};
  font-size: ${STYLE_DEFAULTS.FONTS.SA_FONT_SIZE.SMALL};
  font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.NORMAL};
  color: ${STYLE_DEFAULTS.COLORS.SA_BLACK_B100};
  line-height: 1;
`;

export const DropdownTitle = styled.div`
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.NOTO_SANS};
  font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.NORMAL};
  font-size: 14px; // same in desktop and mobile
  color: ${STYLE_DEFAULTS.COLORS.SA_B070};
`;

export const ErrorContainer = styled.div`
  background: ${STYLE_DEFAULTS.COLORS.SA_B005};
  overflow-x: hidden;
  overflow-wrap: break-word;
`;

export const InFocus = css`
  border: 2px solid #6aa3f9;
  -webkit-box-shadow: 0px 1px 3px 0px rgba(106, 163, 249, 0.8);
  -moz-box-shadow: 0px 1px 3px 0px rgba(106, 163, 249, 0.8);
  box-shadow: 0px 1px 3px 0px rgba(106, 163, 249, 0.8);
  /* prevent default focus state on the same element */
  outline: none;
  ${onHoverStateTransition};
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${STYLE_DEFAULTS.COLORS.SA_B015};
`;

export const VerticalDivider = styled.div`
  width: 1px;
  background-color: ${(props: IProps) => props.color ?? STYLE_DEFAULTS.COLORS.SA_B070};
`;

export const BUTTON_VALUE_CLASS_NAME = "button__value--color";

export const setButtonElementsColor = (color: string) => {
  return `
      .${BUTTON_VALUE_CLASS_NAME} {
          color: ${color};
      }
  
      svg g,
      svg rect,
      svg path {
          fill: ${color};
      }
    `;
};

export const NavyLinkCss = css`
  color: ${STYLE_DEFAULTS.COLORS.SA_NAVY};
  text-decoration: none;

  &.in-focus,
  :hover {
    text-decoration: underline;
    color: ${STYLE_DEFAULTS.COLORS.SA_INVERTED_DIVIDER};
    text-decoration-color: ${STYLE_DEFAULTS.COLORS.SA_INVERTED_DIVIDER};
    ${onHoverStateTransition};
  }

  :focus,
  :active {
    text-decoration: underline;
    color: ${STYLE_DEFAULTS.COLORS.SA_NAVY_B010};
    text-decoration-color: ${STYLE_DEFAULTS.COLORS.SA_NAVY_B010};
    ${onHoverStateTransition};
  }

  :focus {
    ${InFocus}
  }

  :visited {
    color: ${STYLE_DEFAULTS.COLORS.SA_BLUE_B015};
  }
`;

export const NavyLink = styled.a`
  ${NavyLinkCss}
`;
