import { useEffect } from "react";

/**
 * Custom hook that detects clicks outside of the passed ref (search bar)
 * to allow closing search results dropdown
 */
export function OutsideClickDetector(ref: any, setDropdownContainerOpen: any, event?: string) {
  /**
   * Close results dropdown if user clicks outside the search bar
   */
  function handleClickOutside(event: any) {
    if (ref.current && !ref.current.contains(event.target)) {
      setTimeout(() => setDropdownContainerOpen(false), 0);
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener(event ?? "mouseup", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener(event ?? "mouseup", handleClickOutside);
    };
  });
}
