import React from "react";
//import { useNavigate } from "react-router-dom";
import { ConnectionErrorMessageContainer, ConnectionErrorMessageTitle, ConnectionErrorMessageMessage } from "./style";
import Button from "../controls/button/Button";
import { SHOP_MENU_ITEMS, SHOP_MENU_ITEMS_INFO } from "../../constants/shopMenuItems";

const ConnectionErrorMessage: React.FC = () => {
  //let navigate = useNavigate();
  return (
    <ConnectionErrorMessageContainer>
      <ConnectionErrorMessageTitle>Connection timeout</ConnectionErrorMessageTitle>
      <ConnectionErrorMessageMessage>
        Our server is taking too long to respond. Please refresh the page or try again later.
      </ConnectionErrorMessageMessage>
      {/* <Button onClick={() => 
        //navigate(`${SHOP_MENU_ITEMS_INFO[SHOP_MENU_ITEMS.HOME].path}`)
        console.log('TODO')
      } value="Refresh page" primary /> */}
    </ConnectionErrorMessageContainer>
  );
};

export default ConnectionErrorMessage;
