import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import ALERT_TYPE from "../../../../constants/alerts";
import OfflineStoreContext, { IError } from "../../../../stores/offlineLayoutStore";
import UserStore from "../../../../stores/userStore";
import ReaderLayoutContext, { IReaderLayoutContext } from "../readerLayoutContext";
import Alert from "../../../alerts/Alert";
import LinkButton from "../../../controls/linkButton/LinkButton";
import SideMenuContentHeader from "./sideMenuContentHeader";
import {
  SideContentContainer,
  SideMenuOfflineContainer,
  SideMenuOfflineDesc,
  SideMenuOfflineDesignation,
  SideMenuOfflineDownloadingState,
  SideMenuOfflineDownloadingText,
  SideMenuOfflineDownloadingLoaderContainer,
  SideMenuOfflineDownloadedState,
  ModalContent,
  SideMenuOfflineLastSyncText,
  SideMenuOfflineLastSyncDate,
  PopoverOptionText,
  PopoverOption,
  SidePanelLoader,
} from "./style";
import { LOADING_STATUS } from "../../../../constants/loadingStatus";
import Loader from "../../../loader/loader";
import STYLE_DEFAULTS from "../../../../constants/styles";
import ICONS from "../../../../constants/icons";
import Icon from "../../../icons";
import GenericPopover from "../../../controls/popover/GenericPopover";
import { Detector } from "react-detect-offline";
import DeleteOfflineDocumentModal from "./DeleteOfflineDocumentModal";
import { GenericInfoModal } from "../../../alerts/GenericModal";
import BUTTONS_TYPES from "../../../../constants/buttons";
import { useBreakpoint } from "../../../../services/breakpointProvider/BreakpointProvider";
import { MEDIA_QUERIES_NAMES } from "../../../../constants/mediaQueries";
import utils from "../../../../utils/utils";

const renderNotDownloadState = (
  downloadDocumentForOffline: (
    designation: string | undefined,
    token: string | undefined,
    sectionsToBeFetched: number[],
  ) => void,
  designation: string | undefined,
  token: string | undefined,
  sectionsToBeFetched: number[],
) => {
  return (
    <>
      <SideMenuOfflineDesc>This file is available for download to your browser.</SideMenuOfflineDesc>
      {/* <LinkButton
        text="Download to browser"
        onClick={() => downloadDocumentForOffline(designation, token, sectionsToBeFetched)} 
      />*/}
    </>
  );
};

const renderOfflinePreparingState = () => {
  return (
    <>
      <SideMenuOfflineDesc>This file is available for download to your browser.</SideMenuOfflineDesc>
      <SideMenuOfflineDownloadingState>
        <SideMenuOfflineDownloadingLoaderContainer>
          <Loader color={STYLE_DEFAULTS.COLORS.SA_ORANGE_ACTIVE} height="16px" width="16px" />
        </SideMenuOfflineDownloadingLoaderContainer>
        <SideMenuOfflineDownloadingText>Loading content</SideMenuOfflineDownloadingText>
      </SideMenuOfflineDownloadingState>
    </>
  );
};

const renderDownloadingState = (designation: string | undefined) => {
  return (
    <>
      <SideMenuOfflineDesignation>{designation}</SideMenuOfflineDesignation>
      <SideMenuOfflineDownloadingState>
        <SideMenuOfflineDownloadingLoaderContainer>
          <Loader color={STYLE_DEFAULTS.COLORS.SA_ORANGE_ACTIVE} height="16px" width="16px" />
        </SideMenuOfflineDownloadingLoaderContainer>
        <SideMenuOfflineDownloadingText>Downloading offline content</SideMenuOfflineDownloadingText>
      </SideMenuOfflineDownloadingState>
    </>
  );
};

const renderDownloadedState = (
  designation: string | undefined,
  isDeletePopoverOpen: boolean,
  setDeletePopoverOpen: (isOpen: boolean) => void,
  showDeleteOfflineConfirmation: boolean,
  setDeleteOfflineConfirmation: any,
  isOffline: boolean,
  lastSyncedTime: string | undefined,
) => {
  return (
    <div>
      <SideMenuOfflineDesignation>
        {designation}
        <div>
          <GenericPopover
            backgroundColor={STYLE_DEFAULTS.COLORS.SA_WHITE_B000}
            arrow={true}
            arrowColor={STYLE_DEFAULTS.COLORS.SA_WHITE_B000}
            isOpen={isDeletePopoverOpen && !showDeleteOfflineConfirmation}
            onClickOutside={() => setDeletePopoverOpen(false)}
            content={
              <PopoverOption
                isDisabled={isOffline}
                onClick={() => {
                  setDeletePopoverOpen(false);
                  setDeleteOfflineConfirmation(true);
                }}
              >
                <Icon
                  disabled={isOffline}
                  icon={ICONS.BIN}
                  tiny
                  size="20px"
                  internalSize="20px"
                  clickable
                  color={isOffline ? STYLE_DEFAULTS.COLORS.SA_DISABLED : STYLE_DEFAULTS.COLORS.SA_RED}
                />
                <PopoverOptionText isDisabled={isOffline}>Remove from browser</PopoverOptionText>
              </PopoverOption>
            }
          >
            <div onClick={() => setDeletePopoverOpen(true)}>
              <Icon icon={ICONS.MORE_VERT} tiny clickable />
            </div>
          </GenericPopover>
        </div>
      </SideMenuOfflineDesignation>
      <SideMenuOfflineDownloadedState>
        <Icon icon={ICONS.TICK_CIRCLE} tiny clickable color={STYLE_DEFAULTS.COLORS.SA_GREEN} />
        <SideMenuOfflineLastSyncText>Last Sync:</SideMenuOfflineLastSyncText>
        <SideMenuOfflineLastSyncDate>{lastSyncedTime}</SideMenuOfflineLastSyncDate>
      </SideMenuOfflineDownloadedState>
    </div>
  );
};

const renderLoader = () => {
  return (
    <SidePanelLoader>
      <Loader />
    </SidePanelLoader>
  );
};

const renderDownloadingErrorDialog = (
  isMobile: boolean,
  showErrorDialog: boolean,
  setShowErrorDialog: any,
  resetDownloadingError: any,
  downloadDocumentForOffline: (
    designation: string | undefined,
    token: string | undefined,
    sectionsToBeFetched: number[],
  ) => void,
  designation: string | undefined,
  token: string | undefined,
  sectionsToBeFetched: number[],
  downloadingError: IError | undefined,
) => {
  return (
    <GenericInfoModal
      title={downloadingError?.title}
      mobileButtonsFullWidth={isMobile}
      isOpen={showErrorDialog}
      setOpen={setShowErrorDialog}
      hasCloseIcon
      shadow
      acceptButtonType={BUTTONS_TYPES.PRIMARY}
      acceptValue="Retry"
      textAlign="center"
      top="60px"
      onAccept={async () => {
        setShowErrorDialog(false);
        resetDownloadingError();
        downloadDocumentForOffline(designation, token, sectionsToBeFetched);
      }}
      onCancel={() => {
        setShowErrorDialog(false);
        resetDownloadingError();
      }}
    >
      <ModalContent>{downloadingError?.content}</ModalContent>
    </GenericInfoModal>
  );
};

enum CacheState {
  UNINTIALISED = 0,
  CHECKING,
  NOT_CACHED,
  CACHED,
}

const SideMenuOffline: React.FC = observer(() => {
  const breakpoints: any = useBreakpoint();
  const isMobile: boolean = breakpoints[MEDIA_QUERIES_NAMES.STOREFRONT_MD];
  const offlineStoreContext = useContext(OfflineStoreContext);
  const readerContext = useContext<IReaderLayoutContext>(ReaderLayoutContext);
  const authContext = useContext(UserStore);
  const { readerStore } = readerContext;
  const { contentLoadingStatus } = readerStore;
  const {
    downloadDocumentForOffline,
    downloadingStatus,
    availableCaches,
    checkCache,
    deleteCache,
    lastSyncedTime,
    calculateLastUpdatedTime,
    resetDownloadingError,
    downloadingError,
  } = offlineStoreContext;
  const [cacheState, setCachedState] = useState(CacheState.UNINTIALISED);
  const [isDeletePopoverOpen, setDeletePopoverOpen] = useState(false);
  const [showDeleteOfflineConfirmation, setDeleteOfflineConfirmation] = useState(false);
  const [showDownloadErrorDialog, setDownloadErrorDialog] = useState(false);

  useEffect(() => {
    if (cacheState === CacheState.UNINTIALISED) {
      setCachedState(CacheState.CHECKING);
      checkCache(readerStore.DesignationId);
    }
    // prettier-ignore
    if (availableCaches && readerStore.DesignationId && availableCaches.includes(utils.getDesignationUrl(readerStore.DesignationId))) {
      setCachedState(CacheState.CACHED);
      calculateLastUpdatedTime(readerStore.DesignationId);
    } else {
      // No cache available for this designation.
      setCachedState(CacheState.NOT_CACHED);
    }
  }, [availableCaches]);

  useEffect(() => {
    if (downloadingStatus === LOADING_STATUS.ERROR) {
      setDownloadErrorDialog(true);
    }
  }, [downloadingStatus]);

  function renderOfflineState(online: boolean) {
    // prettier-ignore
    console.log("cacheState : " + cacheState + ", contentLoadingStatus : " + contentLoadingStatus + ", downloadingStatus : " + downloadingStatus);
    // If cache is not yet checked or checking let's show the loading.
    if (contentLoadingStatus !== LOADING_STATUS.DONE && cacheState === CacheState.NOT_CACHED) {
      // If content is still loading and document is not saved we need to show that content is still loading and available to save once its done.
      return renderOfflinePreparingState();
    } else if (cacheState === CacheState.CACHED) {
      // If document is already cached we need to show that state.
      return renderDownloadedState(
        readerStore.DesignationId,
        isDeletePopoverOpen,
        setDeletePopoverOpen,
        showDeleteOfflineConfirmation,
        setDeleteOfflineConfirmation,
        !online,
        lastSyncedTime,
      );
    } else if (downloadingStatus === LOADING_STATUS.LOADING) {
      return renderDownloadingState(readerStore.DesignationId);
    } else {
      return renderNotDownloadState(
        downloadDocumentForOffline,
        readerStore.DesignationId,
        authContext.token,
        readerStore.sectionsFetched,
      );
    }
  }

  return (
    <>
      {showDownloadErrorDialog &&
        renderDownloadingErrorDialog(
          isMobile,
          showDownloadErrorDialog,
          setDownloadErrorDialog,
          resetDownloadingError,
          downloadDocumentForOffline,
          readerStore.DesignationId,
          authContext.token,
          readerStore.sectionsFetched,
          downloadingError,
        )}
      {showDeleteOfflineConfirmation && (
        <DeleteOfflineDocumentModal
          isOpen={true}
          onAccept={() => {
            setDeleteOfflineConfirmation(false);
            deleteCache(readerStore.DesignationId);
          }}
          onCancel={() => setDeleteOfflineConfirmation(false)}
          setOpen={setDeleteOfflineConfirmation}
        />
      )}
      <Detector
        polling={false}
        render={({ online }) => (
          <SideContentContainer>
            <SideMenuContentHeader title={"Offline viewing"} />
            <SideMenuOfflineContainer>
              {cacheState === CacheState.UNINTIALISED || cacheState === CacheState.CHECKING ? (
                renderLoader()
              ) : (
                <div>
                  {renderOfflineState(online)}
                  <div style={{ marginTop: "32px" }}>
                    <Alert
                      type={ALERT_TYPE.INFO}
                      message="Any file downloaded to your browser will expire after 30 days from when it was last synced.<br/><br/>Please note that you cannot create or delete bookmarks, highlights or comments in offline mode."
                    />
                  </div>
                </div>
              )}
            </SideMenuOfflineContainer>
          </SideContentContainer>
        )}
      />
    </>
  );
});

export default SideMenuOffline;
