import React from "react";
import ALERT_TYPE from "../../../../constants/alerts";
import ICONS from "../../../../constants/icons";
import Alert from "../../../alerts/Alert";
import { OfflineAlertContainer } from "./style";

export const OfflineHeader: React.FC<any> = (props: any) => {
  return (
    <OfflineAlertContainer>
      <Alert
        icon={ICONS.VIEW}
        type={ALERT_TYPE.NEUTRAL}
        title="You are in offline mode"
        message="You are not connected to the internet. Bookmarking, highlighting and commenting are disabled in offline mode."
        onClose={() => {
          props.setShowOfflineHeader(false);
        }}
      />
    </OfflineAlertContainer>
  );
};
