import React, { Fragment, useContext } from "react";
import _ from "lodash";
import {
  NoMarkerContainer,
  NoMarkerHeader,
  NoMarkerBody,
  MarkerContainer,
  MarkerHeader,
  MarkerBody,
  MarkerOptions,
  MarkerOptionsIcon,
  MarkerContents,
  MarkerCommentsContainer,
  MarkerCommentsCount,
  MarkerCommentsWrapper,
  MarkerOptionsText,
  MarkerOptionsArrow,
  MarkeSubheader,
  ArchivedInfo
} from "./style";
import { HeaderSideMenuUnderline } from "../../style";
import ICONS from "../../../../../../constants/icons";
import Icon from "../../../../../icons";
import DeleteMarkerModal from "../../../../controls/DeleteMarkerModal/DeleteMarkerModal";
import { ScrollIntoViewViaIdOrClassName } from "../../../../../../helpers/ScrollIntoView";
import ReaderLayoutStore from "../../../../../../stores/readerLayoutStore";
import { observer } from "mobx-react-lite";
import { runInAction } from "mobx";
import STYLE_DEFAULTS from "../../../../../../constants/styles";
import CONTENT_SEARCH_DEFAULTS from "../../../../../../constants/contentSearch";
import { Detector } from "react-detect-offline";

// sort markers via their order of children i.e. "1.2.3.4" vs "1.2"
export const sortMarkers = (a: any, b: any) => {
  let i, diff;
  const regExStrip0 = /(\.0+)+$/;
  const segmentsA = a.order.replace(regExStrip0, "").split(".");
  const segmentsB = b.order.replace(regExStrip0, "").split(".");
  const l = Math.min(segmentsA.length, segmentsB.length);

  for (i = 0; i < l; i++) {
    diff = parseInt(segmentsA[i], 10) - parseInt(segmentsB[i], 10);
    if (diff) {
      return diff;
    }
  }
  return segmentsA.length - segmentsB.length;
};

export const findSectionViaId = (toc: any, sectionId: any) => {
  for (let i = 0; i < toc?.length; i++) {
    const section = toc[i];
    // reset
    section.subOrder = undefined;
    let tmp: any = {};
    tmp = findSectionViaId(section.children, sectionId);
    if (tmp) {
      tmp.subOrder = section.order + "." + (tmp.subOrder ?? tmp.order);

      return tmp as any;
    }
    if (section.tableOfContentId === sectionId) return section;
  }
  return null;
};

export const findSectionViaKey = (toc: any, key: any) => {
  for (let i = 0; i < toc?.length; i++) {
    const section = toc[i];
    // reset
    section.subOrder = undefined;
    let tmp: any = {};
    tmp = findSectionViaKey(section.children, key);
    if (tmp) {
      tmp.subOrder = section.order + "." + (tmp.subOrder ?? tmp.order);
      return tmp as any;
    }
    if (section.key === key) return section;
  }
  return null;
};

const onScrollClick = (key: any, SetIsNavigating: any, readerContext: any, navigationKey?: string) => {
  runInAction(() => {
    !readerContext.isMobile && SetIsNavigating(true);
  });
  // add extra 40px top for comment box
  ScrollIntoViewViaIdOrClassName(key, CONTENT_SEARCH_DEFAULTS.READER_CONTENT, 40);
  if (navigationKey) {
    // Because highlight can exist in the bottom section of a particular section, we need to first scroll to the section and then to the actual highlight.
    setTimeout(() => {
      // add extra 40px top for comment box
      ScrollIntoViewViaIdOrClassName(navigationKey, CONTENT_SEARCH_DEFAULTS.READER_CONTENT, 40);
    }, 200);
  }
  setTimeout(() => {
    runInAction(() => {
      !readerContext.isMobile && SetIsNavigating(false);
    });
  }, 0);
  if (readerContext.isMobile) {
    readerContext.setSideMenuOpen(false);
  }
};

export const NoMarkers: React.FC<any> = (props: any) => {
  const { title, body } = props;
  return (
    <NoMarkerContainer>
      <NoMarkerHeader>{title}</NoMarkerHeader>
      <NoMarkerBody>{body}</NoMarkerBody>
    </NoMarkerContainer>
  );
};

const renderBody = (props: any) => {
  const {
    index,
    header,
    subHeader,
    body,
    bodyHighlight,
    onClick,
    onDeleteClick,
    showOptions,
    setShowOptions,
    commentsCount,
    onCommentsBadgeClick,
    isOffline,
    isArchived
  } = props;

  const handleOnDeleteClick = () => {
    setShowOptions(0);
    onDeleteClick(index);
  };

  return (
    <div key={index}>
      <MarkerContainer>
        <MarkerContents onClick={onClick}>
          {header && (
            <MarkerHeader>
              {header}
              {subHeader && <MarkeSubheader>{subHeader}</MarkeSubheader>}
            </MarkerHeader>
          )}

          <MarkerBody highlight={bodyHighlight}>{body}</MarkerBody>
        </MarkerContents>
        {isArchived && (
          <MarkerOptionsIcon>
            <Icon icon={ICONS.INFO} tiny onClick={() => {setShowOptions(!showOptions ? index : 0)} }/>
            {showOptions && showOptions === index ? (
              <>
                <ArchivedInfo isDisabled={true}>
                  <MarkerOptionsText isDisabled={true}>The archived version represents a copy of the standard prior to its amendment.  This includes users&apos; highlights and/or comments, bookmarks. Click on the archived link in the My Markers section to go to the previous highlights and/or comments, bookmarks in a new tab.</MarkerOptionsText>
                </ArchivedInfo>
                <MarkerOptionsArrow />
              </>
            ) : null}
          </MarkerOptionsIcon>
        )}
        {!isArchived && (
          <MarkerOptionsIcon>
          <Icon icon={ICONS.MORE_VERT} tiny clickable onClick={() => setShowOptions(!showOptions ? index : 0)} />
          {showOptions && showOptions === index ? (
            <>
              <MarkerOptions isDisabled={isOffline} onClick={() => !isOffline && handleOnDeleteClick()}>
                <Icon
                  icon={ICONS.BIN}
                  tiny
                  disabled={isOffline}
                  size="20px"
                  internalSize="20px"
                  clickable
                  color={isOffline ? STYLE_DEFAULTS.COLORS.SA_DISABLED : STYLE_DEFAULTS.COLORS.SA_RED}
                />
                <MarkerOptionsText isDisabled={isOffline}>Delete</MarkerOptionsText>
              </MarkerOptions>
              <MarkerOptionsArrow />
            </>
          ) : null}
        </MarkerOptionsIcon>
        )}
      </MarkerContainer>
      {commentsCount > 0 && (
        <MarkerCommentsWrapper>
          <MarkerCommentsContainer onClick={onCommentsBadgeClick}>
            <Icon icon={ICONS.COMMENT_FILLED} tiny clickable color={STYLE_DEFAULTS.COLORS.SA_B070} />
            <MarkerCommentsCount>{commentsCount}</MarkerCommentsCount>
          </MarkerCommentsContainer>
        </MarkerCommentsWrapper>
      )}
      <HeaderSideMenuUnderline />
    </div>
  );
};

const onClick = (props: any) => {
  const { marker, section, ReaderLayoutStoreContext, readerContext } = props;
  const {
    SetIsNavigating,
    SetBookmarkCommentsInFocusId,
    SetBookmarkInFocusId,
    SetHighlightCommentsInFocusId,
    SetHighlightInFocusId,
  } = ReaderLayoutStoreContext;

  if (marker.isArchived){
    window.open(`${window.location.href}?version=${marker.version}` + '', "_blank");
  }

  marker.key &&
    onScrollClick(
      section === "highlight" || marker.isBookmarkComment === false
        ? "section-" + marker.sectionId
        : marker.navigationKey,
      SetIsNavigating,
      readerContext,
      marker.navigationKey,
    );

  // indicate marker clicked on side panel
  if (section === "highlight") {
    SetHighlightInFocusId(marker.key);
  } else if (section === "bookmark") {
    SetBookmarkInFocusId(marker.key);
  } else {
    // Comment
    if (marker.isBookmarkComment) {
      SetBookmarkCommentsInFocusId(marker.parentKey);
    } else {
      SetHighlightCommentsInFocusId(marker.parentKey);
    }
  }
};

const Markers: React.FC<any> = React.forwardRef((props: any, ref: any) => {
  const {
    section,
    markers,
    readerContext,
    noMarkerBody,
    onDeleteAccept,
    showMarkerOptions,
    setShowMarkerOptions,
    isMarkerDeleteOpen,
    setIsMarkerDeleteOpen,
  } = props;
  const ReaderLayoutStoreContext = useContext(ReaderLayoutStore);
  const { SetBookmarkCommentsInFocusId, SetHighlightCommentsInFocusId } = ReaderLayoutStoreContext;

  if (!markers || markers.length === 0) {
    return <NoMarkers title={`No ${section}s yet`} body={noMarkerBody} />;
  }

  return (
    <Detector
      polling={ false }
      render={({ online }) => (
        <div ref={ref}>
          {markers.map((marker: any, index: number) => {
            if(marker){
              return (
                <Fragment key={index}>
                  {renderBody({
                    key: index,
                    index: marker.key,
                    header: marker.header,
                    subHeader: marker.subHeader,
                    body: marker.body,
                    isArchived: marker.isArchived,
                    bodyHighlight: marker.bodyHighlight,
                    showOptions: showMarkerOptions,
                    setShowOptions: setShowMarkerOptions,
                    commentsCount: marker.commentsCount,
                    onCommentsBadgeClick: () => {
                      // indicate marker for which comment badge is clicked on side panel
                      if (section === "highlight") {
                        SetHighlightCommentsInFocusId(marker.key);
                      } else if (section === "bookmark") {
                        SetBookmarkCommentsInFocusId(marker.key);
                      }
                    },
                    onClick: () => onClick({ marker, section, ReaderLayoutStoreContext, readerContext }),
                    onDeleteClick: () => {
                      marker.key && marker.onDeleteClick(marker.key);
                      setIsMarkerDeleteOpen(marker.key);
                    },
                    isOffline: !online,
                  })}
                  <DeleteMarkerModal
                    section={section}
                    isOpen={isMarkerDeleteOpen && isMarkerDeleteOpen === marker.key}
                    commentsCount={marker.commentsCount}
                    onAccept={() => {
                      setIsMarkerDeleteOpen(0);
                      onDeleteAccept();
                    }}
                    onCancel={() => setIsMarkerDeleteOpen(0)}
                    showCommentsDeleteAlert
                  />
                </Fragment>
              );
            }
          })}
        </div>
      )}
    />
  );
});

Markers.displayName = "Markers";

export default Markers;
