import React from "react";
import { MainSectionContainer } from "./style";
import SideMenu from "../sideMenu/sideMenu";
import DocumentView from "../documentView/DocumentView";
import ReaderLayoutContext from "./../readerLayoutContext";

const MainSection: React.FC = () => {
  return (
    <MainSectionContainer>
      <ReaderLayoutContext.Consumer>{(value) => <SideMenu readerLayoutContext={value} />}</ReaderLayoutContext.Consumer>
      <DocumentView />
    </MainSectionContainer>
  );
};

export default React.memo(MainSection);
