import styled from "styled-components";
import STYLE_DEFAULTS from "../../../../constants/styles";

interface IProps {
  isMobile?: boolean;
  isMenuItemActive?: boolean;
  isSideMenu?: boolean;
}

export const ReaderMenuIconContainer = styled.div`
  position: relative;
  width: ${(props: IProps) => (props.isMobile ? "48px" : "60px")};
  height: ${(props: IProps) => (props.isMobile ? "48px" : "60px")};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const ActiveIndicator = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  visibility: ${(props: IProps) => (props.isMenuItemActive ? "visible" : "hidden")};
  background-color: ${STYLE_DEFAULTS.COLORS.SA_ORANGE_ACTIVE};

  ${(props: IProps) => {
    let style = "";

    if (props.isSideMenu) {
      style += `
        width: 2px;
        height: 100%;
        `;
    } else {
      style += `
        width: 100%;
        height: 2px;
        `;
    }

    return style;
  }}
`;
