import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import { observer } from "mobx-react-lite";
import STYLE_DEFAULTS from "../../../constants/styles";
import {
  markExistingHighlights,
  textSelectOrHighlight,
  onHighlightSelect,
  isDescendant,
  onCancelTextSelect,
  DEFAULT_SECTION_ID,
  onHighlightSelectCancel,
  SELECTION_HIGHLIGHT_STYLE,
  addHighlightCommentsBadgeListener,
  removeHighlightCommentsBadgeListener,
  resetSectionHtml,
  onBookmarkSelectCancel,
  selectHighlight,
  selectHighlightCancel,
  getMobileOperatingSystem,
} from "./helpers/helpers";
import CommentModal from "./comments/CommentModal";
import COMMENT_MODAL_STATE from "../../../constants/comments";
import IHighlightMark from "../../../models/highlightMark";
import ReaderLayoutContext, { IReaderLayoutContext } from "../layout/readerLayoutContext";
import ReaderContentContext, { IReaderContentContext } from "./readerContentContext";
import BookmarksStore from "../../../stores/bookmarkStore";
import HighlightsStore from "../../../stores/highlightStore";
import UserStore from "../../../stores/userStore";
import SectionBookmarkContainer from "./partials/renderSectionBookmarkContainer";
import DeleteMarkerModal from "../controls/DeleteMarkerModal/DeleteMarkerModal";
import ReaderLayoutStore from "../../../stores/readerLayoutStore";
import { Detector } from "react-detect-offline";
import getBrowser from "../../../helpers/browser";

/**
 * onMouseDownEvent is responsible for actions initiated on mouse down:
 *
 * - start highlighting
 * - cancel the highlight selection if user clicks outside the highlight selection
 * - cancel selection of bookmark action if user has bookmark toolbox open and clicks outside it
 *
 * @param props
 */
const onMouseDownEvent = (props: any) => {
  const { event, currentContentState, isOffline } = props;
  const {
    setMouseDownPosition,
    isMobile,
    highlightToolboxOpenId,
    commentModalState,
    sectionBookmarkToolboxId,
    sectionId,
  } = currentContentState;

  // This is the starting point of highlighting, among other operations.
  // Position is used in calculating the direction of highlighting: left to right, top to bottom or the opposite
  setMouseDownPosition({
    left: event.pageX,
    top: event.pageY,
  });

  if (event.target.hasAttribute("data-highlight-start-index")) {
    onHighlightSelect(currentContentState, event, isOffline);
  }

  if (isMobile && event.target.classList.contains(SELECTION_HIGHLIGHT_STYLE.SELECTED)) {
    // User has to touch the text after dragging selection handles on mobile - do not cancel selection
    return;
  }

  // Cancel the highlight selection if user clicks outside, but only if comments modal is not open
  if (highlightToolboxOpenId && commentModalState === COMMENT_MODAL_STATE.CLOSED) {
    const selectedHighlight = document.getElementById(`highlight-${highlightToolboxOpenId}`);

    if (!isDescendant(selectedHighlight, event.target)) {
      // User clicked outside the selected highlight - cancel highlight selection
      onHighlightSelectCancel(currentContentState, event);
    }
  }

  // Hide bookmark toolbox if user clicks outside of it, but not on comment modal or
  // bookmark badge - handled separately
  if (sectionBookmarkToolboxId && commentModalState === COMMENT_MODAL_STATE.CLOSED) {
    const bookmarkToolbox = document.getElementById(`bookmark-toolbox`);
    const bookmarkBadge = document.getElementById(`bookmark-badge-${sectionId}`);

    // Will be null if modal is closed
    const bookmarkDeleteModal = document.getElementById(`generic-modal`);

    if (
      !bookmarkDeleteModal &&
      !isDescendant(bookmarkToolbox, event.target) &&
      !isDescendant(bookmarkBadge, event.target)
    ) {
      // User clicked outside the open bookmark toolbox - cancel bookmark selection
      onBookmarkSelectCancel(currentContentState, event);
    }
  }

  if (!isDescendant(document.getElementById("text-select-popover"), event.target)) {
    // User clicked outside the selection popover - cancel selection
    onCancelTextSelect();
  }
};

/**
 * Handle rendering of one publication section, handle operations such as
 * highlighting bookmarking and commenting
 */
const ContentSection: React.FC<any> = observer((props: any) => {
  const t0 = performance.now();
  const { section } = props.params;
  const sectionId = section.id;

  const context = useContext<IReaderContentContext>(ReaderContentContext);
  const {
    highlightToolboxOpenId,
    setHighlightToolboxOpenId,

    sectionBookmarkToolboxId,
    setSectionBookmarkToolboxId,

    commentModalState,
    setCommentModalState,

    inFocusSection,
    setInFocusSection,
  } = context;
  const ReaderLayoutStoreContext = useContext(ReaderLayoutStore);

  // Indicate hovering over a section (any section)
  // TODO: check if possible to eliminate this one
  const [inFocus, setInFocus] = useState(false);

  // Track mouse down location to determine direction of text select
  const [mouseDownPosition, setMouseDownPosition] = useState({
    left: 0,
    top: 0,
  });

  const [bookmarkCommentsSectionId, setBookmarkCommentsSectionId] = useState(DEFAULT_SECTION_ID);

  const { HighlightInFocusId } = ReaderLayoutStoreContext;

  const { setIsReaderLoading, isMobile, readerStore, isPreview } = useContext<IReaderLayoutContext>(ReaderLayoutContext);
  const authContext = useContext(UserStore);
  const userAuthId = authContext.authId;
  const { DesignationId } = readerStore;

  const { bookmarkModeActive, bookmarks, deleteBookmark, createBookmark } = useContext(BookmarksStore);
  const HighlightsStoreContext = useContext(HighlightsStore);
  const {
    highlightModeActive,
    highlights,
    deleteHighlight,
    createHighlight,
    getHighlightsBySectionId,
  } = HighlightsStoreContext;

  const sectionBookmark = _.find(bookmarks, (bookmark: any) => bookmark.sectionId === sectionId);
  const isSectionBookmarked = sectionBookmark != null;
  const shouldHighlightBookmarkMode =
    isSectionBookmarked || (bookmarkModeActive && inFocus && sectionId === inFocusSection);
  const shouldShowBookmarkBadge = isSectionBookmarked || isMobile || shouldHighlightBookmarkMode;

  const backgroundColor = isSectionBookmarked
    ? STYLE_DEFAULTS.COLORS.SA_BLUE_LIGHT_1
    : shouldHighlightBookmarkMode && !isMobile
    ? STYLE_DEFAULTS.COLORS.SA_B015
    : STYLE_DEFAULTS.COLORS.SA_WHITE_B000;

  const [isHighlightDeleteConfirmOpen, setIsHighlightDeleteConfirmOpen] = useState(false);
  const selectedHighlight = _.find(
    highlights,
    (highlight: IHighlightMark) => highlight.highlightId === highlightToolboxOpenId,
  );

  const currentContentState = {
    sectionHtml: section.partialHtmlBody,
    highlightModeActive,
    designationId: DesignationId,
    userAuthId,
    sectionId,
    highlights,
    createHighlight,
    isSectionBookmarked,
    isMobile,
    isPreview,
    backgroundColor,
    createBookmark,
    shouldShowBookmarkBadge,
    lastSubSection: !section.subSections || !section.subSections.length,
    sectionBookmarkToolboxId,
    setSectionBookmarkToolboxId,
    commentModalState,
    setCommentModalState,
    mouseDownPosition,
    setMouseDownPosition,
    highlightToolboxOpenId,
    setHighlightToolboxOpenId,
    setIsHighlightDeleteConfirmOpen,
    getHighlightsBySectionId,
    setBookmarkCommentsSectionId,
    onDeleteBookmarkAccept: async () => {
      if (sectionBookmark) {
        setIsReaderLoading(true);
        await deleteBookmark(sectionBookmarkToolboxId);
        setIsReaderLoading(false);
        setSectionBookmarkToolboxId(DEFAULT_SECTION_ID);
        setInFocusSection(DEFAULT_SECTION_ID);
        setInFocus(false);
      }
    },
    onDeleteHighlightAccept: async () => {
      setIsReaderLoading(true);
      await deleteHighlight(highlightToolboxOpenId);
      setIsReaderLoading(false);
      setHighlightToolboxOpenId(0);
      setIsHighlightDeleteConfirmOpen(false);
    },
    bookmarkComments: sectionBookmark ? sectionBookmark.comments : [],
    ReaderLayoutStoreContext,
  };

  useEffect(() => {
    const sectionHighlights = getHighlightsBySectionId(sectionId);

    if (sectionHighlights?.length) {
      markExistingHighlights({
        sectionHtml: section.partialHtmlBody,
        sectionHighlights,
        sectionId,
        isMobile,
        highlightInFocusId: ReaderLayoutStoreContext.HighlightCommentsInFocusId,
      });

      addHighlightCommentsBadgeListener({
        sectionHighlights,
        setHighlightToolboxOpenId,
        setCommentModalState,
      });

      return () => {
        removeHighlightCommentsBadgeListener({
          sectionHighlights,
          setHighlightToolboxOpenId,
          setCommentModalState,
        });
      };
    } else {
      // In case *last* (not latest) removed, highlight is still visible, remove it
      resetSectionHtml(sectionId, section.partialHtmlBody);
    }
  }, [highlights, ReaderLayoutStoreContext.HighlightCommentsInFocusId]);

  useEffect(() => {
    if (HighlightInFocusId) {
      const inFocusHighlight = _.find(
        highlights,
        (highlight: IHighlightMark) => highlight.highlightId === HighlightInFocusId,
      );

      if (inFocusHighlight?.sectionId === sectionId) {
        selectHighlight(HighlightInFocusId);
      }
    } else {
      selectHighlightCancel();
    }
  }, [HighlightInFocusId]);

  const t1 = performance.now();
  let selectionEndTimeout: any = null;

  return (
    <Detector
      polling={false}
      render={({ online }) => (
        <div>
          <DeleteMarkerModal
            section="highlight"
            commentsCount={selectedHighlight?.comments?.length || 0}
            isOpen={isHighlightDeleteConfirmOpen}
            onAccept={currentContentState.onDeleteHighlightAccept}
            onCancel={() => setIsHighlightDeleteConfirmOpen(false)}
          />

          <div
            style={{ position: "relative" }}
            onMouseEnter={(event: any) => {
              if (bookmarkModeActive) {
                setInFocusSection(sectionId);
                setInFocus(true);
              }
            }}
            onMouseLeave={(event: any) => {
              if (bookmarkModeActive) {
                setInFocusSection(DEFAULT_SECTION_ID);
                setInFocus(false);
              }
            }}
            onMouseDown={(event: any) => onMouseDownEvent({ event, currentContentState, isOffline: !online })}
            onMouseUp={(event: any) => {
              ReaderLayoutStoreContext.ResetFocusState();
              textSelectOrHighlight(currentContentState, event.pageX, event.pageY, !online);
            }}
            onTouchEnd={(event: any) => {
              // Check actual OS, not screen width
              const mobileOs = getMobileOperatingSystem();
              const browser = getBrowser();

              // Different devices and browsers require different approach to highlighting,
              // modify behavior based on device/browser type
              if (mobileOs !== "unknown" && browser === "Mozilla Firefox") {
                // Some browsers do not handle onTouchEnd well - Firefox on Android does not
                // allow text selection when TouchEnd is detected, works well with onMouseUp only
                // other mobile browsers require both onMouseUp and TouchEnd for correct operation
                return;
              } else if (mobileOs === "iOS") {
                // Some device fire onTouchEnd immediately when user releases the touch, give user a chance
                // to adjust selection - timer is reset while dragging selection
                if (selectionEndTimeout) {
                  clearTimeout(selectionEndTimeout);
                }

                selectionEndTimeout = setTimeout(function () {
                  ReaderLayoutStoreContext.ResetFocusState();
                  textSelectOrHighlight(currentContentState, event.pageX, event.pageY, !online);
                }, 1000);
              } else {
                ReaderLayoutStoreContext.ResetFocusState();
                textSelectOrHighlight(currentContentState, event.pageX, event.pageY, !online);
              }
            }}
          >
            <CommentModal
              comments={
                selectedHighlight?.highlightId
                  ? selectedHighlight?.comments ?? null
                  : currentContentState.bookmarkComments
              }
              isHighlightComments={selectedHighlight?.highlightId !== undefined}
              highlightId={selectedHighlight?.highlightId ? selectedHighlight.highlightId : null}
              modalStateOnOpen={
                (selectedHighlight?.highlightId && selectedHighlight.sectionId === sectionId) ||
                bookmarkCommentsSectionId === sectionId
                  ? commentModalState
                  : COMMENT_MODAL_STATE.CLOSED
              }
              onClose={() => {
                setBookmarkCommentsSectionId(DEFAULT_SECTION_ID);
                setCommentModalState(COMMENT_MODAL_STATE.CLOSED);
              }}
              isMobile={isMobile}
              sectionId={sectionId}
            />
            <SectionBookmarkContainer {...currentContentState} />
          </div>
        </div>
      )}
    />
  );
});

export default ContentSection;
