import { useContext, useEffect, useState } from 'react';
import { AuthenticatedTemplate, useMsal, useIsAuthenticated } from "@azure/msal-react";
import { EventType, InteractionStatus } from "@azure/msal-browser";
import { loginRequest, msalInstance, passwordRequest } from './authConfig';
import _ from "lodash";
import App from 'App';
import React from 'react';
import UserStore from 'stores/userStore';
import { useLocation } from 'react-router-dom';
import env from "./models/Configuration";
import { useIdleTimer } from 'react-idle-timer';

const LoginB2C = () => {

  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress, accounts } = useMsal();
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const userContext = useContext(UserStore);
  const location = useLocation();

  const account = accounts[0];

  const timeout = (env?.app?.sessionTimeoutInMinute ?? 30) * 60 * 1000;

  const handleOnIdle = () => {
    handleLogout();
  }

  const idleTimer = useIdleTimer({
    timeout,
    //onAction: handleOnAction,
    onIdle: handleOnIdle
  });

  useEffect(() => {

    if (window.location.href.indexOf('activateAccount') >= 0) {
      if (!isPasswordReset) {
        passwordRequest.state = window.location.search;
      }
      setIsPasswordReset(true);
    }

    if (inProgress === InteractionStatus.None && !isAuthenticated) {
      isPasswordReset ? instance.loginRedirect(passwordRequest) : instance.loginRedirect(loginRequest);
    }
  }, [inProgress])

  useEffect(() => {
    const callbackId = instance.addEventCallback((message: any) => {

      if (message.eventType === EventType.LOGIN_SUCCESS) {
        setIsUserLoggedIn(true);
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    }
  }, []);

  const handleLogout = async () => {
    msalInstance.logoutRedirect({ postLogoutRedirectUri: location.pathname });
  }

  useEffect(() => {
    if(isAuthenticated) {
      setIsUserLoggedIn(true);
      initUser();
    }
  }, [isAuthenticated]);


  const initUser = () => {

    let user: any = Object;

    let accountName = account?.name;
    let accountIdTokenClaims : any = account?.idTokenClaims;
    if(accountIdTokenClaims && (accountIdTokenClaims['given_name'] || accountIdTokenClaims['family_name'])){
             user.firstName = accountIdTokenClaims['given_name'];
             user.lastName =  accountIdTokenClaims['family_name'];
    } else if(accountName) {
        let accountNames = accountName.split(' ');
        user.firstName = accountNames[0];
        user.lastName = accountNames[0];
    } else {
        user.firstName = "";
        user.lastName = "";
    }
    user.userId = account.username;
    user.userInitials = user.firstName.substring(0, 1) + user.lastName.substring(0, 1);
    userContext.InitialiseUser(user);
  }

  return (
    <AuthenticatedTemplate>
      <App isAuthenticated={isUserLoggedIn} isAuthorised = {true} onlogout={handleLogout} />
    </AuthenticatedTemplate>
  );
}

export default LoginB2C;

