import React from "react";
import * as Styled from "./style";

// State Management
import { useAppDispatch } from "hooks/useAppDispatch";

// Components
import Button from "components/controls/button/Button";
import { DividerHr } from "components/styledComponents/hr";
import { closeModal } from "state/slices/customModalSlice";
import broadcastChannelService, { BROADCAST_CHANNEL, BROADCAST_CHANNEL_MESSAGES } from "services/broadcastChannelService";

const LogOut = () => {
    const dispatch = useAppDispatch();
    const channel = new broadcastChannelService(BROADCAST_CHANNEL.AUTHENTICATION);
    const handleClickOnLogout = async () => {
        channel.postMessage(BROADCAST_CHANNEL_MESSAGES.LOG_OUT);
        dispatch(closeModal());
    }

    const handleClickOnCancel = () => {
        dispatch(closeModal());
    }

    return (
        <Styled.LogOutContainer>
            <p>Are you sure you want to logout?</p>
            <DividerHr></DividerHr>
            <Styled.LogOutFooter>
                <Styled.LogOutButtonContainer>
                    <Button primary onClick={handleClickOnLogout} value="Logout" />
                </Styled.LogOutButtonContainer>
                <Styled.CancelButtonContainer>
                    <Button secondary onClick={handleClickOnCancel} value="Cancel" />
                </Styled.CancelButtonContainer>
            </Styled.LogOutFooter>
        </Styled.LogOutContainer>
    )
}

export default LogOut;