import styled from "styled-components";
import STYLE_DEFAULTS from "../../../constants/styles";

export const CounterBadgeContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  background-color: ${STYLE_DEFAULTS.COLORS.SA_RED};
  border-radius: 50%;
`;

export const CounterBadgeValue = styled.div`
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.NOTO_SANS};
  font-size: 10px; // Same for desktop and mobile
  font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.MEDIUM};
  color: ${STYLE_DEFAULTS.COLORS.SA_WHITE_B000};
`;

export const FeaturedBadgeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: -moz-linear-gradient(26deg, #00172e 0%, #002f5f 100%);
  background: -webkit-linear-gradient(26deg, #00172e 0%, #002f5f 100%);
  background: linear-gradient(26deg, #00172e 0%, #002f5f 100%);
  width: 85px;
  box-sizing: border-box;
  opacity: 95%;
  padding: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_1} 6px;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
`;

export const FeaturedBadgeValue = styled.div`
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.HELVETICA_NEUE_MEDIUM};
  font-size: 11px; // Same for desktop and mobile
  font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.MEDIUM};
  letter-spacing: 2px;
  line-height: 1;
  color: ${STYLE_DEFAULTS.COLORS.SA_WHITE_B000};
  text-transform: uppercase;
`;
