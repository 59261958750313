//MODIFIED_FILE
import React, { Fragment, useContext, useState } from "react";
import {
  MobileClosedMenuContainer,
  MobileClosedMenuHeader,
  MobileClosedRightMenuContainer,
  MobileClosedLeftMenuContainer,
  PreviewIcon,
} from "./style";
import Icon from "../../../../../icons";
import ICONS from "../../../../../../constants/icons";
import ReaderHeaderControls from "../ReaderHeaderControls";
import { NavigateFunction, RouteProps } from "react-router-dom";
import ReaderLayoutContext, { IReaderLayoutContext } from "./../../../readerLayoutContext";
import { observer } from "mobx-react-lite";
import ReaderSearchBar from "../../readerSearch/readerSearchBar";
import TRACKING_EVENTS from "../../../../../../services/segmentProvider/models/trackingEvents";
import { useNavigate } from "react-router-dom";
import IBreadcrumb from "../../../../../../models/breadcrumb";
import BreadcrumbsStore from "../../../../../../stores/breadcrumbsStore";
import { goBackFromReader, isAdmin } from "../../../../../../helpers/reader";
import { Detector } from "react-detect-offline";
import CallToAction from "../../callToAction/CallToAction";

const renderClosedMenu = (
  value: IReaderLayoutContext,
  setIsMobileSearchOpen: any,
  userInitials: string,
  breadcrumbs: Array<IBreadcrumb>,
  navigate: NavigateFunction
) => {

  return (
    <Detector
      polling={false}
      render={({ online }) => (
        <MobileClosedMenuContainer>
          <MobileClosedLeftMenuContainer>
            {value.showBackButton &&
              <div onClick={() => goBackFromReader(breadcrumbs, navigate)}>
                <Icon small internalSize="20px" icon={ICONS.CHEVRON_LEFT} clickable disabled={!online} />
              </div>
            }
            <MobileClosedMenuHeader
              onClick={() => {
                value.setSelectedMenuIndex(0);
                // Delay making menu visible to allow animation on menu open
                //setTimeout(() => {
                value.setSideMenuOpen(true);
                //}, 50);
              }}
            >
              <Icon small internalSize="20px" icon={ICONS.TOC} clickable />
            </MobileClosedMenuHeader>
          </MobileClosedLeftMenuContainer>
          <ReaderHeaderControls isDisabled={value.isPreview || !online || isAdmin()} isMobile />
          <MobileClosedRightMenuContainer>
            <Icon
              small
              internalSize="20px"
              icon={ICONS.SEARCH}
              onClick={() => setIsMobileSearchOpen(true)}
              clickable
            />
            {value.readerStore.CallToActionButton.isEnabled && value.isPreview ? (
              <PreviewIcon disabled={!online}>
                <Icon
                  small
                  internalSize="20px"
                  disabled={!online}
                  icon={value.readerStore.CallToActionButton.title === 'Buy now' ? ICONS.CART_ADD : ICONS.INFO}
                  onClick={() =>
                    navigate(value.readerStore.CallToActionButton.url)
                  }
                />
              </PreviewIcon>
            ) : (
              <CallToAction />
            )}
          </MobileClosedRightMenuContainer>
        </MobileClosedMenuContainer>
      )}
    />
  );
};

const MobileMenu: React.FC<RouteProps> = observer((props: RouteProps) => {
  const [isMobileSearchOpen, setIsMobileSearchOpen] = useState(false);
  const BreadcrumbsStoreContext = useContext(BreadcrumbsStore);
  const { breadcrumbs } = BreadcrumbsStoreContext;
  let navigate = useNavigate();
  return (
    <ReaderLayoutContext.Consumer>
      {(value) => (
        <Fragment>
          {isMobileSearchOpen && <ReaderSearchBar isMobile setIsMobileSearchOpen={setIsMobileSearchOpen} />}
          {renderClosedMenu(value, setIsMobileSearchOpen, value.userInitials, breadcrumbs, navigate)}
        </Fragment>
      )}
    </ReaderLayoutContext.Consumer>
  );
});
export default MobileMenu;
