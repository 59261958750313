import React, { useContext, useState } from "react";
import { observer } from "mobx-react-lite";
import Interweave from 'interweave';
import ReaderLayoutContext, { IReaderLayoutContext } from "../readerLayoutContext";
import { LOADING_STATUS } from "../../../../constants/loadingStatus";
import Loader from "../../../../components/loader/loader";
import {
  SideContentContainer,
  SideMenuUnderline,
  TocSection,
  TocSubSection,
  TocExpand,
  TocTitle,
  SidePanelLoader,
  TocDocSchema,
  TocDocDesignation,
  TocDocTitle,
  SideMenuTocContainer,
} from "./style";
import { Collapse } from "react-collapse";
import { ReactComponent as Down } from "./../../../../assets/icons/chevron_down.svg";
import { ReactComponent as Up } from "./../../../../assets/icons/chevron_up.svg";
import { ReactComponent as NoView } from "./../../../../assets/icons/no_view.svg";
import { ScrollIntoViewViaIdOrClassName, ScrollToTop } from "../../../../helpers/ScrollIntoView";
import CONTENT_SEARCH_DEFAULTS from "../../../../constants/contentSearch";
import DocTypeLabel from "../../../controls/badges/DocTypeLabel";
import StatusLabel from "../../../controls/statusLabel/StatusLabel";
import SideMenuContentHeader from "./sideMenuContentHeader";
import ReaderLayoutStore from "../../../../stores/readerLayoutStore";
import { runInAction } from "mobx";

const TocIcon: React.FC<any> = (props: any) => {
  const { isOpen, isDisabled } = props;
  if (isDisabled === true) {
    return (
      <div>
        <NoView />
        &nbsp;&nbsp;
        <Down />
      </div>
    );
  }
  if (isOpen === false) {
    return <Down />;
  }
  return <Up />;
};

const CloseMobileMenu = function (context: any) {
  if (context.isMobile) {
    context.setSideMenuOpen(false);
  }
};

const ScrollIntoView = function (id: any, context: any) {
  CloseMobileMenu(context);
  ScrollIntoViewViaIdOrClassName(id, CONTENT_SEARCH_DEFAULTS.READER_CONTENT, 40);
};

const RenderSection: React.FC<any> = observer((props: any) => {
  const { section, isSubTitle } = props;
  const context = useContext<IReaderLayoutContext>(ReaderLayoutContext);
  const { SetIsNavigating } = useContext(ReaderLayoutStore);
  const [isOpen, setIsOpen] = useState(false);
  const isDisabled = section.isDisabled && context.isPreview;
  const hasChildren = section.children?.length > 0;
  let TocIconElement = null;
  let disabledClass = {};
  if (hasChildren) {
    TocIconElement = (
      <TocExpand onClick={() => setIsOpen(!isOpen)} isDisabled={isDisabled}>
        <TocIcon isOpen={isOpen} isDisabled={isDisabled} />
      </TocExpand>
    );
  } else if (isDisabled) {
    TocIconElement = <NoView />;
  }
  if (isDisabled) {
    disabledClass = { className: "disabled" };
  }

  return (
    <div>
      <TocSection isDisabled={isDisabled}>
        <TocTitle
          isSubTitle={isSubTitle}
          isDisabled={isDisabled}
          onClick={() => {
            runInAction(() => {
              !context.isMobile && SetIsNavigating(true);
            });
            ScrollIntoView(section.key, context);
            setTimeout(() => {
              runInAction(() => {
                !context.isMobile && SetIsNavigating(false);
              });
            }, 0);
          }}
        >
          <div {...disabledClass}>{section.value}</div>
        </TocTitle>
        {TocIconElement}
      </TocSection>
      <SideMenuUnderline />
      {!isDisabled && (
        <TocSubSection>
          {hasChildren && (
            <Collapse isOpened={isOpen}>
              {isOpen && //without isOpen here the entire children will be rendered, only load when open
                section.children?.map((item: any) => (
                  <RenderSection key={"sub_toc_" + item.key} section={item} isSubTitle />
                ))}
            </Collapse>
          )}
        </TocSubSection>
      )}
    </div>
  );
});

const SideMenuToc: React.FC = observer(() => {
  const context = useContext<IReaderLayoutContext>(ReaderLayoutContext);
  const store = context.readerStore;
  const toc = store.Toc;
  /* eslint-disable no-fallthrough */
  switch (store.Toc.status) {
    /*Content loading*/
    case LOADING_STATUS.LOADING:
      return (
        <SidePanelLoader>
          <Loader />
        </SidePanelLoader>
      );
    /*Error retrieving content */
    case LOADING_STATUS.ERROR:
    //TODO: return error handler
    /*No Content found */
    case LOADING_STATUS.EMPTY:
    //TODO: return empty handler
    /*Content ready to display */ 
    case LOADING_STATUS.READY:
    default:
      return (
        <SideContentContainer>
          <SideMenuContentHeader title={"Table of contents"} hasUnderline>
            {!context.isMobile && (
              <TocDocSchema>
                <DocTypeLabel docTypeName={toc.content?.schema?.docType ?? "Standard"} />
                <div style={{ paddingTop: "12px" }}>
                  <TocDocDesignation onClick={() => ScrollToTop(CONTENT_SEARCH_DEFAULTS.READER_CONTENT)}>
                    {toc.content?.schema?.designation}
                  </TocDocDesignation>
                  <StatusLabel label={toc.content?.schema?.status && `[${toc.content?.schema?.status}]`} />
                </div>
                <TocDocTitle>
                  <Interweave content={toc.content?.schema?.title} />
                </TocDocTitle>
              </TocDocSchema>
            )}
          </SideMenuContentHeader>
          <SideMenuTocContainer>
            <br />
            {toc.content?.tableOfContents
              ?.filter((item: any) => item.value !== null && item.value.trim().length > 0) // To filter out any TOC which doesn't have a value or having a empty value.
              ?.map((item: any) => {
                if (item.key && !item.key.endsWith("sts_header")) {
                  return <RenderSection key={item.key} section={item} />;
                }
              })}
          </SideMenuTocContainer>
        </SideContentContainer>
      );
  }
  /* eslint-enable no-fallthrough */
});

export default SideMenuToc;
