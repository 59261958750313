import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import {
  SideMenuPanelCloseButton,
  SideContentHeading,
  SideMenuContentHeaderContainer,
  HeaderSideMenuUnderline,
} from "./style";
import Icon from "../../../icons";
import ICONS from "../../../../constants/icons";
import { onSidePanelClose } from "./sideMenu";
import ReaderLayoutContext, { IReaderLayoutContext } from "../readerLayoutContext";

const SideMenuContentHeader: React.FC<any> = observer((props: any) => {
  const {children, title, hasUnderline } = props;
  const context = useContext<IReaderLayoutContext>(ReaderLayoutContext);

  return (
    <SideMenuContentHeaderContainer>
      <SideMenuPanelCloseButton>
        <Icon
          icon={ICONS.CLOSE}
          small
          clickable
          onClick={() => {
            onSidePanelClose(context);
          }}
        />
      </SideMenuPanelCloseButton>
      <SideContentHeading>{title}</SideContentHeading>
      {children}
      {hasUnderline && <HeaderSideMenuUnderline />}
    </SideMenuContentHeaderContainer>
  );
});

export default SideMenuContentHeader;
