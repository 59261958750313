import React from "react";
import DynamicBadge from "./private/DynamicBadge";
import ICONS from "../../../constants/icons";
import STYLE_DEFAULTS from "../../../constants/styles";

interface IDocTypeLabel {
  docTypeName: string;
  icon?: ICONS;
}

const DocTypeLabel = (props: IDocTypeLabel) => {
  const { docTypeName, icon } = props;

  return (
    <DynamicBadge
      value={docTypeName}
      icon={icon || ICONS.DOCTYPE}
      height="20px"
      paddingRight={STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_1}
      paddingLeft={STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_1}
      background={STYLE_DEFAULTS.COLORS.SA_B015}
      letterSpacing="0.5px"
    />
  );
};

export default DocTypeLabel;
