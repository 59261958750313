import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';

const LogOutPage = () => {
  const auth = useAuth();
  useEffect(() => {
      auth.removeUser();
  }, []);

  return (
    <div className='center'>
      User is logged out
    </div>
  )
}
export default LogOutPage;

