import _ from "lodash";
import FILTER_ITEMS from "../constants/exploreStandardsFilters";

function add(array: any[], value: any) {
  if (array.indexOf(value) === -1) {
    array.push(value);
  }
}

const toggle = async (
  item: any,
  refine: Function,
  refinedValues: any[],
  setRefinedValues: Function,
  setQuery?: any,
) => {
  // For some reason, inconsistently, receive string instead of list of strings - fix it
  // This happens when selecting items from different refinement lists
  if (typeof item.value === "string") {
    add(refinedValues, item.value);
  } else {
    // update `selected filter values` on toggle
    refinedValues = [];
    item.value.forEach((value: any) => add(refinedValues, value));
  }
  const queryValue = refinedValues.join("_").replace(/\s|\//g, "");
  if (setQuery) await setQuery(queryValue);
  // Update local search state to use new values
  setRefinedValues(refinedValues);

  refine(item.value);
};

const checkQueryValue = (
  items: any,
  queryValue: any,
  refine: Function,
  refinedValues: any[],
  setRefinedValues: Function,
) => {
  if (!items || items.length === 0 || !refinedValues) return;
  const queryList = queryValue.split("_");
  items.forEach((item: any) => {
    queryList.forEach((queryItem: any) => {
      if (
        item.label.replace(/\s|\//g, "") === queryItem &&
        refinedValues.filter((e: any) => e.replace(/\s|\//g, "") == queryItem).length === 0
      ) {
        toggle(item, refine, refinedValues, setRefinedValues);
      }
    });
  });
};

const buildRefinementsList = (
  items: any[],
  refine: Function,
  refinedValues: any[],
  setRefinedValues: Function,
  setQuery: any,
  getQueryValue: any,
) => {
  const sortedItems = _.sortBy(items, (item: any) => item.count).reverse();
  if (getQueryValue) {
    checkQueryValue(sortedItems, getQueryValue, refine, refinedValues, setRefinedValues);
  }
  const listItems = sortedItems.map((item: any) => {
    return {
      label: item.label,
      miscLabel: `(${item.count})`,
      checked: item.isRefined,
      onChange: () => toggle(item, refine, refinedValues, setRefinedValues, setQuery),
    };
  });

  return listItems;
};

const getStandardSectorUrlByName = function getUrlByName(filter: string) {
  const item = FILTER_ITEMS.find(
    (item) => item.options?.some((i: { name: string }) => i.name == filter) || item.name == filter,
  );

  if (!item) {
    return;
  }

  if (item?.name == filter) {
    return item.url;
  }

  return item?.options?.find((i) => i.name == filter).url;
};

const getStandardSectorNameByUrl = function getNameByUrl(filter: string) {
  const item = FILTER_ITEMS.find(
    (item) => item.options?.some((i: { url: string }) => i.url == filter) || item.url == filter,
  );

  if (!item) {
    return;
  }

  if (item?.url == filter) {
    return item.name;
  }

  return item?.options?.find((i) => i.url == filter).name;
};

const getDesignationUrl = function getDesignationUrl(designationId: string | undefined) {
  if (!designationId) return "";
  const regex = /[^\w]/gi;
  return designationId?.replace(regex, "-")?.toLowerCase();
};

const utils = {
  toggle,
  buildRefinementsList,
  getStandardSectorUrlByName,
  getStandardSectorNameByUrl,
  getDesignationUrl,
};

export default utils;
