import styled from "styled-components";
import STYLE_DEFAULTS from "../../../constants/styles";
import { MEDIA_QUERIES } from "../../../constants/mediaQueries";
import { onHoverStateTransition } from "../../../styles/global";

interface IProps {
  headerPosition?: string;
  height?: string;
  width?: string;
  hasUnderline?: boolean;
  flexRow?: boolean;
  borderLeft?: boolean;
  tabHeaderBackgroundColor?: string;
  iconPadding?: string;
  headerHeight?: string;
  headerWidth?: string;
  scrollable?: boolean;
  isCentered?: boolean;
  disabled?: boolean;
  hasHeaderUnderline?: boolean;
}

export const ShopTabItemContainer = styled.div`
  ${(props: IProps) => (props.scrollable ? "overflow: auto;" : "")}
  height: ${(props: IProps) =>
    props.height || `calc(100vh - 170px); /* 170px is the height of eReader header + My markers section */`};
  ${(props: IProps) => (props.width ? `width: ${props.width};` : "")}
  background-color: ${STYLE_DEFAULTS.COLORS.SA_WHITE_B000};

  @media ${MEDIA_QUERIES.SM} {
    height: calc(100vh - 110px);
  }
`;

export const ShopTabFlexContainer = styled.div`
  display: flex;
  flex-direction: ${(props: IProps) => (props.flexRow ? "row" : "column")};
  ${(props: IProps) => (props.isCentered ? `align-items: center; height: 100%;` : "")}
`;

export const ShopTabTitle = styled.div`
  font-size: 24px; //only desktop
  text-align: center;
  padding-top: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_8};
  padding-bottom: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_8};
`;

export const ShopTabHeaderContainer = styled.div`
  display: flex;
  flex-direction: ${(props: IProps) => (props.flexRow ? "row" : "column")};
  justify-content: ${(props: IProps) => (props.headerPosition ? props.headerPosition : "center")};
  background-color: ${(props: IProps) => props.tabHeaderBackgroundColor ?? STYLE_DEFAULTS.COLORS.SA_WHITE_B000};
  ${(props: IProps) => (props.headerWidth ? `width: ${props.headerWidth};` : "")}
  :hover {
    cursor: pointer;
    ${onHoverStateTransition};
  }

  ${(props: IProps) => (props.hasHeaderUnderline ? `border-bottom: 1px solid ${STYLE_DEFAULTS.COLORS.SA_B010};` : "")}
`;

export const ShopTabHeader = styled.div`
  height: ${(props: IProps) => props.headerHeight ?? ""};
  display: inline-block;
  list-style: none;
  padding: 0.5rem 0.75rem;
  ${(props: IProps) => (props.hasUnderline ? "border-bottom: 1px solid #ccc;" : "")}
  ${(props: IProps) => (props.borderLeft ? `border-left: 2px solid ${STYLE_DEFAULTS.COLORS.SA_WHITE_B000};` : "")}

  ${(props: IProps) =>
    props.disabled
      ? `
      color: ${STYLE_DEFAULTS.COLORS.SA_DISABLED};
      
      & svg path {
        fill: ${STYLE_DEFAULTS.COLORS.SA_DISABLED};
        stroke: ${STYLE_DEFAULTS.COLORS.SA_DISABLED};
      }
    `
      : ""}
`;

export const ShopTabHeaderIcon = styled.div`
  ${(props: IProps) => (props.iconPadding ? `padding: ${props.iconPadding};` : "")}
`;

export const ShopTabHeaderActive = styled.div`
  height: ${(props: IProps) => props.headerHeight ?? ""};
  display: inline-block;
  list-style: none;
  padding: 0.5rem 0.75rem;
  background-color: ${STYLE_DEFAULTS.COLORS.SA_WHITE_B000};
  border-${(props: IProps) => (props.borderLeft ? "left" : "bottom")}: 1px solid ${
  STYLE_DEFAULTS.COLORS.SA_ORANGE_ACTIVE
};
`;

export const ShopTabItem = styled.div`
  padding: 4px;
`;
