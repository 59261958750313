import { observable, action, configure, runInAction, computed } from "mobx";
import { computedFn, keepAlive } from "mobx-utils";
import _ from "lodash";
import { createContext } from "react";
import { ShopErrorHandler } from "../components/error/ErrorBoundary";
import SYSTEM_ERRORS from "../constants/errors";
import deliveryService from "../apis/deliveryService";
import IHighlightMark, { IHighlightRequest } from "../models/highlightMark";
import { createMarkerComment, updateMarkerComment, deleteMarkerComment } from "./common/helpers";

configure({ enforceActions: "always" });

export class HighlightsStore {
  // Map allows to observe content changes better in our case
  @observable _highlightsRegistry: Map<number, IHighlightMark> = new Map();
  @observable highlightModeActive = false;

  @action toggleHighlightMode = async () => {
    runInAction("toggle highlight mode", () => {
      this.highlightModeActive = !this.highlightModeActive;
    });
  };

  @action getHighlights = async (designationId: string) => {
    try {
      const response = await deliveryService.fetchHighlights(designationId);

      runInAction("get highlights", () => {

        //clear highlights befor assigning new ones to make sure not coming from cache
        this._highlightsRegistry.clear();

        response.data.forEach((highlight: IHighlightMark) => {
          this._highlightsRegistry.set(highlight.highlightId, highlight);
        });
      });
    } catch (error) {
      runInAction("get highlights error", () => {
        ShopErrorHandler(SYSTEM_ERRORS.HIGHLIGHT_ERROR);
      });
    }
  };

  //create highlight will only be called from the iframe
  @action createHighlight = async (props: IHighlightRequest) => {
    if (!props.selectionPlainText) {
      // Safeguard against accidental highlight create
      return;
    }

    try {
      // Add new highlight optimistically
      this.addHighlight(props);

      const result = await deliveryService.createHighlight(props);

      // Adding new selection might require changing existing highlights
      runInAction("create highlight", () => {
        // Remove optimistic and insert the actually created bookmark

        this.removeHighlight(0);
        this._highlightsRegistry.set(result.data.highlightId, result.data);
      });
    } catch (error) {
      runInAction("create highlight error", () => {
        // Restore original highlights
        this.removeHighlight(0);
        ShopErrorHandler(SYSTEM_ERRORS.HIGHLIGHT_ERROR);
      });
    }
  };

  //delete highlight will only be called from the parent
  //this deletes a highlight via api then updates the iframe
  @action deleteHighlight = async (highlightId: number) => {
    const highlightToRemove = this._highlightsRegistry.get(highlightId);

    try {
      if (!highlightToRemove) {
        throw new Error(`Unable to find highlight from given highlightId ${highlightId}`);
      }

      // Optimistically remove highlight
      this.removeHighlight(highlightId);

      const response = await deliveryService.deleteHighlight(highlightId);

      if (response.status !== 200) {
        throw "Delete highlight failed";
      }
    } catch (error) {
      runInAction("delete highlight API error", () => {
        this._highlightsRegistry.set(highlightToRemove!.highlightId, highlightToRemove!);
        ShopErrorHandler(SYSTEM_ERRORS.HIGHLIGHT_ERROR);
      });
    }
  };

  @action createHighlightComment = async (highlightId: number, content: string) => {
    const highlightToUpdate = this._highlightsRegistry.get(highlightId);

    await createMarkerComment({
      marker: highlightToUpdate,
      markerId: highlightToUpdate?.highlightId,
      markerType: "highlight",
      commentContent: content,
    });

    runInAction("create comment: force deep observe on comments", () => {
      const id = highlightToUpdate!.highlightId;
      const updated = Object.assign({}, highlightToUpdate, {
        endOffset: highlightToUpdate!.endOffset,
      });
      this._highlightsRegistry.set(id, updated);
    });
  };

  @action updateHighlightComment = async (highlightId: number, editCommentId: number, content: string) => {
    const highlightToUpdate = this._highlightsRegistry.get(highlightId);
    await updateMarkerComment({
      marker: highlightToUpdate,
      markerType: "highlight",
      commentId: editCommentId,
      commentContent: content,
    });

    runInAction("update comment: force deep observe on comments", () => {
      const id = highlightToUpdate!.highlightId;
      const updated = Object.assign({}, highlightToUpdate, {
        endOffset: highlightToUpdate!.endOffset,
      });
      this._highlightsRegistry.set(id, updated);
    });
  };

  @action deleteHighlightComment = async (highlightId: number, commentId: number) => {
    const highlightToUpdate = this._highlightsRegistry.get(highlightId);
    await deleteMarkerComment({
      marker: highlightToUpdate,
      markerType: "highlight",
      commentId,
    });
    runInAction("delete comment: force deep observe on comments", () => {
      const id = highlightToUpdate!.highlightId;
      const updated = Object.assign({}, highlightToUpdate, {
        endOffset: highlightToUpdate!.endOffset,
      });
      this._highlightsRegistry.set(id, updated);
    });
  };

  @action removeHighlight = (highlightId: number) => {
    runInAction("remove either existing or optimistic highlight", () => {
      this._highlightsRegistry.delete(highlightId);
    });
  };

  @action addHighlight = (props: any) => {
    runInAction("add optimistic highlight", () => {
      const highlight = {
        highlightId: 0,
        sectionId: props.sectionId,
        startDataIndex: props.startDataIndex,
        startPreviousSiblingDataIndex: props.startPreviousSiblingDataIndex,
        startOffset: props.startOffset,
        endDataIndex: props.endDataIndex,
        endPreviousSiblingDataIndex: props.endPreviousSiblingDataIndex,
        endOffset: props.endOffset,
        selectionPlainText: props.selectionPlainText,
        isArchived: props.isArchived,
        version: props.version
      };

      this._highlightsRegistry.set(highlight.highlightId, highlight);
    });
  };

  getHighlightsBySectionId = computedFn(
    (sectionId: number) => {
      const sectionRegistry: Map<number, IHighlightMark> = new Map();
      this._highlightsRegistry.forEach((highlight: IHighlightMark) => {
        if (highlight.sectionId === sectionId) {
          sectionRegistry.set(highlight.highlightId, highlight);
        }
      });

      return Array.from(sectionRegistry.values());
    },
    {
      keepAlive: true,
    },
  );

  @computed({ keepAlive: true })
  get highlights() {
    return Array.from(this._highlightsRegistry.values());
  }
}

export default createContext(new HighlightsStore());
