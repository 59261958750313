import STYLE_DEFAULTS from "../../../constants/styles";
import styled from "styled-components";

interface IProps {
  backgroundColor?: string;
  width?: string;
}

export const PopoverContent = styled.div`
  border-radius: ${STYLE_DEFAULTS.BUTTONS.SA_BUTTON_RADIUS};
  -webkit-border-radius: ${STYLE_DEFAULTS.BUTTONS.SA_BUTTON_RADIUS};
  -moz-border-radius: ${STYLE_DEFAULTS.BUTTONS.SA_BUTTON_RADIUS};
  background-color: ${(props: IProps) =>
    props.backgroundColor ? props.backgroundColor : STYLE_DEFAULTS.COLORS.SA_B010};
  padding: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_2};
  color: ${STYLE_DEFAULTS.COLORS.SA_B070};
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.NOTO_SANS};
  font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.NORMAL};
  font-size: ${STYLE_DEFAULTS.FONTS.SA_FONT_SIZE.SMALLER};
  max-width: 320px;
  ${(props: IProps) => (props.width ? `width: ${props.width};` : "")}
  margin: 0 2px 2px 2px;
  -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
  opacity: 1;
`;
