import React, { useState, useEffect, Fragment, useRef } from "react";
import _ from "lodash";
import ResizeObserver from "resize-observer-polyfill";
import ContentSection from "./ContentSectionController";
import { PositionMarkers } from "../layout/documentView/PositionMarkers";
import ReaderContentContext, { IReaderContentContext } from "./readerContentContext";

/**
 * Parse sections HTML to be displayed, add pre-existing highlights and bookmarks for sections,
 * show bookmarks mode if active
 *
 * @param props
 */

const ContentController: React.FC<any> = (props: any) => {
  console.log("ContentController....");
  const { section } = props;

  // Track whether the content marker's like "amnd", new and dated markers are positioned or not for that component.
  const contentMarkersPositioned = useRef(false);
  const divRef = useRef(null);
  const mathJaxInitialised = useRef(false);

  useEffect(() => {
    if (contentMarkersPositioned.current == false) {
      PositionMarkers(document.getElementById("section-" + section.id));
      contentMarkersPositioned.current = true;
    }
  }, [contentMarkersPositioned]);

  useEffect(() => {
    //@ts-ignore
    if (window.MathJax !== undefined && mathJaxInitialised.current === false && section.partialHtmlBody.includes("<math")) {
      mathJaxInitialised.current = true;
      // @ts-ignore
      window.MathJax.typeset(document.querySelectorAll(`div#section-${section.id}`))
    }
  }, [])

  const resizeObserver = new ResizeObserver((entries: any) => {
    // Wrap it in requestAnimationFrame to avoid "ResizeObserver loop limit exceeded" error
    // This error means that ResizeObserver was not able to deliver all observations within a single animation frame. It is benign
    window.requestAnimationFrame(() => {
      if (!Array.isArray(entries) || !entries.length) {
        return;
      }
      // Let's update the variable to indicate that markers are not positioned and needs to be positioned again as size changed.
      PositionMarkers(document.getElementById("section-" + section.id));
    });
  });
  useEffect(() => {
    // @ts-ignore
    resizeObserver.observe(divRef.current);

    return () => {
      // @ts-ignore
      resizeObserver.disconnect();
    };
  }, []);

  const params = {
    section: section,
  };

  return (
    <div ref={divRef}>
      <ReaderContentContext.Consumer>
        {(value: IReaderContentContext) => <ContentSection key={section.id} params={params} />}
      </ReaderContentContext.Consumer>
    </div>
  );
};

export default ContentController;
