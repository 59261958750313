export const SA_BASIC_SPACING_PX = 4;

export const SA_SPACING_FACTOR_1 = `${SA_BASIC_SPACING_PX}px`;
export const SA_SPACING_FACTOR_2 = `${SA_BASIC_SPACING_PX * 2}px`;
export const SA_SPACING_FACTOR_3 = `${SA_BASIC_SPACING_PX * 3}px`;
export const SA_SPACING_FACTOR_4 = `${SA_BASIC_SPACING_PX * 4}px`;
export const SA_SPACING_FACTOR_5 = `${SA_BASIC_SPACING_PX * 5}px`;
export const SA_SPACING_FACTOR_6 = `${SA_BASIC_SPACING_PX * 6}px`;
export const SA_SPACING_FACTOR_7 = `${SA_BASIC_SPACING_PX * 7}px`;
export const SA_SPACING_FACTOR_8 = `${SA_BASIC_SPACING_PX * 8}px`;
export const SA_SPACING_FACTOR_9 = `${SA_BASIC_SPACING_PX * 9}px`;
export const SA_SPACING_FACTOR_10 = `${SA_BASIC_SPACING_PX * 10}px`;
