export const SA_NAVY = "#002F5F";
export const SA_BLACK_B100 = "#000000";
export const SA_B070 = "#4C4C4C";
export const SA_B030 = "#A6A6A6";
export const SA_B005 = "#FCFCFC";
export const SA_B007 = "#FEFEFE";
export const SA_B010 = "#F8F8F8";
export const SA_B015 = "#EEEEEE";
export const SA_B013_3 = "#DDDDDD";
export const SA_B020 = "#E6E6E6";
export const SA_B060 = "#808285";
export const SA_B075 = "#565656";
export const SA_B085 = "#58595B";
export const SA_DISABLED = "#898989";
export const SA_PLACEHOLDER = "#898989";
export const SA_GREY = "#A7A9AC";
export const SA_GREY_LIGHT = "#f3f3f3";

export const SA_WHITE_B000 = "#FFFFFF";
export const SA_MENU_COLOR = "#D9E0E7";
export const SA_MOBILE_MENU_TEXT_COLOR = "#B8C6D4";
export const SA_BLUE_HIGHLIGHT = "#B4D8FF";
export const SA_BLUE_PREVIEW = "#FAFDFF";

export const SA_INVERTED_DEFAULT = "#B3C1CF";
export const SA_INVERTED_DISABLED = "#597796";
export const SA_INVERTED_DIVIDER = "#264D76";

export const SA_ORANGE_ACTIVE = "#FFB500";
export const SA_ORANGE_W010 = "#FFBC1A";
export const SA_ORANGE_W015 = "#FFC026";
export const SA_ORANGE_W025 = "#FFC740";

export const SA_YELLOW = "#FFE511";
export const SA_YELLOW_B010 = "#E5CD0F";
export const SA_YELLOW_B015 = "#D9C20E";
export const SA_YELLOW_B025 = "#BFAB0C";
export const SA_YELLOW_W025 = "#FFEB4C";

export const SA_BLUE = "#007AC9";
export const SA_BLUE_B010 = "#006DB4";
export const SA_BLUE_B015 = "#0067AB";
export const SA_BLUE_B025 = "#005B96";
export const SA_BLUE_W025 = "#409BD6";
export const SA_BLUE_LIGHT_1 = "#EEF3F6";
export const SA_BLUE_SELECTED = "#B3D7FF";
export const SA_BLUE_FOCUS = "#6AA3F9";
export const SA_HIGHLIGHT_YELLOW = "#FFF8C5";
export const SA_NAVY_2 = "#00264D";
export const SA_NAVY_B010 = "#00172E";

export const SA_RED = "#CE1114";
export const SA_RED_B010 = "#B80F11";
export const SA_RED_B015 = "#AF0E11";
export const SA_RED_B025 = "#9A0C0E";
export const SA_RED_W025 = "#DA4C4E";

export const SA_GREEN = "#1A936F";

export const SA_BG_BLUE = "#F2F8FC";
export const SA_BG_ORANGE = "#FFFBF2";
export const SA_BG_GREEN = "#F3F9F8";
export const SA_BG_RED = "#FCF3F3";

export const SA_RUBY = "#E0115F";

export const SA_LINEAR_GRADIENT_FEATURED = "linear-gradient(to left, #00172E, #002F5F)";
