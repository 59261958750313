import React, { useState, useContext, useRef } from "react";
import _, { now } from "lodash";
import { Waypoint } from "react-waypoint";
import ContentController from "./ContentController";
import HtmlContent from "./HtmlContent";
import { observer } from "mobx-react-lite";
import ReaderLayoutStore from "../../../stores/readerLayoutStore";

interface ISectionRef {
  id: any;
  timestamp: any;
}

const VizAwareContentController: React.FC<any> = (props: any) => {
  const { IsNavigating } = useContext(ReaderLayoutStore);
  const { section, isPreview } = props;

  const [isVisible, setVisible] = useState(false);
  const sectionRef = useRef({
    id: -1,
    timestamp: -1
  });
  
  
  if (IsNavigating === true) {
    return (
      <div>
          <HtmlContent sectionId={section.id} html={section.partialHtmlBody} isPreview={isPreview}/>
        </div>
    )
  } else {
    return (
      <Waypoint
        onEnter={() => {
          if (sectionRef.current.id === -1) {
            sectionRef.current = {
              id: section.id,
              timestamp: performance.now()
            }
          }
          setVisible(true);
        }}
        onLeave={() => {
          // Check if we have the entered event.
          if (sectionRef.current.id === section.id) {
            // Means we cam in onLeave() event of same section.
            // Let's check the time spent within that events.
            const timeSpent = sectionRef.current.timestamp - performance.now();
            if (timeSpent > 20) {
              setVisible(false);
            }
          } else {
            setVisible(false);  
          } 
        }}
        fireOnRapidScroll={false}
      >
        {isVisible && !IsNavigating ? (
          <div>
            <ContentController section={section} />
          </div>
        ) : (
          <div>
            <HtmlContent sectionId={section.id} html={section.partialHtmlBody} isPreview={isPreview}/>
          </div>
        )}
      </Waypoint>
    );
  }
};

export default React.memo(VizAwareContentController);
