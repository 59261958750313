import { observer } from "mobx-react-lite";
import React from "react";
import Modal from "react-modal";
import ICONS from "../../constants/icons";
import Button from "../controls/button/Button";
import Icon from "../icons";
import {
  ModalButton,
  ModalButtonSection,
  ModalCloseButton,
  ModalContainer,
  ModalSection,
  ModelTitle,
  ModelInfoTitle,
  ModalInfoButtonSection,
  ModelInfoIcon,
  ModalSectionInput,
} from "./style";
import BUTTONS_TYPES from "../../constants/buttons";
import STYLE_DEFAULTS from "../../constants/styles";
import { ISegmentPackage } from "../../services/segmentProvider/models/segmentPackage";

// todo create button modal and use that object for primary/cancel params
interface IGenericModel {
  isOpen: boolean;
  hasCloseIcon?: boolean;
  hasCancelButton?: boolean;
  cancelButtonWidth?: string;
  hasPrimaryButton?: boolean;
  primaryButtonWidth?: string;
  title?: string;
  titleIcon?: ICONS;
  tileContent?: string;
  acceptValue?: string;
  cancelValue?: string;
  onAcceptDisabled?: boolean;
  acceptButtonType?: BUTTONS_TYPES;
  children?: any;
  width?: string;
  height?: string;
  padding?: string;
  background?: string;
  buttonPosition?: string;
  setOpen?: (n: any) => any;
  onAccept?: () => any;
  onCancel?: () => any;
  top?: string;
  bottom?: string;
  mobileButtonsFullWidth?: boolean;
  shadow?: boolean;
  textAlign?: string;
  acceptSegmentPackage?: ISegmentPackage;
  cancelSegmentPackage?: ISegmentPackage;
  footerContent?: any;
  primaryButtonFirst?: boolean;
  isPrimaryLoading?: boolean;
  isCancelLoading?: boolean;
}

const modalStyle = {
  overlay: {
    zIndex: 999,
    backgroundColor: null as any,
  },
  //explicity set to 0px to remove padding
  content: {
    top: "0px",
    left: "0px",
    right: "0px",
    bottom: "0px",
    background: null as any,
  },
};

// There's a few modals used in the UI, the below modal should be used when showing information to the user
// The design of the modal has a specific title styling, close icon location and button spacing that isn't used in the GenericModal
export const GenericInfoModal: React.FC<IGenericModel> = (props: IGenericModel) => {
  const {
    title,
    titleIcon,
    tileContent,
    mobileButtonsFullWidth,
    hasPrimaryButton = true,
    hasCancelButton = true,
    isOpen,
    setOpen,
    hasCloseIcon,
    acceptButtonType,
    acceptValue,
    onAccept,
    cancelValue,
    onCancel,
    children,
    textAlign,
    top,
    width,
    shadow,
    acceptSegmentPackage,
    cancelSegmentPackage,
    isPrimaryLoading,
    isCancelLoading,
  } = props;

  const deleteButton = acceptButtonType === BUTTONS_TYPES.DELETE;
  const onAcceptButton = {
    primary: acceptButtonType === BUTTONS_TYPES.PRIMARY,
    secondary: acceptButtonType === BUTTONS_TYPES.SECONDARY,
    deleteButton,
    deleteButtonValue: deleteButton ? acceptValue : undefined,
  };

  return (
    // @ts-ignore
    <Modal
      id="generic-info-modal"
      style={modalStyle}
      isOpen={isOpen}
      onRequestClose={() => setOpen && setOpen(false)}
      ariaHideApp={false}
    >
      <ModalContainer>
        <ModalSectionInput width={width} shadow={shadow} top={top}>
          <ModalSection textAlign={textAlign}>
            {hasCloseIcon && setOpen && (
              <ModalCloseButton>
                <Icon clickable tiny icon={ICONS.CLOSE} onClick={() => setOpen(false)} />
              </ModalCloseButton>
            )}
            {title && (
              <ModelInfoTitle content={tileContent}>
                {titleIcon && (
                  <ModelInfoIcon>
                    <Icon internalSize="20px" icon={titleIcon} color={STYLE_DEFAULTS.COLORS.SA_RED} tiny />
                  </ModelInfoIcon>
                )}
                {title}
              </ModelInfoTitle>
            )}
            {children && children}
            {(setOpen || onCancel) && (
              <ModalInfoButtonSection mobileButtonsFullWidth={mobileButtonsFullWidth}>
                {hasPrimaryButton && (
                  <ModalButton style={{ marginTop: "15px" }} mobileButtonsFullWidth={mobileButtonsFullWidth}>
                    <Button
                      isLoading={isPrimaryLoading}
                      {...onAcceptButton}
                      segmentPackage={acceptSegmentPackage}
                      onClick={(event: any) => {
                        event.stopPropagation();
                        onAccept && onAccept!();
                      }}
                      value={acceptValue}
                      width={mobileButtonsFullWidth ? "100%" : "180px"}
                    />
                  </ModalButton>
                )}
                {hasCancelButton && (
                  <ModalButton style={{ marginTop: "20px" }} mobileButtonsFullWidth={mobileButtonsFullWidth}>
                    <Button
                      isLoading={isCancelLoading}
                      secondary
                      segmentPackage={cancelSegmentPackage}
                      value={cancelValue || "Cancel"}
                      onClick={() => {
                        if (onCancel) {
                          onCancel();
                        } else if (setOpen) {
                          setOpen(false);
                        }
                      }}
                      width={mobileButtonsFullWidth ? "100%" : "180px"}
                    />
                  </ModalButton>
                )}
              </ModalInfoButtonSection>
            )}
          </ModalSection>
        </ModalSectionInput>
      </ModalContainer>
    </Modal>
  );
};

/**
 * On close click - priority to set open function if provided
 *
 * @param props
 */
const onCloseClick = (props: { setOpen: undefined | ((b: boolean) => void); onCancel: undefined | (() => void) }) => {
  const { setOpen, onCancel } = props;

  if (onCancel) {
    onCancel();
  } else if (setOpen) {
    setOpen(false);
  }
};

/**
 * On cancel - priority to cancel function if provided
 *
 * @param props
 */
const onCancelClick = (props: { setOpen: undefined | ((b: boolean) => void); onCancel: undefined | (() => void) }) => {
  const { setOpen, onCancel } = props;

  if (onCancel) {
    onCancel();
  } else if (setOpen) {
    setOpen(false);
  }
};

const renderPrimaryButton = (props: IGenericModel) => {
  const {
    hasPrimaryButton = true,
    mobileButtonsFullWidth,
    acceptButtonType,
    acceptSegmentPackage,
    onAcceptDisabled,
    onAccept,
    acceptValue,
    primaryButtonWidth,
  } = props;

  const onAcceptButton = {
    primary: acceptButtonType === BUTTONS_TYPES.PRIMARY,
    secondary: acceptButtonType === BUTTONS_TYPES.SECONDARY,
    deleteButton: acceptButtonType === BUTTONS_TYPES.DELETE,
  };

  return (
    <>
      {hasPrimaryButton && (
        <ModalButton mobileButtonsFullWidth={mobileButtonsFullWidth}>
          <Button
            {...onAcceptButton}
            segmentPackage={acceptSegmentPackage}
            disabled={onAcceptDisabled}
            onClick={(event: any) => {
              event.stopPropagation();
              onAccept && onAccept!();
            }}
            value={acceptValue}
            width={primaryButtonWidth || "100%"}
          />
        </ModalButton>
      )}
    </>
  );
};

const renderSecondaryButton = (props: IGenericModel) => {
  const {
    hasCancelButton,
    mobileButtonsFullWidth,
    onCancel,
    setOpen,
    cancelSegmentPackage,
    cancelValue,
    cancelButtonWidth,
  } = props;

  return (
    <>
      {hasCancelButton && (onCancel || setOpen) && (
        <ModalButton mobileButtonsFullWidth={mobileButtonsFullWidth}>
          <Button
            segmentPackage={cancelSegmentPackage}
            secondary
            value={cancelValue || "Cancel"}
            onClick={() => onCancelClick({ setOpen, onCancel })}
            width={cancelButtonWidth || "100%"}
          />
        </ModalButton>
      )}
    </>
  );
};

const renderButtons = (props: IGenericModel) => {
  const {
    hasCancelButton,
    hasPrimaryButton = true,
    buttonPosition,
    mobileButtonsFullWidth,
    primaryButtonFirst,
  } = props;

  return (
    <>
      {(hasCancelButton || hasPrimaryButton) && (
        <ModalButtonSection buttonPosition={buttonPosition} mobileButtonsFullWidth={mobileButtonsFullWidth}>
          {primaryButtonFirst ? (
            <>
              {renderPrimaryButton(props)}
              {renderSecondaryButton(props)}
            </>
          ) : (
            <>
              {renderSecondaryButton(props)}
              {renderPrimaryButton(props)}
            </>
          )}

          <br />
        </ModalButtonSection>
      )}
    </>
  );
};

// Default modal used in the UI, this has a blank template and takes in children
const GenericModal: React.FC<IGenericModel> = observer((props: IGenericModel) => {
  const {
    isOpen,
    hasCloseIcon,
    title,
    tileContent,
    children,
    width,
    height,
    padding,
    background,
    setOpen,
    onCancel,
    top,
    bottom,
    shadow,
    textAlign,
    footerContent,
  } = props;

  return (
    // @ts-ignore
    <Modal
      id="generic-modal"
      style={modalStyle}
      isOpen={isOpen}
      onRequestClose={() => setOpen && setOpen(false)}
      ariaHideApp={false}
    >
      <ModalContainer>
        <ModalSectionInput height={height} top={top} bottom={bottom} width={width} shadow={shadow}>
          <ModalSection background={background} padding={padding} textAlign={textAlign}>
            {hasCloseIcon && setOpen && (
              <ModalCloseButton>
                <Icon
                  clickable
                  tiny
                  icon={ICONS.CLOSE}
                  // Give priority to setOpen over onCancel - close button functionality might be different from cancel button
                  onClick={() => onCloseClick({ setOpen, onCancel })}
                />
              </ModalCloseButton>
            )}
            {title && <ModelTitle content={tileContent}>{title}</ModelTitle>}
            {children}
            {renderButtons(props)}
          </ModalSection>
          {footerContent && footerContent}
        </ModalSectionInput>
      </ModalContainer>
    </Modal>
  );
});

export default GenericModal;
