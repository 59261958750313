import { getProductName } from "../../constants/productType";
import {
  dateToCustomString,
  dateToCustomStringNullable,
  dateToString,
  getDateNYearsFromDateSubDays,
} from "../../helpers/date";

// based on position of term in set
export const getSetPosition = (term: number) => {
  switch (term) {
    case 3:
      return 1;
    case 2:
      return 2;
    case 1:
      return 3;
    default:
      return undefined;
  }
};

// generates sinlge checkout item
export const generateCheckoutOrder = (
  totalAmount: number,
  shippingFee: number,
  tax: number,
  items: Array<any>,
) => {
  return {
    affiliation: "Standard Online",
    revenue: totalAmount.toFixed(2),
    shipping: shippingFee.toFixed(2),
    tax: tax.toFixed(2),
    coupon: "",
    currency: "AUD",
    products: items.map((item: any, index: number) => generateSegmentProduct(item, index + 1)),
  };
};

// generates a single product item from cart item
export const generateSegmentProduct = (item: any, position = 1) => {
  const data = {
    product_id: item.productId?.toString(),
    price: (item.listPrice * item.quantity).toFixed(2),
    format: getProductName(item.type),
    licence: item.licenceCount,
    quantity: item.quantity,
  };

  if (item.set && item.setSubscriptionTerm) {
    const startDate = new Date();
    // prettier-ignore
    return {
      ...data,
      ...generateSetItem(item.set, position),
      payment_plan: `${item.setSubscriptionTerm} year subscription`,
      payment_period: `${dateToString(startDate)} - ${getDateNYearsFromDateSubDays(startDate,item.setSubscriptionTerm, 1)}`,
      payment_term: "Annual",
    };
  }

  return {
    ...data,
    ...generateProductItem(item, position),
  };
};

// generates a single publication item
export const generateProductItem = (data: any, position?: number) => {
  return {
    name: data.designation,
    label: data.designation,
    brand: data.docType,
    category: data.sector,
    position: data.position ?? position,
    variant: dateToCustomStringNullable(data.publishDate ?? data.published_date, "dd-MMM-yyyy"),
    product_type: "Standard",
  };
};

// generates a single publication item by calling generateProductItem per item in list
export const generateProductList = (listId: string, data: Array<any>) => {
  return {
    list_id: listId,
    products: data.map((x: any, index: number) => {
      const data = x.publication ? x.publication : x;
      return generateProductItem(data, index + 1);
    }),
  };
};

// generates a list of set items by calling generateSetItem per item in list
export const generateSetList = (sets: Array<any>) => {
  return {
    list_id: "Small Business Sets",
    products: sets.map((set: any, index: number) => {
      return generateSetItem(set, index + 1);
    }),
  };
};

// generates a single set item
export const generateSetItem = (set: any, position?: number) => {
  const name = set.title ?? set.setTitle;
  return {
    name: name,
    label: name,
    brand: "Set",
    licence: set.licenceCount,
    position: position,
    product_type: "Subscription",
    category: set.sector,
    variant: dateToCustomString(set.releasedDate, "dd-MMM-yyyy"),
  };
};
