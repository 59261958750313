import { format, add, subDays } from "date-fns";
import { utcToZonedTime } from 'date-fns-tz';

const toDate = (date: Date | string) => {
  if (typeof date === "string") {
    const newDate = new Date(date);
    if (!isNaN(newDate.getTime())) return newDate;
    const datePart = date.split(/[^0-9]+/);
    return new Date(`${datePart[1]}/${datePart[0]}/${datePart[2]}`);
  }
  return date;
};

export const dateToString = (date?: Date | string) => {
  if (!date) return "";
  return format(toDate(date), "dd/MM/yyyy");
};

export const dateToCustomString = (date: Date | string, dateFormat: string) => {
  if (!date) return "";
  return format(toDate(date), dateFormat);
};

export const dateToCustomStringNullable = (date: Date | string, dateFormat: string) => {
  if (!date) return undefined;
  return format(toDate(date), dateFormat);
};

export const stringToDate = (date: string) => {
  return new Date(date);
};

export const getDateNYearsFromDate = (date: Date | string, nTerms: number) => {
  return dateToString(
    add(toDate(date), {
      years: nTerms,
    }),
  );
};

export const getDateNYearsFromDateSubDays = (date?: Date | string, nTerms?: number, daysToSub?: number) => {
  if (!date) return "";
  return dateToString(
    subDays(
      add(toDate(date), {
        years: nTerms ?? 0,
      }),
      daysToSub ?? 0,
    ),
  );
};

export const isSubscriptionPaymentOverdue = (date: Date | string, paymentCount: number) => {
  const SYDNEY_TIME_ZONE = 'Australia/Sydney';
  const DATE_ONLY_FORMAT = 'yyyy-MM-dd'
  const todayInSydney = toDate(format(utcToZonedTime(new Date(), SYDNEY_TIME_ZONE), DATE_ONLY_FORMAT));
  const orderDateInSydney = toDate(format(utcToZonedTime(toDate(date), SYDNEY_TIME_ZONE), DATE_ONLY_FORMAT));
  return todayInSydney > add(orderDateInSydney, { years: paymentCount })
};
