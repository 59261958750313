import { INDUSTRY_SECTORS_INFO_LIST } from "./industrySectors";

export const FILTERS = {
  INDUSTRY_SECTORS: 0,
  PUBLISHER: 1,
  NEW_RELEASES: 2,
  POPULAR_STANDARDS: 3,
};

interface IStandardItem {
  name: string;
  url?: string;
  image?: string;
  background?: string;
  options?: any[];
}

const FILTER_ITEMS: IStandardItem[] = [
  {
    name: "By Industry Sector",
    options: INDUSTRY_SECTORS_INFO_LIST,
    background: "(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%)",
  },
  {
    name: "By Publisher",
    options: [
      {
        name: "ISO",
        url: "iso",
        displayName: "ISO International Standard",
        image: "../assets/images/popular-ISO.jpg",
        logo: "../assets/images/iso-international-standards.png",
        background: "(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%)",
      },
      {
        name: "IEC",
        url: "iec",
        displayName: "IEC International Standard",
        image: "../assets/images/popular-IEC.jpg",
        logo: "../assets/images/iec-international-electrotechnical-commission.png",
        background: "(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%)",
      },
    ],
  },
  {
    name: "New Releases",
    url: "new-releases",
    image: "../assets/images/new-releases.jpg",
    background: "(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%)",
  },
  {
    name: "Popular Standards",
    url: "popular-standards",
    image: "../assets/images/top50_splash.jpg",
    background: "(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%)",
  },
];

export default FILTER_ITEMS;
