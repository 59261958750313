//MODIFIED_FILE
import React, { useContext, useEffect, useState } from "react";
import { DocumentViewPreviewFooter, DocumentViewContent, DocumentViewHeader, FootButtonContainer, AlertContainer } from "./style";
import Button from "../../../controls/button/Button";
//import Router from "next/router";
import { useParams, useNavigate } from "react-router-dom";
import { SHOP_MENU_ITEMS_INFO, SHOP_MENU_ITEMS } from "../../../../constants/shopMenuItems";
import ReaderStore from "../../../../stores/ReaderStore";
import ICONS from "../../../../constants/icons";
import Alert from "../../../alerts/Alert";
import ALERT_TYPE from "../../../../constants/alerts";
import TRACKING_EVENTS from "../../../../services/segmentProvider/models/trackingEvents";
import utils from "../../../../utils/utils";
import ReaderLayoutContext, { IReaderLayoutContext } from "../readerLayoutContext";

const cover = "/images/standard-cover.png";

export const PreviewHeader: React.FC<any> = (props: any) => {
  const { isMobile } = props;
  return (
    <AlertContainer>
      <Alert
        icon={ICONS.VIEW}
        type={ALERT_TYPE.INFO}
        title="You are previewing this document"
        message="Did you know that you can search for terms, figures or formulas within this publication? Try out the search
          feature on the top of this e-reader"
      />
    </AlertContainer>
  );
};

export const PreviewFooter: React.FC<any> = (props: any) => {
  const { isMobile } = props;
  const readerStore = useContext(ReaderStore);

  let navigate = useNavigate();

  return (
    <DocumentViewPreviewFooter isMobile={isMobile}>
      {!isMobile && <img src={cover} alt="Document Cover" height="310" width="230px" />}
      <DocumentViewContent>
        <DocumentViewHeader isFooter>End of preview</DocumentViewHeader>
        This is just snapshot of the document, but there is so much more.
        <br />
        <br />
        Please purchase to continue reading.
        <FootButtonContainer isMobile={isMobile}>
        {readerStore.CallToActionButton.isEnabled && <Button
            primary
            value= {readerStore.CallToActionButton.title}
            
            width="120px"
            onClick={() =>
              navigate(
                readerStore.CallToActionButton.url 
              )
            }
          />}
        </FootButtonContainer>
      </DocumentViewContent>
    </DocumentViewPreviewFooter>
  );
};

