import React from "react";
import { DynamicBadgeContainer, DynamicBadgeValue, DynamicBadgeIcon } from "./style";
import ICONS from "../../../../constants/icons";
import Icon from "../../../icons";

interface IDynamicBadge {
  value: string;
  icon?: ICONS;
  iconColor?: string;
  background?: string;
  borderColor?: string;
  caseSensitive?: boolean;
  fontWeight?: string;
  fontColor?: string;
  letterSpacing?: string;
  height?: string;
  paddingRight?: string;
  paddingLeft?: string;
  onClick?: () => void;
}

const DynamicBadge = (props: IDynamicBadge) => {
  const {
    value,
    icon,
    iconColor,
    background,
    height,
    paddingRight,
    paddingLeft,
    borderColor,
    caseSensitive,
    fontWeight,
    fontColor,
    letterSpacing,
    onClick,
  } = props;

  return (
    <DynamicBadgeContainer
      height={height}
      paddingRight={paddingRight}
      paddingLeft={paddingLeft}
      borderColor={borderColor}
      background={background}
      onClick={onClick}
    >
      {icon ? (
        <DynamicBadgeIcon>
          <Icon icon={icon} size="12px" internalSize="12px" color={iconColor} />
        </DynamicBadgeIcon>
      ) : null}
      <DynamicBadgeValue
        fontWeight={fontWeight}
        caseSensitive={caseSensitive}
        letterSpacing={letterSpacing}
        fontColor={fontColor}
      >
        {value}
      </DynamicBadgeValue>
    </DynamicBadgeContainer>
  );
};

export default DynamicBadge;
