import styled, { css } from "styled-components";
import device from "constants/device";
import { linkHoverStyle } from "styles/global";
import STYLE_DEFAULTS from "constants/styles";

interface IStyledAccountProps {
    isAccountPopoverOpen?: boolean;
}

export const CallToActionContainer = styled.div`
    color: #FFFFFF;
    
    @media ${device.tabletMin}{
        width: 65%;
    }
`;

export const AccountContainer = styled.div`
    position: relative;
    float: right;
    color: #FFFFFF;
    display: flex;
`;

export const Account = styled.div`
`;

export const AccountSA = styled.div`
    border: solid 2px #FFFFFF;
    border-radius: 50%;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const AccountPopover = styled.div<IStyledAccountProps>`
    position: absolute;
    top: 35px;
    right: 0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.5);
    background: #FFFFFF;
    width: 22rem;
    padding: 20px;
    border: 1px solid #E0E0E0;
    color: #58595B;
    border-radius: 8px;
    display : ${props => props.isAccountPopoverOpen ? 'inline-block' : 'none'};
    z-index: ${STYLE_DEFAULTS.Z_INDEX_LAYOUT.ACCOUNT_POPOVER};
    
    svg {
        margin-right: 10px;
    }
`;

export const AccountMenuItem = styled.div`
    ${linkHoverStyle};
    margin-top: 10px;
    
    & > div {
        display: inline-block;
        height: auto;
        width: auto;
    }
`;