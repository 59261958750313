import React, { useContext, useEffect, useRef, useState } from "react";
import {
  ShopTabHeaderContainer,
  ShopTabHeader,
  ShopTabItem,
  ShopTabHeaderActive,
  ShopTabItemContainer,
  ShopTabHeaderIcon,
  ShopTabFlexContainer,
  ShopTabTitle,
} from "./style";
import Icon from "../../icons";
import * as segmentUtils from "utils/segmentUtils";
import TRACKING_EVENTS from "services/segmentProvider/models/trackingEvents";
import _ from "lodash";

interface IShopTabProps {
  children: any;
  headerPosition?: string;
  height?: string;
  width?: string;
  title?: string;
  hasUnderline?: boolean;
  tabHeaderBackgroundColor?: string;
  iconPadding?: string;
  headerHeight?: string;
  headerWidth?: string;
  scrollable?: boolean;
  direction?: "vertical" | "horizontal";
  hasHeaderUnderline?: boolean;
}

const RenderTabHeader = (props: any): React.ReactElement => {
  const { child, index, activeIndex, setActiveIndex, hasUnderline, direction, iconPadding, headerHeight } = props;
  const vertical = direction === "vertical";
  const elements = (
    <ShopTabFlexContainer flexRow={vertical} isCentered={vertical}>
      {child.props.icon && (
        <ShopTabHeaderIcon iconPadding={iconPadding}>
          <Icon size="20px" icon={child.props.icon} clickable />
        </ShopTabHeaderIcon>
      )}
      {child.props.label}
    </ShopTabFlexContainer>
  );

  const segmentReference = useRef<HTMLDivElement>(null);

  if (index === activeIndex) {
    return (
      <ShopTabHeaderActive headerHeight={headerHeight} borderLeft={vertical}>
        {elements}
      </ShopTabHeaderActive>
    );
  }

  const onTabClick = () => {
    if (!child.props.disabled) {
      setActiveIndex(index);
      segmentUtils.trackButtonClickAction(TRACKING_EVENTS.READER.MARKERS.event, TRACKING_EVENTS.READER.MARKERS.category, `My Markers ${_.startCase(child.props.label.toLowerCase())}`, `My Markers ${_.startCase(child.props.label.toLowerCase())}`);
      child.props.onClick && child.props.onClick();
    }
  }

  return (
    <ShopTabHeader
      ref={segmentReference}
      headerHeight={headerHeight}
      borderLeft={vertical}
      hasUnderline={hasUnderline}
      disabled={child.props.disabled}
      onClick={() => {onTabClick()}}
    >
      {elements}
    </ShopTabHeader>
  );
};

const ShopTabs = (props: IShopTabProps): React.ReactElement => {
  const {
    children,
    headerPosition,
    height,
    width,
    hasUnderline,
    iconPadding,
    title,
    tabHeaderBackgroundColor,
    headerHeight,
    headerWidth,
    direction = "horizontal",
    scrollable = true,
    hasHeaderUnderline,
  } = props;

  const [activeIndex, setActiveIndex] = useState(0);
  const [childrenList, setChildrenList] = useState([] as any[]);

  useEffect(() => {
    let activeTab = {} as any;

    if (Array.isArray(children)) {
      activeTab = children.filter((child: any) => child.props.activeTab);
      setChildrenList(children);

      if (activeTab.length === 1) {
        setActiveIndex(children.indexOf(activeTab[0]));
      }
    } else {
      setChildrenList([children]);
    }
  }, [children]);

  return (
    <ShopTabFlexContainer flexRow={direction === "vertical"}>
      <ShopTabHeaderContainer
        hasHeaderUnderline={hasHeaderUnderline}
        flexRow={direction === "horizontal"}
        headerPosition={headerPosition}
        tabHeaderBackgroundColor={tabHeaderBackgroundColor}
        headerWidth={headerWidth}
      >
        {title && <ShopTabTitle>{title}</ShopTabTitle>}
        {childrenList.map((child: any, index: any) => {
          return (
            <RenderTabHeader
              key={`shop-tab-header-${index}`}
              child={child}
              index={index}
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
              hasUnderline={hasUnderline}
              direction={direction}
              iconPadding={iconPadding}
              tabHeaderBackgroundColor={tabHeaderBackgroundColor}
              headerHeight={headerHeight}
            />
          );
        })}
      </ShopTabHeaderContainer>
      <ShopTabItemContainer height={height} width={width} scrollable={scrollable}>
        {childrenList.map((child: any, index: any) => {
          if (index !== activeIndex) return undefined;
          return <ShopTabItem key={`shop-tab-item-${index}`}>{child.props.children}</ShopTabItem>;
        })}
      </ShopTabItemContainer>
    </ShopTabFlexContainer>
  );
};

export default ShopTabs;
