export const SA_FONT_FAMILY = {
  HELVETICA_NEUE: "HelveticaNeueLTStd-Lt, Helvetica, Arial, sans-serif",
  HELVETICA_NEUE_REGULAR: "HelveticaNeueLTStd-Roman, HelveticaNeue, Helvetica, Arial, sans-serif",
  HELVETICA_NEUE_MEDIUM: "HelveticaNeueLTStd-Md, HelveticaNeue, Helvetica, Arial, sans-serif",
  NOTO_SANS: '"Noto Sans", Helvetica, Arial, sans-serif',
};

export const SA_FONT_SIZE = {
  LARGEST: "2.0rem",
  LARGER: "1.5rem",
  LARGE: "1.125rem",
  NORMAL: "1rem",
  SMALL: "0.875rem",
  SMALLER: "0.75rem",
};

export const SA_FONT_WEIGHT = {
  LIGHT: 300,
  NORMAL: 400,
  MEDIUM: 500,
  SEMIBOLD: 600,
  BOLD: 700,
};

export const SA_FONT_LINE_HEIGHT = {
  NORMAL: 1.25,
  HIGHER: 1.5,
};
