//MODIFIED_FILE
import React, { useState, useContext, useEffect, useRef } from "react";
import { ArchivedWatermark, ArchivedWrapper, ReaderContainer, ReaderContentWrapper } from "./readerStyle";
import ReaderHeader from "./header/ReaderHeader";
import MainSection from "./mainSection/MainSection";
import { useBreakpoint } from "../../../services/breakpointProvider/BreakpointProvider";
import { MEDIA_QUERIES_NAMES } from "../../../constants/mediaQueries";
import ReaderLayoutContext from "./readerLayoutContext";
import ReaderStore, { ICallToActionButton } from "../../../stores/ReaderStore";
import { observer } from "mobx-react-lite";
import UserStore from "../../../stores/userStore";
import { useParams, useNavigate, NavigateFunction  } from "react-router-dom";
import messageService from "../../../services/messagesService";
import ConnectionErrorMessage from "../../error/connectionErrorMessage";
import SYSTEM_ERRORS from "../../../constants/errors";
import ShopErrorBoundary from "../../error/ErrorBoundary";
import { SHOP_MENU_ITEMS_INFO, SHOP_MENU_ITEMS } from "../../../constants/shopMenuItems";
import GenericModal from "../../alerts/GenericModal";
import { Loader } from "../../loader/loader";
import { buildUserInitials } from "../../../helpers/userInitials";
import utils from "../../../utils/utils";
import CustomModal from "components/customModal/CustomModal";
import { oidcInstance } from "authConfigIAM";

const HandleRedirect = function (responseCode: number | undefined, navigate : NavigateFunction) {
  if ( //navigate. === "/error" || 
      !responseCode) {
    return;
  }
  if(responseCode == 401) {

    oidcInstance.signinSilent().then((response) => {
      if(!response){
        oidcInstance.signoutRedirect();
      } else {
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    }).catch(() => {
      oidcInstance.signoutRedirect();
    })
    
  }
  else if (responseCode > 401 && responseCode <= 599) {
    navigate(SHOP_MENU_ITEMS_INFO[SHOP_MENU_ITEMS.ERROR].path);
  }
};

const ReaderLayout: React.FC = observer(() => {
  //const router = useRouter();
  let navigate = useNavigate();
  let params = useParams();
  let callToActionButton : ICallToActionButton;
  /*
  const { preview } = router.query;
  const designationId = router.query.designationId || "";
  const version = router.query.version || null;
  */
  const preview  = params.preview || null;
  const designationId = params.designationId || "";
  const version = params.version || null;

  const [isPreview, setIsPreview] = useState(preview == "preview");
  const breakpoints: any = useBreakpoint();

  // It takes time to compute breakpoints and determine whether is mobile - assume mobile in the meantime
  const isMobile: boolean = breakpoints.hasOwnProperty(MEDIA_QUERIES_NAMES.XS)
    ? breakpoints[MEDIA_QUERIES_NAMES.XS] || breakpoints[MEDIA_QUERIES_NAMES.SM]
    : true;

  const readerStore = useContext(ReaderStore);
  const userContext = useContext(UserStore);
  const { SetReaderToc, SetReaderSection } = readerStore;
  const { isInitialised, user } = userContext;

  useEffect(() => {
    if (!designationId) return; //TODO: show error message and return to home shop
    // Create an scoped async function in the hook
    async function asyncInitialiseUserStore(designationId: any, version: any) {
      //InitialiseUserStore();
      if (true) {
        // If we're not logged in, or explicitly in preview, we only see the preview
        // We need to set a local variable to the value we want to use
        const isPreviewSynchronous = isPreview //(//!isLoggedIn && !isAdmin()) || preview == "1";
        // Then we set its sate
        setIsPreview(isPreviewSynchronous);
        // Logged in or not we are allowed to see the Toc
        SetReaderToc(designationId, version).then(() => {
          // Also let's check again if the content is preview or not.
          if (!isPreviewSynchronous) {
            // Also we don't want to set/reset preview if user is not logged in or preview is set to 1
            setIsPreview(readerStore.Toc.isPreview);
          }
          HandleRedirect(readerStore.Toc.responseCode, navigate);
        });
        SetReaderSection(designationId, isPreviewSynchronous, version).then(() => {
          // Also let's check again if the content is preview or not.
          if (!isPreviewSynchronous) {
            // Also we don't want to set/reset preview if user is not logged in or preview is set to 1
            setIsPreview(readerStore.ReaderSections.isPreview);
          }
          HandleRedirect(readerStore.ReaderSections.responseCode, navigate);
        });
      }
    }
    // Execute the created function directly
    asyncInitialiseUserStore(designationId, version);
  }, []);

  const [error, setError] = useState(null);

  // When error is caught delivery service,
  // it sends error notification to the reader
  useEffect(() => {
    messageService.getMessage().subscribe((error: any) => {
      if (error.text === SYSTEM_ERRORS.DELIVERY_ERROR) {
        setError(error);
      }
    });
    return () => {
      setError(null);
    };
  }, []);

  // Controls the side menu items selection
  const [selectedMenuIndex, setSelectedMenuIndex] = useState(0);

  // Side menu is open when user selects any of its items, otherwise menu panel is closed and
  // the menu itself can either be visible or not
  const [isSideMenuOpen, setSideMenuOpen] = useState(false);

  // In mobile mode, keep track of side panel during closing transition: isSideMenuOpen is already set to false,
  // however the closed menu is not yet to be shown
  const [isSidePanelClosingTransition, setSidePanelClosingTransition] = useState(false);

  // Set if reader is loading is open
  const [isReaderLoading, setIsReaderLoading] = useState(false);

  const readerLayoutContext = {
    readerStore,
    isPreview,
    isMobile, // whether currently viewed on mobile screen size (size menu might not be visible)
    designationId,
    isSideMenuOpen, // whether any of the menu items are selected and menu item content is open in a panel
    selectedMenuIndex,
    setSideMenuOpen: setSideMenuOpen,
    isSidePanelClosingTransition,
    setSidePanelClosingTransition,
    setSelectedMenuIndex,
    isReaderLoading,
    setIsReaderLoading,
    userInitials: buildUserInitials(user),
    showBackButton: false
  };

  useEffect(() => {
    // When switching to mobile view, indicate to close the side menu,
    // to make sure header shows
    setSideMenuOpen(!isMobile);

    readerStore.SetIsMobileMode(isMobile);

    //TODO: add logic to set call to action button here
    callToActionButton = {isEnabled : false, title : "Buy now", url : `${SHOP_MENU_ITEMS_INFO[SHOP_MENU_ITEMS.HOME].path}/${utils.getDesignationUrl(readerStore.DesignationId!)}`}
    readerStore.SetCallToActionButton(callToActionButton);
  }, [isMobile]);

  if (error) {
    return <ConnectionErrorMessage />;
  }

  //disable ctrl-a function 
  useEffect(() => {
    const ctrl1 = (e: any) => e.ctrlKey; 
    const handler = (e: any) => {
      if (ctrl1(e)) {
        return false;
    }};
    const ignore = (e:any) => {
      if (ctrl1(e)) {
        e.preventDefault();
      }
    };
    window.addEventListener("keyup", handler);
    window.addEventListener("keydown", ignore);

    return () => {
      window.removeEventListener("keyup", handler);
      window.removeEventListener("keydown", ignore);
    };
  }, []);

  return (
    <ShopErrorBoundary>
      <ReaderLayoutContext.Provider value={readerLayoutContext}>
        <ReaderHeader />
        <ReaderContainer>
          <ReaderContentWrapper isMobile={isMobile}>
            {
              version && (
                        <ArchivedWrapper>
                          <ArchivedWatermark>
                            Archived
                          </ArchivedWatermark>
                        </ArchivedWrapper>
              )
            }
            <MainSection />            
          </ReaderContentWrapper>
          <footer></footer>
        </ReaderContainer>
        <CustomModal />
      </ReaderLayoutContext.Provider>
      <GenericModal isOpen={isReaderLoading}>
        <Loader />
      </GenericModal>
    </ShopErrorBoundary>
  );
});

export default ReaderLayout;
