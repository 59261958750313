import styled from "styled-components";
import STYLE_DEFAULTS from "../../../../constants/styles";

export const MainSectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
  max-height: 100vh;
  background-color: ${STYLE_DEFAULTS.COLORS.SA_WHITE_B000};
`;
