//MODIFIED_FILE
//import Router from "next/router";
import { useParams, useNavigate, NavigateFunction } from "react-router-dom";
import { SHOP_MENU_ITEMS, SHOP_MENU_ITEMS_INFO } from "../constants/shopMenuItems";
import IBreadcrumb from "../models/breadcrumb";

export const goBackFromReader = (breadcrumbs: Array<IBreadcrumb>, navigate : NavigateFunction) => {
  // Let's check what last page.
  //let navigate = useNavigate();
  const lastPath = breadcrumbs[breadcrumbs.length - 1]?.pathname;
  if (!lastPath) {
    navigate(SHOP_MENU_ITEMS_INFO[SHOP_MENU_ITEMS.HOME].path);
  } else {
    //Router.back();
  }
};

export const closestElementDataIndexForTopOffset = (offset: number, parent: HTMLElement | null) => {
  const allElements = parent?.querySelectorAll('[data-index]') ?? [];
  let closestDistance = Math.abs(offset - (allElements.length > 0 ? (allElements[0] as HTMLElement).getBoundingClientRect().top : 0)); // We want on the element which is closest to 0;
  let closestDataIndex = null;
  for (let i = 1; i < allElements.length; i++) {
      // Let's find the element which is at the top of the page.
      const distanceFromTop = allElements[i].getBoundingClientRect().top;
      const distance = Math.abs(offset - distanceFromTop);
      if (distance < closestDistance) {
          closestDistance = distance;
          closestDataIndex = allElements[i].getAttribute('data-index');
      }
  }
  return closestDataIndex;
};

/**
 * Check if the reader is called from the Backoffice. This is required to check if we need to call backOffice API's for the 
 * publishing team to review the document.
 */
export function isAdmin(): boolean {
  if (typeof window !== "undefined") {
    return window.location ? (new URLSearchParams(window.location.search).get("admin")) == 'true' : false;
  } else {
    return false;
  }
}