import React, { useState, useRef, useContext } from "react";
import _ from "lodash";
import { SidePanelLoader } from "../style";
import Loader from "../../../../loader/loader";
import { SA_HIGHLIGHT_YELLOW } from "../../../../../constants/styles/colors";
import { SELECTION_HIGHLIGHT_STYLE } from "../../../contentController/helpers/helpers";
import { extractMaxWords } from "../../../../../constants/regex";
import Markers, { findSectionViaId } from "./common/Markers";
import { OutsideClickDetector } from "../../../../../helpers/outsideClickDetector";
import IHighlightMark from "../../../../../models/highlightMark";
import { observer } from "mobx-react-lite";
import ReaderLayoutContext, { IReaderLayoutContext } from "../../readerLayoutContext";
import HighlightStore from "../../../../../stores/highlightStore";

const sortHighlights = (h1: IHighlightMark, h2: IHighlightMark) => {
  if (h1.startDataIndex !== h2.startDataIndex) {
    return h1.startDataIndex - h2.startDataIndex;
  } else {
    if (!h1.startPreviousSiblingDataIndex || !h2.startPreviousSiblingDataIndex) {
      if (!h1.startPreviousSiblingDataIndex) {
        return -1;
      } else {
        return 1;
      }
    } else if (h1.startPreviousSiblingDataIndex !== h2.startPreviousSiblingDataIndex) {
      return h1.startPreviousSiblingDataIndex - h2.startPreviousSiblingDataIndex;
    } else {
      return h1.startOffset - h2.startOffset;
    }
  }
};

const HighlightsMarkers: React.FC<any> = observer((props: any) => {
  const {
    isHighlightsLoading,
    showMarkerOptions,
    setShowMarkerOptions,
    isMarkerDeleteOpen,
    setIsMarkerDeleteOpen,
  } = props;
  const [highlightId, setHighlightId] = useState(0);
  const wrapperRef = useRef(null);

  const readerContext = useContext<IReaderLayoutContext>(ReaderLayoutContext);
  const { setIsReaderLoading } = readerContext;
  const highlightContext = useContext(HighlightStore);

  OutsideClickDetector(wrapperRef, setShowMarkerOptions);

  if (isHighlightsLoading) {
    return (
      <SidePanelLoader>
        <Loader />
      </SidePanelLoader>
    );
  }

  let sortedRawHighlights: IHighlightMark[] = [];

  // Sort highlights first (using original data), then build markers based on sorted array
  highlightContext.highlights.map((highlight: IHighlightMark) => {
    if (highlight.startDataIndex && highlight.selectionPlainText) {
      sortedRawHighlights.push(highlight);
    }
  });

  //map each highlight and add it's ToC section
  const highlightMarkersSorted: any[] = [];

  sortedRawHighlights = sortedRawHighlights.sort(sortHighlights)
  .sort((a, b) =>{
    if(a.isArchived){
      return 1;
    }
    return -1;
  });
  sortedRawHighlights.map((highlight: IHighlightMark) => {
    const section = findSectionViaId(readerContext.readerStore.Toc.content?.tableOfContents, highlight.sectionId);
    // Save guard for now for all the problematic highlights
    if (section && section.value && highlight.selectionPlainText) {
      highlightMarkersSorted.push({
        key: highlight.highlightId,
        navigationKey: SELECTION_HIGHLIGHT_STYLE.ID + highlight.highlightId,
        header: section.value, //displays the marker header, can be subject to change
        sectionHeader: section.value, //contains the section header
        sectionId: highlight.sectionId,
        body: extractMaxWords(highlight.selectionPlainText, 8),
        bodyHighlight: SA_HIGHLIGHT_YELLOW,
        order: section.subOrder ?? section.order.toString(),
        commentsCount: highlight.comments?.length || 0,
        onDeleteClick: (id: any) => {
          setHighlightId(id);
        },
      });
    }
    else if(highlight.isArchived){
      highlightMarkersSorted.push({
        key: highlight.highlightId,
        navigationKey: SELECTION_HIGHLIGHT_STYLE.ID + highlight.highlightId,
        header: `[Archived] [v.${highlight.version}]`,//section.value, //displays the marker header, can be subject to change
        //sectionHeader: "sectionHeader",//section.value, //contains the section header
        //sectionId: highlight.sectionId,
        body: extractMaxWords(highlight.selectionPlainText, 8),
        bodyHighlight: SA_HIGHLIGHT_YELLOW,
        order: highlightMarkersSorted.length + 1,//section.subOrder ?? section.order.toString(),
        commentsCount: highlight.comments?.length || 0,
        isArchived: highlight.isArchived,
        version: highlight.version,
        onDeleteClick: (id: any) => {
          setHighlightId(id);
        },
      });
    }
  });

  highlightMarkersSorted.forEach((x: any, index: any) => {
    if (index > 0 && !x.isArchived && x.sectionHeader === highlightMarkersSorted[index - 1].sectionHeader) x.header = null;
  });

  return (
    <Markers
      ref={wrapperRef}
      section="highlight"
      noMarkerBody="Use the highlight and note tool to create highlights on the Online WebReader."
      markers={highlightMarkersSorted}
      readerContext={readerContext}
      onDeleteAccept={async () => {
        setIsReaderLoading(true);
        await highlightContext.deleteHighlight(highlightId);
        setIsReaderLoading(false);
      }}
      showMarkerOptions={showMarkerOptions}
      setShowMarkerOptions={setShowMarkerOptions}
      isMarkerDeleteOpen={isMarkerDeleteOpen}
      setIsMarkerDeleteOpen={setIsMarkerDeleteOpen}
    />
  );
});

export default HighlightsMarkers;
