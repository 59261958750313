import styled, { css } from "styled-components";
import TextareaAutosize from "react-textarea-autosize";
import { MEDIA_QUERIES } from "../../../constants/mediaQueries";
import STYLE_DEFAULTS from "../../../constants/styles";
import { onHoverStateTransition } from "../../../styles/global";

interface IProps {
  isHover?: boolean;
  focused?: boolean;
  height?: string;
  readonly?: boolean;
}

export const TextArea = styled.textarea`
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.NOTO_SANS};
  font-size: ${STYLE_DEFAULTS.FONTS.SA_FONT_SIZE.NORMAL};
  line-height: 1.5;
  border-radius: ${STYLE_DEFAULTS.BUTTONS.SA_BUTTON_RADIUS};
  -webkit-border-radius: ${STYLE_DEFAULTS.BUTTONS.SA_BUTTON_RADIUS};
  -moz-border-radius: ${STYLE_DEFAULTS.BUTTONS.SA_BUTTON_RADIUS};
  outline: none;
  padding-left: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_3};
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  color: ${STYLE_DEFAULTS.COLORS.SA_BLACK_B100};
  box-sizing: border-box;
  width: 100%;
  height: ${(props: IProps) => props.height || "100%"};
  resize: none;
  white-space: pre-wrap;

  &::placeholder {
    color: ${STYLE_DEFAULTS.COLORS.SA_PLACEHOLDER};
  }

  ${(props: IProps) => {
    if (props.focused) {
      return `
            border: 2px solid ${STYLE_DEFAULTS.COLORS.SA_ORANGE_ACTIVE};
            background-color: ${STYLE_DEFAULTS.COLORS.SA_WHITE_B000};
            -webkit-box-shadow: 0px 0 4px 0px ${STYLE_DEFAULTS.COLORS.SA_ORANGE_ACTIVE};
            -moz-box-shadow: 0px 0 4px 0px ${STYLE_DEFAULTS.COLORS.SA_ORANGE_ACTIVE};
            box-shadow: 0px 0 4px 0px ${STYLE_DEFAULTS.COLORS.SA_ORANGE_ACTIVE};
            font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.MEDIUM};
          `;
    } else if (props.isHover) {
      return `
            background-color: ${STYLE_DEFAULTS.COLORS.SA_B010};
            border: 1px solid ${STYLE_DEFAULTS.COLORS.SA_BLACK_B100};
            font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.NORMAL};
            ${onHoverStateTransition};
          `;
    } else {
      return `
            background-color: ${STYLE_DEFAULTS.COLORS.SA_B010};
            border: 1px solid ${STYLE_DEFAULTS.COLORS.SA_B015};
            font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.NORMAL};
          `;
    }
  }}

  @media ${MEDIA_QUERIES.SM} {
    padding-left: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_2};
    border-radius: 0;
    border: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
`;

export const TextAreaAutosizeWrapper = styled(TextareaAutosize)`
  width: 100%;
  height: auto;
  resize: none;
`;
