import styled from "styled-components";
import STYLE_DEFAULTS from "../../constants/styles";
import ALERT_TYPE from "../../constants/alerts";
import ICONS from "../../constants/icons";
import { onHoverStateTransition } from "../../styles/global";

interface IProps {
  display?: string;
  type?: ALERT_TYPE;
  withTitle?: boolean;
  buttonPosition?: string;
  width?: string;
  height?: string;
  top?: string;
  bottom?: string;
  background?: string;
  mobileButtonsFullWidth?: boolean;
  shadow?: boolean;
  noPadding?: boolean;
  padding?: string;
  color?: string;
  content?: string;
  textAlign?: string;
  clickable?: boolean;
}

export const alertTypeToIconMap = {
  [ALERT_TYPE.INFO]: {
    icon: ICONS.INFO,
    color: STYLE_DEFAULTS.COLORS.SA_BLUE,
    backgroundColor: STYLE_DEFAULTS.COLORS.SA_BG_BLUE,
  },
  [ALERT_TYPE.WARNING]: {
    icon: ICONS.WARNING,
    color: STYLE_DEFAULTS.COLORS.SA_ORANGE_ACTIVE,
    backgroundColor: STYLE_DEFAULTS.COLORS.SA_BG_ORANGE,
  },
  [ALERT_TYPE.ERROR]: {
    icon: ICONS.WARNING,
    color: STYLE_DEFAULTS.COLORS.SA_RED,
    backgroundColor: STYLE_DEFAULTS.COLORS.SA_BG_RED,
  },
  [ALERT_TYPE.SUCCESS]: {
    icon: ICONS.TICK_CIRCLE,
    color: STYLE_DEFAULTS.COLORS.SA_GREEN,
    backgroundColor: STYLE_DEFAULTS.COLORS.SA_BG_GREEN,
  },
  [ALERT_TYPE.NEUTRAL]: {
    icon: ICONS.NO_VIEW,
    color: STYLE_DEFAULTS.COLORS.SA_B070,
    backgroundColor: STYLE_DEFAULTS.COLORS.SA_B010,
  },
};

export const ModalContainer = styled.div`
  display: flex;
  // all model contents must be in the center
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.05);
`;

export const ModalSection = styled.div`
  background: ${(props: IProps) => props.background || STYLE_DEFAULTS.COLORS.SA_WHITE_B000};
  padding: ${(props: IProps) =>
    props.padding ||
    `${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_5} ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_5}
    ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_3} ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_5}`};
  text-align: ${(props: IProps) => props.textAlign || "center"};
  height: -moz-available; /* For Mozzila */
  height: -webkit-fill-available; /* For Chrome */
  height: fill-available;
  max-height: calc(100% - 40px);
`;

export const ModalSectionInput = styled.div`
  position: fixed;
  height: ${(props: IProps) => props.height ?? "auto"};
  top: ${(props: IProps) => props.top ?? "25%"};
  ${(props: IProps) => props.bottom && `bottom ${props.bottom}`};

  ${(props: IProps) => {
    if (props.width) {
      return `width: ${props.width}; box-sizing: border-box;`;
    } else {
      return `
        width: 80%;
        max-width: 380px;
        `;
    }
  }}

  ${(props: IProps) => {
    if (props.shadow) {
      return `
      -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
      -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);`;
    }
  }}

  border-radius: ${STYLE_DEFAULTS.BUTTONS.SA_BUTTON_RADIUS};
  -webkit-border-radius: ${STYLE_DEFAULTS.BUTTONS.SA_BUTTON_RADIUS};
  -moz-border-radius: ${STYLE_DEFAULTS.BUTTONS.SA_BUTTON_RADIUS};
`;

export const ModalCloseButton = styled.div`
  position: absolute;
  top: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_5};
  right: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_5};
`;

export const ModelTitle = styled.div`
  display: flex;
  margin-right: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_6};
  justify-content: ${(props: IProps) => props.content ?? "center"};
  font-size: 21px;
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.NOTO_SANS};
  font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.MEDIUM};
  margin-bottom: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_5};
`;

export const ModelInfoTitle = styled.div`
  display: flex;
  font-size: 18px;
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.HELVETICA_NEUE_MEDIUM};
  font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.MEDIUM};
  justify-content: ${(props: IProps) => props.content ?? "center"};
  padding-top: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_5};
`;

export const ModelInfoIcon = styled.div`
  padding-right: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_1};
`;

export const ModalButtonSection = styled.div`
  padding-top: ${(props: IProps) =>
    props.mobileButtonsFullWidth ? "10px" : STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_3};
  display: flex;
  flex-direction: ${(props: IProps) => (props.mobileButtonsFullWidth ? "column" : "row")};
  align-items: ${(props: IProps) =>
    props.mobileButtonsFullWidth || !props.buttonPosition ? "center" : props.buttonPosition};
  justify-content: ${(props: IProps) => (props.buttonPosition ? props.buttonPosition : "center")};
`;

export const ModalInfoButtonSection = styled.div`
  padding-top: ${(props: IProps) =>
    props.mobileButtonsFullWidth ? "10px" : STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_3};
  display: flex;
  flex-direction: column;
  align-items: ${(props: IProps) =>
    props.mobileButtonsFullWidth || !props.buttonPosition ? "center" : props.buttonPosition};
  justify-content: ${(props: IProps) => (props.buttonPosition ? props.buttonPosition : "center")};
`;

export const ModalButton = styled.div`
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.HELVETICA_NEUE};
  font-size: 1em;
  padding-top: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_10};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: ${(props: IProps) => (props.mobileButtonsFullWidth ? STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_3 : 0)} 10px 0
    10px;

  ${(props: IProps) => {
    if (props.mobileButtonsFullWidth) {
      return `width: 100%`;
    }
  }}
`;

export const AlertContainer = styled.div`
  position: relative;
  width: 100%;
  display: ${(props: IProps) => props.display || 'flex'};
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  border: 1px solid ${(props: IProps) => alertTypeToIconMap[props.type!].color};
  background-color: ${(props: IProps) => alertTypeToIconMap[props.type!].backgroundColor};
  border-radius: ${STYLE_DEFAULTS.BUTTONS.SA_BUTTON_RADIUS};
  -webkit-border-radius: ${STYLE_DEFAULTS.BUTTONS.SA_BUTTON_RADIUS};
  -moz-border-radius: ${STYLE_DEFAULTS.BUTTONS.SA_BUTTON_RADIUS};
  margin-bottom: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_3};
  padding: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_3};
  box-sizing: border-box;

  ${onHoverStateTransition};

  ${(props: IProps) => {
    if (props.shadow) {
      return `
      -webkit-box-shadow: 0px 0px 0px 1px ${alertTypeToIconMap[props.type!].color};
      -moz-box-shadow: 0px 0px 0px 1px ${alertTypeToIconMap[props.type!].color};
      box-shadow: 0px 0px 0px 1px ${alertTypeToIconMap[props.type!].color};`;
    }
  }}

  ${(props: IProps) => {
    if (props.clickable) {
      return `
      :hover {
        cursor: pointer;
      }`;
    }
  }}
`;

export const AlertMessageContainer = styled.div`
  margin-right: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_3};
`;

export const AlertIcon = styled.div`
  margin-right: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_2};
`;

export const AlertContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const AlertTitle = styled.div`
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.NOTO_SANS};
  font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.MEDIUM};
  font-size: 14px; /** Same for desktop and mobile */
  line-height: 19px;
  margin-bottom: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_1};
`;

export const AlertMessage = styled.div`
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.NOTO_SANS};
  font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.NORMAL};
  font-size: 14px; /** Same for desktop and mobile */
  line-height: 21px;
  color: ${STYLE_DEFAULTS.COLORS.SA_B070};
`;
