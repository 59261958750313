import Modal, { Styles } from 'react-modal';

export const modalStyleBase : Styles = {
    overlay: {
        zIndex: 999,
        background: "rgba(0, 0, 0, 0.08)",
    },
    content: {
        position: 'absolute',
        bottom: "auto",
        border: '1px solid #ccc',
        background: '#fff',
        overflow: 'auto',
        WebkitOverflowScrolling: 'touch',
        borderRadius: '4px',
        outline: 'none',
        padding: '20px'
    },
};
