import useScreenSize from "hooks/useScreenSize";
import * as Styled from "./StyledErrorModal";
import Modal from 'react-modal';
import { DividerHr } from "components/styledComponents/hr";
import ICONS from "constants/icons";
import Button from "components/controls/button/Button";
import Icon from "components/icons";
import { MouseEvent, useEffect } from "react";
import { modalStyleBase } from "constants/modalStyleBase";

interface IErrorModalProps {
    isOpen?: boolean;
    title?: string;
    message?: string;
    onOkClick?: (e: MouseEvent<HTMLButtonElement>) => void;
    onCloseClick?: (e: MouseEvent<HTMLButtonElement>) => void;
}

const ErrorModal = ({isOpen, title, message, onOkClick, onCloseClick}: IErrorModalProps) => {


    const screenSize = useScreenSize();

    const modalStyle = {
        ...modalStyleBase, content: {
            ...modalStyleBase.content,
            top: screenSize.IsMediumScreen ? "5%" : "30%",
            left: screenSize.IsMediumScreen ? "5%" : "30%",
            right: screenSize.IsMediumScreen ? "5%" : "30%",
            bottom: "auto"
        }
    };
    

    return (
        <Modal
            isOpen={isOpen}
            style={modalStyle}
            ariaHideApp={false}
        >
            <Styled.ErrorModalHeader>
                <Styled.ErrorModalTitle>
                    <span>{title}</span> 
                </Styled.ErrorModalTitle>
                <Styled.ErrorModalCloseButton onClick={onCloseClick}>
                    <Icon icon={ICONS.CLOSE} clickable tiny internalSize="16px" />
                </Styled.ErrorModalCloseButton>
            </Styled.ErrorModalHeader>
            <DividerHr></DividerHr>

            <Styled.ErrorModalContent>
                {message}
            </Styled.ErrorModalContent>

            <DividerHr></DividerHr>

            <Styled.ErrorModalFooter>
                <Button primary onClick={onOkClick} value="Ok" />
            </Styled.ErrorModalFooter>
        </Modal>
    );
};

export default ErrorModal;