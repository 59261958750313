import styled, { css } from "styled-components";
import { MEDIA_QUERIES } from "../../../../../constants/mediaQueries";
import STYLE_DEFAULTS from "../../../../../constants/styles";
import Styles from "../../../../../styles";
import { SA_BLUE_HIGHLIGHT } from "../../../../../constants/styles/colors";
import { SA_SPACING_FACTOR_1, SA_SPACING_FACTOR_3 } from "../../../../../constants/styles/spacing";
import { SA_FONT_WEIGHT } from "../../../../../constants/styles/fonts";
import { onHoverStateTransition } from "../../../../../styles/global";

interface IProps {
  isMobile?: boolean;
  selected?: boolean;
}

export const InfiniteScrollLoader = styled.div`
  padding: 4px 4px 4px 4px;
  text-align: center;
`;

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`;

export const SearchBarClose = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  height: 50px;
  right: 10px;
`;

export const SearchBar = styled.div`
  width: calc(100% - 16px);
  margin: 0 8px;
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: flex-start;
  height: 48px;
  align-items: stretch;
  padding-top: ${(props: IProps) => (props.isMobile ? "8px" : "0px")};

  & input:focus {
    width: ${(props: IProps) => (props.isMobile ? "100%" : "298px")};
    height: 44px;
    border-top: 2px solid ${STYLE_DEFAULTS.COLORS.SA_ORANGE_ACTIVE};
    border-bottom: 2px solid ${STYLE_DEFAULTS.COLORS.SA_ORANGE_ACTIVE};
    border-left: 2px solid ${STYLE_DEFAULTS.COLORS.SA_ORANGE_ACTIVE};
    border-right: 2px solid ${STYLE_DEFAULTS.COLORS.SA_ORANGE_ACTIVE};
    -webkit-box-shadow: 0px 0px 4px 0px ${STYLE_DEFAULTS.COLORS.SA_ORANGE_ACTIVE};
    -moz-box-shadow: 0px 0px 4px 0px ${STYLE_DEFAULTS.COLORS.SA_ORANGE_ACTIVE};
    box-shadow: 0px 0px 4px 0px ${STYLE_DEFAULTS.COLORS.SA_ORANGE_ACTIVE};
    background-color: ${STYLE_DEFAULTS.COLORS.SA_WHITE_B000};
  }

  & input {
    font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.NOTO_SANS};
    width: ${(props: IProps) => (props.isMobile ? "100%" : "300px")};
    height: 58px;
    border: none;
    outline: none;
    padding-left: 35px;
    padding-right: 25px;
    background: url("../../../../../assets/images/search.svg") no-repeat scroll left 12px center;
    background-color: ${STYLE_DEFAULTS.COLORS.SA_B010};
    border: none;
    border-radius: 4px;
    height: 48px;
    font-size: ${(props: IProps) => (props.isMobile ? "16px" : "1em")};

    @media ${MEDIA_QUERIES.SM} {
      padding-left: 35px;
      height: 48px;
    }
  }

  & input::placeholder {
    font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.NOTO_SANS};
    font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.NORMAL};
    padding-left: 10px;
    font-size: ${STYLE_DEFAULTS.FONTS.SA_FONT_SIZE.NORMAL};
    color: ${STYLE_DEFAULTS.COLORS.SA_PLACEHOLDER};
    //set to normal and important to support safari
    line-height: normal !important;

    @media ${MEDIA_QUERIES.MD} {
      font-size: ${STYLE_DEFAULTS.FONTS.SA_FONT_SIZE.NORMAL};
    }
  }

  & input:focus::-webkit-input-placeholder {
    opacity: 0;
    ${onHoverStateTransition};
  }
  & input:focus::-moz-placeholder {
    opacity: 0;
    ${onHoverStateTransition};
  }

  & input:focus:-ms-input-placeholder {
    opacity: 0;
    ${onHoverStateTransition};
  }

  & input:focus:-moz-placeholder {
    opacity: 0;
    ${onHoverStateTransition};
  }

  div:nth-of-type(2) {
    border-top-right-radius: ${STYLE_DEFAULTS.BUTTONS.SA_BUTTON_RADIUS};
    border-bottom-right-radius: ${STYLE_DEFAULTS.BUTTONS.SA_BUTTON_RADIUS};
  }
`;

export const SearchResultsContainer = styled.div`
  position: absolute;
  ${(props: IProps) => (props.isMobile ? "padding: 4px 0px;" : "")}
  max-height: 450px;
  top: ${(props: IProps) => (props.isMobile ? "64px" : "50px")};
  background-color: ${STYLE_DEFAULTS.COLORS.SA_WHITE_B000};
  width: calc(100% - 16px);
  z-index: 100; /* Its important to set the index high so that the comments and bookmark icons won't come on top */
  left: 8px;

  @media ${MEDIA_QUERIES.SM} {
    width: calc(100vw - 16px);
    height: 90vh;
    max-height: 90vh;
  }
`;

export const SearchResultsPanel = styled.div`
  max-height: 450px;
  overflow-y: auto;
  border-radius: ${STYLE_DEFAULTS.BUTTONS.SA_BUTTON_RADIUS};
  -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.75);

  @media ${MEDIA_QUERIES.SM} {
    border-radius: 0;
    max-height: 80%;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

export const ResultHeader = styled.div`
  position: relative;
`;

export const ResultPreviewHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.NOTO_SANS};
  background-color: ${STYLE_DEFAULTS.COLORS.SA_BLUE_PREVIEW};
  margin: 4%;
  font-weight: ${SA_FONT_WEIGHT.NORMAL};
  font-size: ${SA_SPACING_FACTOR_3};

  -webkit-box-shadow: 0px 0px ${SA_SPACING_FACTOR_1} 0px ${STYLE_DEFAULTS.COLORS.SA_BLUE};
  -moz-box-shadow: 0px 0px ${SA_SPACING_FACTOR_1} 0px ${STYLE_DEFAULTS.COLORS.SA_BLUE};
  box-shadow: 0px 0px ${SA_SPACING_FACTOR_1} 0px ${STYLE_DEFAULTS.COLORS.SA_BLUE};
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
`;

export const ResultUnderline = styled(Styles.Underline)`
  height: 4px;
  background-color: ${STYLE_DEFAULTS.COLORS.SA_ORANGE_ACTIVE};
  margin-top: 10px;
`;

export const ResultTitleHeader = styled.div`
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.HELVETICA_NEUE};
  font-weight: ${STYLE_DEFAULTS.FONTS.SA_FONT_WEIGHT.BOLD};
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 0.6875rem;
  line-height: 0.8125rem;
  margin: 20px 0 0px;
  width: fit-content;
`;

export const ResultContainer = styled.div``;

export const ResultPhrase = styled.div`
  background-color: ${SA_BLUE_HIGHLIGHT};
  display: inline;
`;

export const ResultItem = styled.div`
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.NOTO_SANS};
  letter-spacing: 0;
  font-size: 16px; // Same in desktop and mobile
  line-height: 1.5;
  padding: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_2} ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_3}
    ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_3} ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_3};
  overflow-wrap: break-word;
  color: ${STYLE_DEFAULTS.COLORS.SA_B070};

  background-color: ${(props: IProps) => (props.selected ? STYLE_DEFAULTS.COLORS.SA_BLUE_LIGHT_1 : "")};

  :hover {
    cursor: pointer;
    ${onHoverStateTransition};
  }
`;

export const ResultSection = styled.div`
  border-top: 0.5px solid ${STYLE_DEFAULTS.COLORS.SA_B015};
  border-bottom: 1px solid ${STYLE_DEFAULTS.COLORS.SA_B015};
  padding-top: 6px;
  color: #808285;
  vertical-align: middle;
  line-height: 22px;
  font-size: 14px;
  padding-left: 4px;
  font-family: ${STYLE_DEFAULTS.FONTS.SA_FONT_FAMILY.NOTO_SANS};
  background-color: #fcfcfc;
  min-height: 30px;
  weight: 500;
`;
