import styled from "styled-components";
import STYLE_DEFAULTS from "../../constants/styles";
import IIcon from "../../models/icon";
import { MEDIA_QUERIES } from "../../constants/mediaQueries";

const SIZE_MAP = {
  TINY: "20px",
  SMALL: "40px",
  MEDIUM: "48px",
  REGULAR: "60px",
  LARGE: "80px",
};

const size = (props: IIcon) => {
  if (props.tiny) {
    return SIZE_MAP.TINY;
  }
  if (props.small) {
    return SIZE_MAP.SMALL;
  }
  if (props.medium) {
    return SIZE_MAP.MEDIUM;
  }
  if (props.large) {
    return SIZE_MAP.LARGE;
  }
  if (props.size) {
    return props.size;
  }

  return SIZE_MAP.REGULAR;
};

export const IconControl = styled.div<IIcon>`
  width: ${(props: IIcon) => size(props)};
  height: ${(props: IIcon) => size(props)};
  transform: ${(props: IIcon) => props.rotate};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props: IIcon) => (props.clickable ? "pointer" : "default")};
  position: relative;

  svg {
    pointer-events: none;
  }

  ${(props: IIcon) => {
    if (props.height && props.width) {
      return `
          & svg {
            width: ${props.width};
            height: ${props.height};
          }
          `;
    } else if (props.internalSize) {
      return `
      & svg {
        width: ${props.internalSize};
        height: ${props.internalSize};
      }
    `;
    }
  }}
  ${(props: IIcon) =>
    props.disabled &&
    `
    & svg g,
    & svg rect,
    & svg path {
        fill: ${STYLE_DEFAULTS.COLORS.SA_DISABLED};
        stroke: ${props.strokeColor};
    }
    & g {
        opacity: 0.8;
    }
  `}
    ${(props: IIcon) =>
    props.color &&
    `
    & svg g,
    & svg rect,
    & svg path {
        fill: ${props.color};
        stroke: ${props.strokeColor};
    }
  `}
   
   ${(props: IIcon) =>
    props.background &&
    `
    background-color: ${props.background}
  `};

  /* There is a discrepance between library sketch and mobile sketch in mobile menu height,
       check with Shelsa and change to XS in needed. Otherwise remove xsScreenSize and xsScreenInternalSize
       properties and use mobile indication */
  @media ${MEDIA_QUERIES.SM} {
    ${(props: IIcon) => {
      let style = "";

      if (props.xsScreenSize) {
        style += `
                width: ${props.xsScreenSize};
                height: ${props.xsScreenSize};
            `;
      }

      if (props.xsScreenInternalSize) {
        style += `
              & svg {
                width: ${props.xsScreenInternalSize};
                height: ${props.xsScreenInternalSize};
              }
            `;
      }

      return style;
    }}
  }
`;

export const IconContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: ${(props: IIcon) => props.height || props.internalSize || "24px"};
  width: ${(props: IIcon) => props.width || props.internalSize || "24px"};
`;

interface IProps {
  iconSize: string;
}

export const IconBadge = styled.div`
  position: absolute;
  top: -8px; /* Half the size of the counter badge */
  left: ${(props: IProps) => props.iconSize || "24px"}; /* Full height of the icon near which badge is placed */
`;

export const SelectedIndication = styled.div`
  background-color: ${STYLE_DEFAULTS.COLORS.SA_ORANGE_ACTIVE};
  width: 100%;
  height: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_1};
  position: absolute;
  bottom: 0;
`;
