import React from "react";
import { ISegmentPackage } from "../../../services/segmentProvider/models/segmentPackage";
import { ShopTabItem } from "./style";

interface IShopTabsProps {
  children?: any;
  //the below fields are used in <ShopTabs>
  label?: string;
  icon?: any;
  activeTab?: boolean;
  disabled?: boolean;
  onClick?: (event: any) => void;
  segmentPackage?: ISegmentPackage;
}

const ShopTab = (props: IShopTabsProps): React.ReactElement => {
  const { children } = props;

  return <ShopTabItem>{children}</ShopTabItem>;
};

export default ShopTab;
