export enum AddressType {
  Unknown = "Unknown",
  Billing = "Billing",
  Shipping = "Shipping",
}

export interface IUserAddress {
  addressId?: number;
  firstName?: string;
  lastName?: string;
  companyName?: string;
  line1?: string;
  line2?: string;
  suburb?: string;
  state?: string;
  country?: string;
  postCode?: string;
  phone?: string;
  type?: AddressType;
  isAddressValid?: boolean;
  createdAt?: number;
  deletedAt?: number;
  isAusTaxResident?: boolean;
  ownsAusBusiness?: boolean;
  hasAusInvestment?: boolean;
  hasAusPermanentEstablishment?: boolean;
}

export interface IUserCard {
  userCardId: number;
  maskedCardNumber: string;
  type: string;
  nameOnCard: string;
  expiryMonth: string;
  expiryYear: string;
  attachedToOrder: boolean;
}

export interface IUserInfo {
  id: number;
  authId: string;
  email: string;
  phone: string;
  password: string;
  firstName?: string;
  lastName?: string;
  companyName?: string;
  companySize?: number;
  jobRole?: string;
  industrySector?: string;
  tousVersion?: string;
  addresses?: Array<IUserAddress>;
  cartCode?: string;
  marketingCommunicationPref: boolean;
  trackingCommunicationPref: boolean;
  createdAtDateTime?: Date;
  userInitials?: string;
}

export function getAddressTypeString(addressType: AddressType, upperCase = false) {
  if (upperCase) {
    return addressType === AddressType.Shipping ? "Delivery" : "Billing";
  }
  return addressType === AddressType.Shipping ? "delivery" : "billing";
}
