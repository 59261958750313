const SYSTEM_ERRORS = {
  GENERIC_ERROR: "generic-error",
  DELIVERY_ERROR: "delivery-service-error",
  CART_ERROR: "cart-error",
  CART_ITEM_ALREADY_OWNED_ERROR: "cart-item-already-owned-error",
  CART_ITEM_INVALID_COMPANY_SIZE_ERROR: "cart-item-invalid-company-size-error",
  CART_ITEM_EMPTY_COMPANY_SIZE_ERROR: "cart-item-empty-company-size-error",
  CART_CONFLICT: "cart-conflict-error", // user tried to add SS (single sale) to SBS (set), SBS to SS or SBS to SBS
  BOOKMARK_ERROR: "bookmark-error",
  HIGHLIGHT_ERROR: "highlight-error",
  COMMENT_ERROR: "comment-error",
  USER_DATA_ERROR: "user-data-error",
  RESET_PASSWORD_ERROR: "reset-password-error",
  CHANGE_PASSWORD_ERROR: "change-password-error",
  READ_KONTENT_DATA_ERROR: "read-kontent-data-error",
  CART_PRICE_CONFLICT: "PriceSource of Product doesn't match PriceSource of OrderProduct",
};

export default SYSTEM_ERRORS;
