import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { IPublicClientApplication } from "@azure/msal-browser";
import { loginRequest, msalInstance } from "../authConfig";
import * as sumoLog from 'utils/logUtils';
import { oidcInstance } from "authConfigIAM";
import { featureFlagNames } from "services/appConfigService";
import * as appConfigService from 'services/appConfigService';

const DEFAULT_REQUEST_TIMEOUT_MSEC = 30000;
const DEFAULT_HEADER = {
  'Cache-Control': 'no-cache',
  'Pragma': 'no-cache',
  'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
};

export interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  // custom attribute that let's the controller know to encode the query params. Default is true
  encodeURI?: boolean;
}



//generic API function
async function callApi(config: CustomAxiosRequestConfig) {
  const options: AxiosRequestConfig = {
    baseURL: config.baseURL,
    url: config.url ?? "",
    method: config.method,
    responseType: config.responseType,
    timeout: config.timeout ?? DEFAULT_REQUEST_TIMEOUT_MSEC,
    withCredentials: config.withCredentials, // By default is false, for external APIs
    // Set low timeout to test connection error message
    // timeout: 2,
  };

  if (config.data) {
    options.data = config.data;
  }

  let isIAM = await appConfigService.getConfigByKey(featureFlagNames.IAMAuthentication);

  let token = isIAM ? await getIAMToken() : await getB2CToken();
  
  // add keep-alive header to all request
  config.headers = {
    Authorization: `Bearer ${token}`,
    ...DEFAULT_HEADER
  };

  
  options.headers = config.headers;
  
  

  if (config.params) {
    options.url += convertToQueryParam(config.params, config.encodeURI);
  }

  /*
  if (config.withCredentials) {
    options.withCredentials = config.withCredentials;
  }
  */


  return axios(options)
    .then((response: AxiosResponse) => {
      return response;
    })
    .catch((error: any) => {
      const response = error.response ?? error.message;
      const requestData = options.data ? JSON.stringify(options.data) : "";
      const baseErrorMessage = `Error occurred. Error message: ${error?.message}, Request base url: ${options?.baseURL}, Request url: ${options?.url}, Request method: ${options?.method}, Request header: ${options?.headers?.Authorization}, Request data: ${requestData}`;
      if(error.response) {
        sumoLog.log(`${baseErrorMessage}, error details: ${error?.response?.data?.message}, error status: ${error?.response?.data?.statusCode}`);
      } else if (error.message) {
        sumoLog.log(baseErrorMessage);
      }
      return response;
    });
}

const convertToQueryParam = (obj: any, encodeURI = true) => {
  const str = [];
  for (const p in obj)
    if (obj.hasOwnProperty(p) && obj[p]) {
      if (encodeURI) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      } else {
        str.push(p + "=" + obj[p]);
      }
    }
  return `?${str.join("&")}`;
}

const acquireAccessToken = async (msalInstance : IPublicClientApplication) => {
  try {
    const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
    const accounts = msalInstance.getAllAccounts();

    if (!activeAccount && accounts.length === 0) {
        /*
        * User is not signed in. Throw error or wait for user to login.
        * Do not attempt to log a user in outside of the context of MsalProvider
        */   
    }
    const request = {
        ...loginRequest,
        account: activeAccount || accounts[0]
    };

    const authResult = await msalInstance.acquireTokenSilent(request);

    return authResult.accessToken
  } catch(error) {
    sumoLog.logException(error); 
    msalInstance.logoutRedirect({ postLogoutRedirectUri: "/" });
  } 
};

export const getIAMToken = async () => {
  try {
      let iamUser = await oidcInstance.getUser();
      if(iamUser == null) throw 'failed getting iam token';
      return  iamUser?.access_token; 
  } catch(error) {
      oidcInstance.signoutRedirect();
  }
}

export const getB2CToken = async () => {
  try{
      return  await acquireAccessToken(msalInstance);
  }
  catch (error) {
      msalInstance.logoutRedirect({ postLogoutRedirectUri: "/" });
  }
}

export default callApi;
