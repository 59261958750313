import React, { useRef } from "react";
import ILink from "../../../models/link";
import Icon from "../../icons";
import { LinkContainer, LinkIcon, LinkValue, ButtonContainer } from "./style";
import STYLE_DEFAULTS from "../../../constants/styles";
import { BUTTON_VALUE_CLASS_NAME } from "../../../styles/typography";
import Loader from "../../loader/loader";
import SeoLink from "../seoLink/SeoLink";

const renderText = (props: ILink) => {
  const { notoSans, text, small, color, disabled, resizeText, textSize, heavy } = props;

  if (!text) {
    return null;
  }

  return (
    <LinkValue
      color={color}
      disabled={disabled}
      className={BUTTON_VALUE_CLASS_NAME}
      small={small}
      resizeText={resizeText}
      notoSans={notoSans}
      textSize={textSize}
      heavy={heavy}
    >
      {text}
    </LinkValue>
  );
};

const renderIcon = (props: ILink) => {
  if (!props.icon) {
    return null;
  }

  return (
    <LinkIcon iconLast={props.iconLast} small={props.small}>
      <Icon
        icon={props.icon}
        clickable={!props.disabled}
        tiny
        size={props.small ? "16px" : "20px"}
        internalSize={props.small ? "16px" : "18px"}
        noRole
      />
    </LinkIcon>
  );
};

// Either URL or onClick should be provided, in case both - URL is used
const LinkButton = (props: ILink) => {
  const {
    iconLast,
    url,
    primary,
    secondary,
    red,
    inverted,
    white,
    disabled,
    onClick,
    isLoading,
    inFocus,
    width,
    small,
  } = props;

  const segmentReference = useRef<any>(null);

 

  if (isLoading) {
    const size = {
      height: small ? "10px" : "15",
      width: small ? "10px" : "15",
    };
    return (
      <LinkContainer primary={primary} disabled width={width}>
        <Loader color={STYLE_DEFAULTS.COLORS.SA_B030} {...size} />
      </LinkContainer>
    );
  }

  if (url) {
    return (
      <SeoLink href={url}>
        <LinkContainer
          primary={primary}
          secondary={secondary}
          red={red}
          inverted={inverted}
          white={white}
          disabled={disabled}
          className={inFocus ? "in-focus" : ""}
          value={props.text}
          role="button"
          aria-label={props.text}
          ref={segmentReference}
        >
          {iconLast ? renderText(props) : renderIcon(props)}
          {iconLast ? renderIcon(props) : renderText(props)}
        </LinkContainer>
      </SeoLink>
    );
  }

  return (
    <ButtonContainer
      ref={segmentReference}
      onClick={onClick}
      primary={primary}
      secondary={secondary}
      red={red}
      white={white}
      inverted={inverted}
      disabled={disabled}
      className={inFocus ? "in-focus" : ""}
      value={props.text}
      aria-label={props.text}
    >
      {iconLast ? renderText(props) : renderIcon(props)}
      {iconLast ? renderIcon(props) : renderText(props)}
    </ButtonContainer>
  );
};

export default LinkButton;
