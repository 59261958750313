
import { UserManager, UserManagerSettings, WebStorageStateStore } from "oidc-client-ts";
import env from "./models/Configuration";

const oidcConfig : UserManagerSettings = {
    authority: env?.IAM.config.authority,
    client_id: env?.IAM.config.client_id,
    redirect_uri: env?.IAM?.config.redirect_uri,
    userStore:  new WebStorageStateStore({ store: window.localStorage }),
    scope: "openid profile offline_access",
    refreshTokenAllowedScope: "",
    automaticSilentRenew: false // this feature relies on token response having "expires_at", which IAM has not.
  }

  export const oidcInstance = new UserManager(oidcConfig);