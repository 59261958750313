import React from "react";
import { ReaderStoreClass } from "../../../stores/ReaderStore";

export interface IReaderLayoutContext {
  readerStore: ReaderStoreClass;
  isMobile: boolean;
  isPreview: boolean;
  designationId: string | string[];
  isSideMenuOpen: boolean;
  isSidePanelClosingTransition: boolean;
  selectedMenuIndex: number;
  isReaderLoading: boolean;
  setSelectedMenuIndex: (n: number) => void;
  setSideMenuOpen: (state: boolean) => void;
  setSidePanelClosingTransition: (state: boolean) => void;
  setIsReaderLoading: (state: boolean) => void;
  userInitials: string;
  showBackButton: boolean;
}

// Set defaults
const contextValue: IReaderLayoutContext = {
  readerStore: new ReaderStoreClass(),
  isPreview: true,
  isMobile: true,
  designationId: "",
  isSideMenuOpen: false,
  isSidePanelClosingTransition: false,
  selectedMenuIndex: 0,
  isReaderLoading: false,
  setSelectedMenuIndex: (n) => undefined,
  setSideMenuOpen: (state) => undefined,
  setSidePanelClosingTransition: (bool) => undefined,
  setIsReaderLoading: (bool) => undefined,
  userInitials: "",
  showBackButton: false
};

export default React.createContext(contextValue);
