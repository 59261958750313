import STYLE_DEFAULTS from "constants/styles";
import styled from "styled-components";

export const LogOutContainer = styled.div`

`;

export const LogOutFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;

  button {
    width: 120px;
  }

  & > :nth-child(2) {
    margin-left: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_3};
  }
`;

export const LogOutButtonContainer = styled.div`
`;

export const CancelButtonContainer = styled.div`
`;