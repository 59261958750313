import React, { Fragment, useEffect } from "react";
import {
  SideMenuItemsContainer,
  SideMenuItemsTop,
  SideMenuIcon,
  SideMenuIconSelectIndicator,
  SideMenuPanelContainerMobile,
  SideMenuPanelContainerDesktop,
} from "./style";
import ICONS from "../../../../constants/icons";
import { READER_MENU_ITEMS } from "../../../../constants/readerMenuItems";
import { IReaderLayoutContext } from "../readerLayoutContext";
import ReactTooltip from "react-tooltip";
import SideMenuToc from "./sideMenuToc";
import SideMenuMarkers from "./sideMenuMarkers/sideMenuMarkers";
import ReaderMenuIcon from "../../controls/ReaderMenuIcon/ReaderMenuIcon";
import SideMenuOffline from "./sideMenuOffline";
import TRACKING_EVENTS from "../../../../services/segmentProvider/models/trackingEvents";
import BlankModal, { ETransitionDirection } from "../../../controls/modal/BlankModal";
import { isAdmin } from "../../../../helpers/reader";
import * as segmentUtils from "utils/segmentUtils";

interface ICurrentState {
  isPreview: boolean;
  selectedMenuIndex: number;
  setSelectedMenuIndex: (n: number) => void;
  isMobile: boolean;
  isSideMenuOpen: boolean;
  onClick: (n: number) => void;
}

const MENU_STRUCTURE = [
  {
    menuItemIndex: READER_MENU_ITEMS.TOC,
    icon: ICONS.TOC,
    displayInMobile: true,
    displayInDesktop: true,
    segmentPackage: TRACKING_EVENTS.READER.TOC,
  },
  {
    menuItemIndex: READER_MENU_ITEMS.BOOKMARKS,
    icon: ICONS.BOOKMARKS,
    disabledMessage: "My markers is enabled on the<br/>paid version of this publication",
    displayInMobile: true,
    displayInDesktop: true,
    segmentPackage: TRACKING_EVENTS.READER.MARKERS,
  }
  //,PRJSTE-923 - Hide download button
  // {
  //   menuItemIndex: READER_MENU_ITEMS.DOWNLOAD,
  //   icon: ICONS.DOWNLOAD,
  //   disabledMessage: "Saving for offline usage is enabled on the<br/>paid version of this publication",
  //   displayInMobile: true,
  //   displayInDesktop: true,
  // },
  // PRJPROD-1408 - Hide settings button (settings not implemented yet)
  //   {
  //     menuItemIndex: READER_MENU_ITEMS.SETTINGS,
  //     icon: ICONS.SETTINGS,
  //     disabledMessage: "Render settings is enabled on the paid<br/>version of this publication",
  //     displayInMobile: false,
  //     displayInDesktop: true,
  //   },
];

export function onSidePanelClose(readerLayoutContext: IReaderLayoutContext) {
  // Delay making menu invisible to allow animation on menu close
  //setTimeout(() => {
  // Panel closing transition ended
  readerLayoutContext.setSidePanelClosingTransition(false);
  //}, STYLE_DEFAULTS.ANIMATIONS.MENU_TRANSITION_DURATION_SEC * 1000);
  readerLayoutContext.setSideMenuOpen(false);
  readerLayoutContext.setSelectedMenuIndex(-1);
  // Panel closing transition started
  readerLayoutContext.setSidePanelClosingTransition(true);
}

const renderSideMenuItem = (currentState: ICurrentState, itemIndex: number) => {
  const menuItemData = MENU_STRUCTURE[itemIndex];
  let isDisabled = currentState.isPreview
    ? currentState.isPreview && menuItemData.disabledMessage !== undefined
    : false;
  // if the request is coming from backoffice we need to display full reader but we disabled the options..
  if (isAdmin() && menuItemData.disabledMessage !== undefined) {
    isDisabled = true;
  }
  const clickedItemIndex = menuItemData.menuItemIndex;
  const sideMenuIconElements = isDisabled
    ? {
        isDisabled: isDisabled,
      }
    : {
        selected: currentState.selectedMenuIndex === menuItemData.menuItemIndex,
        onClick: () => currentState.onClick(clickedItemIndex),
        isDisabled: isDisabled,
      };

  return (
    <SideMenuIcon key={itemIndex} {...sideMenuIconElements}>
      <SideMenuIconSelectIndicator id="selectIndicator" />
      <a data-tip={menuItemData.disabledMessage} data-for={`side-menu-tooltip-${itemIndex}`}>
        <ReaderMenuIcon
          icon={menuItemData.icon}
          isMobile={currentState.isMobile}
          isMenuItemActive={false}
          isLoggedInMenu={false}
          isSideMenu={false}
          isDisabled={isDisabled}
        />
      </a>
      <ReactTooltip
        id={`side-menu-tooltip-${itemIndex}`}
        place="right"
        type="dark"
        effect="solid"
        html
        disable={!isDisabled}
        delayHide={0}
      />
    </SideMenuIcon>
  );
};

const renderSideMenuItems = (currentState: ICurrentState) => {
  return (
    <SideMenuItemsContainer isMobile={currentState.isMobile}>
      <SideMenuItemsTop>
        {MENU_STRUCTURE.filter(
          (data) => data.displayInDesktop !== currentState.isMobile || data.displayInMobile === currentState.isMobile,
        ).map((_menuItem, index) => renderSideMenuItem(currentState, index))}
      </SideMenuItemsTop>
    </SideMenuItemsContainer>
  );
};

const renderSideMenuPanelContents = (currentState: ICurrentState) => {
  switch (currentState.selectedMenuIndex) {
    case READER_MENU_ITEMS.TOC:
      return <SideMenuToc />;
    case READER_MENU_ITEMS.BOOKMARKS:
      return <SideMenuMarkers />;
    case READER_MENU_ITEMS.DOWNLOAD:
      return <SideMenuOffline />;
    case READER_MENU_ITEMS.SETTINGS:
      return <div />;
  }
};

const renderSideMenuDesktop = (currentState: ICurrentState) => {
  const isScrollable = currentState.selectedMenuIndex === READER_MENU_ITEMS.TOC;
  return (
    <>
      {renderSideMenuItems(currentState)}
      <SideMenuPanelContainerDesktop
        isSideMenuOpen={currentState.isSideMenuOpen}
        isPreview={currentState.isPreview}
        isScrollable={isScrollable}
      >
        {renderSideMenuPanelContents(currentState)}
      </SideMenuPanelContainerDesktop>
    </>
  );
};

const renderSideMenuMobile = (currentState: ICurrentState) => {
  const isScrollable = currentState.selectedMenuIndex === READER_MENU_ITEMS.TOC;
  return (
    <BlankModal
      id="toc-mobile"
      isOpen={currentState.isMobile}
      hasCloseButton={false}
      hasCancelButton={false}
      hasPrimaryButton={false}
      scrollable={false}
      transitionDirection={ETransitionDirection.leftRight}
    >
      <div style={{ display: "flex" }}>
        {renderSideMenuItems(currentState)}
        <SideMenuPanelContainerMobile
          isSideMenuOpen={currentState.isSideMenuOpen}
          isPreview={currentState.isPreview}
          isScrollable={isScrollable}
        >
          {renderSideMenuPanelContents(currentState)}
        </SideMenuPanelContainerMobile>
      </div>
    </BlankModal>
  );
};

interface IProps {
  readerLayoutContext: IReaderLayoutContext;
}

const SideMenu: React.FC<IProps> = (props: IProps) => {
  const { readerLayoutContext } = props;

  useEffect(() => {
    // It takes a while for context isMobile to get value, so call useEffect if this changes
    if (
      !readerLayoutContext.isMobile &&
      !readerLayoutContext.isSideMenuOpen &&
      readerLayoutContext.selectedMenuIndex === -1
    ) {
      readerLayoutContext.setSideMenuOpen(true);
      readerLayoutContext.setSelectedMenuIndex(READER_MENU_ITEMS.TOC);
    }
  }, [readerLayoutContext.isMobile]);

  const onClick = (clickedItemIndex: number) => {
    const nextSideMenuOpen =
      clickedItemIndex !== readerLayoutContext.selectedMenuIndex || !readerLayoutContext.isSideMenuOpen;

    if (!nextSideMenuOpen) {
      // Delay making menu invisible to allow animation on menu close
      onSidePanelClose(readerLayoutContext);
    } else {
      readerLayoutContext.setSideMenuOpen(nextSideMenuOpen);
    }

    if (!nextSideMenuOpen && !readerLayoutContext.isMobile) {
      // Calling readerLayoutContext.setSelectedMenuIndex(-1); will empty the panel, we need to keep
      // the content for the duration of the  close panel animation
      //setTimeout(() => {
      readerLayoutContext.setSelectedMenuIndex(-1);
      //}, STYLE_DEFAULTS.ANIMATIONS.MENU_TRANSITION_DURATION_SEC * 1000);
    } else {
      // On opening the panel, show the content immediately to be available for open  panel animation
      readerLayoutContext.setSelectedMenuIndex(clickedItemIndex || 0);
    }

    const menueItem = MENU_STRUCTURE.find(menuItem => menuItem.menuItemIndex === clickedItemIndex);
    segmentUtils.trackButtonClickAction(menueItem?.segmentPackage?.event??"", menueItem?.segmentPackage?.category??"", menueItem?.segmentPackage?.eventName??"", menueItem?.segmentPackage?.eventName??"");
  };

  const currentState = {
    isPreview: readerLayoutContext.isPreview,
    selectedMenuIndex: readerLayoutContext.selectedMenuIndex,
    setSelectedMenuIndex: readerLayoutContext.setSelectedMenuIndex,
    isMobile: readerLayoutContext.isMobile,
    isSideMenuOpen: readerLayoutContext.isSideMenuOpen,
    onClick,
  };

  // Vertical menu should always be rendered on large screens, in mobile - based on user actions
  const isRenderMenu = !currentState.isMobile || currentState.isSideMenuOpen;

  if (!isRenderMenu) {
    return null;
  }

  return (
    <Fragment>
      {!currentState.isMobile && renderSideMenuDesktop(currentState)}
      {currentState.isMobile && renderSideMenuMobile(currentState)}
    </Fragment>
  );
};

export default SideMenu;
