//MODIFIED_FILE
import React, { useEffect, useRef } from "react";
import { ButtonContainer, ButtonIcon, ButtonValue } from "./style";
import IButton from "../../../models/button";
import Icon from "../../icons";
import STYLE_DEFAULTS from "../../../constants/styles";
import { BUTTON_DEFAULTS } from "../../../constants/buttons";
import { BUTTON_VALUE_CLASS_NAME } from "../../../styles/typography";
import Loader from "../../loader/loader";

const calculateButtonWidth = (props: IButton) => {
  return props.width || BUTTON_DEFAULTS.WIDTH;
};

const calculateButtonHeight = (props: IButton) => {
  const { small } = props;

  return small ? BUTTON_DEFAULTS.HEIGHT_SMALL : BUTTON_DEFAULTS.HEIGHT;
};

function renderButtonContentContainer(props: any): React.ReactElement {
  const { buttonValue, icon, buttonTextColor, deleteButton, disabled, small, isLoading } = props;

  if (isLoading) {
    const size = small ? "16px" : "24px";
    return <Loader color={STYLE_DEFAULTS.COLORS.SA_B030} height={size} width={size} grey />;
  }

  const iconColor = disabled
    ? STYLE_DEFAULTS.COLORS.SA_DISABLED
    : deleteButton
    ? STYLE_DEFAULTS.COLORS.SA_WHITE_B000
    : STYLE_DEFAULTS.COLORS.SA_BLACK_B100;

  return (
    <>
      {icon && (
        <ButtonIcon withMargin={(!!icon && !!buttonValue) || false} small={small}>
          <Icon
            icon={icon}
            clickable={!disabled}
            size={small ? "16px" : "20px"}
            internalSize={small ? "16px" : "20px"}
            color={iconColor}
            noRole
          />
        </ButtonIcon>
      )}
      {buttonValue && (
        <ButtonValue className={BUTTON_VALUE_CLASS_NAME} color={buttonTextColor} small={small}>
          {buttonValue}
        </ButtonValue>
      )}
    </>
  );
}

/**
 * Style primary, secondary, delete buttons
 *
 * Note:
 *      1. Use class names to address internal button elements from top element
 *
 * @param props
 */
function Button(props: IButton): React.ReactElement {
  const {
    buttonId,
    value,
    icon,
    onClick,
    primary,
    secondary,
    deleteButton,
    deleteButtonValue,
    disabled,
    small,
    isLoading,
    isInFocus,
  } = props;
  const buttonValue = deleteButton && !deleteButtonValue ? "Delete" : value;
  const buttonWidth = calculateButtonWidth(props);
  const buttonHeight = calculateButtonHeight(props);
  const buttonTextColor = deleteButton
    ? STYLE_DEFAULTS.COLORS.SA_WHITE_B000
    : disabled
    ? STYLE_DEFAULTS.COLORS.SA_DISABLED
    : STYLE_DEFAULTS.COLORS.SA_BLACK_B100;

  const eventReference = useRef<HTMLButtonElement>(null);

  const clickEvent = (e: any) => {
    if (!disabled && onClick) {
      onClick(e);
    }
  };

  

  // add keyup event listeners
  useEffect(() => {
    const callBack = (e: any) => e.key === "Enter" && clickEvent(e);
    if (!disabled) {
      eventReference.current?.addEventListener("keyup", callBack);
    }
    return () => {
      window.document.removeEventListener("keyup", callBack);
    };
  }, [onClick, disabled]);

  const accessibleAttributes = {
    value: buttonValue || icon,
    name: buttonValue || icon,
    "aria-label": buttonValue || icon,
    role: "button",
    tabIndex: 0,
  };

  return (
    <ButtonContainer
      id={buttonId}
      ref={eventReference}
      onClick={(e) => clickEvent(e)}
      primary={!!primary}
      secondary={!!secondary}
      deleteButton={!!deleteButton}
      disabled={!!disabled}
      isLoading={isLoading}
      width={buttonWidth}
      height={buttonHeight}
      small={small}
      isInFocus={isInFocus}
      {...accessibleAttributes}
    >
      {renderButtonContentContainer({
        primary,
        secondary,
        buttonValue,
        icon,
        buttonTextColor,
        deleteButton,
        disabled,
        small,
        isLoading,
      })}
    </ButtonContainer>
  );
}

export default Button;
