import React from "react";
import { CounterBadgeContainer, CounterBadgeValue } from "./style";
import { Loader } from "../../loader/loader";

interface ICounter {
  value: number;
  isLoading: boolean;
}

const CounterBadge = (props: ICounter) => {
  const { value, isLoading } = props;

  if (isLoading) {
    return <Loader height="7px" width="7px" />;
  }

  return (
    <CounterBadgeContainer>
      <CounterBadgeValue>{value}</CounterBadgeValue>
    </CounterBadgeContainer>
  );
};

export default CounterBadge;
