import styled from "styled-components";
import STYLE_DEFAULTS from "../../../../constants/styles";
import { onHoverStateTransition } from "../../../../styles/global";
import { InFocus } from "../../../../styles/typography";

interface IProps {
  bookmarked?: boolean;
  budgeActive?: boolean;
  inFocus?: boolean;
  isMobile?: boolean;
}

export const SectionBookmarkContainer = styled.div`
  :hover {
    cursor: pointer;
    ${onHoverStateTransition};
  }

  ${(props: IProps) => {
    let style = `
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
      -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
      background-color: ${STYLE_DEFAULTS.COLORS.SA_WHITE_B000};
      position: absolute;
      top: ${props.isMobile ? "10px" : "-12px"};
      left: ${STYLE_DEFAULTS.SPACING.SA_SPACING_FACTOR_5};
      `;

    if (props.isMobile || props.inFocus || props.bookmarked) {
      if (props.budgeActive) {
        style = `${style}
        ${InFocus}
            `;
      }

      return style;
    } else {
      return `display: none;`;
    }
  }}
`;
