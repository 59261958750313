function ScrollIntoView(nav: HTMLElement, scrollContainerId: string, paddingTop = 0) {
  
    const documentParent = document.getElementById(scrollContainerId);
    
    nav.scrollIntoView(true);

    //We need to wait for the scrollIntoView to finish
    setTimeout(function () {

      if (!documentParent?.scrollBy) {
        if (documentParent) {
          documentParent.scrollTop = -paddingTop;
          documentParent.scrollLeft = 0;
        }
      } else {
        documentParent?.scrollBy(0, -paddingTop);
      }
    
      window.scroll(0, 0); //handle side menu/header scroll
    }, 500);
    
}

export function ScrollToTop(scrollContainerId: string) {
  const documentParent = document.getElementById(scrollContainerId);
  documentParent?.scrollTo(0, 0);
}

function ScrollIntoViewViaId(id: any, scrollContainerId: string, paddingTop?: number) {
  const nav = window.document.getElementById(id);
  if (!nav) return false;
  ScrollIntoView(nav, scrollContainerId, paddingTop);
  return true;
}

function ScrollIntoViewViaClassName(id: any, scrollContainerId: string, paddingTop?: number) {
  const nav = window.document.getElementsByClassName(id);
  if (!nav || !nav[0]) return false;
  ScrollIntoView(nav[0] as HTMLElement, scrollContainerId, paddingTop);
  return true;
}

export function ScrollIntoViewViaIdOrClassName(id: any, scrollContainerId: string, paddingTop?: number) {
  setTimeout(function () {
    if (ScrollIntoViewViaId(id, scrollContainerId, paddingTop)) return true;
    if (ScrollIntoViewViaClassName(id, scrollContainerId, paddingTop)) return true;
    return false;
  }, 200);
}
